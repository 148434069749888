import { getLandingUrl } from '@polygence/common';
import { UserState } from '@polygence/common';

import { MentorTile } from 'src/components/Tiles/Mentors/MentorsTile';
import ProfileTile from 'src/components/Tiles/ProfileTile';
import { StudentJourneyTile } from 'src/components/Tiles/StudentJourney/StudentJourneyTile';
import { TileNavigationItem } from 'src/utils/navigation';
import { getTracker } from 'src/utils/tracking/factory';

interface getParentNavItemsProps {
  currentUser: UserState;
  screened: boolean;
  studentJourneyTileSections: string[] | undefined;
  opportunitiesEnabled: boolean;
}

export const getParentTileNavItems = ({
  currentUser,
  screened,
  studentJourneyTileSections,
  opportunitiesEnabled,
}: getParentNavItemsProps): TileNavigationItem[] => {
  const studentHasJourneySections =
    !!studentJourneyTileSections && studentJourneyTileSections.length > 0;

  return [
    {
      name: 'profile-tile',
      TileComponent: (
        <ProfileTile
          userType="parent"
          screened={screened}
          completed={currentUser.applicationCompleted}
          onClick={() => {
            return getTracker().track('Dashboard Tile Clicked', {
              label: 'profile-tile',
            });
          }}
        />
      ),
      cols: 4,
      show: !studentHasJourneySections,
    },
    {
      name: 'my-children',
      label: 'My Children',
      href: '/parent/dashboard',
      illustration: 'my-children',
      cols: 4,
    },
    {
      name: 'student-journey',
      TileComponent: (
        <StudentJourneyTile
          sections={studentJourneyTileSections}
          title={`${(currentUser.otherInfo?.['studentFirstName'] as string) ?? ('My Child' as string)}'s Polygence Journey`}
        />
      ),
      cols: 4,
      rows: studentJourneyTileSections && studentJourneyTileSections.length === 3 ? 4 : 3,
      show: studentHasJourneySections,
    },
    {
      name: 'mentors',
      TileComponent: <MentorTile />,
      cols: 2,
      show: !studentHasJourneySections,
    },
    {
      name: 'pastprojects',
      href: getLandingUrl('/projects'),
      label: 'Past Projects',
      illustration: 'pastprojects',
      cols: 2,
      show: !studentHasJourneySections,
    },
    {
      name: 'showcases',
      show: opportunitiesEnabled,
      href: '/dashboard/showcasing-opportunities',
      label: 'HS Journals & Showcasing Opportunities',
      illustration: 'showcases',
      cols: 4,
    },
    {
      name: 'blog',
      href: getLandingUrl('/blog'),
      label: 'Blog',
      illustration: 'blog',
      cols: 2,
      show: !studentHasJourneySections,
    },
  ];
};
