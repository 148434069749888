import type { Nullable } from '@polygence/common';
import { Icon } from '@polygence/components';
import type { FC, MutableRefObject, ChangeEvent } from 'react';

interface ChatMessageAttachmentProps {
  disabled?: boolean;
  fileInput: MutableRefObject<Nullable<HTMLInputElement>>;
  fileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ChatMessageAttachment: FC<ChatMessageAttachmentProps> = ({
  disabled = false,
  fileInput,
  fileUpload,
}) => {
  return (
    <button type="button" className="toolbar-item">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="m-0 clickable" htmlFor="chat-file-upload">
        <input
          className="d-none"
          type="file"
          id="chat-file-upload"
          multiple
          onChange={(event) => {
            return fileUpload(event);
          }}
          ref={fileInput}
          disabled={disabled}
        />
        <Icon id="paperclip" />
      </label>
    </button>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ChatMessageAttachment;
