import { cn } from '@polygence/components';
import 'src/components/hermes/sessions/BlinkingCircle/blinking-circle.css';

interface BlinkingCircleProps {
  className?: string;
}

export const BlinkingCircle = ({ className }: BlinkingCircleProps) => {
  return <div className={cn('h-blinking-circle blink_me', className)} />;
};
