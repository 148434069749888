import type { Nullable } from '@polygence/common';
import { externalLink, getMediaTypeFrom } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import React, { useEffect, useState } from 'react';

import { getFilenameFromPath } from 'src/components/hermes/utils';

interface AttachmentProps {
  id: number;
}

const Attachment: React.FC<AttachmentProps> = ({ id }) => {
  const [content, setContent] = useState('');
  const [originalFilename, setOriginalFilename] = useState<Nullable<string>>(null);
  useEffect(() => {
    let didCancel = false;
    hermesApi
      .getAttachment(id)
      .then(({ data: { content: newContent, filename } }) => {
        if (didCancel) {
          return;
        }
        if (typeof newContent !== 'string' || typeof filename !== 'string') {
          return;
        }
        setOriginalFilename(filename);
        setContent(newContent);
      })
      .catch(console.error);
    return () => {
      // eslint-disable-next-line fp/no-mutation
      didCancel = true;
    };
  }, [id]);

  const fileName = originalFilename || getFilenameFromPath(content);
  const { isImage, isVideo } = getMediaTypeFrom(fileName ?? '');

  return (
    <>
      {isVideo && (
        <div className="d-flex flex-column w-50">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video className="w-100" controls>
            <source src={content} type="video/webm" />
          </video>
        </div>
      )}
      <a href={content} className="d-block h-chat-attachment" {...externalLink}>
        {isImage && (
          <div className="d-flex justify-content-start">
            <img src={content} alt={fileName} />
          </div>
        )}
        <div className="d-flex justify-content-start tw-break-all">{fileName}</div>
      </a>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Attachment;
