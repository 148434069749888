import Attachment from 'src/components/hermes/messages/Attachment';
import MarkdownMessage from 'src/components/hermes/messages/MarkdownMessage';

interface AttachmentMessageProps {
  content: string;
  messageId: number;
  createdAt?: string;
  extra: {
    attachmentIds: number[];
  };
}
const AttachmentMessage: React.FC<AttachmentMessageProps> = ({
  content,
  messageId,
  createdAt,
  extra,
}) => {
  return (
    <>
      {content && <MarkdownMessage content={content} messageId={messageId} createdAt={createdAt} />}
      {extra.attachmentIds.map((id) => {
        return <Attachment id={id} key={id} />;
      })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AttachmentMessage;
