import { Button, Icon } from '@polygence/components';
import { v4 as uuidv4 } from 'uuid';

import Assignment from 'src/components/hermes/CreateAssignment/Assignment';
import { MAX_ASSIGNMENTS_PER_SESSION } from 'src/components/hermes/constants';

const CreateAssignment = ({ assignments, setAssignments, optional }) => {
  const handleAddAssignment = () => {
    if (assignments.length <= MAX_ASSIGNMENTS_PER_SESSION) {
      setAssignments((prevAssignments) => {
        return [...prevAssignments, { title: '', description: '', _id: uuidv4() }];
      });
    }
  };

  const updateAssignments = (idx, updatedAssignment) => {
    const newList = [...assignments];
    // eslint-disable-next-line fp/no-mutation
    newList[idx] = updatedAssignment;
    setAssignments(newList);
  };

  const removeAssignment = (idx) => {
    const newList = [...assignments];
    const updated = newList.filter((_, index) => index !== idx);
    setAssignments(updated);
  };

  return (
    <>
      <h5>Assignment(s) for next session {optional && ' (optional)'}</h5>
      <p>
        In order to provide structure and guidance for your student, please add assignments for them
        to complete before the next session.{' '}
        <span className="text-bold">
          * The assignment(s) will be sent out to both the student and parent once this session
          summary is submitted.
        </span>
      </p>
      {assignments.map((assignment, idx) => {
        return (
          <Assignment
            key={assignment._id}
            assignment={assignment}
            onChange={(updatedAssignment) => updateAssignments(idx, updatedAssignment)}
            removeAssignment={() => removeAssignment(idx)}
            enableRemove={assignments.length > 1}
            optional={optional}
          />
        );
      })}
      {assignments.length < MAX_ASSIGNMENTS_PER_SESSION ? (
        <Button size="sm" startIcon={<Icon id="plus" />} onClick={handleAddAssignment}>
          Add new assignment
        </Button>
      ) : (
        <p className="text-info">
          You can add maximum assignments of {MAX_ASSIGNMENTS_PER_SESSION} for one session.
        </p>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CreateAssignment;
