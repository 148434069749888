import NiceModal from '@ebay/nice-modal-react';
import { useGetFeatureStatusQuery } from '@polygence/common/api/hermes-ai';
import useSelectedWorkspaceId from '@polygence/common/hooks/selectors/useSelectedWorkspaceId';
import { useEffect } from 'react';

export const useProjectAssistantIntroductionModal = () => {
  const selectedWorkspaceId = useSelectedWorkspaceId();
  const { data, isLoading } = useGetFeatureStatusQuery(selectedWorkspaceId ?? 0, {
    skip: !selectedWorkspaceId,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }

    if (data.featureEnabled && !data.featureIntroductionModalSeen) {
      NiceModal.show('hermes-ai-feature-introduction-modal').catch(console.error);
    }
  }, [data, isLoading]);
};
