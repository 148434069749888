import { useProjectPlanQuery } from '@polygence/common/api/hermes-project';
import useSelectedProject from '@polygence/common/hooks/selectors/useSelectedProject';
import { Button, Icon } from '@polygence/components';
import { Link } from 'react-router-dom';

import { PageLoad } from 'src/components/PageLoad';
import { ProjectPlanComponent } from 'src/components/hermes/project-plan/ProjectPlan';

export const ProjectPlanPage = () => {
  const project = useSelectedProject();
  const { data: projectPlan, isLoading } = useProjectPlanQuery(project?.id ?? 0, {
    skip: !project,
  });

  if (isLoading) {
    return <PageLoad />;
  }

  return (
    <div className="container p-5">
      <Button
        // @ts-expect-error see BSH-5057
        as={Link}
        variant="link"
        startIcon={<Icon id="chevron-left" />}
        to={project?.workspaceId ? `/dashboard/hermes/${project.workspaceId}` : '/dashboard/hermes'}
        className="tw-mb-5"
      >
        Back to Project
      </Button>

      {projectPlan && <ProjectPlanComponent projectPlan={projectPlan} />}
    </div>
  );
};
