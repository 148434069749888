import { type StudentJourneyCoreProgram, type StudentJourneyPSSProgram } from '@polygence/common';
import { Avatar, Badge, Card, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { CompletedAvatarOverlay } from 'src/components/Tiles/StudentJourney/CompletedAvatarOverlay';
import { StudentJourneyTileContext } from 'src/components/Tiles/StudentJourney/StudentJourneyTile';
import styles from 'src/components/Tiles/StudentJourney/common.module.scss';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';
import { ProgressBar } from 'src/components/common/ProgressBar';
import { dayjs } from 'src/utils/dayjsCustom';
import { getTracker } from 'src/utils/tracking/factory';

const MentorAndUpcomingSessionDetails = ({
  data,
}: {
  data: StudentJourneyCoreProgram | StudentJourneyPSSProgram;
}) => {
  if (!data.project) {
    return null;
  }

  const {
    project: { upcomingSession, mentor },
    status,
  } = data;

  return (
    <Card
      className={classNames(
        styles['internalCard'],
        'd-flex flex-column px-7 align-items-center justify-content-center shadow-none gap-3',
        upcomingSession ? 'py-4' : 'py-7',
      )}
    >
      {mentor && (
        <div className="d-flex flex-column align-items-center gap-2">
          <CompletedAvatarOverlay show={status === 'completed'}>
            <Avatar
              size={36}
              user={{
                firstName: mentor.firstName,
                lastName: mentor.lastName,
                profilePicture: mentor.profilePicture,
              }}
            />
          </CompletedAvatarOverlay>
          <Text size="small" fontWeight="bold">
            {`${mentor.firstName} ${mentor.lastName}`}
          </Text>
        </div>
      )}
      {status === 'terminated' && (
        <Text fontWeight="semibold" size="small">
          <Badge variant="danger">TERMINATED</Badge>
        </Text>
      )}
      {status === 'completed' && (
        <Text fontWeight="semibold" size="small">
          <Badge variant="success">COMPLETED</Badge>
        </Text>
      )}
      {upcomingSession && status !== 'terminated' && (
        <div className="d-flex flex-column align-items-center gap-1">
          <Text size="small" fontWeight="light">
            Upcoming:
          </Text>
          <Text size="small" fontWeight="bold">
            Session {upcomingSession.sessionNumber}
          </Text>
          {upcomingSession.scheduledAt ? (
            <>
              <div className="hstack align-self-center">
                <Icon id="calendar" size="xs" className="me-1" />
                <Text size="small" fontWeight="normal">
                  {dayjs(upcomingSession.scheduledAt).format('ddd, MMM D, YYYY')}
                </Text>
              </div>
              <Text size="small" fontWeight="normal">
                {dayjs(upcomingSession.scheduledAt).format('LT')}
              </Text>
            </>
          ) : (
            <Badge variant="warning">Not scheduled yet</Badge>
          )}
        </div>
      )}
    </Card>
  );
};

interface ProjectActiveTileProps {
  projectType: 'core' | 'pss';
}

export const ProjectActiveTile = ({ projectType }: ProjectActiveTileProps) => {
  const history = useHistory();
  const tracker = getTracker();
  const { data } = useStudentJourney();

  const { showWorkspaceLinks } = useContext(StudentJourneyTileContext);

  if (!data) {
    return null;
  }

  const programData = data[projectType];

  if (!programData || !programData.project) {
    return null;
  }

  const {
    project: { allSessions, sessionsCompleted, startedAt, expectedEndAt, workspaceId, completedAt },
    status,
  } = programData;

  const handleGoToWorkspace = () => {
    tracker.track('Go To Workspace', {
      category: 'student_journey_tile',
      workspaceId: workspaceId,
    });
    history.push(`/dashboard/hermes/${workspaceId}`);
  };

  return (
    <div className="d-flex w-100 gap-8 flex-wrap justify-content-center">
      <MentorAndUpcomingSessionDetails data={programData} />
      <div className="d-flex flex-column flex-fill align-items-center justify-content-center gap-2">
        <Text size="small" fontWeight="light">
          {status === 'completed'
            ? 'Congratulations, you did it!'
            : `Completed ${sessionsCompleted} of ${allSessions}`}
        </Text>
        <ProgressBar
          completed={(sessionsCompleted / allSessions) * 100}
          height="24px"
          margin="0"
          indicatorGradient="linear-gradient(93.3deg, #00ffda 16.09%, #00cbae 112.73%)"
          backgroundColor="var(--grayscale-3)"
        />
        <div className="d-flex flex-row w-100 justify-content-between">
          <div className="d-flex flex-column align-items-center ms-n3">
            <Text size="small" fontWeight="bold">
              Started
            </Text>
            <Text size="small" fontWeight="light">
              {dayjs(startedAt).format('ll')}
            </Text>
          </div>
          <div className="d-flex flex-column align-items-center me-n3">
            <Text size="small" fontWeight="bold">
              {status === 'completed' ? 'Finished' : 'Deadline'}
            </Text>
            <Text size="small" fontWeight="light">
              {dayjs(status === 'completed' ? completedAt : expectedEndAt).format('ll')}
            </Text>
          </div>
        </div>
        {showWorkspaceLinks && (
          <Button
            size="sm"
            className="mt-4"
            variant={status === 'active' ? 'primary' : 'secondary'}
            onClick={handleGoToWorkspace}
          >
            Go To Workspace
          </Button>
        )}
      </div>
    </div>
  );
};
