import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import { dayjs } from 'src/utils/dayjsCustom';

export const getDeadlineFromNextSession = (
  nextSession: { scheduledAt: string },
  currentSessionNumber: number,
  timeZone: string,
) => {
  if (!nextSession || !nextSession.scheduledAt) {
    return `48 hours before Session ${currentSessionNumber + 1}`;
  }
  const twoDaysBeforeSession = dayjs(nextSession.scheduledAt).subtract(2, 'day');

  return new DateWrapper(twoDaysBeforeSession)
    .setTimezone(timeZone)
    .format(DateTimeFormat.FORMAT_15);
};
