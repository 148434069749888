import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { ProjectPauseRequest, commonHooks } from '@polygence/common';
import {
  useModifyProjectPauseRequestMutation,
  useSubmitProjectPauseRequestMutation,
} from '@polygence/common/api/hermes-project';
import { Alert, Modal, ModalBody } from '@polygence/components';
import { toast } from 'react-toastify';

import { ProjectPauseForm } from 'src/components/hermes/pause-project/ProjectPauseForm';
import { calculateModalStatus } from 'src/components/hermes/pause-project/utils';

const MentorPopup = () => {
  return (
    <span>
      This feature is only available for students. If you believe this project should be paused,
      please discuss it with your student or contact students@polygence.org.
    </span>
  );
};

export const ProjectPauseRequestModal = NiceModal.create(() => {
  const modal = useModal();
  const currentUser = commonHooks.useCurrentUser();
  const selectedProject = commonHooks.useSelectedProject();
  const latestProjectPauseRequest = commonHooks.useLastProjectPauseRequest();
  const [submitNewRequest, { isLoading }] = useSubmitProjectPauseRequestMutation();
  const [respondToRequest, { isLoading: isRespondLoading }] =
    useModifyProjectPauseRequestMutation();
  const modalStatus = calculateModalStatus(
    currentUser.userType,
    selectedProject?.projectPauseRequests,
  );

  const closeModal = () => {
    modal.hide().catch(console.error);
  };

  const handleStudentRequest = (
    requestData: Pick<ProjectPauseRequest, 'pauseUntil' | 'reasonForPause'>,
  ) => {
    if (!selectedProject) {
      return;
    }
    submitNewRequest({ ...requestData, project: selectedProject.id })
      .unwrap()
      .then(() => {
        toast.success('Project Pause Request submitted to the mentor!');
        void modal.hide();
      })
      .catch(() => {
        toast.error('Something went wrong, try again or contact support!');
      });
  };

  const handleMentorSubmit = (data: Pick<ProjectPauseRequest, 'status' | 'reasonForDecline'>) => {
    if (!latestProjectPauseRequest) {
      return;
    }
    respondToRequest({
      id: latestProjectPauseRequest.id,
      data,
    })
      .unwrap()
      .then(() => {
        toast.success('Your response was recorded.');
        void modal.hide();
      })
      .catch(() => {
        toast.error('Something went wrong, try again or contact support!');
      });
  };

  return (
    <Modal {...bootstrapDialog(modal)} closeButton>
      <h1 className="fs-1_3">Pause Project</h1>
      <Alert variant="warning">
        You can opt to pause the project for up to 30 days. In this time period the workspace will
        remain open, but mentors will not be expecting outreach from students and may not be
        checking the workspace regularly.
      </Alert>
      <ModalBody>
        {modalStatus === 'mentor_popup' && <MentorPopup />}
        {modalStatus === 'mentor_respond' && (
          <ProjectPauseForm
            data={latestProjectPauseRequest}
            onRespond={handleMentorSubmit}
            onClose={closeModal}
            acceptText="Submit"
            cancelText="Cancel"
            readOnly
            loading={isRespondLoading}
            mentorResponding
          />
        )}
        {modalStatus === 'student_request' && (
          <ProjectPauseForm
            onSubmit={handleStudentRequest}
            onClose={closeModal}
            acceptText="Request"
            cancelText="Cancel"
            loading={isLoading}
          />
        )}
        {modalStatus === 'readonly' && (
          <ProjectPauseForm
            data={latestProjectPauseRequest}
            onSubmit={() => undefined}
            onClose={closeModal}
            readOnly
            actionsVisible={false}
          />
        )}
      </ModalBody>
    </Modal>
  );
});
