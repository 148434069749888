import { useCreateUserSnippetMutation } from '@polygence/common/api/hermes-ai';
import { hermesAiActions } from '@polygence/common/store/reducers/hermesAiReducer';
import { useCallback } from 'react';

import { useAppDispatch } from 'src/store';

export const useCreateUserSnippet = () => {
  const [triggerCreateUserSnippet] = useCreateUserSnippetMutation();
  const dispatch = useAppDispatch();

  return useCallback(
    (content: string) => {
      return triggerCreateUserSnippet(content)
        .unwrap()
        .then((snippet) => {
          dispatch(hermesAiActions.addUnsentSnippet(snippet.id));
        });
    },
    [triggerCreateUserSnippet, dispatch],
  );
};
