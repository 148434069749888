import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { HermesAIMessageThreadId } from '@polygence/common';
import { hermesAiApi } from '@polygence/common/api/hermes-ai';
import useSelectedWorkspaceId from '@polygence/common/hooks/selectors/useSelectedWorkspaceId';
import {
  hermesAiSelectors,
  hermesAiActions,
} from '@polygence/common/store/reducers/hermesAiReducer';
import { sendMessage } from '@polygence/common/websocket/hermes-ai';
import { Button, cn, Icon, Text, Tooltip } from '@polygence/components';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { usePrevious } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import LexicalEditor from 'src/components/LexicalEditor';
import PlainTextInputPlugin from 'src/components/LexicalEditor/plugins/PlainTextInputPlugin';
import ReadOnlyPlugin from 'src/components/LexicalEditor/plugins/ReadOnlyPlugin';
import SendMessagePlugin from 'src/components/LexicalEditor/plugins/SendMessagePlugin';
import Divider from 'src/components/LexicalEditor/plugins/toolbar/Divider';
import ToolbarPlugin from 'src/components/LexicalEditor/plugins/toolbar/ToolbarPlugin';
import { UserSnippetPlugin } from 'src/components/hermes-ai/editor/UserSnippetPlugin';
import { useGetMessageCount } from 'src/components/hermes-ai/messages/useGetMessageCount';
import { useCreateAiMessageThread } from 'src/components/hermes-ai/useCreateAiMessageThread';
import { useHermesAIEnabled } from 'src/components/hermes-ai/useHermesAIEnabled';
import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';
import { useHermesAIStatus } from 'src/components/hermes-ai/useHermesAIStatus';
import { UnsentUserSnippets } from 'src/components/hermes-ai/user-snippet/UnsentUserSnippets';
import { useAppDispatch, useAppSelector } from 'src/store';

export const HermesAIMessageEditor = () => {
  const [userInput, setUserInput] = useState('');
  const dispatch = useAppDispatch();
  const { canWrite } = useHermesAIEnabled();
  const status = useHermesAIStatus();
  const aiThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  const selectedWorkspaceId = useSelectedWorkspaceId();
  const [createThread, { isLoading: isCreatingThread }] = useCreateAiMessageThread();
  const unsentSnippets = useAppSelector(hermesAiSelectors.unsentSnippets) ?? [];
  const previousMessageThreadId = usePrevious(aiThreadId);
  const newConversationUrl = useHermesAIRouteUrl();
  const history = useHistory();

  const { maxNumberOfMessages, messageCount } = useGetMessageCount();
  const hasReachedMaxNumberOfMessages =
    !!messageCount && !!maxNumberOfMessages && messageCount >= maxNumberOfMessages;
  const isNearMaxNumberOfMessages =
    !!messageCount && !!maxNumberOfMessages && messageCount / maxNumberOfMessages > 0.5;
  const isCreateNewConversationActive = aiThreadId && !isCreatingThread && messageCount;

  useEffect(() => {
    if (previousMessageThreadId && previousMessageThreadId !== aiThreadId) {
      dispatch(hermesAiActions.clearUnsentSnippets());
    }
  }, [previousMessageThreadId, aiThreadId, dispatch]);

  const handleSend = (message: string, id: HermesAIMessageThreadId) => {
    const clientId = uuidv4();

    sendMessage({
      type: 'message',
      ai_thread_id: id,
      content: message,
      user_snippets: unsentSnippets,
      client_id: clientId,
    });

    dispatch(
      hermesAiApi.util.updateQueryData('getMessages', id, (draft) => {
        draft.messages.unshift({
          role: 'user',
          content: message,
          clientId,
        });
      }),
    );
    dispatch(hermesAiActions.closeThreadSelector());
    dispatch(hermesAiActions.clearUnsentSnippets());
  };

  const onSend = (userMessage: string) => {
    if (!aiThreadId) {
      if (!selectedWorkspaceId || isCreatingThread) {
        return;
      }

      createThread(selectedWorkspaceId)
        .then((id) => {
          handleSend(userMessage, id);
        })
        .catch(() => {
          toast.error('Something went wrong.');
        });
    } else {
      handleSend(userMessage, aiThreadId);
    }
  };

  const navigateToNewConversation = () => {
    history.push(newConversationUrl);
  };

  if (!canWrite) {
    return (
      <div className="tw-flex tw-w-full tw-justify-center tw-p-2 tw-pb-0">
        <Text size="small" fontWeight="bold" alignment="center" className="tw-text-gray-500">
          You can not send messages to Project Assistant.
        </Text>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-w-full tw-items-end tw-justify-between tw-gap-2">
      <div className="tw-relative tw-w-full tw-drop-shadow-md">
        <UnsentUserSnippets />
        <LexicalEditor
          onChange={setUserInput}
          defaultValue={userInput}
          className={cn(
            'tw-mt-3 tw-flex tw-w-full [&_.editor-container]:tw-rounded-l-3xl [&_.editor-container]:tw-rounded-r-none [&_.editor-inner]:tw-rounded-l-3xl [&_.editor-inner]:tw-rounded-r-none',
            hasReachedMaxNumberOfMessages &&
              '[&_.editor-input]:tw-cursor-not-allowed [&_.editor-input]:tw-text-gray-400',
          )}
        >
          <PlainTextInputPlugin
            placeholder={
              hasReachedMaxNumberOfMessages ? 'Max number of messages reached' : 'Ask something...'
            }
          />
          <ReadOnlyPlugin readOnly={hasReachedMaxNumberOfMessages} />
          <ToolbarPlugin className="tw-items-center tw-rounded-bl-none tw-rounded-br-3xl tw-rounded-tl-none tw-rounded-tr-3xl">
            <UserSnippetPlugin messageThreadId={aiThreadId} />
            <Divider className="tw-h-[80%]" />
            <SendMessagePlugin
              disabled={
                userInput.trim() === '' ||
                status !== 'ready' ||
                isCreatingThread ||
                hasReachedMaxNumberOfMessages
              }
              onSend={onSend}
              sendOnEnter
            />
          </ToolbarPlugin>
          <AutoFocusPlugin />
        </LexicalEditor>
      </div>
      <Tooltip tip="Create new conversation" placement="top">
        <Button
          size="lg"
          variant="tertiary"
          className={cn(
            'tw-ml-auto disabled:tw-text-gray-400',
            isNearMaxNumberOfMessages && '[&_i]:-tw-ml-2',
          )}
          onClick={navigateToNewConversation}
          disabled={!isCreateNewConversationActive}
          endIcon={
            <Icon
              id="message-circle"
              className='after:tw-tw-place-items-center tw-relative after:tw-absolute after:tw-inset-0 after:tw-grid after:tw-pl-[1px] after:tw-text-sm after:tw-font-bold after:tw-not-italic after:tw-text-[currentColor] after:tw-content-["+"]'
            />
          }
        >
          {isNearMaxNumberOfMessages && (
            <motion.span
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.2, duration: 0.4 } }}
              layout="position"
              className="tw-mr-2 tw-hidden md:tw-inline"
            >
              New conversation
            </motion.span>
          )}
        </Button>
      </Tooltip>
    </div>
  );
};
