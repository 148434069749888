import { commonReducers, commonHooks } from '@polygence/common';
import { Button } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { calculateModalStatus } from 'src/components/hermes/additional-session/AdditionalSessionModalStatus';
import MultiLineText from 'src/components/hermes/messages/MultiLineText';

interface AdditionalSessionMessageProps {
  content: string;
  extra: {
    uuid: string;
    additionalSessionsNumber: number;
    reasonForSessions: string;
    proposedPlan: string;
  };
}

const AdditionalSessionMessage: React.FC<AdditionalSessionMessageProps> = ({
  content,
  extra: {
    uuid = '',
    additionalSessionsNumber = -1,
    reasonForSessions = '',
    proposedPlan = '',
  } = {},
}) => {
  const dispatch = useDispatch();
  const currentUser = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();

  if (project == null || currentUser == null) {
    return null;
  }
  const { userType } = currentUser;

  const { additionalSessions } = project;
  const modalStatus = calculateModalStatus(userType, additionalSessions);
  const isLatestMessage = uuid === additionalSessions?.[0]?.uuid;
  const showButton = isLatestMessage && modalStatus === 'mentor_respond';

  return (
    <div className="h-extension-request-message">
      <MultiLineText>{content}</MultiLineText>
      <p>Number of additional sessions: {additionalSessionsNumber}</p>
      <p>Reason for sessions: {reasonForSessions}</p>
      <p>
        Proposed plan for sessions:
        <br />
        {proposedPlan}
      </p>

      {showButton && (
        <Button
          className="m-2"
          size="sm"
          variant="tertiary"
          onClick={() => {
            return dispatch(commonReducers.additionalSessionActions.openAdditionalSessionModal());
          }}
        >
          Respond to Additional Sessions
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AdditionalSessionMessage;
