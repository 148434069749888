import type { Nullable } from '@polygence/common';
import classNames from 'classnames';
import type { FC, MutableRefObject, ChangeEvent } from 'react';

import GoogleDriveCreateFiles from 'src/components/GoogleDriveWidget/GoogleDriveCreateFiles';
import LexicalEditor from 'src/components/LexicalEditor';
import type { LexicalTextEditorProps } from 'src/components/LexicalEditor/LexicalEditor';
import GifSearchPlugin from 'src/components/LexicalEditor/plugins/GifSearchPlugin';
import RichTextInputPlugin from 'src/components/LexicalEditor/plugins/RichTextInputPlugin';
import SendMessagePlugin from 'src/components/LexicalEditor/plugins/SendMessagePlugin';
import Bold from 'src/components/LexicalEditor/plugins/toolbar/Bold';
import Code from 'src/components/LexicalEditor/plugins/toolbar/Code';
import CodeBlock from 'src/components/LexicalEditor/plugins/toolbar/CodeBlock';
import Divider from 'src/components/LexicalEditor/plugins/toolbar/Divider';
import { HermesAI } from 'src/components/LexicalEditor/plugins/toolbar/HermesAI';
import Italic from 'src/components/LexicalEditor/plugins/toolbar/Italic';
import OrderedList from 'src/components/LexicalEditor/plugins/toolbar/OrderedList';
import Strikethrough from 'src/components/LexicalEditor/plugins/toolbar/StrikeThrough';
import ToolbarPlugin from 'src/components/LexicalEditor/plugins/toolbar/ToolbarPlugin';
import UnorderedList from 'src/components/LexicalEditor/plugins/toolbar/UnorderedList';
import ChatMessageAttachment from 'src/components/hermes/chat/ChatMessageAttachment';
import 'src/components/hermes/chat/ChatMessageEditor.sass';

interface ChatMessageEditorProps extends LexicalTextEditorProps {
  defaultValue: string;
  setGifTerm: (gifTerm: string) => void;
  fileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  fileInput: MutableRefObject<Nullable<HTMLInputElement>>;
  workspace: { workspaceId: number };
  listFiles: boolean;
  isMessageSendDisabled: boolean;
  isEditorDisabled: boolean;
  handleMessageSend: (message: string) => void;
}

const ChatMessageEditor: FC<ChatMessageEditorProps> = ({
  defaultValue,
  onChange,
  setGifTerm,
  fileUpload,
  fileInput,
  workspace,
  isMessageSendDisabled,
  isEditorDisabled,
  handleMessageSend,
}) => {
  return (
    <div className={classNames('chat-message-editor__wrapper', { disabled: isEditorDisabled })}>
      <LexicalEditor onChange={onChange} defaultValue={defaultValue} readOnly={isEditorDisabled}>
        <GifSearchPlugin setGifTerm={setGifTerm} />
        <RichTextInputPlugin placeholder="Send a message..." />
        <ToolbarPlugin>
          <div className="tw-flex tw-grow tw-flex-wrap">
            <Bold />
            <Italic />
            <Strikethrough />
            <Code />
            <Divider />
            <UnorderedList />
            <OrderedList />
            <CodeBlock />
            <Divider />
            <ChatMessageAttachment
              disabled={isEditorDisabled}
              fileUpload={fileUpload}
              fileInput={fileInput}
            />
            <GoogleDriveCreateFiles workspace={workspace} disabled={isEditorDisabled} />
          </div>
          <div className="tw-flex tw-grow-0 tw-align-middle">
            <HermesAI />
            <SendMessagePlugin disabled={isMessageSendDisabled} onSend={handleMessageSend} />
          </div>
        </ToolbarPlugin>
      </LexicalEditor>
    </div>
  );
};
// eslint-disable-next-line import/no-default-export -- autodisabled
export default ChatMessageEditor;
