import type { ApplicationComponentSection } from 'src/students/student-application';
import { EXPERIMENT_SECTIONS_WHITE_LABEL } from 'src/white-label/student-application/sections/experiment-section';
import { INTERESTS_SECTION_WHITE_LABEL } from 'src/white-label/student-application/sections/interest-section';
import { OUTPUT_SECTION_WHITE_LABEL } from 'src/white-label/student-application/sections/output-section';
import { POST_PROJECT_IDEA_SECTIONS_WHITE_LABEL } from 'src/white-label/student-application/sections/post-project-idea-section';
import { PROJECT_IDEA_SECTION_WHITE_LABEL } from 'src/white-label/student-application/sections/project-idea-section';

export const studentApplicationWhiteLabelSections = Object.freeze<ApplicationComponentSection[]>([
  INTERESTS_SECTION_WHITE_LABEL,
  OUTPUT_SECTION_WHITE_LABEL,
  ...EXPERIMENT_SECTIONS_WHITE_LABEL,
  ...PROJECT_IDEA_SECTION_WHITE_LABEL,
  ...POST_PROJECT_IDEA_SECTIONS_WHITE_LABEL,
]);
