import * as backendApi from '@polygence/common/api/backend';
import { commonHooks } from '@polygence/common/hooks';
import { InputField, Button, Spacer } from '@polygence/components';
import { useState } from 'react';
import { toast } from 'react-toastify';

import CopyReferralUrlButton from 'src/components/ReferralPage/CopyReferralUrlButton';
import { isStudent } from 'src/components/getMyInfo';

interface SendReferralEmailFormProps {
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

const initialState = {
  name: '',
  email: '',
};

const SendReferralEmailForm: React.FC<SendReferralEmailFormProps> = ({ onSubmit }) => {
  const { referralUrl } = commonHooks.useCurrentUser();
  const [friendToInvite, setFriendsToInvite] = useState(initialState);
  const [showError, setShowError] = useState(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!friendToInvite.email || !friendToInvite.name) {
      setShowError(true);
      return;
    }

    backendApi
      .sendInvitation({ inviteeEmail: friendToInvite.email, inviteeFirstName: friendToInvite.name })
      .then(() => {
        toast.success(`Invitation sent to ${friendToInvite.name}`);
        setFriendsToInvite(initialState);
        if (onSubmit) {
          onSubmit(e);
        }
      })
      .catch(() => {
        toast.error(`Could not send invitation to ${friendToInvite.name}.`);
      });
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    setFriendsToInvite((prevState) => ({ ...prevState, [name]: value }));
    setShowError(false);
  };

  return (
    // eslint-disable-next-line @transferwise/form-has-method-attribute
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-3">
        <InputField
          label="First name"
          name="name"
          type="text"
          value={friendToInvite.name}
          onChange={handleChange}
          isInvalid={showError && !friendToInvite.name}
          validateInitialState
        />
        <InputField
          label="Email"
          name="email"
          type="email"
          value={friendToInvite.email}
          onChange={handleChange}
          isInvalid={showError && !friendToInvite.email}
          validateInitialState
        />
      </div>
      <Spacer size={6} />
      <div className="d-flex flex-wrap gap-6 align-items-center justify-content-center">
        <Button variant="primary" type="submit">
          {`Send ${isStudent() ? 'nomination' : 'referral'} email`}
        </Button>
        {referralUrl && 'or'}
        <CopyReferralUrlButton referralUrl={referralUrl} />
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SendReferralEmailForm;
