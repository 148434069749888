import { useGetFeatureStatusQuery } from '@polygence/common/api/hermes-ai';
import useSelectedWorkspaceId from '@polygence/common/hooks/selectors/useSelectedWorkspaceId';

export const useHermesAIEnabled = () => {
  const selectedWorkspaceId = useSelectedWorkspaceId();
  const { data: { featureVisible: canSeeFeature = false, featureEnabled: canWrite = false } = {} } =
    useGetFeatureStatusQuery(selectedWorkspaceId ?? 0, { skip: !selectedWorkspaceId });

  return { canWrite, canSeeFeature };
};
