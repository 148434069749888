import {
  commonReducers,
  commonHooks,
  AdditionalSessionStatus,
  AdditionalSessionData,
} from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { Modal, ModalBody } from '@polygence/components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import AdditionalSessionForm from 'src/components/hermes/additional-session/AdditionalSessionForm';
import { calculateModalStatus } from 'src/components/hermes/additional-session/AdditionalSessionModalStatus';

const MentorPopup = () => {
  return (
    <span>
      This feature is only available for students. If you believe this project requires additional
      sessions, please discuss it with your student and instruct them to submit the request
    </span>
  );
};

export const AdditionalSessionModal = () => {
  const { isModalOpen } = commonHooks.useAdditionalSessionModal();
  const [loading, setLoading] = useState(false);
  const currentUser = commonHooks.useCurrentUser();
  const selectedProject = commonHooks.useSelectedProject();
  const latestAdditionalSession = selectedProject?.additionalSessions?.[0];
  const modalStatus = calculateModalStatus(
    currentUser.userType,
    selectedProject?.additionalSessions,
  );

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(commonReducers.additionalSessionActions.closeAdditionalSessionModal());
  };

  const handleStudentRequest = (additionalSessionsData: { [k: string]: FormDataEntryValue }) => {
    if (!selectedProject) {
      return;
    }
    setLoading(true);
    const payload: AdditionalSessionData = {
      project: selectedProject.id,
      requestedBy: currentUser.id,
      ...additionalSessionsData,
    };
    hermesApi
      .createAdditionalSessionRequest(payload)
      .then(() => {
        toast.success('Additional sessions requested.');
      })
      .catch(() => {
        toast.error('Could not process extension request.');
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  const handleMentorAccept = () => {
    if (!latestAdditionalSession) {
      return;
    }
    setLoading(true);
    const payload = {
      status: AdditionalSessionStatus.PENDING,
      respondedBy: currentUser.id,
    };
    hermesApi
      .updateAdditionalSessionRequest(latestAdditionalSession.id, payload)
      .then(() => {
        toast.success('Requested additional sessions accepted.');
      })
      .catch(() => {
        toast.error('Could not process extension accept.');
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  const handleMentorDecline = () => {
    if (!latestAdditionalSession) {
      return;
    }
    setLoading(true);
    const payload = {
      status: AdditionalSessionStatus.DECLINED,
      respondedBy: currentUser.id,
    };
    hermesApi
      .updateAdditionalSessionRequest(latestAdditionalSession.id, payload)
      .then(() => {
        toast.success('Requested additional sessions declined.');
      })
      .catch(() => {
        toast.error('Could not process extension accept.');
      })
      .finally(() => {
        setLoading(false);
        closeModal();
      });
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} backdrop="static" closeButton>
      <ModalBody>
        {modalStatus === 'mentor_popup' && <MentorPopup />}
        {modalStatus === 'mentor_respond' && (
          <AdditionalSessionForm
            data={latestAdditionalSession}
            expectedEndAt={selectedProject?.expectedEndAt ?? ''}
            onSubmit={handleMentorAccept}
            onClose={handleMentorDecline}
            acceptText="Accept"
            cancelText="Decline"
            readOnly
            loading={loading}
          />
        )}
        {modalStatus === 'student_request' && (
          <AdditionalSessionForm
            expectedEndAt={selectedProject?.expectedEndAt ?? ''}
            onSubmit={handleStudentRequest}
            onClose={closeModal}
            acceptText="Request"
            cancelText="Cancel"
            loading={loading}
          />
        )}
        {modalStatus === 'readonly' && (
          <AdditionalSessionForm
            data={latestAdditionalSession}
            expectedEndAt={selectedProject?.expectedEndAt ?? ''}
            onSubmit={() => undefined}
            onClose={closeModal}
            readOnly
            actionsVisible={false}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
