import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { useSetFeatureIntroductionModalSeenMutation } from '@polygence/common/api/hermes-ai';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Badge, Button, Heading, Modal, ModalBody, ModalFooter, Text } from '@polygence/components';
import { useHistory } from 'react-router-dom';

import projectAssistantIntroImage from 'src/components/hermes-ai/introduction-modal/project_assistant.jpeg';
import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';

const STUDENT_LABELS = {
  subtitle: 'Feeling stuck?',
  body: 'Use your project assistant to ask questions about how to use the workspace, the Polygence program, or even about your specific project.',
};

const MENTOR_LABELS = {
  subtitle: 'Your student got access to our brand new Project Assistant!',
  body: 'They can use it to ask questions about how to use the workspace, the Polygence program, or even about your specific project.',
};

export const HermesAIFeatureIntroductionModal = NiceModal.create(() => {
  const modal = useModal();
  const currentUser = useCurrentUser();
  const url = useHermesAIRouteUrl();
  const history = useHistory();

  const subTitle =
    currentUser.userType === 'student' ? STUDENT_LABELS.subtitle : MENTOR_LABELS.subtitle;
  const bodyText = currentUser.userType === 'student' ? STUDENT_LABELS.body : MENTOR_LABELS.body;

  const [setModalSeen] = useSetFeatureIntroductionModalSeenMutation();

  const closeModal = () => {
    return Promise.all([setModalSeen(), modal.hide()]).catch(console.error);
  };

  const openAiAssistant = () => {
    closeModal()
      .then(() => {
        history.push(url);
      })
      .catch(console.error);
  };

  return (
    <Modal
      {...bootstrapDialog(modal)}
      closeButton
      onHide={() => {
        void closeModal();
      }}
    >
      <ModalBody className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-py-2">
        <Heading size="h4" alignment="center" className="tw-mb-3 tw-flex tw-items-center">
          Interactive Project Assistant
          <Badge variant="primary" size="small" className="tw-ml-2">
            BETA
          </Badge>
        </Heading>
        <div>
          <Text size="large" fontWeight="semibold" alignment="center">
            {subTitle}
          </Text>
          <Text size="medium" alignment="center" textWrap="balance" className="tw-mb-4">
            {bodyText}
          </Text>
        </div>
        <img
          className="tw-max-w-[80%]"
          width={400}
          src={projectAssistantIntroImage}
          alt="Project Assistant Introduction"
        />
      </ModalBody>
      <ModalFooter className="tw-mt-6 tw-flex tw-justify-center">
        <Button
          variant="link"
          onClick={() => {
            void closeModal();
          }}
        >
          Close
        </Button>
        {currentUser.userType === 'student' && (
          <Button variant="primary" onClick={openAiAssistant}>
            Let's check it out!
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
});
