import { HermesAIMessageThreadId, Message } from '@polygence/common';
import { usePrefetch } from '@polygence/common/api/hermes-ai';
import { Button, Icon, cn, Text } from '@polygence/components';
import { Link } from 'react-router-dom';

import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';

export const HermesAIThreadMessage = ({
  aiMessageThread,
  aiMessageThreadTitle,
  sentBy,
}: {
  aiMessageThread?: HermesAIMessageThreadId | null;
  aiMessageThreadTitle?: Message['aiMessageThreadTitle'];
  sentBy?: { firstName: string };
}) => {
  const url = useHermesAIRouteUrl();
  const prefetch = usePrefetch('getMessages');

  if (!aiMessageThread) {
    return null;
  }

  const firstName = sentBy?.firstName ?? 'User';

  return (
    <div className="h-hermes-ai-message tw-flex tw-flex-col tw-items-start">
      <div className="tw-text-gray-500">
        {firstName} has started a new Project Assistant conversation.
      </div>
      <div className="tw-flex">
        <Text size="small" fontWeight="semibold" className="tw-ml-2 tw-pt-px tw-text-gray-400">
          {aiMessageThreadTitle}
        </Text>
        <Button
          // @ts-expect-error see BSH-5057
          as={Link}
          className={cn(
            'tw-relative tw-ml-3 tw-mr-3 tw-border-b-2 tw-p-1 tw-pl-4',
            'before:tw-absolute before:tw-left-0 before:tw-top-0 before:tw-isolate before:tw-h-[60%] before:tw-w-2 before:tw-rounded-bl before:tw-border-0 before:tw-border-b-2 before:tw-border-l-2 before:tw-border-solid before:tw-border-gray-500 before:tw-content-[""]',
          )}
          size="sm"
          to={`${url}/${aiMessageThread}`}
          variant="link"
          endIcon={<Icon id="chevron-right" />}
          onMouseEnter={() => prefetch(aiMessageThread)}
        >
          Open thread
        </Button>
      </div>
    </div>
  );
};
