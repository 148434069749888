import { useState } from 'react';

import { DefaultProfilePicture } from 'src/components/common/ProfilePicture/DefaultProfilePicture';
import { getMonogramOf } from 'src/components/hermes/utils';

import 'src/components/common/ProfilePicture/ProfilePicture.sass';

interface ProfilePictureProps {
  user: { profilePicture?: string; firstName?: string; lastName?: string };
  size?: number;
}

export const ProfilePicture = ({ user, size = 140 }: ProfilePictureProps) => {
  const [error, setError] = useState(false);
  const { profilePicture, firstName } = user;

  return (
    <>
      {!error && profilePicture && firstName && (
        <img
          src={profilePicture}
          alt={firstName}
          className="profile-picture"
          // eslint-disable-next-line react/forbid-dom-props -- autodisabled
          style={{ width: size, height: size }}
          onError={() => {
            setError(true);
          }}
        />
      )}
      {(!profilePicture || error) && (
        <DefaultProfilePicture monogram={getMonogramOf(user)} size={size} />
      )}
    </>
  );
};
