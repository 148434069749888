import type { Nullable } from '@polygence/common';
import React, { useState } from 'react';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { SyntaxHighlighter } from 'src/components/aux/markdown/SyntaxHighlighter';
import { LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import SilentReportButton from 'src/components/hermes/SilentReportButton';
import SilentReportFeedback from 'src/components/hermes/SilentReportButton/SilentReportFeedback';
import MessageTimestamp from 'src/components/hermes/messages/MessageTimestamp';
import type { YesAndNo } from 'src/types/common';

interface MarkdownMessageProps {
  messageId: number;
  content: string;
  createdAt?: string;
  showSilentReport?: boolean;
}

const components = { a: LinkRenderer, pre: SyntaxHighlighter };

const MarkdownMessage: React.FC<MarkdownMessageProps> = ({
  messageId,
  content,
  createdAt,
  showSilentReport = false,
}) => {
  const [reportAnswer, setReportAnswer] = useState<Nullable<YesAndNo>>(null);

  return (
    <div className="d-flex flex-column">
      <div className="h-simple-message">
        <ReactMarkdown components={components}>{content}</ReactMarkdown>
      </div>
      <div className="message-box-footer">
        {reportAnswer && <SilentReportFeedback answer={reportAnswer} />}
        {showSilentReport && (
          <SilentReportButton
            messageId={messageId}
            onReport={(answer: Nullable<YesAndNo>) => {
              setReportAnswer(answer);
            }}
          />
        )}
        {createdAt && <MessageTimestamp timestamp={createdAt} />}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MarkdownMessage;
