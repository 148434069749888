import { isOndemand } from '@polygence/common/utils/environment';

import { DEFAULT_TENANT_DOMAIN } from 'src/white-label/whiteLabel.constants';

export const extractTenantDomainFromUrl = (): string => {
  if (window.location.hostname === 'localhost' || isOndemand()) {
    return DEFAULT_TENANT_DOMAIN;
  }

  return window.location.hostname.match(/^app\.([^.]+)\./)?.[1] ?? '';
};
