import { commonReducers } from '@polygence/common';
import { useDispatch } from 'react-redux';

import { ClickableState } from 'src/components/hermes/sessions/actions/State';

const ScheduleClickableState = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(commonReducers.hermesActions.openScheduler({}));
  return (
    <ClickableState iconName="add_date" variant="info" label="Schedule" onClick={handleClick} />
  );
};

export { ScheduleClickableState };
