import { commonReducers, commonHooks, commonThunks, useCommonSelector } from '@polygence/common';
import type { DateTime } from '@polygence/common';
import {
  Button,
  Heading,
  Icon,
  InputField,
  Spacer,
  Text,
  Modal,
  ModalBody,
} from '@polygence/components';
import { MouseEventHandler, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { dateStamp } from 'src/components/aux/dateStamp';
import { SharedResourceFavourite } from 'src/components/hermes/SharedResource/SharedResourceFavourite';
import { useAppDispatch } from 'src/store';

export const SharedResourceEditModal = () => {
  const { open } = useCommonSelector((state) => {
    return state.sharedResources.sharedResourceModal;
  });
  const resource = commonHooks.useSelectedSharedResource();
  const workspaceId = commonHooks.useSelectedWorkspaceId() as number;
  const [formData, setFormData] = useState<{
    displayName?: string;
    description?: string;
  }>({});
  const [state, setState] = useState<'editing' | 'error' | 'loading'>('editing');

  const trimmedFormData = {
    displayName: formData.displayName?.trim() ?? '',
    description: formData.description?.trim() ?? '',
  };

  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(commonReducers.sharedResourcesActions.closeSharedResourceEditModal());
  };

  useEffect(() => {
    setState('editing');
    setFormData({
      displayName: resource?.displayName,
      description: resource?.description,
    });
  }, [resource?.displayName, resource?.description]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value, name },
  }) => {
    if (state === 'loading') {
      return;
    }

    setState('editing');
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (!resource || state === 'error' || state === 'loading') {
      return;
    }

    if (!trimmedFormData.displayName) {
      setState('error');
      return;
    }

    setState('loading');
    dispatch(
      commonThunks.sharedResourcesThunks.updateSharedResource({
        resourceId: resource.id,
        workspaceId,
        payload: trimmedFormData,
      }),
    )
      .catch(() => {
        toast.error('Could not process this request.');
      })
      .finally(() => {
        setState('editing');
        closeModal();
      });
  };

  const handleArchive = () => {
    if (resource) {
      dispatch(
        commonThunks.sharedResourcesThunks.updateSharedResource({
          resourceId: resource.id,
          workspaceId,
          payload: {
            archived: true,
          },
        }),
      )
        .catch(() => {
          toast.error('Error archiving shared resource.');
        })
        .finally(() => {
          setState('editing');
          closeModal();
        });
    }
  };

  if (!resource) {
    return null;
  }

  return (
    <Modal show={open} onHide={closeModal} closeButton>
      <ModalBody>
        <div className="p-5">
          <Heading as="h3" size="h4" alignment="left" className="mb-6">
            Edit resource
          </Heading>
          <div className="d-flex justify-content-between align-items-center mb-6">
            <SharedResourceFavourite
              resource={resource}
              workspaceId={workspaceId}
              label="Favorite"
            />
            <SharedByDetails name={resource.createdByFirstName} createdAt={resource.createdAt} />
          </div>
          <div className="d-grid gap-3">
            <InputField
              type="text"
              name="displayName"
              label="Name"
              value={formData?.displayName}
              onChange={handleChange}
              isInvalid={state === 'error' && !trimmedFormData.displayName}
              disabled={state === 'loading'}
            />
            <InputField
              type="textarea"
              name="description"
              label="Description"
              value={formData?.description}
              onChange={handleChange}
              disabled={state === 'loading'}
            />
          </div>
          <Spacer size={3} />
          {resource.type === 'link' && (
            <div className="ps-8">
              <Text fontWeight="light" size="small">
                URL
              </Text>
              <Text fontWeight="light" size="small">
                {resource.url}
              </Text>
            </div>
          )}
          <Spacer size={5} />
          <div className="d-flex gap-5 justify-content-center">
            <Button variant="secondary" disabled={state === 'loading'} onClick={handleArchive}>
              <Icon size="sm" id="archive" />
              Archive
            </Button>
            <Button
              variant="primary"
              disabled={['loading', 'error'].includes(state)}
              onClick={handleSubmit}
            >
              Save Details
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const SharedByDetails = ({ name, createdAt }: { name: string; createdAt: DateTime }) => {
  return (
    <div className="d-flex gap-4">
      <Text fontWeight="light" size="medium">
        Shared by: <b>{name}</b>
      </Text>
      <Text fontWeight="light" size="medium">
        {dateStamp(createdAt)}
      </Text>
    </div>
  );
};
