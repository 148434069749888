import { commonReducers } from '@polygence/common';
import { useDispatch } from 'react-redux';

import type { ActionStateProps } from 'src/components/hermes/sessions/actions/ActionStateProps';
import { ClickableState } from 'src/components/hermes/sessions/actions/State';

const ConfirmProposalClickableState = ({ sessionId }: ActionStateProps) => {
  const dispatch = useDispatch();
  const handleClick = () =>
    dispatch(commonReducers.hermesActions.openScheduler({ sessionId, responding: true }));

  return (
    <ClickableState iconName="add_date" variant="danger" label="Respond" onClick={handleClick} />
  );
};

export { ConfirmProposalClickableState };
