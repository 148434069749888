import type { CustomButtonProps } from '@polygence/components';
import { Button, Modal, ModalBody } from '@polygence/components';
import {
  ComponentProps,
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

const ALIGN_BUTTON_CLASSES = {
  center: 'justify-content-center',
  right: 'justify-content-end',
  left: 'justify-content-start',
} as const;

interface ConfirmationModalProps {
  trigger: ReactElement<{ onClick: () => void }>;
  triggerClassName?: string;
  action?: ReactElement<{ onClick: () => void }>;
  children: ReactNode;
  skipConfirmation?: boolean;
  canConfirm?: boolean;
  canCancel?: boolean;
  confirmButtonText?: string;
  confirmButtonVariant?: CustomButtonProps['variant'];
  cancelButtonText?: string;
  cancelButtonVariant?: CustomButtonProps['variant'];
  alignButtons?: keyof typeof ALIGN_BUTTON_CLASSES;
  showControls?: boolean;
  backdrop?: ComponentProps<typeof Modal>['backdrop'];
}

export const ConfirmationModalContext = createContext<{ closeModal: () => void }>({
  closeModal: () => {
    return;
  },
});

export const ConfirmationModal = ({
  trigger,
  triggerClassName,
  action = trigger,
  children,
  skipConfirmation,
  canConfirm = true,
  canCancel = true,
  confirmButtonText = 'Yes, I confirm',
  confirmButtonVariant = 'danger',
  cancelButtonText = 'No, go back',
  cancelButtonVariant = 'secondary',
  alignButtons = 'center',
  showControls = true,
  backdrop = 'static',
}: ConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    setIsOpen(true);
  };
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const alignButtonsClass = ALIGN_BUTTON_CLASSES[alignButtons] || ALIGN_BUTTON_CLASSES.center;

  const confirmationModalContextValue = useMemo(() => {
    return {
      closeModal: close,
    };
  }, [close]);

  if (skipConfirmation) {
    return action;
  }

  return (
    <ConfirmationModalContext.Provider value={confirmationModalContextValue}>
      <div
        className={triggerClassName}
        onClickCapture={(event) => {
          event.stopPropagation();
          open();
        }}
      >
        {trigger}
      </div>
      <Modal show={isOpen} backdrop={backdrop} onHide={close} closeButton={!showControls}>
        <ModalBody>
          <div className="px-3 py-2">
            <div className="d-flex justify-content-center">{children}</div>
            {showControls && (
              <div className={`d-flex ${alignButtonsClass} mt-7 gap-5`}>
                {canCancel && (
                  <Button onClick={close} variant={cancelButtonVariant}>
                    {cancelButtonText}
                  </Button>
                )}
                <Button
                  disabled={!canConfirm}
                  onClick={
                    canConfirm
                      ? () => {
                          action.props.onClick();
                          close();
                        }
                      : undefined
                  }
                  variant={confirmButtonVariant}
                >
                  {confirmButtonText}
                </Button>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};
