import { Icon } from 'src/components/Icon';

export const SuccessConfirmation = ({ proposing = false }) => {
  return (
    <>
      <div className="text-center">
        <Icon name="done" color="#02e0c0" size="50px" />
      </div>
      {proposing ? (
        <>
          <p className="text-center text-primary m-0">Thank you for proposing a time!</p>
          <p className="text-center text-primary">
            Your mentor will be asked to confirm the time and date.
          </p>
        </>
      ) : (
        <p className="text-center text-primary" data-cy="session-scheduled">
          Your session is scheduled.
        </p>
      )}
    </>
  );
};
