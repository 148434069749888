import { commonHooks } from '@polygence/common';
import classNames from 'classnames';

import { formatDatetimeAsLocal } from 'src/components/aux/dateStamp';
import { isMentor, isStudent } from 'src/components/getMyInfo';
import MentorProposalMessage from 'src/components/hermes/messages/MentorProposalMessage';
import StudentProposalMessage from 'src/components/hermes/messages/StudentProposalMessage';

export interface SessionProps {
  scheduledAt: string;
  sessionNumber: number;
  sessionScheduled: boolean | undefined;
}

export interface ProposedSessionProps {
  sentBy: { firstName: string };
  extra: ExtraProps;
}

export interface ExtraProps {
  sessionId: number;
  scheduledAt: string;
  sessionNumber: number;
  proposalDeclineReason: string;
}

const ProposedSession = ({
  sentBy,
  extra: {
    sessionId,
    scheduledAt: scheduledAtFallback,
    sessionNumber: sessionNumberFallback,
  } = {} as ExtraProps,
}: ProposedSessionProps) => {
  const project = commonHooks.useSelectedProject();
  const session = project?.sessions?.find(({ id }) => {
    return id === sessionId;
  });

  const meeting = commonHooks.useMeetingById(session?.meetingId);
  const scheduledAt = meeting?.scheduledAt || scheduledAtFallback;
  const sessionNumber = session?.sessionNumber || sessionNumberFallback;
  const sessionScheduled = session && !session.proposing;
  const sessionCanceled = !session;

  const formattedDate = formatDatetimeAsLocal(scheduledAt);

  if (!(scheduledAt && sessionNumber)) {
    return null;
  }

  return (
    <div
      data-cy="proposed-session-message"
      className={classNames('h-proposed-session-message', {
        'text-line-through': sessionCanceled,
      })}
    >
      {isStudent() && (
        <StudentProposalMessage
          sessionNumber={sessionNumber}
          scheduledAt={formattedDate}
          sessionScheduled={sessionScheduled}
        />
      )}
      {isMentor() && (
        <MentorProposalMessage
          studentName={sentBy.firstName}
          sessionId={sessionId}
          sessionNumber={sessionNumber}
          scheduledAt={formattedDate}
          sessionScheduled={sessionScheduled}
          sessionCanceled={sessionCanceled}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProposedSession;
