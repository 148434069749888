import { LoginData } from '@polygence/common/types/backend';
import { UUID } from '@polygence/common/types/common';

import { VerifyAccount } from 'src/components/auth/PasswordlessSignUp/VerifyAccount';
import DateWrapper from 'src/components/aux/dateWrapper';
import { useVerifyAccountMutation } from 'src/reducers/whiteLabelReducer';

interface WhiteLabelVerifyAccountProps {
  domain: string;
  onLogin: (data: Pick<LoginData, 'access' | 'refresh'>, callback?: () => void) => void;
}

export const WhiteLabelVerifyAccount = ({ domain, onLogin }: WhiteLabelVerifyAccountProps) => {
  const [verifyAccount] = useVerifyAccountMutation();

  const handleAccountVerification = (uuid: UUID, password: string) => {
    return verifyAccount({
      domain,
      payload: {
        uuid,
        password,
        timeZone: DateWrapper.guessTimezone(),
      },
    }).unwrap();
  };

  return <VerifyAccount onAccountVerification={handleAccountVerification} onLogin={onLogin} />;
};
