import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import type { AdmissionAdvisorModal } from '@polygence/common/types/marketplace';

import styles from 'src/components/student/ApplicationHubPage/ApplicationHubPage.module.scss';
import { ApplicationCompleteStep } from 'src/components/student/ApplicationHubPage/StudentCongratulationPage/ApplicationCompleteStep';
import { InterviewStep } from 'src/components/student/ApplicationHubPage/StudentCongratulationPage/InterviewStep';
import { LoginToWorkspaceStep } from 'src/components/student/ApplicationHubPage/StudentCongratulationPage/LoginToWorkspaceStep';
import { ScholarshipStep } from 'src/components/student/ApplicationHubPage/StudentCongratulationPage/ScholarshipStep';

export const StudentCongratulationPage = ({
  admissionAdvisor,
  isScholarship = false,
}: {
  admissionAdvisor?: AdmissionAdvisorModal;
  isScholarship?: boolean;
}) => {
  const currentUser = useCurrentUser();
  const partnerPaysWorkflow = currentUser.otherInfo['isPartnerPaysWorkflow'] as boolean;
  return (
    <div className={styles['flexContainer']}>
      <ApplicationCompleteStep partnerPaysWorkflow={partnerPaysWorkflow} />
      {isScholarship ? (
        <ScholarshipStep />
      ) : (
        <>
          {admissionAdvisor && (
            <InterviewStep
              admissionAdvisor={admissionAdvisor}
              partnerPaysWorkflow={partnerPaysWorkflow}
            />
          )}
          <LoginToWorkspaceStep partnerPaysWorkflow={partnerPaysWorkflow} />
        </>
      )}
    </div>
  );
};
