import type { Lounge } from '@polygence/common';

import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';

const RoomListLoungePicture: React.FC<{ lounge: Lounge }> = ({ lounge }) => {
  const image = lounge.image || defaultProfileImage;
  return (
    <div className="h-profile-pictures py-3">
      <img src={image} alt={lounge.name} />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default RoomListLoungePicture;
