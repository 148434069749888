import { commonReducers } from '@polygence/common';
import { Text } from '@polygence/components';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { StudentJourneyTileContext } from 'src/components/Tiles/StudentJourney/StudentJourneyTile';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';
import { ShowcasingMatchingSurveyModal } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingSurveyModal';
import { useAppDispatch } from 'src/store';
import { getTracker } from 'src/utils/tracking/factory';

export const SurveyPSSJourneyTile = () => {
  const { showWorkspaceLinks } = useContext(StudentJourneyTileContext);
  const dispatch = useAppDispatch();
  const tracker = getTracker();
  const { data } = useStudentJourney();

  if (!data || !data.pss) {
    return null;
  }

  const handleClick = () => {
    dispatch(commonReducers.showcasingAddonActions.openMatchingModal(data.pss.coreProjectId));

    tracker.track('Get Matched', {
      category: 'student_journey_tile',
      projectId: data.pss.coreProjectId,
    });
  };

  return (
    <div className="d-flex flex-column flex-fill align-items-center justify-content-center">
      <Text size="small" alignment="center" textWrap="balance" className="pt-4 pb-5">
        It’s time to think about showcasing your project! Get matched with your dedicated showcasing
        specialist now.
      </Text>
      {showWorkspaceLinks && (
        <Button size="sm" variant="secondary" onClick={handleClick}>
          Get Matched
        </Button>
      )}
      <ShowcasingMatchingSurveyModal />
    </div>
  );
};
