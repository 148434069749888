import { commonHooks, MeetingId, permissions, ProjectId, SessionId } from '@polygence/common';
import { Button, Icon, Spacer, Tooltip } from '@polygence/components';

import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import { RenderIfAnyValue } from 'src/components/common/RenderIfAny';
import { ZoomJoin } from 'src/components/hermes/ZoomJoin';
import { SessionMeetingCancelModal } from 'src/components/hermes/sessions/SessionMeetingCancel';
import ReportMentorNoShow from 'src/components/hermes/sessions/mentor-no-show/ReportMentorNoShow';
import useIsPastDateTime from 'src/hooks/useIsPastDateTime';

const RESCHEDULE_DISABLED_TIP = `You are within 24 hours of your upcoming session. Rescheduling is disabled at this time.`;

const RescheduleTooltip = ({
  children,
  rescheduleDisabled,
}: {
  children: JSX.Element;
  rescheduleDisabled: boolean;
}) => {
  if (!rescheduleDisabled) {
    return children;
  }

  return (
    <Tooltip tip={RESCHEDULE_DISABLED_TIP} placement="top">
      <div className="reschedule-tooltip">{children}</div>
    </Tooltip>
  );
};

export const MeetingTime = ({
  meetingId,
  projectId,
  sessionId,
  timeZone,
  handleEdit,
  cancellable = false,
}: {
  meetingId: MeetingId;
  projectId?: ProjectId;
  sessionId?: SessionId;
  timeZone: string;
  handleEdit?: () => void;
  cancellable?: boolean;
}) => {
  const meeting = commonHooks.useMeetingById(meetingId);
  const session = commonHooks.useSessionByMeetingId(meeting?.id);
  const userPermissions = commonHooks.usePermissions();
  const scheduledAt = new DateWrapper(meeting?.scheduledAt).setTimezone(timeZone, 'default');
  const currentDate = new DateWrapper(Date.now()).setTimezone(timeZone, 'default');
  const isMeetingInPast = scheduledAt.date < currentDate.date;
  const oneDayBeforeMeeting = scheduledAt.date.subtract(24, 'hour');
  const isLessThan24HoursUntil = useIsPastDateTime({
    dateTime: oneDayBeforeMeeting.toISOString(),
  });

  const isLateCancel =
    (isMeetingInPast || isLessThan24HoursUntil) &&
    !session?.proposing &&
    (permissions.canPropose(userPermissions) || permissions.canScheduleLimited(userPermissions));
  const joinable = commonHooks.useMeetingJoinable(meeting, session);

  return (
    <div className="h-session-time mb-2">
      <time>{scheduledAt.format(DateTimeFormat.FORMAT_6)}</time>
      <RenderIfAnyValue values={[handleEdit]}>
        <Spacer size={3} />
        <div className="d-flex justify-content-start justify-content-lg-center mb-2">
          {handleEdit && (
            <RescheduleTooltip rescheduleDisabled={isLateCancel}>
              <Button
                data-value="Reschedule"
                variant="secondary"
                size="sm"
                onClick={handleEdit}
                disabled={isLateCancel}
              >
                Reschedule
              </Button>
            </RescheduleTooltip>
          )}
          {session?.cancellable && cancellable && (
            <SessionMeetingCancelModal
              projectId={projectId}
              sessionId={sessionId}
              meetingId={meetingId}
              isLateCancel={isLateCancel}
            />
          )}
        </div>
      </RenderIfAnyValue>
      {joinable && projectId && (
        <div className="d-flex flex-column align-items-start align-items-lg-center">
          <Spacer size={3} />
          <ZoomJoin meeting={meeting} canJoin={joinable} sourceLocation="session_list">
            <Button startIcon={<Icon id="camera" />} variant="primary" size="sm" className="me-3">
              Join Zoom
            </Button>
          </ZoomJoin>
          <Spacer size={2} />
          <ReportMentorNoShow meeting={meeting} />
          <Spacer size={2} />
          {!!meeting?.zoom['password'] && (
            <p className="text-muted">
              Zoom Password: <span>{(meeting?.zoom['password'] as string | undefined) ?? ''}</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};
