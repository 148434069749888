import type { DateTime, UserId, WorkspaceId } from '@polygence/common';

import DateWrapper, { DateTimeFormat, TimeFormat } from 'src/components/aux/dateWrapper';
import { ProfilePicture } from 'src/components/common/ProfilePicture/ProfilePicture';
import { UserCardTrigger } from 'src/components/common/UserCard/UserCardTrigger';

interface ChatPartnerProps {
  id: UserId;
  workspaceId: WorkspaceId;
  profilePicture: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  displayedTime?: DateTime | Date;
  showImages?: boolean;
  showNames?: boolean;
  shortFormat?: boolean;
}

export const ChatPartner = ({
  id: userId,
  workspaceId,
  profilePicture,
  firstName,
  lastName,
  timeZone,
  displayedTime = new Date(),
  showImages = false,
  showNames = false,
  shortFormat = false,
}: ChatPartnerProps) => {
  const currentTime = new DateWrapper(displayedTime).setTimezone(timeZone, 'default');
  const formatter = shortFormat ? TimeFormat.FORMAT_2 : DateTimeFormat.FORMAT_11;
  return (
    <div className="h-partner d-flex gap-3 align-items-center">
      {showImages && (
        <UserCardTrigger userId={userId} workspaceId={workspaceId}>
          <ProfilePicture user={{ profilePicture, firstName, lastName }} size={40} />
        </UserCardTrigger>
      )}
      <div className="h-partner-time">
        <div className="d-flex flex-row align-items-center gap-2">
          <p className="text-bold">{showNames ? 'Time for' : 'Current time for'}</p>
          <UserCardTrigger userId={userId} workspaceId={workspaceId}>
            <p className="text-bold text-secondary">{firstName}</p>
          </UserCardTrigger>
          <p className="text-bold">:</p>
        </div>
        <time>{currentTime.format(formatter)}</time>
      </div>
    </div>
  );
};
