import { useListMessageThreadsQuery } from '@polygence/common/api/hermes-ai';
import useSelectedWorkspaceId from '@polygence/common/hooks/selectors/useSelectedWorkspaceId';
import { useMemo } from 'react';

import { dayjs } from 'src/utils/dayjsCustom';

export const useListMessageThreads = () => {
  const selectedWorkspaceId = useSelectedWorkspaceId();
  const { data, isLoading } = useListMessageThreadsQuery(selectedWorkspaceId ?? 0, {
    skip: !selectedWorkspaceId,
    refetchOnMountOrArgChange: true,
  });

  return useMemo(() => {
    if (!selectedWorkspaceId) {
      return { data: [], isLoading: false };
    }

    return {
      data: [...(data ?? [])].sort((a, b) =>
        dayjs(a.lastMessageAt).diff(dayjs(b.lastMessageAt)) > 0 ? -1 : 1,
      ),
      isLoading,
    };
  }, [data, selectedWorkspaceId, isLoading]);
};
