import type { OpportunityCardSideBoxProps } from '@polygence/common/types/showcasing';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Badge, Icon, Button } from '@polygence/components';
import classNames from 'classnames';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import styles from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityCard.module.scss';

export const OpportunityCardSideBox = ({
  generalWebsite,
  submissionDetails,
  deadline,
  deadlineAt,
  submissionFee,
  submissionFeeInCents,
  originalResearch,
  reviewPaper,
  peerReviewed,
  fastTrackReview,
  approximateReviewTime,
}: OpportunityCardSideBoxProps) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column flex-shrink-0 pt-5 pt-md-0 ps-md-5 align-items-start',
        styles['sideBox'],
      )}
    >
      {generalWebsite && (
        <Button
          size="sm"
          variant="link"
          href={generalWebsite}
          endIcon={<Icon id="external-link" />}
          className="ms-n6"
          {...externalLink}
        >
          Website
        </Button>
      )}
      {submissionDetails && (
        <Button
          size="sm"
          variant="link"
          href={submissionDetails}
          endIcon={<Icon id="external-link" />}
          className="ms-n6"
          {...externalLink}
        >
          Submission page
        </Button>
      )}
      <div className="mt-5 mb-8 d-flex flex-wrap gap-5 flex-column align-items-start">
        {deadline && (
          <Badge variant="danger" size="small" className="d-flex align-items-center gap-2">
            <Icon id="calendar" size="sm" />
            <span className="text-capitalize">
              {deadlineAt
                ? `By ${new DateWrapper(deadlineAt).format(DateFormat.FORMAT_4)}`
                : 'Deadline'}
            </span>
          </Badge>
        )}
        {submissionFee && (
          <Badge variant="secondary" size="small" className="d-flex align-items-center gap-2">
            <Icon id="dollar-sign" size="sm" />
            <span className="text-capitalize">
              {submissionFeeInCents ? `${submissionFeeInCents / 100} USD` : 'Submission fee'}
            </span>
          </Badge>
        )}
        {originalResearch && (
          <Badge variant="warning" size="small" className="d-flex align-items-center gap-2">
            <Icon id="book-open" size="sm" />
            <span className="text-capitalize">Experimental research</span>
          </Badge>
        )}
        {reviewPaper && (
          <Badge variant="tertiary" size="small" className="d-flex align-items-center gap-2">
            <Icon id="pen-tool" size="sm" />
            <span className="text-capitalize">Accepts review papers</span>
          </Badge>
        )}
        {peerReviewed && (
          <Badge variant="success" size="small" className="d-flex align-items-center gap-2">
            <Icon id="check-circle" size="sm" />
            <span className="text-capitalize">Peer reviewed</span>
          </Badge>
        )}
        {approximateReviewTime && (
          <Badge variant="warning" size="small" className="d-flex align-items-center gap-2">
            <Icon id="clock" size="sm" />
            <span>Usually reviewed {approximateReviewTime}</span>
          </Badge>
        )}
        {fastTrackReview && (
          <Badge variant="dark" size="small" className="d-flex align-items-center gap-2">
            <Icon id="zap" size="sm" />
            <span className="text-capitalize">Fast Track Review</span>
          </Badge>
        )}
      </div>
    </div>
  );
};
