import { WorkspaceId } from '@polygence/common';
import { hermesAiApi, useCreateAiMessageThreadMutation } from '@polygence/common/api/hermes-ai';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';
import { useAppDispatch } from 'src/store';

export const useCreateAiMessageThread = () => {
  const [createAiMessageThread, { isLoading }] = useCreateAiMessageThreadMutation();
  const aiMessageThreadUrl = useHermesAIRouteUrl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const createMessageThread = useCallback(
    (workspaceId: WorkspaceId) => {
      return createAiMessageThread(workspaceId)
        .unwrap()
        .then(({ id }) => {
          return dispatch(hermesAiApi.endpoints.getMessages.initiate(id)).unwrap();
        })
        .then(({ id }) => {
          history.push(`${aiMessageThreadUrl}/${id}`);
          return id;
        });
    },
    [createAiMessageThread, dispatch, history, aiMessageThreadUrl],
  );

  return [createMessageThread, { isLoading }] as const;
};
