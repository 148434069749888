import { commonThunks, useFeatureToggle, commonHooks } from '@polygence/common';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { ConfettiAnimation } from 'src/components/ConfettiAnimation';
import { TodoListItem } from 'src/components/todo/TodoListItem';
import { useAppDispatch } from 'src/store';

export const TodoList = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const { data: todoItems } = commonHooks.useTodoItems();

  useEffect(() => {
    void dispatch(commonThunks.hermesThunks.getTodoItems());
  }, [dispatch]);

  const unseenCompletedItems = useMemo(
    () =>
      todoItems?.filter(
        (item) => !item.blueprint.manual && item.status === 'completed' && !item.completionSeenAt,
      ) ?? [],
    [todoItems],
  );

  const incompleteItems = useMemo(
    () => todoItems?.filter((item) => item.status === 'created') ?? [],
    [todoItems],
  );

  useClickAway(containerRef, () => {
    for (const itemId of selectedItemIds) {
      void dispatch(
        commonThunks.hermesThunks.updateTodoItem({
          id: itemId,
          payload: { status: 'completed' },
        }),
      );
    }

    setSelectedItemIds([]);
  });

  useEffect(() => {
    if (unseenCompletedItems.length) {
      void dispatch(
        commonThunks.hermesThunks.markCompletedTodoItemsAsSeen({
          todoItemIds: unseenCompletedItems.map((item) => item.id),
        }),
      );
    }
  }, [dispatch, unseenCompletedItems]);

  const todoEnabled = useFeatureToggle('todoEnabled');
  if (!todoEnabled) {
    return null;
  }

  const totalTodoCount = todoItems?.length ?? 0;
  const completedTodoCount = totalTodoCount - incompleteItems.length + selectedItemIds.length;
  const todoCounterText = totalTodoCount ? `${completedTodoCount}/${totalTodoCount}` : '';

  return (
    <div ref={containerRef} className="tw-relative tw-flex tw-w-full tw-flex-col tw-px-7 tw-py-5">
      <h2 className="tw-mb-3 tw-select-none tw-text-xl">Stay on track {todoCounterText}</h2>
      <ul className="tw-m-0 tw-flex tw-w-full tw-list-none tw-flex-col tw-p-0">
        <AnimatePresence>
          {!incompleteItems.length && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ delay: 0.5 }}
            >
              <p className="tw-text-xs tw-font-bold">
                You did it! You've completed every single item on your list.
              </p>
            </motion.div>
          )}
          {unseenCompletedItems.map((item) => (
            <TodoListItem
              key={item.id}
              title={item.blueprint.title}
              description={item.blueprint.description}
              manual={item.blueprint.manual}
              checked
            />
          ))}
          {incompleteItems.map((item) => (
            <TodoListItem
              key={item.id}
              title={item.blueprint.title}
              description={item.blueprint.description}
              checked={selectedItemIds.includes(item.id)}
              manual={item.blueprint.manual}
              onChange={(checked) =>
                setSelectedItemIds((selectedItemIds) =>
                  checked
                    ? [...selectedItemIds, item.id]
                    : selectedItemIds.filter((itemId) => itemId !== item.id),
                )
              }
            />
          ))}
        </AnimatePresence>
      </ul>
      <ConfettiAnimation />
    </div>
  );
};
