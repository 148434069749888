import { commonHooks, WorkspaceDisplay, WorkspaceDisplayCategory } from '@polygence/common';
import { Heading, Icon } from '@polygence/components';
import classNames from 'classnames';
import type { KeyboardEventHandler } from 'react';

import ChatListItem from 'src/components/hermes/chat/WorkspaceList/ChatListItem';
import { usePersistentState } from 'src/hooks/usePersistentState';

export const WorkspaceCategoryGroup = ({
  category,
  workspaces,
}: {
  category: WorkspaceDisplayCategory;
  workspaces: WorkspaceDisplay[];
}) => {
  const selectedWorkspaceId = commonHooks.useSelectedWorkspaceId();
  const [isOpen, setIsOpen] = usePersistentState(true, `workspace_category_${category}`);

  const toggleOpen = () => {
    setIsOpen((open) => !open);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      toggleOpen();
    }
  };

  return (
    <div className={classNames('h-workspace-category-group', { closed: !isOpen })}>
      <div
        role="button"
        tabIndex={0}
        aria-controls={category}
        aria-expanded={isOpen}
        className="h-workspace-category-group-header"
        onClick={toggleOpen}
        onKeyDown={handleKeyDown}
      >
        <Heading as="h2" size="h5" alignment="left">
          {category}
        </Heading>
        <Icon
          className="h-workspace-category-group-header-icon"
          id={isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </div>
      <div id={category}>
        {workspaces.map((workspace) => {
          return (
            <ChatListItem
              key={workspace.id}
              workspace={workspace}
              isSelected={workspace.id === selectedWorkspaceId}
            />
          );
        })}
      </div>
    </div>
  );
};
