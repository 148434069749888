import { commonHooks } from '@polygence/common/hooks/';
import { Button, Tooltip } from '@polygence/components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAddAdditionalWritingFeedbackMutation } from 'src/reducers/feedbackRequestReducers';
import { urls } from 'src/urls';

const STUDENT_TOOLTIP =
  'Add an additional session of writing feedback to receive more assistance from a writing fellow on your work.';

const MENTOR_TOOLTIP =
  'Your student can click here to add additional writing feedback to their project.';

export const AdditionalWritingFeedbackButton = () => {
  const currentUser = commonHooks.useCurrentUser();
  const selectedProject = commonHooks.useSelectedProject();
  const [createAdditionalWritingFeedback] = useAddAdditionalWritingFeedbackMutation();
  const history = useHistory();

  const handleClick = () => {
    if (currentUser.utilities.isMentor || !selectedProject) {
      return;
    }
    createAdditionalWritingFeedback({ projectId: selectedProject.id })
      .unwrap()
      .then(({ paymentIntentUuid }) => {
        history.push(urls.paymentIntent(paymentIntentUuid));
      })
      .catch(() =>
        toast.error(
          'Could not create additional writing feedback. Please reach out to student team.',
        ),
      );
  };
  return (
    <Tooltip
      placement="auto"
      tip={currentUser.utilities.isStudent ? STUDENT_TOOLTIP : MENTOR_TOOLTIP}
    >
      <Button size="sm" onClick={handleClick}>
        Add Writing Feedback
      </Button>
    </Tooltip>
  );
};
