import { useEffect, useState } from 'react';

import { getServerTime } from '../api/backend';
import { useCommonDispatch } from '../store/store';
import { commonThunks } from '../store/thunks';
import { dayjs } from '../utils/dayjsCustom';

import useCurrentUser from './selectors/useCurrentUser';
import useSelectedProject from './selectors/useSelectedProject';

function isTimeCorrect(date: string | Date) {
  const diffInMinutes = dayjs().diff(dayjs(date), 'minute', true);
  return Math.abs(diffInMinutes) < 1;
}

export const useCheckServerTime = ({
  reportError,
}: {
  reportError?: (
    userTimeZone: string,
    userTime: string,
    originalServerTime: string,
    convertedServerTime: string,
  ) => void;
} = {}) => {
  const [isServerTimeCorrect, setIsServerTimeCorrect] = useState(true);
  const [serverTime, setServerTime] = useState('');
  const selectedProject = useSelectedProject();
  const currentUser = useCurrentUser();
  const dispatch = useCommonDispatch();

  useEffect(() => {
    getServerTime()
      .then(({ data: serverTime }) => {
        setServerTime(serverTime);
        setIsServerTimeCorrect(isTimeCorrect(serverTime));
      })
      .catch(() => setIsServerTimeCorrect(false));
  }, []);

  useEffect(() => {
    if (!isServerTimeCorrect) {
      const format = 'YYYY-MM-DD HH:mm:ss';
      if (reportError) {
        reportError(
          currentUser.timeZone,
          dayjs().format(format),
          serverTime,
          dayjs(serverTime).format(format),
        );
      }

      const interval = setInterval(() => {
        if (selectedProject?.id) {
          void dispatch(
            commonThunks.hermesThunks.getSessionsForProject({ projectId: selectedProject.id }),
          );
        }
      }, 60_000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [isServerTimeCorrect, serverTime, dispatch, selectedProject, currentUser, reportError]);
};
