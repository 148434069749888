import classNames from 'classnames';
import type { ReactNode } from 'react';

export const SessionListReminder = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <article className={classNames('h-session-list-item h-session-list-reminder', className)}>
      {children}
    </article>
  );
};
