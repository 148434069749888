import classNames from 'classnames';

import { Icon } from 'src/components/Icon';
import styles from 'src/components/hermes/sessions/actions/State.module.scss';
import { getCssVariable } from 'src/utils';

type BaseStateVariant = 'danger' | 'disabled' | 'info' | 'no-action' | 'success';

interface BaseStateProps {
  children: React.ReactNode;
  variant: BaseStateVariant;
  onClick?: React.MouseEventHandler;
}

interface StateProps {
  iconName: string;
  label?: string;
  variant: BaseStateVariant;
}

interface ClickableStateProps extends StateProps {
  onClick: React.MouseEventHandler;
}

const iconColors = {
  danger: getCssVariable('--danger-dark'),
  disabled: getCssVariable('--grayscale-5'),
  info: getCssVariable('--secondary-dark'),
  'no-action': getCssVariable('--grayscale-7'),
  success: getCssVariable('--grayscale-10'),
};

export const BaseState: React.FC<BaseStateProps> = ({
  variant,
  onClick: handleClick,
  children,
}) => {
  return (
    <button
      className={classNames(
        'd-flex flex-shrink-0 flex-column align-items-center justify-content-center h-action',
        styles['state'],
        styles[variant],
        { [styles['non-clickable'] as string]: handleClick == null },
      )}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export const State: React.FC<StateProps> = ({ variant, iconName, label }) => {
  return (
    <BaseState variant={variant}>
      <Icon name={iconName} color={iconColors[variant]} />
      {label}
    </BaseState>
  );
};

export const ClickableState: React.FC<ClickableStateProps> = ({
  onClick: handleClick,
  variant,
  label,
  iconName,
}) => {
  return (
    <BaseState variant={variant} onClick={handleClick}>
      <Icon name={iconName} color={iconColors[variant]} />
      {label}
    </BaseState>
  );
};
