import { commonReducers, commonThunks, SharedResource, ThunkAppDispatch } from '@polygence/common';
import { toast } from 'react-toastify';

import type { DropdownMenuProps } from 'src/components/DropdownMenu';

interface SharedResourceActionArgs {
  dispatch: ThunkAppDispatch;
  workspaceId: number;
  resource: SharedResource;
}

type SharedResourceActionCreator = (
  args: SharedResourceActionArgs,
) => DropdownMenuProps['items'][number];

const archive: SharedResourceActionCreator = ({ dispatch, workspaceId, resource }) => {
  return {
    name: 'archive',
    label: 'Archive',
    onClick: () => {
      dispatch(
        commonThunks.sharedResourcesThunks.updateSharedResource({
          resourceId: resource.id,
          workspaceId,
          payload: {
            archived: true,
          },
        }),
      ).catch(() => {
        toast.error('Error archiving shared resource.');
      });
    },
    iconId: 'archive',
  };
};

const unarchive: SharedResourceActionCreator = ({ dispatch, workspaceId, resource }) => {
  return {
    name: 'archive',
    label: 'Unarchive',
    onClick: () => {
      dispatch(
        commonThunks.sharedResourcesThunks.updateSharedResource({
          resourceId: resource.id,
          workspaceId,
          payload: {
            archived: false,
          },
        }),
      ).catch(() => {
        toast.error('Error archiving shared resource.');
      });
    },
    iconId: 'archive',
  };
};

const edit: SharedResourceActionCreator = ({ dispatch, resource }) => {
  return {
    name: 'edit',
    label: 'Edit',
    onClick: () => {
      dispatch(commonReducers.sharedResourcesActions.openSharedResourceEditModal(resource.id));
    },
    iconId: 'edit-2',
  };
};

export const getSharedResourceActions = ({
  resource,
  workspaceId,
  dispatch,
}: SharedResourceActionArgs) => {
  const ACTIONS = [];

  if (!resource.archived) {
    ACTIONS.push(archive, edit);
  } else {
    ACTIONS.push(unarchive);
  }

  function createAction(action: SharedResourceActionCreator) {
    return action({ resource, workspaceId, dispatch });
  }

  return ACTIONS.map(createAction);
};
