import { Text } from '@polygence/components';

import { ApplicationHubCard } from 'src/components/student/ApplicationHubPage/ApplicationHubCard';

export const ScholarshipStep = () => {
  return (
    <ApplicationHubCard narrow step={2} status="due">
      <Text size="medium" fontWeight="bold">
        Sit tight while we review your application!
      </Text>
      <Text size="medium">We’ll be in touch soon</Text>
    </ApplicationHubCard>
  );
};
