import { OUTPUT_TYPE_OPTIONS } from 'src/components/hermes/constants';

const display = {
  $and: [
    { $is: { field: '__firstSessionSurveyCompleted', value: 'true' } },
    { $not: { field: '__thirdSessionSurveyCompleted', value: 'true' } },
    { $gte: { field: '__completedSessionCount', value: 2 } },
    { $not: { field: 'countAs', value: 'no_show' } },
  ],
} as const;

/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default [
  {
    component: 'FormInput',
    props: {
      label: "What is the working title of your student's project?",
      name: 'sessionThreeProjectTitle',
      inputType: 'text',
      small_font: 'This will only be visible to you and your student. You can update it anytime.',
      required: true,
      htmlRequired: true,
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label:
        'Please write a paragraph (5+ sentences) describing the topic and project that your student is working on.',
      name: 'sessionThreeProjectDescription',
      inputType: 'textarea',
      small_font: 'This will be visible to you and your student and you can update it anytime.',
      required: true,
      htmlRequired: true,
    },
    display,
  },
  {
    component: 'SelectInput',
    props: {
      label: 'What type of project is your student doing?',
      name: 'expectedOutputType',
      inputType: 'textarea',
      options: OUTPUT_TYPE_OPTIONS,
      required: true,
      htmlRequired: true,
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Please write down the type of the project here:',
      name: 'expectedOutputTypeOther',
      inputType: 'text',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: { field: 'expectedOutputType', value: 'other' },
    },
  },
  {
    component: 'SelectInput',
    props: {
      label:
        'We encourage all students to showcase their work in some way. How will your student do so?',
      name: 'showCaseType',
      inputType: 'textarea',
      small_font: "This is for internal use only - we don't share it with anybody.",
      options: [
        {
          value: 'submit_to_publication',
          label: 'Submitting for publication to student journal',
        },
        { value: 'create_own_blog', label: 'Creating their own blog' },
        { value: 'podcast', label: 'Podcast on iTunes/Spotify/etc.' },
        { value: 'video', label: 'Video(s) on YouTube' },
        {
          value: 'not_public',
          label: 'My student does not want to make his/her project available to the public',
        },
        { value: 'other', label: 'Other' },
        {
          value: 'not_sure_will_discuss',
          label: "I'm not sure. We will discuss this in our next session.",
        },
      ],
      required: true,
      htmlRequired: true,
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Please let us know how:',
      name: 'showCaseTypeOther',
      inputType: 'text',
      required: true,
      htmlRequired: true,
    },
    display: {
      $and: [
        { $is: { field: '__sessionNumber', value: 3 } },
        { $is: { field: 'showCaseType', value: 'other' } },
      ],
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
  {
    component: 'SelectInput',
    props: {
      label:
        'To what extent do you have a plan in place for your student to finish by the final session?',
      name: 'sessionThreeTracking',
      small_font: "This is for internal use only - we don't share it with anybody.",
      options: [
        {
          label: 'Not at all - s/he may not finish in time',
          value: 'not at all"',
        },
        {
          label: 'Somewhat - s/he should finish in time',
          value: 'somewhat',
        },
        {
          label: 'Yes - s/he is on track!',
          value: 'on track',
        },
      ],
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Please elaborate:',
      name: 'sessionThreeTrackingElaborate',
      inputType: 'text',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: { field: '__sessionNumber', value: 3 },
      $not: { field: 'countAs', value: 'no_show' },
      $includes: {
        field: 'sessionThreeTracking',
        value: ['somewhat', 'not at all'],
      },
    },
  },
  {
    component: 'NumberSelector',
    props: {
      label:
        "How feasible do you think the student's project is, based on your understanding of the student's skills?",
      name: 'sessionThreeFeasibility',
      required: true,
      startFrom: 0,
      numberCount: 11,
    },
    display,
  },
  {
    component: 'SelectInput',
    props: {
      label: 'What is your primary area of concern?',
      name: 'sessionThreeAreaOfConcern',
      required: true,
      options: [
        {
          label: 'No concerns',
          value: 'no_concerns',
        },
        {
          label: 'Project timeline is too short',
          value: 'project_timeline_too_short',
        },
        {
          label: 'Student has a skill gap',
          value: 'student_has_skill_gap',
        },
        {
          label: 'Concerns around background knowledge/foundation',
          value: 'concerns_around_background_knowledge',
        },
        {
          label: 'Concerns around writing',
          value: 'concerns_around_writing',
        },
        {
          label: 'Concerns around coding',
          value: 'concerns_around_coding',
        },
        {
          label: 'Scope is too ambitious',
          value: 'scope_too_ambitious',
        },
        {
          label: 'Project requires lab space or materials that will be difficult to access',
          value: 'project_requires_lab_space_or_materials_that_will_be_difficult_to_access',
        },
        {
          label: 'Unrealistic showcasing goal',
          value: 'unrealistic_showcasing_goal',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Please elaborate:',
      name: 'sessionThreeAreaOfConcernOther',
      inputType: 'text',
      required: true,
      htmlRequired: true,
    },
    display: {
      ...display,
      $includes: {
        field: 'sessionThreeAreaOfConcern',
        value: ['other'],
      },
    },
  },
  {
    component: 'RadioButton',
    props: {
      name: 'thirdSessionSurveyCompleted',
      options: [
        {
          label: '',
          value: true,
          default: true,
        },
      ],
      hidden: true,
    },
    display,
  },
] as const;
