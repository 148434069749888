import { commonHooks, ensureProtocol, externalLink, SharedResource } from '@polygence/common';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SharedResourceFavourite } from 'src/components/hermes/SharedResource/SharedResourceFavourite';
import { SharedResourceIcon } from 'src/components/hermes/SharedResource/SharedResourceIcon';
import styles from 'src/components/hermes/SharedResource/SharedResourceItem.module.scss';
import { SharedResourceMenu } from 'src/components/hermes/SharedResource/SharedResourceMenu';
import { getSharedResourceActions } from 'src/components/hermes/SharedResource/sharedResourceActions';
import { useAppDispatch } from 'src/store';

interface SharedResourceProps {
  resource: SharedResource;
}

export const SharedResourceItem = ({ resource }: SharedResourceProps) => {
  const dispatch = useAppDispatch();
  const workspaceId = commonHooks.useSelectedWorkspaceId() ?? 0;
  const url = ensureProtocol(resource.url);

  return (
    <motion.div
      layout
      transition={{ duration: 0.4 }}
      className={classNames('btn btn-sm', styles['sharedResourceItem'])}
    >
      <a href={url} title={url} {...externalLink} className={styles['icon']}>
        <SharedResourceIcon resource={resource} />
      </a>
      <a href={url} title={url} {...externalLink} className={styles['link']}>
        {resource.displayName}
      </a>
      <div className="d-flex gap-2">
        {!resource.archived && (
          <SharedResourceFavourite resource={resource} workspaceId={workspaceId} />
        )}
        <SharedResourceMenu
          sharedResourceActions={getSharedResourceActions({ resource, dispatch, workspaceId })}
        />
      </div>
    </motion.div>
  );
};
