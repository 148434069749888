import { commonReducers } from '@polygence/common';
import { useDispatch } from 'react-redux';

import type { ActionStateProps } from 'src/components/hermes/sessions/actions/ActionStateProps';
import { ClickableState } from 'src/components/hermes/sessions/actions/State';

const SubmitSummaryClickableState = ({ sessionId }: ActionStateProps) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (sessionId) {
      dispatch(commonReducers.hermesActions.openSummarySubmitter({ sessionId }));
    }
  };
  return (
    <ClickableState
      iconName="new_file"
      variant="danger"
      label="Submit Summary"
      onClick={handleClick}
    />
  );
};

export { SubmitSummaryClickableState };
