import { Icon, InputField, Spacer } from '@polygence/components';
import type { ChangeEvent, FC } from 'react';

import MarkdownTextEditor from 'src/components/common/MarkdownTextEditor';
import 'src/components/hermes/CreateAssignment/assignment.sass';

interface AssignmentProps {
  assignment: { title: string; description: string; _id: string };
  onChange: (assignment: { title: string; description: string }) => void;
  removeAssignment: () => void;
  enableRemove: boolean;
  error: { _id: string; title: string; description: string };
  optional: boolean;
}

const MAX_TITLE_LENGTH = 256;

const Assignment: FC<AssignmentProps> = ({
  assignment,
  onChange,
  removeAssignment,
  enableRemove,
  optional,
}) => {
  const { title, description } = assignment;

  const setTitle = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...assignment, title: value });
  };

  const setDescription = (description: string) => {
    onChange({ ...assignment, description });
  };

  const hasDescription = description.length > 0;
  const isTitleRequired = optional ? hasDescription : true;

  return (
    <div className="assignment-form__wrapper">
      {enableRemove && (
        <button type="button" onClick={removeAssignment} className="close-button">
          <Icon id="x" size="sm" />
        </button>
      )}
      <InputField
        name={`assignment-title-${assignment._id}`}
        value={title}
        onChange={setTitle}
        label="Title"
        required={isTitleRequired}
        maxLength={MAX_TITLE_LENGTH}
      />
      <Spacer size={5} />
      <MarkdownTextEditor
        name={`assignment-description-${assignment._id}`}
        defaultValue={description}
        onChange={setDescription}
        placeholder="Instructions"
        required={!optional}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Assignment;
