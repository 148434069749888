import debounce from 'lodash/debounce';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';

export const useUserActivity = () => {
  const [isActive, setIsActive] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const resetInactivityTimeout = useCallback(() => {
    const inactivityTimeout = 1000 * 30;

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsActive(false);
    }, inactivityTimeout);
  }, []);

  const handleUserActivity = useMemo(
    () =>
      debounce(
        () => {
          setIsActive(true);
          resetInactivityTimeout();
        },
        1000 * 5,
        {
          leading: true,
          trailing: false,
          maxWait: 1000 * 10,
        },
      ),
    [resetInactivityTimeout],
  );

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    events.forEach((event) =>
      window.addEventListener(event, () => {
        handleUserActivity();
      }),
    );

    resetInactivityTimeout();

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleUserActivity));
      clearTimeout(timeoutRef.current);
    };
  }, [handleUserActivity, resetInactivityTimeout]);

  return isActive;
};
