import {
  commonHooks,
  ExtensionRequest,
  Nullable,
  StringUserType,
  UserType,
} from '@polygence/common';
import { Button, Text } from '@polygence/components';
import type { FormEvent } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import {
  DatePickerChangeTarget,
  DatePickerWithLabel,
} from 'src/components/common/DatePickerWithLabel';
import { EXTENSION_REQUEST_ACTIONS } from 'src/components/hermes/constants';
import { dayjs } from 'src/utils/dayjsCustom';

interface ProjectDeadlineExtensionFormProps {
  data: Nullable<Partial<ExtensionRequest>>;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onCancel: ((event: FormEvent<HTMLButtonElement>) => void) | (() => void);
  onChange: (event: DatePickerChangeTarget) => void;
  action: (typeof EXTENSION_REQUEST_ACTIONS)[keyof typeof EXTENSION_REQUEST_ACTIONS];
}

const getHeaderText = (userType: StringUserType) => {
  if (userType === UserType.STUDENT) {
    return 'Send Extension Request to Mentor';
  }

  if (userType === UserType.MENTOR) {
    return 'Update Deadline';
  }

  return 'Extension request';
};

export const ProjectDeadlineExtensionForm = ({
  data,
  onSubmit: handleSubmit,
  onCancel: handleCancel,
  onChange: handleChange,
  action,
}: ProjectDeadlineExtensionFormProps) => {
  const project = commonHooks.useSelectedProject();
  const readOnly =
    action === EXTENSION_REQUEST_ACTIONS.student_view ||
    action === EXTENSION_REQUEST_ACTIONS.mentor_view;
  const responding = action === EXTENSION_REQUEST_ACTIONS.respond;
  const currentUser = commonHooks.useCurrentUser();
  const headerText = getHeaderText(currentUser.userType);

  const disabledInputFields = readOnly || responding;

  const requestOrUpdate = currentUser.userType === UserType.MENTOR ? 'Update' : 'Request';
  return (
    <form onSubmit={handleSubmit}>
      <h1 className="fs-1_3">{headerText}</h1>
      {!responding && currentUser.userType === UserType.MENTOR && (
        <Text size="medium">
          Have you discussed this with your student? If not, please do so before extending this
          project’s deadline.
        </Text>
      )}
      <DatePickerWithLabel
        id="extendedDeadline"
        name="extendedDeadline"
        label={responding ? 'Requested Project Deadline' : 'What is your desired deadline?'}
        placeholder="No value set"
        value={data?.extendedDeadline}
        onChange={handleChange}
        disabled={disabledInputFields}
        minDate={
          project?.expectedEndAt
            ? dayjs.max(dayjs(project.expectedEndAt), dayjs()).add(1, 'day').toDate()
            : new Date()
        }
        maxDate={
          project?.expectedEndAt && currentUser.userType === UserType.MENTOR
            ? dayjs.max(dayjs(project.expectedEndAt), dayjs()).add(60, 'day').toDate()
            : undefined
        }
        required
      />
      <FormInput
        inputType="textarea"
        type="textarea"
        name="extendedDeadlineBenefit"
        label="Why would this project benefit from an extended deadline?"
        value={data?.extendedDeadlineBenefit}
        onChange={handleChange}
        isDisabled={disabledInputFields}
        htmlRequired
        small_font={
          !responding
            ? 'Note: All answers in this request will be shared with students, parents, and mentors verbatim.'
            : ''
        }
      />
      <FormInput
        inputType="textarea"
        type="textarea"
        name="extendedDeadlineActions"
        label="What will be done over the extended period to ensure the project is completed by the new deadline?"
        value={data?.extendedDeadlineActions}
        onChange={handleChange}
        isDisabled={disabledInputFields}
        htmlRequired
        small_font={
          !responding
            ? 'Note: All answers in this request will be shared with students, parents, and mentors verbatim.'
            : ''
        }
      />
      {!readOnly && (
        <div className="d-flex justify-content-end gap-5">
          <Button variant="secondary" onClick={handleCancel}>
            {responding ? 'Decline' : 'Cancel'}
          </Button>
          <Button type="submit" variant="primary">
            {responding ? 'Accept' : requestOrUpdate}
          </Button>
        </div>
      )}
    </form>
  );
};
