import { usePrefetch } from '@polygence/common/api/hermes-ai';
import {
  hermesAiSelectors,
  hermesAiActions,
} from '@polygence/common/store/reducers/hermesAiReducer';
import { cn, Text } from '@polygence/components';
import * as Dialog from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { MessageCount } from 'src/components/hermes-ai/messages/toolbar/MessageCount';
import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';
import { useListMessageThreads } from 'src/components/hermes-ai/useMessageThreadList';
import { useAppSelector, useAppDispatch } from 'src/store';
import { dayjs } from 'src/utils/dayjsCustom';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.015,
    },
  },
};

const listItem = {
  hidden: { x: 20, opacity: 0 },
  show: { x: 0, opacity: 1 },
};

export const HermesAIThreadSelector = () => {
  const { data, isLoading } = useListMessageThreads();
  const selectedThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  const isThreadSelectorOpen = useAppSelector(hermesAiSelectors.isThreadSelectorOpen);
  const dispatch = useAppDispatch();
  const toggleThreadSelector = () => {
    dispatch(hermesAiActions.toggleThreadSelector);
  };
  const baseUrl = useHermesAIRouteUrl();
  const prefetch = usePrefetch('getMessages');

  return (
    <Dialog.Root open={isThreadSelectorOpen} modal={false} onOpenChange={toggleThreadSelector}>
      <Dialog.Content className="tw-isolate">
        <div
          className={cn(
            'tw-grid tw-h-full tw-w-full tw-items-start tw-justify-end tw-bg-gray-100',
            !data?.length && 'tw-items-center tw-justify-center',
          )}
        >
          {!data?.length && isLoading && (
            <Text size="medium" fontWeight="normal">
              Loading...
            </Text>
          )}
          {!data?.length && !isLoading && (
            <Text size="medium" fontWeight="normal">
              No conversations yet
            </Text>
          )}
          {!!data?.length && (
            <motion.ul
              className="tw-m-0 tw-flex tw-list-none tw-flex-col tw-gap-1 tw-p-0 tw-text-right"
              variants={container}
              initial="hidden"
              animate="show"
            >
              {data.map((thread) => {
                return (
                  <motion.li key={thread.id} variants={listItem}>
                    <Link
                      to={`${baseUrl}/${thread.id}`}
                      className={cn(
                        'tw-flex tw-items-center tw-justify-end tw-gap-2 tw-border tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-transparent tw-from-white tw-to-transparent tw-px-4 tw-py-2 tw-text-gray-800 hover:tw-border-gray-300 hover:tw-bg-gradient-to-l',
                        selectedThreadId === thread.id && 'tw-border-gray-300',
                      )}
                      onPointerDown={() => prefetch(thread.id)}
                      onMouseEnter={() => prefetch(thread.id)}
                    >
                      <MessageCount
                        count={thread.messageCount}
                        maxNumberOfMessages={thread.maxNumberOfMessages}
                      />
                      <Text
                        as="span"
                        size="medium"
                        fontWeight={selectedThreadId === thread.id ? 'bold' : 'light'}
                      >
                        {thread.conversationTitle || 'New conversation'}
                      </Text>
                      <span>-</span>
                      <Text
                        as="span"
                        size="small"
                        fontWeight="normal"
                        className="tw-text-gray-400"
                        title={dayjs(thread.lastMessageAt).format('LLL')}
                      >
                        {dayjs(thread.lastMessageAt).fromNow()}
                      </Text>
                    </Link>
                  </motion.li>
                );
              })}
            </motion.ul>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
