import { Text } from '@polygence/components';

import { preparationLengthQuestions } from 'src/components/hermes/surveys/mentor/generalQuestions';
import { PSS_TRACK_PROJECT_TYPES } from 'src/components/hermes/utils';

const displayAll = {
  $and: [
    { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } },
    { $not: { field: 'countAs', value: 'no_show' } },
  ],
};

const displayAllExceptLastSession = {
  $and: [
    { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } },
    { $not: { field: 'countAs', value: 'no_show' } },
    { $not: { field: '__sessionsRemaining', value: 1 } },
  ],
};

const displayOnlyLastSession = {
  $and: [
    { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } },
    { $not: { field: 'countAs', value: 'no_show' } },
    { $is: { field: '__sessionsRemaining', value: 1 } },
  ],
};

const generalQuestions = [
  {
    component: 'FormInput',
    props: {
      label: (
        <>
          Please provide a couple of sentences on what you covered in this session.{' '}
          <Text as="span" fontWeight="bold" size="medium">
            Please note that students and parents will be able to see your response to this
            question, but not for the following questions.
          </Text>
        </>
      ),
      inputType: 'textarea',
      name: 'content',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  ...preparationLengthQuestions,
  {
    component: 'FormInput',
    props: {
      label: 'What were your initial expectations for this session?',
      inputType: 'textarea',
      name: 'initialExpectations',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Based on these expectations, did this session meet them? Why or why not?',
      inputType: 'textarea',
      name: 'initialExpectationsFollowUp',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
];

const allExceptLastSessionQuestions = [
  {
    component: 'FormInput',
    props: {
      label: 'Was the showcasing planning template useful for you? Why or why not?',
      inputType: 'textarea',
      name: 'showcasingTemplateUsefulness',
      required: true,
      htmlRequired: true,
    },
    display: displayAllExceptLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Describe a moment in this session that surprised you and how did you handle it?',
      inputType: 'textarea',
      name: 'surprisedMoment',
      required: false,
    },
    display: displayAllExceptLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label: 'As you prepare for your next session, what questions do you still have?',
      inputType: 'textarea',
      name: 'remainingQuestions',
      required: false,
    },
    display: displayAllExceptLastSession,
  },
];

const onlyLastSessionQuestions = [
  {
    component: 'FormInput',
    props: {
      label:
        'Looking back, what do you wish you’d known before your first session or before you met this showcasing student?',
      inputType: 'textarea',
      name: 'knownBeforeFirstSession',
      required: true,
      htmlRequired: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Use three words to describe how you felt serving as a showcasing specialist',
      name: 'showcasingSpecialistFeelingInThreeWords',
      required: false,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'Paragraph',
    props: {
      text: 'Help us track this student’s outcome! What was this student’s showcasing outcome after working with you? (Please list all of the outlets the student submitted to and their status. To add additional outlets, click the "Add new outcome" button.)',
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'ShowcasingOutcomeInput',
    props: {
      name: 'showcasingOutcomes',
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label:
        'Are there any next steps with your student? Any unfinished work that wasn’t accomplished in your sessions together?',
      inputType: 'textarea',
      name: 'anyNextSteps',
      required: true,
      htmlRequired: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label:
        'We know there’s always room for improvement, what can Polygence do to make showcasing support even better for the mentor experience?',
      inputType: 'textarea',
      name: 'feedbackToPolygence',
      required: true,
      htmlRequired: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'RadioButton',
    props: {
      label: 'Are you willing to take on this role again?',
      name: 'willingToTakeAgain',
      options: [
        {
          label: 'Yes.',
          value: 'yes',
        },
        {
          label: 'No.',
          value: 'no',
        },
      ],
      required: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label: 'If no, why?',
      inputType: 'textarea',
      name: 'feedbackToPolygence',
      required: true,
      htmlRequired: true,
    },
    display: {
      $and: [displayOnlyLastSession, { $is: { field: 'willingToTakeAgain', value: 'no' } }],
    },
  },
  {
    component: 'FormInput',
    props: {
      label: 'Any additional thoughts or feedback?',
      inputType: 'textarea',
      name: 'additionalFeedback',
      required: false,
    },
    display: displayOnlyLastSession,
  },
];

export const premiumShowcasingQuestions = [
  ...generalQuestions,
  ...allExceptLastSessionQuestions,
  ...onlyLastSessionQuestions,
];
