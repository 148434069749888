import { Badge, Button } from '@polygence/components';
import { useState } from 'react';

interface OpportunityKeywordListProps {
  items: string[];
  initialDisplayItems?: number;
}

export const OpportunityKeywordList = ({
  items,
  initialDisplayItems = 7,
}: OpportunityKeywordListProps) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const [displayItems, setDisplayItems] = useState(Math.min(initialDisplayItems, items.length));

  const toggleItems = () => {
    setShowAllItems(!showAllItems);
    setDisplayItems(showAllItems ? Math.min(initialDisplayItems, items.length) : items.length);
  };

  return (
    <div className="d-flex flex-wrap gap-3 mb-5 align-items-center">
      {items.slice(0, displayItems).map((item) => (
        <Badge variant="light" pill key={item}>
          {item}
        </Badge>
      ))}
      {items.length > initialDisplayItems && (
        <Button variant="link" size="sm" onClick={toggleItems}>
          {!showAllItems ? '...Show more' : '...Show less'}
        </Button>
      )}
    </div>
  );
};
