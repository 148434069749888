import { externalLink } from '@polygence/common';
import React from 'react';
import Linkify from 'react-linkify';

interface MultiLineTextProps {
  children?: string;
}

function componentDecorator(
  decoratedHref: string,
  decoratedText: string,
  key: number,
): React.ReactNode {
  return (
    <a href={decoratedHref} key={key} {...externalLink} className="h-link-max">
      {decoratedText}
    </a>
  );
}

const MultiLineText: React.FC<MultiLineTextProps> = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {children.split('\n').map((line, idx, array) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={idx}>
            <Linkify componentDecorator={componentDecorator}>{line}</Linkify>
            {idx !== array.length - 1 && <br />}
          </React.Fragment>
        );
      })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MultiLineText;
