import { commonHooks } from '@polygence/common/hooks';
import { hermesActions } from '@polygence/common/store/reducers/hermesReducer';
import { commonThunks } from '@polygence/common/store/thunks';
import { Modal, ModalBody } from '@polygence/components';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'src/components/Icon';
import { premiumShowcasingQuestions } from 'src/components/hermes/surveys/student/premiumShowcasingQuestions';
import { researchCoachQuestions } from 'src/components/hermes/surveys/student/researchCoachQuestions';
import { isPSSTrackProjectType } from 'src/components/hermes/utils';
import { FormSection } from 'src/components/mentor/FormSection';

const isPositiveRating = {
  $or: [{ $is: { field: 'rating', value: '4' } }, { $is: { field: 'rating', value: '5' } }],
};

const feedbackParts = [
  {
    component: 'Heading',
    props: {
      alignment: 'left',
      className: 'tw-mb-2',
      size: 'h5',
      text: 'Please complete your session feedback',
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'This feedback is confidential. We do not share it with your mentor.',
    },
  },
  {
    component: 'NumberSelector',
    props: {
      label:
        'We’d love your feedback. Now that you’ve met your mentor, how would you rate this mentor match on a scale from 0-10?',
      name: 'matchRating',
      required: true,
      startFrom: 0,
      numberCount: 11,
    },
    display: {
      $is: { field: '__sessionNumber', value: 1 },
    },
  },
  {
    component: 'FormInput',
    props: {
      inputType: 'textarea',
      label: 'Please elaborate on your match rating.',
      name: 'matchRatingComment',
    },
    display: {
      $is: { field: '__sessionNumber', value: 1 },
    },
  },
  {
    component: 'StarRating',
    props: {
      label: 'How useful was your last session?',
      name: 'rating',
      starCount: 5,
      size: '40px',
      required: true,
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'What was positive about this session (select all that apply and we’ll share this with your mentor)? My mentor was:',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Prepared/Organized',
      name: 'preparedOrganized',
      id: 'preparedOrganized',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Supportive/Encouraging',
      name: 'supportiveEncouraging',
      id: 'supportiveEncouraging',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Very knowledgeable',
      name: 'knowledgeable',
      id: 'knowledgeable',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Great listener',
      name: 'greatListener',
      id: 'greatListener',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Guidance/provided direction',
      name: 'guidance',
      id: 'guidance',
    },
    display: isPositiveRating,
  },
  {
    component: 'FormInput',
    labelVariables: ['__mentorFirstName'],
    props: {
      label:
        'If you have any highlights or examples of the qualities you selected above, share them here! We love to get this feedback and to celebrate {{__mentorFirstName}}’s moments of positive impact with you.',
      name: 'mentorStarNote',
      inputType: 'input',
    },
    display: isPositiveRating,
  },
  {
    component: 'FormInput',
    props: {
      inputType: 'textarea',
      label:
        'Your research journal marks your progress — please reflect in 4-5 sentences on what you learned in your session:',
      name: 'research_journal',
      small_font:
        'Your journal will be viewable by your mentor and parent or college counselor if either have a Polygence account.',
      htmlRequired: true,
    },
  },
  {
    component: 'FormInput',
    props: {
      label: 'How many hours did you spend preparing for this session?',
      name: 'preparationLength',
      inputType: 'input',
      type: 'number',
      min: 0,
      htmlRequired: true,
    },
  },
  {
    component: 'FormInput',
    props: {
      label: 'How could we have improved your experience of the session today?',
      name: 'content',
      inputType: 'textarea',
    },
  },
];

const SuccessConfirmation = () => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="d-flex align-items-center">
      <Icon name="superwoman" width="178px" height="122px" style={{ flex: '1 0 auto' }} />
      <div>
        <h4>Thank you for your feedback!</h4>
        <p className="text-bold h-blue">
          We care about your feedback and make every effort to make adjustments in response.
        </p>
      </div>
    </div>
  );
};

const SessionFeedbackForm = () => {
  const dispatch = useDispatch();
  const { sending, sessionId } = useSelector((state) => {
    return state.hermes.studentFeedback;
  });
  const project = commonHooks.useSelectedProject();
  const session = project?.sessions?.find(({ id }) => {
    return id === sessionId;
  });
  const [data, setData] = useState({
    __sessionNumber: session?.number,
    __projectType: project?.type,
    __isLastSession: project?.sessionsPaidFor === session?.number,
    __mentorFirstName: project?.mentorFirstName,
  });

  const handleChange = ({ target: { name, value } }) => {
    return setData(() => {
      return { ...data, [name]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = Object.fromEntries(new FormData(form).entries());
    dispatch(
      commonThunks.hermesThunks.submitStudentFeedback({
        content: formData,
        session: sessionId,
      }),
    );
  };

  const feedback = useMemo(() => {
    if (isPSSTrackProjectType(project.type)) {
      return [...premiumShowcasingQuestions];
    }
    if (project.type === 'research_coach') {
      return [...researchCoachQuestions];
    }
    return feedbackParts;
  }, [project?.type]);

  return (
    <form onSubmit={handleSubmit} data-cy="session-feedback-modal">
      <FormSection fields={feedback} onChange={handleChange} profile={data} helpColumn={false} />
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary" disabled={sending}>
          {sending ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

const SessionFeedbackModal = () => {
  const dispatch = useDispatch();
  const { open, success } = useSelector((state) => {
    return state.hermes.studentFeedback;
  });
  return (
    <Modal
      show={open}
      onHide={() => {
        return dispatch(hermesActions.closeSessionFeedback());
      }}
      closeButton
    >
      <ModalBody>{success ? <SuccessConfirmation /> : <SessionFeedbackForm />}</ModalBody>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SessionFeedbackModal;
