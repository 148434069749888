import { MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK } from 'src/constants';

const display = {
  $and: [
    { $is: { field: '__completedSessionCount', value: 0 } },
    { $not: { field: 'countAs', value: 'no_show' } },
    { $not: { field: '__firstSessionSurveyCompleted', value: 'true' } },
  ],
} as const;

/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default [
  {
    component: 'FormInput',
    props: {
      label: 'Please provide any comments below',
      name: 'continueWorkingWithComment',
      inputType: 'text',
    },
    display: {
      $is: { field: 'continueWorkingWith', value: 'yes' },
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: `All good! [Would you be willing to get on a 5 min phone call so we can learn whether we should rematch this student or not?](${MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK})`,
    },
    display: {
      $is: { field: 'continueWorkingWith', value: 'no' },
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: `No problem. [We'd love to have a quick phone call about your concerns.](${MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK}) Are there any questions you'd like us to ask the student before we chat?`,
    },
    display: {
      $is: { field: 'continueWorkingWith', value: 'maybe' },
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'continueWorkingWithQuestionsToStudent',
      inputType: 'textarea',
    },
    display: {
      $is: { field: 'continueWorkingWith', value: 'maybe' },
    },
  },
  {
    component: 'NumberSelector',
    props: {
      label: 'Now that you’ve met your student, on a scale of 0-10, how would you rate this match?',
      name: 'firstSessionMatchRating',
      required: true,
      startFrom: 0,
      numberCount: 11,
    },
    display,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Please elaborate on your match rating.',
      name: 'firstSessionMatchRatingComment',
      inputType: 'textarea',
    },
    display,
  },
  {
    component: 'RadioButton',
    props: {
      name: 'firstSessionSurveyCompleted',
      options: [
        {
          label: '',
          value: true,
          default: true,
        },
      ],
      hidden: true,
    },
    display,
  },
] as const;
