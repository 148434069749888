import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Redirect } from 'react-router-dom';

import { urls } from 'src/urls';

export const WhiteLabelLoginCheck = () => {
  const currentUser = useCurrentUser();

  if (currentUser.loggedIn) {
    return <Redirect to={currentUser.hermesEnabled ? urls.hermes() : urls.studentApplication()} />;
  }

  return <Redirect to={urls.login()} />;
};
