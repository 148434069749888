import { Button } from '@polygence/components';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { HeadingRenderer, LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';

export const AcceptPolicyModal = ({
  policy: {
    title,
    content,
    accept_text: acceptText,
    reject_text: rejectText,
    policy_type: policyType,
  },
  isOpen,
  onAccept,
  onReject,
}) => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Modal
      isOpen={isOpen}
      overlayClassName="ReactModal__Overlay"
      className="modal-overlay policy-overlay session-overlay-text session-style"
    >
      <div className="p-10">
        <h3 className="text-center">
          <ReactMarkdown components={{ heading: HeadingRenderer, link: LinkRenderer }}>
            {title}
          </ReactMarkdown>
        </h3>
        <ReactMarkdown components={{ heading: HeadingRenderer, link: LinkRenderer }}>
          {content}
        </ReactMarkdown>
        <div className="text-center">
          <Button
            variant="primary"
            // eslint-disable-next-line react/forbid-dom-props -- autodisabled
            style={{ maxWidth: '90%', minWidth: '90%', margin: '0 auto' }}
            onClick={onAccept}
          >
            {acceptText}
          </Button>
          {policyType === 'accept_reject' ? (
            <Button
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              style={{ maxWidth: '80%', margin: '0 auto' }}
              className="text-center mt-3"
              onClick={onReject}
            >
              {rejectText}
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

AcceptPolicyModal.propTypes = {
  policy: PropTypes.objectOf(PropTypes.string),
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};
