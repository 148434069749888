import { Heading as ComponentsHeading } from '@polygence/components';

export const Heading = ({
  text,
  className,
  size,
  alignment,
}: {
  text: string;
  className?: string;
  size: React.ComponentProps<typeof ComponentsHeading>['size'];
  alignment: React.ComponentProps<typeof ComponentsHeading>['alignment'];
}) => {
  return (
    <ComponentsHeading size={size} className={className} alignment={alignment}>
      {text}
    </ComponentsHeading>
  );
};
