import { externalLink } from '@polygence/common';
import type { FC } from 'react';

import { Icon } from 'src/components/Icon';
import { SpinningWheel } from 'src/components/SpinningWheel';
import { getFilenameFromPath, splitFileNameAndExtension } from 'src/components/hermes/utils';

const actionIconStyle = {
  size: '14px',
  color: 'var(--h-grey-1)',
  role: 'button',
};

interface AssignmentFileProps {
  id?: number;
  url: string;
  loading: boolean;
  editable?: boolean;
  removeFile?: (id: number) => void;
}

const AssignmentFile: FC<AssignmentFileProps> = ({ id, url, loading, editable, removeFile }) => {
  if (!url) {
    return null;
  }

  const fileName = getFilenameFromPath(url);
  const [fileNameWithoutExtension, extension] = splitFileNameAndExtension(fileName ?? '');

  const handleClick = () => {
    if (!id || !removeFile) {
      return;
    }

    removeFile(id);
  };

  return (
    <div className="h-assignment-file">
      <a href={url} className="h-assignment-file__icon" {...externalLink}>
        <Icon color="#fff" name="file" local={false} />
      </a>
      <div className="h-assignment-file__details">
        <strong>{fileNameWithoutExtension}</strong>
        <span>{extension}</span>
      </div>
      <div className="h-assignment-file__action">
        {loading && <SpinningWheel className="d-inline-block" height={14} width={14} />}
        {!loading && editable && (
          <Icon local name="cross" onClick={handleClick} {...actionIconStyle} />
        )}
        {!loading && !editable && (
          <a href={url} {...externalLink} download>
            <Icon local name="link" {...actionIconStyle} />
          </a>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AssignmentFile;
