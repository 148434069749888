import { ProjectPauseRequestStatuses } from '@polygence/common/types/data/hermes';
import type { ProjectPauseRequest } from '@polygence/common/types/hermes';
import type { StringUserType } from '@polygence/common/types/user';

export const ProjectPauseRequestModalStatus = {
  MENTOR_POPUP: 'mentor_popup',
  MENTOR_RESPOND: 'mentor_respond',
  STUDENT_REQUEST: 'student_request',
  READONLY: 'readonly',
  HIDDEN: 'hidden',
} as const;

type ProjectPauseRequestModalStatusType =
  (typeof ProjectPauseRequestModalStatus)[keyof typeof ProjectPauseRequestModalStatus];

export const isRequested = (projectPauseRequest?: ProjectPauseRequest) => {
  return projectPauseRequest?.status === ProjectPauseRequestStatuses.REQUESTED;
};

export const isAccepted = (projectPauseRequest?: ProjectPauseRequest) => {
  return projectPauseRequest?.status === ProjectPauseRequestStatuses.ACCEPTED;
};

export const isDeclined = (projectPauseRequest?: ProjectPauseRequest) => {
  return projectPauseRequest?.status === ProjectPauseRequestStatuses.DECLINED;
};

export function calculateModalStatus(
  userType: StringUserType,
  projectPauseRequests: ProjectPauseRequest[] = [],
): ProjectPauseRequestModalStatusType {
  const latestProjectPauseRequest = projectPauseRequests[0];
  const isMentor = userType === 'mentor';
  const isStudent = userType === 'student';

  if (isMentor) {
    if (isRequested(latestProjectPauseRequest)) {
      return ProjectPauseRequestModalStatus.MENTOR_RESPOND;
    }
    return ProjectPauseRequestModalStatus.MENTOR_POPUP;
  }

  if (isStudent) {
    if (isRequested(latestProjectPauseRequest)) {
      return ProjectPauseRequestModalStatus.READONLY;
    }
    return ProjectPauseRequestModalStatus.STUDENT_REQUEST;
  }

  return ProjectPauseRequestModalStatus.HIDDEN;
}

export const buttonTexts: Record<ProjectPauseRequestModalStatusType, string> = {
  mentor_respond: 'Respond to Project Pause Request',
  student_request: 'Pause Project',
  mentor_popup: 'Pause Project',
  readonly: 'Project Pause Request pending',
  hidden: '',
};
