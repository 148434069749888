/* eslint-disable sonarjs/no-duplicate-string */
import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  contentSpacer,
  headingProps,
  headingSpacer,
  illustrationSize,
} from 'src/students/student-application/common-section-components';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const PROJECT_IDEA_SECTION_WHITE_LABEL: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.PROJECT_IDEA_SECTION_ID,
    display: {
      $not: { field: 'intendToDoSciFair', value: true },
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        background: 'background-white',
        props: {
          children: [
            null,
            [
              {
                component: 'CenteredIllustration',
                props: {
                  name: 'SpaceTelescope',
                  size: illustrationSize,
                },
              },
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'What would you like to explore at Polygence?',
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsText',
                props: {
                  children: `In order to find your perfect mentor, we need to know a little bit about what you’d like to work on. Explain your project idea here as if you were describing it to your mentor in 1-2 paragraphs (between 100 to 300 words).`,
                  className: 'mb-7',
                  size: 'medium',
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `It’s okay if your idea is a little abstract and broad at this point. Your mentor will help you refine your project scope and crystalize your thoughts.`,
                  className: 'mb-7',
                  size: 'medium',
                },
              },
              {
                component: 'ProjectIdeas',
              },
              {
                component: 'Spacer',
                props: { size: 7 },
              },
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'interests',
                  showWordCount: true,
                  rows: 10,
                  placeholder: `I would like to...\nSo that I can...\nI am interested in this because...`,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 100,
                    maximum: 300,
                  },
                },
              },
            ],
            null,
          ],
        },
      },
    ],
  },
];
