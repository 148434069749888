import { commonReducers, commonThunks, commonHooks } from '@polygence/common';
import type { AssignmentSolution } from '@polygence/common';
import { pluralize } from '@polygence/common/utils/pluralize';
import { Alert, Button, Card, StyledCollapsible, Text } from '@polygence/components';
import type { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import DateWrapper from 'src/components/aux/dateWrapper';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import { ConfirmationModal } from 'src/components/common/ConfirmationModal';
import { isStudent as testIsStudent } from 'src/components/getMyInfo';
import { AssignmentHeader } from 'src/components/hermes/assignment/AssignmentHeader';
import AssignmentSubmission from 'src/components/hermes/assignment/AssignmentSubmission';
import { useAssignmentById } from 'src/components/hermes/hooks';
import { useAppDispatch } from 'src/store';
import { getTracker } from 'src/utils/tracking/factory';

interface AssignmentProps {
  assignmentId: number;
  open: boolean;
}

const CollapsibleAssignment: FC<AssignmentProps> = ({ assignmentId, open }) => {
  const dispatch = useAppDispatch();
  const user = commonHooks.useCurrentUser();
  const isStudent = testIsStudent(user.userType);
  const assignment = useAssignmentById(assignmentId);

  const isSubmitted = Boolean(assignment?.assignmentSolution?.submittedAt);
  const isEditable = isStudent && !isSubmitted;
  const numberOfAttachments =
    (assignment?.assignmentSolution?.files?.length || 0) +
    (assignment?.assignmentSolution?.links?.length || 0);

  if (user == null) {
    return null;
  }

  const update = (updatedFields: Partial<AssignmentSolution>) => {
    dispatch(
      commonReducers.hermesActions.updateAssignmentSolution({
        assignmentId,
        solution: updatedFields,
      }),
    );

    if (updatedFields.links || updatedFields.submittedAt !== undefined) {
      void dispatch(
        commonThunks.hermesThunks.patchAssignmentSolution({
          assignmentId,
          solution: updatedFields,
        }),
      );
    }
  };

  const submit = () => {
    update({ submittedAt: DateWrapper.utc().format() });
    getTracker().track('Assignment solution submitted', {
      assignmentId: assignment?.id ?? null,
      userType: user.userType,
    });
  };

  const unSubmit = () => {
    update({ submittedAt: null });
    getTracker().track('Assignment solution unsubmitted', {
      assignmentId: assignment?.id ?? null,
      userType: user.userType,
    });
  };

  if (!assignment) {
    return null;
  }

  const { title, description, assignmentSolution } = assignment;
  const { files = [], links = [] } = assignmentSolution ?? {};
  const submittedWithoutAttachments = isSubmitted && files.length === 0 && links.length === 0;

  return (
    <StyledCollapsible
      defaultOpen={open}
      summary={<AssignmentHeader title={title} assignmentSolution={assignmentSolution} />}
    >
      <Row>
        <Col sm="8">
          <ReactMarkdown components={{ a: LinkRenderer }}>{description}</ReactMarkdown>
        </Col>
        <Col sm="4" className="vstack gap-4 align-items-end">
          {(isStudent || isSubmitted) && (
            <Card className="w-100">
              <Card.Body>
                <Text size="medium" fontWeight="bold" className="mb-4">
                  {isSubmitted ? 'Submissions' : 'Submissions (if necessary)'}
                </Text>
                {submittedWithoutAttachments && (
                  <Alert variant="warning">Submitted without attachments.</Alert>
                )}
                <AssignmentSubmission
                  onUpdate={update}
                  editable={isEditable}
                  solution={assignmentSolution ?? {}}
                  assignmentId={assignmentId}
                />
              </Card.Body>
            </Card>
          )}
          {isEditable && (
            <ConfirmationModal
              trigger={
                <Button variant="primary" onClick={submit}>
                  Submit
                </Button>
              }
              confirmButtonVariant="tertiary"
              confirmButtonText="Submit"
              cancelButtonVariant="secondary"
              cancelButtonText="Cancel"
              alignButtons="right"
              skipConfirmation={false}
            >
              <div className="h-assignment-page-modal flex-grow-1">
                <h2>Submit your assignment?</h2>
                <p className="h-assignment-submit__attachment-text">
                  {numberOfAttachments} {pluralize('attachment', numberOfAttachments)} will be
                  submitted.
                </p>
              </div>
            </ConfirmationModal>
          )}
          {isSubmitted && isStudent && (
            <Button variant="primary" onClick={unSubmit}>
              Resubmit
            </Button>
          )}
        </Col>
      </Row>
    </StyledCollapsible>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CollapsibleAssignment;
