import { commonReducers } from '@polygence/common';
import { Modal, ModalBody } from '@polygence/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CancellationResponse = () => {
  const dispatch = useDispatch();
  const {
    open: cancelerOpen,
    success: cancelerSuccess,
    isLate,
    proposal,
  } = useSelector((state) => {
    return state.hermes.canceler;
  });

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Modal
      show={cancelerOpen}
      onHide={() => {
        return dispatch(commonReducers.hermesActions.closeCanceler());
      }}
      closeButton
    >
      <ModalBody>
        <div className="px-7 py-10 d-flex flex-column align-items-center">
          {!cancelerSuccess && <span>Your session was not cancelled. Please try it again.</span>}
          {cancelerSuccess && !proposal && (
            <>
              <span>Your session was successfully cancelled.</span>
              {!isLate && <span>Please reschedule the session at your earliest convenience.</span>}
            </>
          )}
          {cancelerSuccess && proposal && (
            <span>
              We let your student know that the proposed time does not work. Please work with your
              student to find a new date/time for the session at your earliest convenience.
            </span>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CancellationResponse;
