import { externalLink } from '@polygence/common';
import { Button, Text } from '@polygence/components';

import { SessionListReminder } from 'src/components/hermes/sessions/SessionListReminder';

interface FinalSurveyReminderProps {
  completed: boolean;
  url: string;
  userType: string;
}

const FinalStudentSurveyReminder = ({ completed, url, userType }: FinalSurveyReminderProps) => {
  const link: { href: string; target: string; rel: string } | Record<string, never> = url
    ? {
        href: url,
        ...externalLink,
      }
    : {};
  return (
    <SessionListReminder>
      {completed ? (
        <>
          <Text size="small" fontWeight="bold">
            Thank you for submitting the final survey. You and your{' '}
            {userType === 'student' ? 'mentor' : 'student'} can access the final session now.
          </Text>
        </>
      ) : (
        <>
          <Text
            size="small"
            fontWeight="bold"
            className={`${url || userType === 'mentor' ? '' : 'text-muted'}`}
          >
            You’re almost there!{' '}
            {userType === 'student'
              ? 'Before you meet for your final session, you need to fill out the Final Program Survey.'
              : 'Before you meet your student for final session, your student needs to fill out the Final Program Survey.'}{' '}
            You’ll not be able to join the Zoom session until the survey has been submitted.
            {!url && (
              <>
                {' '}
                The survey becomes available when the previous session is completed.
                {userType === 'mentor' &&
                  ' You can complete the previous session by submitting the summary.'}
              </>
            )}
          </Text>
          {userType === 'student' && (
            <Button variant="primary" size="sm" disabled={!url} {...link}>
              Complete final survey
            </Button>
          )}
        </>
      )}
    </SessionListReminder>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default FinalStudentSurveyReminder;
