import { commonHooks } from '@polygence/common';

import { Loader } from 'src/components/Loader';
import { MilestoneModal } from 'src/components/hermes/Milestone';
import CancellationResponse from 'src/components/hermes/sessions/CancellationResponse';
import NotAvailableSession from 'src/components/hermes/sessions/NotAvailableSession';
import { ProgressSection } from 'src/components/hermes/sessions/ProgressSection';
import { SessionListItem } from 'src/components/hermes/sessions/SessionListItem';
import MentorNoShowReported from 'src/components/hermes/sessions/mentor-no-show/MentorNoShowReported';

const SessionList = () => {
  const { sessions, selectedProject } = commonHooks.useSessionList();
  const currentUser = commonHooks.useCurrentUser();
  const userType = currentUser?.userType;

  if (!selectedProject?.sessions || !currentUser.id) {
    return <Loader className="mt-10 mx-auto" />;
  }

  const url = currentUser && selectedProject?.finalStudentSurveyUrl?.[currentUser.id];
  const completed = selectedProject?.finalStudentSurveyCompleted;

  return (
    <section className="h-session-list h-sidebar-container">
      <CancellationResponse />
      <MilestoneModal />
      <MentorNoShowReported />
      <ProgressSection
        title="Session Progress"
        eventText="sessions completed"
        pastEventNum={selectedProject.sessionsCompleted}
        totalEventNum={selectedProject.sessionsPaidFor}
      />
      {sessions.map((session) => {
        return <SessionListItem key={session.id} session={session} />;
      })}
      {selectedProject.sessionsSchedulable > 0 && (
        <SessionListItem session={{ sessionNumber: sessions.length + 1 }} />
      )}
      {selectedProject.sessionsSchedulable > 1 &&
        Array.from({
          length: Math.ceil(selectedProject.sessionsSchedulable - 1),
        }).map((_, idx, array) => {
          const sessionNumber = sessions.length + 2 + idx;
          return (
            <NotAvailableSession
              key={`${selectedProject.id}__${sessionNumber}`}
              sessionNumber={sessionNumber}
              isLast={idx + 1 === array.length}
              reminderProps={{ url: url ?? '', userType, completed }}
              feedbackReminderProps={{
                sessionId: sessions[0]?.id ?? -1,
                userType,
                sessionNumber,
                project: selectedProject,
              }}
            />
          );
        })}
    </section>
  );
};

export { SessionList };
