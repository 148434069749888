import { commonReducers } from '@polygence/common';
import { Button, Modal, ModalBody } from '@polygence/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MentorNoShowReported = () => {
  const isOpen = useSelector((state) => {
    return state.hermes.noShowReportedModal.open;
  });
  const dispatch = useDispatch();
  const close = () => {
    dispatch(commonReducers.hermesActions.closeNoShowReportedModal());
  };
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Modal show={isOpen} onHide={close} closeButton>
      <ModalBody>
        <h2 className="h5">Thank you for letting us know.</h2>
        <p className="text-muted">
          Please reach out to your mentor to reschedule your session. We will also check in with
          them.
        </p>
        <div className="d-flex justify-content-end mt-7">
          <Button variant="primary" onClick={close}>
            Okay
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MentorNoShowReported;
