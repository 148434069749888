import { Icon, Text, cn } from '@polygence/components';

export const MessageCount = ({
  count,
  maxNumberOfMessages,
}: {
  count: number | null;
  maxNumberOfMessages: number | null;
}) => {
  const showMessageCount = count && maxNumberOfMessages;

  if (!showMessageCount) {
    return null;
  }

  return (
    <div className="tw-inline-flex tw-items-center tw-gap-1 tw-rounded-md tw-bg-gray-200 tw-px-2 tw-py-1">
      <Icon
        id="message-circle"
        size="xs"
        className={cn('tw-text-primary-500', count === maxNumberOfMessages && 'tw-text-red-500')}
        strokeWidth={3}
      />
      <Text as="span" size="small" fontWeight="semibold" className="tw-text-gray-400">
        {count} of {maxNumberOfMessages}
      </Text>
    </div>
  );
};
