import { Badge, Icon, Text, Tooltip } from '@polygence/components';
import { Link } from 'react-router-dom';

import { HermesAIFeatureIntroductionModal } from 'src/components/hermes-ai/introduction-modal/HermesAIFeatureIntroductionModal';
import { useProjectAssistantIntroductionModal } from 'src/components/hermes-ai/introduction-modal/useProjectAssistantIntroductionModal';
import { useHermesAIEnabled } from 'src/components/hermes-ai/useHermesAIEnabled';
import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';

export const HermesAI = () => {
  const { canSeeFeature } = useHermesAIEnabled();
  const url = useHermesAIRouteUrl();
  useProjectAssistantIntroductionModal();

  if (!canSeeFeature) {
    return null;
  }

  return (
    <>
      <Tooltip
        tip={
          <span>
            <Badge variant="primary" size="small">
              <Text size="small" fontWeight="bold">
                NEW!
              </Text>
            </Badge>
            <Text
              size="intermediate"
              fontWeight="normal"
              className="tw-ml-3 tw-text-white"
              as="span"
            >
              Project Assistant
            </Text>
          </span>
        }
        placement="top"
      >
        <Link to={url} className="toolbar-item">
          <Icon id="sparkles" className="tw-text-primary-500" />
        </Link>
      </Tooltip>
      <HermesAIFeatureIntroductionModal id="hermes-ai-feature-introduction-modal" />
    </>
  );
};
