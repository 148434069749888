import { commonReducers, commonHooks } from '@polygence/common';
import { Modal, ModalBody } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { MeetingScheduler } from 'src/components/hermes/scheduler/MeetingScheduler';
import { SessionScheduler } from 'src/components/hermes/scheduler/SessionScheduler';
import { useAppSelector } from 'src/store';

const Scheduler = () => {
  const dispatch = useDispatch();
  const selectedProject = commonHooks.useSelectedProject();
  const { open } = useAppSelector((state) => {
    return state.hermes.scheduler;
  });

  return (
    <Modal
      show={open}
      onHide={() => {
        return dispatch(commonReducers.hermesActions.closeScheduler());
      }}
      closeButton
    >
      <ModalBody>
        <div className="h-scheduler">
          {selectedProject ? <SessionScheduler /> : <MeetingScheduler />}
        </div>
      </ModalBody>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Scheduler;
