import {
  Message,
  MessageId,
  Nullable,
  Project,
  Room,
  UserId,
  UserState,
  Workspace,
  dayjs,
} from '@polygence/common';

import getLastMessageFromOther from 'src/components/hermes/SilentReportButton/getLastMessageFromOther';
import { isProjectActive } from 'src/components/hermes/utils';
import { getDaysUntilToday } from 'src/components/overviews/student/utils';

const OLD_MESSAGE_MIN_IN_DAYS = 2;

interface MessageForSilentUserReport {
  id: MessageId;
  sentBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
  silentReport: Nullable<number>;
  messageType: string;
  createdAt: string | undefined;
}

function isWorkspaceDisabled(workspace: Workspace | undefined) {
  return !workspace || workspace.disabled === true;
}

function isProjectPaused(project: Project | undefined) {
  return project?.pausedUntil && dayjs().isBefore(project.pausedUntil);
}

function hasAlreadySilentReport(currentMessage: MessageForSilentUserReport) {
  return currentMessage.silentReport !== null;
}

function isCurrentMessageBelongsToOtherUser(
  currentMessage: MessageForSilentUserReport,
  currentUserId: UserId,
) {
  return !currentMessage.sentBy || currentMessage.sentBy?.id !== currentUserId;
}

function isCurrentMessageOlder(
  currentMessage: MessageForSilentUserReport,
  lastMessageFromOther: Message,
) {
  return currentMessage.id <= lastMessageFromOther.id;
}

function isMessageOlderThanTwoDays(message: MessageForSilentUserReport) {
  if (!message?.createdAt) {
    return false;
  }
  return getDaysUntilToday(message?.createdAt) >= OLD_MESSAGE_MIN_IN_DAYS;
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function isSilentReportAvailable(
  workspace: Workspace | undefined,
  project: Project | undefined,
  currentUser: UserState | undefined,
  currentMessage: MessageForSilentUserReport,
  currentRoom: Room | undefined,
) {
  if (
    !currentUser ||
    isWorkspaceDisabled(workspace) ||
    !isProjectActive(project) ||
    isProjectPaused(project)
  ) {
    return false;
  }

  const lastMessageFromOther = getLastMessageFromOther(
    currentUser,
    currentRoom?.messages,
    currentRoom?.participants,
  );

  if (
    !currentMessage ||
    isCurrentMessageBelongsToOtherUser(currentMessage, currentUser.id) ||
    hasAlreadySilentReport(currentMessage)
  ) {
    return false;
  }

  if (lastMessageFromOther && isCurrentMessageOlder(currentMessage, lastMessageFromOther)) {
    return false;
  }

  return isMessageOlderThanTwoDays(currentMessage);
}
