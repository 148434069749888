import { commonHooks, Participant, UserState, Workspace } from '@polygence/common';
import { Link } from 'react-router-dom';

import { isMentor } from 'src/components/getMyInfo';
import { ChatPartner } from 'src/components/hermes/sidebar/ChatPartner';
import { urls } from 'src/urls';

export const PartnerDetails = ({
  partners,
  ...detailProps
}: {
  partners: Participant[];
  displayedTime?: string;
  shortFormat?: boolean;
  showImages?: boolean;
  showNames?: boolean;
}) => {
  const currentUser = commonHooks.useCurrentUser();
  const selectedWorkspace = commonHooks.useSelectedWorkspace();

  const _canShowDetailsAndActions = (
    currentUser: UserState,
    partner: Participant,
    selectedWorkspace: Workspace | undefined,
  ) => {
    return (
      currentUser?.isAdmin &&
      !partner.isAdmin &&
      selectedWorkspace &&
      !selectedWorkspace?.loungeId &&
      !selectedWorkspace?.projectId
    );
  };

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-3 p-4">
      {partners.map((partner) => {
        return isMentor(partner.userType) ? (
          <>
            <ChatPartner {...partner} {...detailProps} workspaceId={selectedWorkspace.id} />
            {_canShowDetailsAndActions(currentUser, partner, selectedWorkspace) && (
              <Link key={partner.profileId} to={urls.mentorDetails(partner.profileId)}>
                Details and actions
              </Link>
            )}
          </>
        ) : (
          <ChatPartner
            key={partner.id}
            {...partner}
            {...detailProps}
            workspaceId={selectedWorkspace.id}
          />
        );
      })}
    </div>
  );
};
