import { AdditionalSessionStatus } from '@polygence/common';
import type { AdditionalSession } from '@polygence/common';

export const isRequested = (additionalSession: AdditionalSession) => {
  return additionalSession?.status === AdditionalSessionStatus.REQUESTED;
};

export const isResponded = (additionalSession: AdditionalSession) => {
  return additionalSession?.status === AdditionalSessionStatus.PENDING;
};

export const isDeclined = (additionalSession: AdditionalSession) => {
  return additionalSession?.status === AdditionalSessionStatus.DECLINED;
};

export const isCompleted = (additionalSession: AdditionalSession) => {
  return additionalSession?.status === AdditionalSessionStatus.COMPLETED;
};
