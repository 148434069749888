import { Button, Icon, Text, Spacer, Checkbox } from '@polygence/components';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useState, useMemo } from 'react';
import { useQueryParams, BooleanParam, StringParam, ArrayParam } from 'use-query-params';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { RtkPaginator } from 'src/components/admin/components/aux/RtkPaginator';
import { OpportunityCard } from 'src/components/showcasing/ShowcasingPage/OpportunityCard';
import styles from 'src/components/showcasing/ShowcasingPage/ShowcasingPage.module.scss';
import {
  useGetOpportunitiesQuery,
  useGetKeywordsQuery,
  useGetTypesQuery,
  useGetAgeLevelsQuery,
} from 'src/reducers/showcasingReducers';

interface ExpandableMultipleChoiceFilterProps {
  values: { name: string; id: number }[];
  selectedValues: (string | null)[];
  onChange: (event: { target: { value: (string | null)[] } }) => void;
  name: string;
}

const ExpandableMultipleChoiceFilter = ({
  values,
  selectedValues,
  onChange,
  name,
}: ExpandableMultipleChoiceFilterProps) => {
  const limit = 7;
  const [displayAll, setDisplayAll] = useState(false);
  const displayedValues = displayAll
    ? values
    : values
        .slice(0, limit)
        .concat(values.slice(limit).filter((value) => selectedValues.includes(value.name)));

  return (
    <div className="d-flex flex-column gap-4">
      {displayedValues.map(({ id, name: valueName }) => {
        return (
          <Checkbox
            key={id}
            name={name}
            label={valueName}
            value={valueName}
            size="small"
            checked={selectedValues.includes(valueName)}
            onChange={(event) => {
              if (selectedValues.includes(event.target.value)) {
                return onChange({
                  target: { value: selectedValues.filter((v) => v !== event.target.value) },
                });
              }
              onChange({ target: { value: [...selectedValues, event.target.value] } });
            }}
          />
        );
      })}
      {values.length > limit && (
        <Button
          variant="link"
          size="sm"
          className="align-self-start"
          onClick={() => {
            return setDisplayAll((prev) => !prev);
          }}
          endIcon={<Icon id={displayAll ? 'chevron-up' : 'chevron-down'} />}
        >
          {displayAll ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  );
};

export const ShowcasingPage = () => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    liked: BooleanParam,
    deadline: BooleanParam,
    review_paper: BooleanParam,
    peer_reviewed: BooleanParam,
    submission_fee: BooleanParam,
    fast_track_review: BooleanParam,
    age_level: ArrayParam,
    keywords: ArrayParam,
    types: ArrayParam,
  });

  const { data: ageLevels = [] } = useGetAgeLevelsQuery();
  const { data: keywords = [] } = useGetKeywordsQuery();
  const { data: types = [] } = useGetTypesQuery();
  const [search, setSearch] = useState(query.search ?? '');
  const debouncedSetQuery = useMemo(() => debounce(setQuery, 300), [setQuery]);
  const MIN_CHARS = 3;

  return (
    <div className="container py-5">
      <div className="d-flex flex-column flex-md-row gap-5">
        <aside
          className={classNames('px-4 py-5 flex-shrink-0 align-self-md-start', styles['aside'])}
        >
          <>
            <Text size="medium" fontWeight="semibold" className="mb-3">
              Category
            </Text>
            <div className="d-flex flex-column gap-4">
              {(
                [
                  { name: 'liked', label: 'My Favorites', value: true },
                  { name: 'deadline', label: 'Has Deadline', value: true },
                  { name: 'deadline', label: 'No Deadline', value: false },
                  { name: 'review_paper', label: 'Accepts Literature Reviews', value: true },
                  { name: 'peer_reviewed', label: 'Peer Reviewed', value: true },
                  { name: 'submission_fee', label: 'Has Submission Fee', value: true },
                  { name: 'submission_fee', label: 'No Submission Fee', value: false },
                  { name: 'fast_track_review', label: 'Fast Track Review Available', value: true },
                ] as const
              ).map(({ name, label, value }) => {
                return (
                  <Checkbox
                    key={`${name}-${value.toString()}`}
                    name={name}
                    label={label}
                    size="small"
                    checked={query[name] === value}
                    onChange={(event) => {
                      setQuery({ [name]: event.target.checked ? value : undefined });
                    }}
                  />
                );
              })}
            </div>
            <Spacer size={9} vertical />
            <Text size="medium" fontWeight="semibold" className="mb-3">
              Types
            </Text>
            <ExpandableMultipleChoiceFilter
              name="types"
              values={types ?? []}
              selectedValues={query.types ?? []}
              onChange={(event) => {
                setQuery({ types: event.target.value });
              }}
            />
            <Spacer size={9} vertical />
            <Text size="medium" fontWeight="semibold" className="mb-3">
              Subject areas
            </Text>
            <ExpandableMultipleChoiceFilter
              values={keywords ?? []}
              selectedValues={query.keywords ?? []}
              onChange={(event) => {
                setQuery({ keywords: event.target.value });
              }}
              name="keywords"
            />
            <Spacer size={9} vertical />
            <Text size="medium" fontWeight="semibold" className="mb-3">
              Age levels
            </Text>
            <ExpandableMultipleChoiceFilter
              name="age_level"
              values={ageLevels ?? []}
              selectedValues={query.age_level ?? []}
              onChange={(event) => {
                setQuery({ age_level: event.target.value });
              }}
            />
          </>
        </aside>
        <main className="flex-grow-1">
          <div>
            <h1>Showcasing Database</h1>
          </div>
          <div>
            <Text size="medium">
              You've spent months developing and revising your project - it's time to share it with
              the world! Search our database for opportunities to showcase your research paper, art
              work, or any student project you've been working on. All options are relevant for high
              school students looking for a chance to share their work with a wider audience,
              including experts in their topic of interest.
            </Text>
          </div>
          <Spacer size={5} vertical />
          <div className="row">
            <div className="col">
              <FormInput
                placeholder={`Search opportunities or subject areas (min. ${MIN_CHARS} characters)`}
                type="text"
                name="search"
                label=""
                value={search}
                onChange={({ target: { value } }: { target: { value: string } }) => {
                  setSearch(value);
                  if (value.length >= MIN_CHARS || value === '') {
                    debouncedSetQuery({ search: value || undefined });
                  }
                }}
                onError={[]}
                required={false}
                className="Showcasing-search-input"
              />
            </div>
          </div>
          <RtkPaginator query={useGetOpportunitiesQuery} paginationArgs={{ ...query }}>
            {(queryResult) => {
              return (
                <div className="d-flex flex-column gap-5">
                  {queryResult?.currentData &&
                    queryResult.currentData.results.map((item) => {
                      return <OpportunityCard key={item.id} {...item} />;
                    })}
                </div>
              );
            }}
          </RtkPaginator>
        </main>
      </div>
    </div>
  );
};
