/* eslint-disable fp/no-mutation */
import { TRANSFORMERS, $convertToMarkdownString } from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HermesAIMessageThreadId } from '@polygence/common';
import {
  useGetUserSnippetsQuery,
  useEditUserSnippetMutation,
  useGetUnsentUserSnippetQuery,
  hermesAiApi,
} from '@polygence/common/api/hermes-ai';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { $createParagraphNode, $createTextNode, $getRoot, EditorState } from 'lexical';
import { debounce } from 'lodash';
import { useCallback } from 'react';

import PlainTextInputPlugin from 'src/components/LexicalEditor/plugins/PlainTextInputPlugin';
import ReadOnlyPlugin from 'src/components/LexicalEditor/plugins/ReadOnlyPlugin';
import theme from 'src/components/LexicalEditor/theme';
import { useAppDispatch } from 'src/store';

export const UserSnippetEditor = ({
  userSnippetId,
  aiThreadId,
}: {
  userSnippetId: number;
  aiThreadId?: HermesAIMessageThreadId | null;
}) => {
  const { data } = useGetUserSnippetsQuery(aiThreadId ?? 0, { skip: !aiThreadId });
  const { data: unsentData } = useGetUnsentUserSnippetQuery(userSnippetId, { skip: !!aiThreadId });

  const snippet = aiThreadId
    ? data?.find((snippet) => snippet.id === Number(userSnippetId))
    : unsentData;
  const [editSnippet] = useEditUserSnippetMutation();
  const currentUser = useCurrentUser();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEdit = useCallback(
    debounce((markdown: string) => {
      editSnippet({ id: userSnippetId, content: markdown }).catch(() => {});
    }, 800),
    [editSnippet, userSnippetId],
  );

  const handleChange = (editorState: EditorState) => {
    editorState.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      dispatch(
        aiThreadId
          ? hermesAiApi.util.updateQueryData('getUserSnippets', aiThreadId, (draft) => {
              const snippet = draft.find((snippet) => snippet.id === userSnippetId);
              if (snippet) {
                snippet.content = markdown;
              }
            })
          : hermesAiApi.util.updateQueryData('getUnsentUserSnippet', userSnippetId, (draft) => {
              if (draft) {
                draft.content = markdown;
              }
            }),
      );
      debouncedEdit(markdown);
    });
  };

  if (!snippet) {
    return null;
  }

  const isReadonly = snippet.createdBy !== currentUser?.id;

  const initialEditorState = () => {
    snippet.content.split('\n\n').forEach((line) => {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(line);
      paragraph.append(textNode);
      $getRoot().append(paragraph);
    });
  };

  const initialConfig = {
    namespace: 'LexicalTextEditor',
    editorState: initialEditorState,
    theme,
    onError(error: Error) {
      throw error;
    },
  };

  return (
    <div className="tw-flex tw-w-full tw-grow">
      <LexicalComposer initialConfig={initialConfig}>
        <PlainTextInputPlugin />
        <HistoryPlugin />
        <OnChangePlugin onChange={handleChange} ignoreSelectionChange ignoreInitialChange />
        <ReadOnlyPlugin readOnly={isReadonly} />
      </LexicalComposer>
    </div>
  );
};
