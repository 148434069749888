/* eslint-disable sonarjs/no-duplicate-string */
import NiceModal from '@ebay/nice-modal-react';
import {
  commonHooks,
  commonReducers,
  commonThunks,
  Lounge,
  permissions,
  Project,
  Room,
  useCommonSelector,
} from '@polygence/common';
import {
  Button,
  Alert,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
  Spacer,
  Tooltip,
} from '@polygence/components';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { isStudent } from 'src/components/getMyInfo';
import { ProjectDetails } from 'src/components/hermes/ProjectDetails';
import ProjectStartWarning from 'src/components/hermes/ProjectStartWarning';
import { SharedResourceList } from 'src/components/hermes/SharedResource';
import { SubmitFinalProjectButton } from 'src/components/hermes/SubmitFinalProjectButton';
import { AdditionalSession } from 'src/components/hermes/additional-session/AdditionalSession';
import { AdditionalSessionModal } from 'src/components/hermes/additional-session/AdditionalSessionModal';
import { AdditionalWritingFeedbackButton } from 'src/components/hermes/additional-writing-feedback/AdditionalWritingFeedbackButton';
import { ProjectDeadlineExtension } from 'src/components/hermes/extension-request/ProjectDeadlineExtension';
import { ProjectDeadlineExtensionModal } from 'src/components/hermes/extension-request/ProjectDeadlineExtensionModal';
import { useExtensionRequestAction, useSelectedLounge } from 'src/components/hermes/hooks';
import { ProjectPauseRequestModal } from 'src/components/hermes/pause-project/ProjectPauseRequestModal';
import { RespondToProjectPauseRequest } from 'src/components/hermes/pause-project/RespondToProjectPauseRequest';
import { PurchaseShowcasingAddonButton } from 'src/components/hermes/premium-showcasing-support/PurchaseShowcasingAddonButton';
import { PurchaseShowcasingAddonModal } from 'src/components/hermes/premium-showcasing-support/PurchaseShowcasingAddonModal';
import { CancellationResponse, MeetingTime, SessionList } from 'src/components/hermes/sessions';
import { PartnerDetails } from 'src/components/hermes/sidebar/PartnerDetails';
import { getDaysRemainingUntil } from 'src/components/overviews/student/utils';
import { useAppDispatch } from 'src/store';
import { getCssVariable } from 'src/utils';
import { dayjs } from 'src/utils/dayjsCustom';

const SHARED_DOCUMENTS_LABEL = 'Shared resources';

const ProjectDeadlineInformation = ({ isDeadlineExtendable = true }) => {
  return (
    <>
      <Tooltip
        tip={
          <>
            Your project is due by this deadline. All sessions must be completed by then.
            {isDeadlineExtendable && (
              <>
                <Spacer size={1} />
                If necessary, you may request a deadline extension or pause the project in the
                Manage Project Menu.
              </>
            )}
          </>
        }
      >
        <Icon id="help-circle" className="ms-2 clickable" />
      </Tooltip>
    </>
  );
};

const UpSellSection = ({
  canRequestOrRespondAdditionalSessions,
  showAdditionalWritingFeedbackButton,
  showPremiumShowcasingAddonButton,
}: {
  canRequestOrRespondAdditionalSessions: boolean;
  showAdditionalWritingFeedbackButton: boolean;
  showPremiumShowcasingAddonButton: boolean;
}) => {
  const {
    modal: { ...additionalSession },
  } = useCommonSelector((state) => {
    return state.additionalSession;
  });
  const showcasingAddon = useCommonSelector((state) => {
    return state.showcasingAddon.showcasingPurchaseModal;
  });
  if (
    !canRequestOrRespondAdditionalSessions &&
    !showAdditionalWritingFeedbackButton &&
    !showPremiumShowcasingAddonButton
  ) {
    return null;
  }
  return (
    <>
      <hr className="my-4" />
      <div className="d-flex flex-wrap mt-2 gap-3 justify-content-center" role="group">
        {canRequestOrRespondAdditionalSessions && (
          <>
            <AdditionalSession />
          </>
        )}
        {showPremiumShowcasingAddonButton && <PurchaseShowcasingAddonButton />}
        {showAdditionalWritingFeedbackButton && <AdditionalWritingFeedbackButton />}
      </div>
      {additionalSession.isOpen && <AdditionalSessionModal />}
      {showcasingAddon.open && <PurchaseShowcasingAddonModal />}
    </>
  );
};

const ProjectDeadline = ({
  project: {
    expectedEndAt,
    premiumShowcasingAddonPurchaseEnabled,
    premiumShowcasingSupportPurchasedAt,
    reviewsRemaining,
    additionalWritingFeedbackPurchaseEnabled,
    title,
    pauseEnabled,
    pausedUntil,
  },
  openProjectDetailsTab,
}: {
  project: Project;
  openProjectDetailsTab: () => void;
}) => {
  const {
    modal: { ...projectExtension },
  } = useCommonSelector((state) => {
    return state.projectExtension;
  });
  const dispatch = useAppDispatch();
  const userPermissions = commonHooks.usePermissions();
  const extensionRequestAction = useExtensionRequestAction();
  const canRequestOrRespondAdditionalSessions =
    permissions.canRequestAdditionalSessions(userPermissions) ||
    permissions.canRespondAdditionalSessions(userPermissions);
  const showPremiumShowcasingAddonButton =
    premiumShowcasingAddonPurchaseEnabled && !premiumShowcasingSupportPurchasedAt;

  const showAdditionalWritingFeedbackButton =
    additionalWritingFeedbackPurchaseEnabled && reviewsRemaining < 2;

  const paused = pausedUntil && dayjs().isBefore(pausedUntil);

  if (!expectedEndAt) {
    return null;
  }

  const items: DropdownMenuItem[] = [
    {
      type: 'button',
      label: 'Edit project details',
      iconId: 'edit',
      onSelect: () => openProjectDetailsTab(),
    },
  ];

  if (canRequestOrRespondAdditionalSessions) {
    items.push({
      type: 'button',
      label: extensionRequestAction,
      iconId: 'calendar',
      onSelect: () => dispatch(commonReducers.projectExtensionActions.openProjectExtensionModal()),
    });
  }

  if (pauseEnabled) {
    items.push({
      type: 'button',
      label: 'Pause project',
      iconId: 'pause',
      onSelect: () => void NiceModal.show(ProjectPauseRequestModal),
    });
  }

  const manageButtonVisible = items.length > 1;

  return (
    <div
      className={classNames('m-3 alert text-center', {
        'alert-primary': !paused,
        'alert-warning': paused,
      })}
    >
      {paused && (
        <p>
          This project is paused until {new DateWrapper(pausedUntil).format(DateFormat.FORMAT_2)}.
        </p>
      )}
      {title && <h5>{title}</h5>}
      <p className="my-3">
        Project deadline : {new DateWrapper(expectedEndAt).format(DateFormat.FORMAT_2)}
        <ProjectDeadlineInformation isDeadlineExtendable={canRequestOrRespondAdditionalSessions} />
        {manageButtonVisible && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button className="ms-3" size="sm" startIcon={<Icon id="settings" />}>
                Manage Project
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" menuItems={items} />
          </DropdownMenu>
        )}
        <div className="mt-3">
          <ProjectDeadlineExtension />
          <RespondToProjectPauseRequest />
        </div>
      </p>
      <UpSellSection
        showAdditionalWritingFeedbackButton={showAdditionalWritingFeedbackButton}
        showPremiumShowcasingAddonButton={showPremiumShowcasingAddonButton}
        canRequestOrRespondAdditionalSessions={canRequestOrRespondAdditionalSessions}
      />
      {projectExtension.isOpen && <ProjectDeadlineExtensionModal />}
    </div>
  );
};

const TABS = {
  sessions: 0,
  projectDetails: 2,
};

const ProjectSidebar = () => {
  const project = commonHooks.useSelectedProject();
  const user = commonHooks.useCurrentUser();
  const [tabIndex, setTabIndex] = useState(TABS.sessions);

  const studentHasCompeting = project?.studentPurchasedProductNames?.includes(
    'Premium Showcasing - Competing',
  );

  if (!project) {
    return null;
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <ProjectDeadline
        project={project}
        openProjectDetailsTab={() => setTabIndex(TABS.projectDetails)}
      />
      {isStudent() && project.milestonesEnabled && Number(project.sessionsRemaining) < 3 && (
        <SubmitFinalProjectButton />
      )}
      {user.userType === 'mentor' &&
        studentHasCompeting &&
        project?.type === 'pathfinder_launchpad' && (
          <Alert variant="danger" className="mx-3">
            As a reminder this Launchpad student has also enrolled in the{' '}
            <b>Premium Showcasing Competing Track.</b> This means they most likely want to enter a
            science fair competition. They are meeting you before they meet their competing mentor
            or their core mentor and they will want to use this session to brainstorm potential
            experiment ideas. Please use the agenda outlined in{' '}
            <a
              href="https://docs.google.com/document/d/1HKfL0jdZRYzLPLzSA-2QSk0EOZRCpc_fLw1EaNkAnCs/"
              target="_blank"
              rel="noreferrer"
            >
              this document
            </a>{' '}
            for your session.
          </Alert>
        )}
      <Tabs
        selectedIndex={tabIndex}
        onSelect={setTabIndex}
        className="h-react-tabs"
        selectedTabClassName="h-react-tabs__tab--selected"
      >
        <TabList className={['react-tabs__tab-list', 'h-react-tabs__tab-list']}>
          <Tab className={['react-tabs__tab', 'h-react-tabs__tab']}>Sessions</Tab>
          <Tab className={['react-tabs__tab', 'h-react-tabs__tab']}>{SHARED_DOCUMENTS_LABEL}</Tab>
          {project.detailsEnabled && (
            <Tab className={['react-tabs__tab', 'h-react-tabs__tab']}>Project</Tab>
          )}
        </TabList>

        <TabPanel>
          <SessionList />
        </TabPanel>
        <TabPanel>
          <SharedResourceList />
        </TabPanel>
        <TabPanel>
          <ProjectDetails />
        </TabPanel>
      </Tabs>
    </div>
  );
};

const Meetings = () => {
  const dispatch = useAppDispatch();
  const currentUser = commonHooks.useCurrentUser();
  const selectedWorkspace = commonHooks.useSelectedWorkspace();
  const userPermissions = commonHooks.usePermissions();
  const meetingIds = selectedWorkspace?.meetingIds || [];

  useEffect(() => {
    if (selectedWorkspace?.id) {
      void dispatch(
        commonThunks.hermesThunks.getMeetingsForWorkspace({ workspaceId: selectedWorkspace.id }),
      );
    }
  }, [selectedWorkspace?.id, dispatch]);

  const openEditor = (id: number) => {
    if (
      !(permissions.canSchedule(userPermissions) || permissions.canScheduleLimited(userPermissions))
    ) {
      return undefined;
    }

    return () => dispatch(commonReducers.hermesActions.openScheduler({ meetingId: id }));
  };

  if (!meetingIds.length) {
    return null;
  }

  return (
    <div className="h-meeting m-3">
      <h4>Meetings scheduled</h4>
      {meetingIds.map((id) => {
        return (
          <MeetingTime
            key={id}
            meetingId={id}
            timeZone={currentUser.timeZone}
            cancellable
            handleEdit={openEditor(id)}
          />
        );
      })}
    </div>
  );
};

const BasicSidebar = () => {
  return (
    <div className="d-flex flex-column flex-grow-1">
      <CancellationResponse />
      <Meetings />
      <Tabs className="h-react-tabs" selectedTabClassName="h-react-tabs__tab--selected">
        <TabList className={['react-tabs__tab-list', 'h-react-tabs__tab-list']}>
          <Tab className={['react-tabs__tab', 'h-react-tabs__tab']}>{SHARED_DOCUMENTS_LABEL}</Tab>
        </TabList>

        <TabPanel className="d-flex flex-column flex-grow-1 justify-content-between">
          <SharedResourceList />
        </TabPanel>
      </Tabs>
    </div>
  );
};

const LoungeDetails = ({
  room: { participants, notificationEnabled, id },
  lounge: { image, name },
}: {
  room: Room;
  lounge: Lounge;
}) => {
  const dispatch = useAppDispatch();
  const onNotificationEnabledChange = () => {
    void dispatch(commonThunks.hermesThunks.toggleRoomNotification({ roomId: id }));
  };

  return (
    <>
      <div className="h-chat-lounge">
        <div className="h-chat-lounge-details">
          <div>{image && <img src={image} alt="lounge-logo" />}</div>
          <div>
            <div className="h-chat-lounge-name">{name}</div>
            <div className="h-chat-lounge-participants">Participants: {participants.length}</div>
          </div>
        </div>
        <div className="h-chat-lounge-notification">
          <div>Notifications</div>
          <Switch
            onChange={onNotificationEnabledChange}
            checked={notificationEnabled}
            offColor={getCssVariable('--h-red')}
            height={20}
            width={35}
            className="react-switch"
          />
          <span>{notificationEnabled ? 'On' : 'Off'}</span>
        </div>
      </div>
      <hr className="mt-3" />
    </>
  );
};

export const Sidebar = () => {
  const selectedProject = commonHooks.useSelectedProject();
  const selectedRoom = commonHooks.useSelectedRoom();
  const selectedLounge = useSelectedLounge();
  const currentUser = commonHooks.useCurrentUser();
  const otherParticipants =
    selectedRoom?.participants.filter(({ id }) => {
      return id !== currentUser.id;
    }) ?? [];
  const firstSession = selectedProject?.sessions?.[0];
  const isFirstSessionNotScheduled = !firstSession || firstSession.proposing;
  const projectStartingDate = dayjs.utc(selectedProject?.expectedStartAt);
  const daysRemaining = getDaysRemainingUntil(projectStartingDate.set('date', 1));
  const shouldShowProjectStartWarning =
    selectedProject?.expectedStartAt &&
    selectedProject.sessions &&
    isFirstSessionNotScheduled &&
    daysRemaining > 0;

  return (
    <motion.div
      layoutScroll
      className="h-container h-vertical-scroll-auto d-flex flex-column flex-grow-1"
    >
      {selectedLounge && selectedRoom && (
        <LoungeDetails room={selectedRoom} lounge={selectedLounge} />
      )}
      <PartnerDetails partners={otherParticipants} showImages />
      {shouldShowProjectStartWarning && (
        <ProjectStartWarning
          projectStartingDate={new DateWrapper(projectStartingDate).format(DateFormat.FORMAT_7)}
          daysRemaining={daysRemaining}
          userType={currentUser.userType}
        />
      )}
      {selectedProject ? <ProjectSidebar /> : <BasicSidebar />}
    </motion.div>
  );
};
