import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useGetUserSnippetsQuery } from '@polygence/common/api/hermes-ai';
import { HermesAIMessageThreadId } from '@polygence/common/types/common';
import { Icon, Popover, PopoverTrigger, PopoverContent, Text } from '@polygence/components';
import { COMMAND_PRIORITY_HIGH, PASTE_COMMAND } from 'lexical';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { UserSnippetList } from 'src/components/hermes-ai/user-snippet/UserSnippetList';
import { useCreateUserSnippet } from 'src/components/hermes-ai/user-snippet/useCreateUserSnippet';

export const UserSnippetPlugin = ({
  messageThreadId,
}: {
  messageThreadId?: HermesAIMessageThreadId | null;
}) => {
  const [editor] = useLexicalComposerContext();
  const createUserSnippet = useCreateUserSnippet();
  const { count } = useGetUserSnippetsQuery(messageThreadId ?? 0, {
    skip: !messageThreadId,
    selectFromResult: ({ data }) => {
      if (!messageThreadId) {
        return { count: 0 };
      }

      return { count: data?.length ?? 0 };
    },
  });

  useEffect(() => {
    editor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event: ClipboardEvent) => {
        if (event.clipboardData) {
          const text = event.clipboardData.getData('text/plain');
          if (!text || text.length < 300) {
            return false;
          }

          createUserSnippet(text).catch(() => toast.error('Failed to create snippet.'));
          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [editor, createUserSnippet]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          type="button"
          title="Snippets"
          className="toolbar-item tw-relative"
          aria-label="Snippets"
        >
          <Icon id="file-text" className="tw-text-gray-600" />
          {!!count && (
            <Text
              size="small"
              alignment="left"
              fontWeight="bold"
              className="tw-absolute tw-right-[6px] tw-top-[-2px] tw-text-primary-700"
            >
              {count}
            </Text>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className="tw-z-[1001] tw-grid tw-min-h-28 tw-min-w-[240px] tw-max-w-[90vw] tw-place-items-center xl:tw-max-w-[120ch]">
        <UserSnippetList messageThreadId={messageThreadId} />
      </PopoverContent>
    </Popover>
  );
};
