import { OpenAIMessageId } from '@polygence/common/types/common';
import { ToolCall } from '@polygence/common/types/data/openai';
import { cn, Icon } from '@polygence/components';
import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { MarkdownBase } from 'src/components/hermes-ai/messages/MarkdownBase';
import { MessageContext } from 'src/components/hermes-ai/messages/MessageContext';
import { ToolCalls } from 'src/components/hermes-ai/messages/ToolCall';
import { CitationList } from 'src/components/hermes-ai/messages/citation/CitationList';
import { MessageToolbar } from 'src/components/hermes-ai/messages/toolbar/MessageToolbar';
import { useGetMessageCount } from 'src/components/hermes-ai/messages/useGetMessageCount';

const MessageContextProvider = ({
  children,
  messageId,
}: {
  children: React.ReactNode;
  messageId: OpenAIMessageId | undefined;
}) => {
  const contextValue = useMemo(() => ({ messageId }), [messageId]);

  return <MessageContext.Provider value={contextValue}>{children}</MessageContext.Provider>;
};

export const AssistantMessage = ({
  message,
  id,
  isLast,
  toolCalls,
}: {
  message: string;
  id?: OpenAIMessageId;
  isLast: boolean;
  toolCalls?: ToolCall[] | null;
}) => {
  const { messageCount, maxNumberOfMessages } = useGetMessageCount({ forMessageId: id });
  const showMessageCount = messageCount && maxNumberOfMessages;
  const { hash } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hash && hash.startsWith('#') && hash.substring(1) === String(id)) {
      ref.current?.scrollIntoView();
      ref.current?.animate(
        [
          { backgroundColor: 'transparent' },
          { backgroundColor: 'white', offset: 0.3 },
          { backgroundColor: 'transparent', offset: 1 },
        ],
        {
          duration: 1400,
          iterations: 1,
        },
      );
    }
  }, [hash, id]);

  return (
    <MessageContextProvider messageId={id}>
      <section className={cn('tw-group/message', showMessageCount && '-tw-mb-2')} ref={ref}>
        <div className="tw-flex tw-items-start tw-gap-4 tw-self-start tw-p-2 tw-font-light">
          <div className="tw-flex tw-h-full tw-shrink-0 tw-flex-col tw-justify-between">
            <div className="tw-flex tw-size-11 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-200 tw-shadow-inner">
              <Icon
                id="sparkles"
                className={classNames('tw-text-primary-500', { 'tw-animate-pulse': id == null })}
                size="lg"
              />
            </div>
          </div>
          <div className="tw-pt-2 [&>ol]:tw-flex [&>ol]:tw-flex-col [&>ol]:tw-gap-3 [&>p:last-of-type]:tw-mb-0 [&_li>p]:tw-mb-0">
            <MarkdownBase>{message}</MarkdownBase>
            <CitationList messageId={id} />
          </div>
        </div>
        <MessageToolbar
          show={isLast}
          messageCount={messageCount}
          maxNumberOfMessages={maxNumberOfMessages}
          messageId={id}
        />
      </section>
      {/* this is at the top of the message, because of flex-col-reverse */}
      <ToolCalls toolCalls={toolCalls} />
    </MessageContextProvider>
  );
};
