import * as showcasingApi from '@polygence/common/api/showcasing';
import type { OpportunityPublic } from '@polygence/common/types/showcasing';
import { Card, Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Icon as CustomIcon } from 'src/components/Icon';
import styles from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityCard.module.scss';
import { OpportunityCardHeader } from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityCardHeader';
import { OpportunityCardSideBox } from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityCardSideBox';
import { OpportunityKeywordList } from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityKeywordList';
import { useGetKeywordsQuery } from 'src/reducers/showcasingReducers';

export const OpportunityCard = ({
  id,
  slug,
  name,
  type,
  deadline,
  deadlineAt,
  generalNotes,
  generalWebsite,
  keywords,
  mentorCoauthor,
  originalResearch,
  liked,
  peerReviewed,
  reviewPaper,
  submissionFee,
  submissionFeeInCents,
  submissionDetails,
  promoted,
  logo,
  approximateReviewTime,
  polygenceNotes,
  fastTrackReview,
  showcasingOutcomeCount,
}: OpportunityPublic) => {
  const [isLiked, setIsLiked] = useState(liked);
  const { data: showcasingKeywords } = useGetKeywordsQuery();

  const getKeywordNameFrom = (keywordId: number) => {
    return showcasingKeywords?.find((keyword) => keyword.id === keywordId)?.name || '';
  };

  const keywordNames = keywords
    .map(getKeywordNameFrom)
    .filter((keywordName) => keywordName.length > 0);

  const addOrRemoveOpportunityLike = () => {
    if (isLiked) {
      showcasingApi
        .removeOpportunityLike(id)
        .then(() => {
          toast.success('Removed from favorites');
          setIsLiked(false);
        })
        .catch(console.error);
    } else {
      showcasingApi
        .addOpportunityLike(id)
        .then(() => {
          toast.success('Added to favorites');
          setIsLiked(true);
        })
        .catch(console.error);
    }
  };

  return (
    <Card className={classNames('p-8 flex-md-row gap-5', promoted ? styles['promoted'] : '')}>
      <div className="flex-grow-1 d-flex flex-column gap-5">
        <OpportunityCardHeader
          type={type}
          slug={slug}
          name={name}
          addOrRemoveOpportunityLike={addOrRemoveOpportunityLike}
          isLiked={isLiked}
          logo={logo}
        />
        {keywordNames.length > 0 && <OpportunityKeywordList items={keywordNames} />}
        {generalNotes && (
          <div className="bg-grayscale-2 p-5 base-border-radius d-flex gap-3">
            <Icon id="info" className="mt-1" color="var(--secondary)" />
            <Text size="medium">{generalNotes}</Text>
          </div>
        )}
        {mentorCoauthor && (
          <div className="bg-grayscale-2 p-5 base-border-radius d-flex gap-3">
            <Icon id="users" className="mt-1" color="var(--secondary)" />
            <Text size="medium">
              <span className="text-bold">Mentor co-author:</span> {mentorCoauthor}
            </Text>
          </div>
        )}
        {polygenceNotes && (
          <div className="bg-grayscale-2 p-5 base-border-radius d-flex gap-3">
            <CustomIcon name="polygence-icon-circle" style={{ flexShrink: 0 }} />
            <Text size="medium">{polygenceNotes}</Text>
          </div>
        )}
        {showcasingOutcomeCount > 0 && (
          <div className="d-flex gap-2">
            <Icon id="hash" color="var(--secondary)" />
            <Text size="medium">
              <Text as="span" size="medium" fontWeight="bold">
                {showcasingOutcomeCount}+ students{' '}
              </Text>
              have published here
            </Text>
          </div>
        )}
      </div>
      <OpportunityCardSideBox
        generalWebsite={generalWebsite}
        submissionDetails={submissionDetails}
        deadline={deadline}
        deadlineAt={deadlineAt}
        submissionFee={submissionFee}
        submissionFeeInCents={submissionFeeInCents}
        originalResearch={originalResearch}
        reviewPaper={reviewPaper}
        peerReviewed={peerReviewed}
        fastTrackReview={fastTrackReview}
        approximateReviewTime={approximateReviewTime}
      />
    </Card>
  );
};
