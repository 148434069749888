import { ToolCall } from '@polygence/common/types/data/openai';
import { Icon, Text, Tooltip } from '@polygence/components';
import { Fragment } from 'react';
import { boolean, object, optional, safeParse, string } from 'valibot';

const ResearchPaperSearchToolCallArgs = object({
  query: string(),
  only_open_access: optional(boolean()),
});

const PolygenceKnowledgeBaseToolCallArgs = object({
  query: string(),
});

const ResearchPaperSearchToolCall = ({ toolCall }: { toolCall: ToolCall }) => {
  const { success, output } = safeParse(
    ResearchPaperSearchToolCallArgs,
    JSON.parse(toolCall.function.arguments),
  );

  if (!success) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <Icon id="search" size="xs" />
      <Text size="small" className="tw-text-gray-600">
        Searching for research papers on <span className="tw-font-semibold">{output.query}</span>
        {output.only_open_access && (
          <Tooltip
            tip="Only including open access papers. You can specify to search all papers instead."
            placement="bottom"
          >
            <Icon id="book-open" size="xs" className="tw-ml-1 tw-text-gray-400" />
          </Tooltip>
        )}
      </Text>
    </div>
  );
};

const PolygenceKnowledgeBaseToolCall = ({ toolCall }: { toolCall: ToolCall }) => {
  const { success, output } = safeParse(
    PolygenceKnowledgeBaseToolCallArgs,
    JSON.parse(toolCall.function.arguments),
  );

  if (!success) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <Icon id="search" size="xs" />
      <Text size="small" className="tw-text-gray-600">
        Searching for <span className="tw-font-semibold">{output.query}</span> in the Polygence
        Knowledge Base.
      </Text>
    </div>
  );
};

const InternetSearchToolCall = () => {
  return (
    <div className="internet-search tw-flex tw-items-center tw-gap-2 [&~.internet-search]:tw-hidden">
      <Icon id="search" size="xs" />
      <Text size="small" className="tw-text-gray-600">
        Searching the web for answers.
      </Text>
    </div>
  );
};

const TOOL_CALL_COMPONENTS: Record<string, (props: { toolCall: ToolCall }) => JSX.Element | null> =
  {
    SearchPolygenceKnowledgeBase: PolygenceKnowledgeBaseToolCall,
    ResearchPaperSearch: ResearchPaperSearchToolCall,
    InternetSearch: InternetSearchToolCall,
  };

export const ToolCalls = ({ toolCalls }: { toolCalls: ToolCall[] | undefined | null }) => {
  if (!toolCalls || toolCalls.length === 0) {
    return null;
  }

  const hasResearchPaperSearchToolCall = toolCalls.some(
    (toolCall) => toolCall.function.name === 'ResearchPaperSearch',
  );

  return (
    <div className="-tw-mb-2 tw-flex tw-flex-col tw-gap-3">
      {toolCalls.map((toolCall) => {
        const Component = TOOL_CALL_COMPONENTS[toolCall.function?.name] ?? Fragment;

        return <Component key={toolCall.id} toolCall={toolCall} />;
      })}
      {hasResearchPaperSearchToolCall && (
        <div className="tw-flex tw-gap-3 tw-rounded-md tw-bg-gray-200 tw-px-3 tw-py-2 tw-text-gray-600">
          <Tooltip
            tip='TIP: You can use the "Share message" button to quickly share the results in the chat with your mentor.'
            placement="bottom"
          >
            <Icon id="info" size="md" className="tw-text-gray-500" />
          </Tooltip>
          <Text size="small" className="tw-text-gray-600">
            Always consult your mentor before diving deep into the linked research papers.
          </Text>
        </div>
      )}
    </div>
  );
};
