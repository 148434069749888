import { commonThunks, SharedResource } from '@polygence/common';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import styles from 'src/components/hermes/SharedResource/SharedResourceFavourite.module.scss';
import { useAppDispatch } from 'src/store';

interface SharedResourceProps {
  resource: SharedResource;
  workspaceId: number;
  label?: string;
}

export const SharedResourceFavourite = ({ resource, workspaceId, label }: SharedResourceProps) => {
  const dispatch = useAppDispatch();
  const handleFavouriteItem = () => {
    dispatch(
      commonThunks.sharedResourcesThunks.updateSharedResource({
        resourceId: resource.id,
        workspaceId,
        payload: {
          favourite: !resource.favourite,
        },
      }),
    ).catch(() => {
      toast.error('Error updating shared resource.');
    });
  };
  return (
    <button
      type="button"
      onClick={handleFavouriteItem}
      className={classNames(styles['star'], {
        [styles['favourite'] ?? '']: resource.favourite,
      })}
    >
      ★{label && <span className={styles['label']}>{label}</span>}
    </button>
  );
};
