import { UUID, commonReducers, type LoginData } from '@polygence/common';
import { Card, InputField, Button } from '@polygence/components';
import type { AxiosError } from 'axios';
import axios from 'axios';
import classnames from 'classnames';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';

import styles from 'src/components/FormWithEmailInput/FormWithEmailInput.module.scss';
import { useAppDispatch } from 'src/store';
import { urls } from 'src/urls';
import { UnverifiedUserLocalStorageHandler } from 'src/utils/localStorageManager';
import { getAfterSignInUrl } from 'src/utils/navigation';

type VerificationError = AxiosError<{
  error: string;
  message: string;
}>;

const isVerificationError = (error: AxiosError): error is VerificationError => {
  if (
    error.response &&
    error.response.data != null &&
    typeof error.response.data === 'object' &&
    'error' in error.response.data &&
    'message' in error.response.data
  ) {
    return true;
  }
  return false;
};

interface VerifyAccountProps {
  onAccountVerification: (
    uuid: UUID,
    password: string,
  ) => Promise<{ access?: string; refresh?: string }>;
  onLogin: (data: Pick<LoginData, 'access' | 'refresh'>, callback?: () => void) => void;
}

export const VerifyAccount = ({ onAccountVerification, onLogin }: VerifyAccountProps) => {
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();
  const [query] = useQueryParams({
    from: StringParam,
  });
  const dispatch = useAppDispatch();

  const status = {
    PASSWORD_FORM: 'password_form',
    ACCOUNT_VERIFICATION_COMPLETE: 'account_verification_complete',
    LINK_EXPIRED: 'link_expired',
    UNEXPECTED_RESPONSE: 'unexpected_response',
  };
  const [state, setState] = useState({
    pageStatus: status.PASSWORD_FORM,
    newPassword: '',
    genericErrorMessage: '',
    newPasswordError: '',
    isLoggedIn: false,
    tokens: {
      access: '',
      refresh: '',
    },
  });

  const { genericErrorMessage, newPassword, pageStatus, newPasswordError } = state;

  function handleChange({ target: { name, value } }: ChangeEvent<HTMLInputElement>) {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
        genericErrorMessage: '',
        [`${name}Error`]: '',
      };
    });
  }

  function handleError(error: Error | AxiosError) {
    if (axios.isAxiosError(error) && error.response?.data && isVerificationError(error)) {
      const errorPayload = error.response.data;
      if (errorPayload.error === 'password_error') {
        setState((prevState) => {
          return {
            ...prevState,
            newPasswordError: errorPayload.message,
          };
        });
        return;
      }
      setState((prevState) => {
        return {
          ...prevState,
          genericErrorMessage: errorPayload.message,
          pageStatus: status.LINK_EXPIRED,
        };
      });
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>, data: typeof state) {
    e.preventDefault();
    if (data.newPassword.trim().length === 0) {
      setState((prevState) => {
        return {
          ...prevState,
          genericErrorMessage: 'Please fill out the input field!',
        };
      });
      return;
    }

    onAccountVerification(uuid, data.newPassword)
      .then(({ access, refresh }) => {
        if (access && refresh) {
          setState((prevState) => {
            return {
              ...prevState,
              genericErrorMessage: '',
              pageStatus: status.ACCOUNT_VERIFICATION_COMPLETE,
              tokens: { access, refresh },
            };
          });
        } else {
          dispatch(commonReducers.userActions.updateUser({ isVerified: true }));

          const redirectUrl = UnverifiedUserLocalStorageHandler.verificationRedirectUrl();

          if (redirectUrl) {
            UnverifiedUserLocalStorageHandler.verify();
            return history.replace(redirectUrl);
          }

          setState((prevState) => {
            return {
              ...prevState,
              genericErrorMessage: '',
              pageStatus: status.ACCOUNT_VERIFICATION_COMPLETE,
              isLoggedIn: true,
            };
          });
        }
      })
      .catch(handleError);
  }

  function enterSite() {
    if (state.isLoggedIn) {
      history.replace(getAfterSignInUrl() || '/user/login');
    } else {
      onLogin({ access: state.tokens.access, refresh: state.tokens.refresh });
    }
  }

  const errorMessage = <div className="form-validation-error">{genericErrorMessage}</div>;

  const isPasswordReset = query['from'] === 'password-reset';

  return (
    <div className={styles['cardWrapper']}>
      <Card>
        {pageStatus === status.PASSWORD_FORM && (
          <div className="p-8 d-flex flex-column gap-4">
            <h4 className={classnames('text-center fw-normal m-0', styles['title'])}>
              {isPasswordReset ? 'Reset your password' : 'Verify your account'}
            </h4>
            <div className={classnames('text-center', styles['text'])}>
              Please choose a new password.
            </div>
            {errorMessage}
            <form
              onSubmit={(e) => {
                return handleSubmit(e, state);
              }}
              autoComplete="on"
              className="d-flex flex-column gap-4"
            >
              <InputField
                type="password"
                name="newPassword"
                label="New password"
                value={newPassword}
                onChange={handleChange}
                isInvalid={Boolean(newPasswordError)}
                errorMessage={newPasswordError}
              />
              <Button type="submit" size="lg" variant="primary">
                Submit
              </Button>
            </form>
          </div>
        )}
        {pageStatus === status.ACCOUNT_VERIFICATION_COMPLETE && (
          <div className="d-flex flex-column p-8">
            <div className="user-overlay-text mb-6">
              {isPasswordReset
                ? 'Your password has been reset.'
                : 'Your account has been verified.'}
            </div>
            <Button size="lg" variant="primary" onClick={enterSite}>
              Continue
            </Button>
          </div>
        )}
        {pageStatus === status.UNEXPECTED_RESPONSE && (
          <div className="d-flex flex-column p-8">Something went wrong. Please try again!</div>
        )}
        {pageStatus === status.LINK_EXPIRED && (
          <div className="d-flex flex-column p-8 align-items-center">
            <div className="user-overlay-text mb-6">Link expired or user is already verified!</div>
            {/* @ts-expect-error using as=Link is fine See BSH-5057 */}
            <Button as={Link} variant="primary" to={urls.login()}>
              Back to Login
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
