import { useState } from 'react';
import { Form } from 'react-bootstrap';

interface CheckboxWithValueProps {
  name: string;
  label: string;
  disabled?: boolean;
  value?: string;
  onChange: (event: { target: { name: string; value: string | null | undefined } }) => void;
}

export const CheckboxWithValue = ({
  name,
  label,
  disabled = false,
  value,
  onChange,
}: CheckboxWithValueProps) => {
  const [checked, setChecked] = useState(value !== null);
  const handleChange = (changedValue: string | null | undefined) => {
    if (typeof onChange === 'function') {
      onChange({
        target: {
          name,
          value: checked ? changedValue : null,
        },
      });
    }
  };
  return (
    <>
      <Form.Group>
        <Form.Check
          disabled={disabled}
          type="checkbox"
          label={label}
          name={name}
          id={`check-${name}`}
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
            handleChange(value);
          }}
        />
      </Form.Group>
      {checked && (
        <Form.Group>
          <Form.Label>Please specify:</Form.Label>
          <Form.Control
            disabled={disabled}
            name={`${name}-value`}
            value={value || ''}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Form.Group>
      )}
    </>
  );
};
