import { commonHooks } from '@polygence/common/hooks';
import { LoginData, UserType } from '@polygence/common/types/backend';
import type { Tenant } from '@polygence/common/types/white-label';
import { Route, Switch } from 'react-router-dom';

import { ErrorPage } from 'src/components/ErrorPage';
import { Guidebook } from 'src/components/Guidebook';
import { SettingsRoutes } from 'src/components/SettingsRoutes';
import ShowcasingOpportunity from 'src/components/ShowcasingOpportunity';
import { AdminRoutes } from 'src/components/admin/AdminRoutes.lazy';
import { PrivateRoute, StudentRoute } from 'src/components/authenticatedRoutes';
import HermesWrapper from 'src/components/hermes/HermesWrapper';
import { NotionPageRoutes } from 'src/components/notion/NotionPageRoutes.lazy';
import { NoAccessPage } from 'src/components/showcasing/NoAccessPage';
import { ShowcasingPage } from 'src/components/showcasing/ShowcasingPage';
import { WhiteLabelLoginCheck } from 'src/white-label/WhiteLabelLoginCheck';
import { WhiteLabelStudentRoutes } from 'src/white-label/WhiteLabelRoutes/WhiteLabelStudentRoutes.lazy';
import { WhiteLabelUserRoutes } from 'src/white-label/WhiteLabelRoutes/WhiteLabelUserRoutes';
import { WhiteLabelStudentApplicationForm } from 'src/white-label/student-application/WhiteLabelStudentApplicationForm';
import { WhiteLabelStudentProfileWelcomePage } from 'src/white-label/student-application/WhiteLabelStudentProfileWelcomePage';

interface WhiteLabelRoutesProps {
  tenant: Tenant;
  opportunitiesEnabled: boolean;
  missingDesiredFieldsList: string[];
  onLogin: (
    data: Pick<LoginData, 'access' | 'refresh'>,
    callback?: () => void,
    fromPath?: string,
  ) => void;
}

export const WhiteLabelRoutes = ({
  tenant,
  opportunitiesEnabled,
  missingDesiredFieldsList,
  onLogin,
}: WhiteLabelRoutesProps) => {
  const { loggedIn, userType, channel } = commonHooks.useCurrentUser();

  const userTypeEnumMap: Record<typeof userType, UserType> = {
    student: UserType.STUDENT,
    mentor: UserType.MENTOR,
    counselor: UserType.COUNSELOR,
    parent: UserType.PARENT,
  };

  const handleLogin = (data: Pick<LoginData, 'access' | 'refresh'>, callback?: () => void) => {
    onLogin(data, callback);
  };

  return (
    <Switch>
      <Route path="/administrator">
        <AdminRoutes />
      </Route>
      <Route path="/n">
        <NotionPageRoutes />
      </Route>
      <PrivateRoute path="/dashboard/hermes">
        <HermesWrapper />
      </PrivateRoute>
      <PrivateRoute
        path="/dashboard/showcasing-opportunities/:identifier"
        component={opportunitiesEnabled ? ShowcasingOpportunity : NoAccessPage}
      />
      <PrivateRoute
        path="/dashboard/showcasing-opportunities"
        component={opportunitiesEnabled ? ShowcasingPage : NoAccessPage}
      />
      <PrivateRoute
        path="/user-settings"
        component={SettingsRoutes}
        missingDesiredFieldsList={missingDesiredFieldsList}
      />
      <PrivateRoute
        path="/student-guidebook"
        exact
        component={Guidebook}
        userTypes={[UserType.STUDENT]}
        title="Welcome to Student Guidebook!"
      />
      <PrivateRoute
        path="/student/profile"
        component={WhiteLabelStudentProfileWelcomePage}
        tenant={tenant}
      />
      <PrivateRoute
        path="/student/:profileId/application/:applicationId"
        component={WhiteLabelStudentApplicationForm}
      />
      <StudentRoute
        path="/student"
        component={WhiteLabelStudentRoutes}
        userType={userTypeEnumMap[userType]}
        loggedIn={loggedIn}
        channel={channel}
        applicationJourneyEnabled={false}
      />
      <Route path="/error-page">
        <ErrorPage />
      </Route>
      <Route path="/">
        <div className="tw-grid tw-min-h-[calc(100vh-var(--header-height))] tw-place-content-center tw-bg-gray-200 tw-px-8">
          <Switch>
            <Route exact path="/">
              <WhiteLabelLoginCheck />
            </Route>
            <Route path="/user">
              <WhiteLabelUserRoutes onLogin={handleLogin} />
            </Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  );
};
