import type { Nullable } from '@polygence/common';
import { commonHooks } from '@polygence/common';
import { Button } from '@polygence/components';
import { useState, useEffect } from 'react';

import { Icon } from 'src/components/Icon';
import { externalFetch } from 'src/fetch';

interface GifSelectorProps {
  gifTerm: Nullable<string>;
  setGifTerm: React.Dispatch<React.SetStateAction<string>>;
  sendGif: (gif: string) => void;
}

interface GifItem {
  media: {
    0: {
      webm: {
        url: string;
      };
    };
  };
}

const GifSelector: React.FC<GifSelectorProps> = ({ gifTerm, setGifTerm, sendGif }) => {
  const currentUser = commonHooks.useCurrentUser();
  const [gifs, setGifs] = useState<Nullable<string[]>>(null);

  useEffect(() => {
    if (gifTerm) {
      void externalFetch(
        `https://g.tenor.com/v1/search?q=${gifTerm}&contentfilter=medium&key=EZMPH0R79GPW&limit=5`,
      ).then(({ data }: { data: { results: GifItem[] } }) => {
        setGifs(
          data.results.map((item) => {
            return item.media[0].webm.url;
          }),
        );
      });
    }
  }, [gifTerm]);

  if (currentUser == null) {
    return null;
  }

  const reset = () => {
    setGifs(null);
    setGifTerm('');
  };

  const selectGif = (gif: string) => {
    sendGif(gif);
    reset();
  };
  if (!gifs) {
    return null;
  }

  return (
    <div className="row justify-content-center mx-2">
      {gifs.map((gifItem) => {
        return (
          <Button
            key={gifItem}
            type="button"
            variant="link-primary"
            className="col-2"
            onClick={() => {
              selectGif(gifItem);
            }}
          >
            <video className="w-100" autoPlay controls={false} loop muted>
              <source src={gifItem} type="video/webm" />
            </video>
          </Button>
        );
      })}
      <Button variant="link-primary" className="col-2" onClick={reset}>
        <Icon name="cross" color="var(--primary)" local />
      </Button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default GifSelector;
