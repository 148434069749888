import * as marketplaceApi from '@polygence/common/api/marketplace';
import { commonHooks } from '@polygence/common/hooks';
import { commonReducers } from '@polygence/common/store/reducers';
import { Alert, Button, Heading, Spacer, Text } from '@polygence/components';
import { FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { WarningIndicatorIcon } from 'src/components/NavigationBar/WarningIndicatorIcon';
import { UploadImage } from 'src/components/UploadImage';
import { SocialAuthConnect } from 'src/components/auth/SocialAuthConnect';
import defaultProfileImage from 'src/components/static/images/generic-profile.jpg';
import { AiFeatureSettings } from 'src/components/usersettings/AiFeatureSettings';
import { AutoScheduleSetting } from 'src/components/usersettings/AutoScheduleSetting/AutoScheduleSetting';
import { CounselorNotificationSettings } from 'src/components/usersettings/CounselorNotificationSettings';
import {
  ExternalCalendars,
  useExternalCalendarSyncEnabled,
} from 'src/components/usersettings/ExternalCalendars';
import { ManagePaymentMethodsButton } from 'src/components/usersettings/ManagePaymentMethodsButton';
import { MentorWorkingHours } from 'src/components/usersettings/MentorWorkingHours/MentorWorkingHours';
import { PersonalDetails, Profile } from 'src/components/usersettings/PersonalDetails';
import { useMentorWorkingHours } from 'src/hooks/useMentorWorkingHours';
import { concatenateCommasAnd, replaceUnderscore } from 'src/utils';
import { isClientTimezoneDifferent } from 'src/utils/compareTimezones';

interface UserSettingsProps {
  profile: Profile;
  missingDesiredFieldsList: string[];
}

interface StateType {
  successMessage: string;
}

const SchedulingSettings = ({ isAutoAccepting }: { isAutoAccepting: boolean }) => {
  const {
    isDayEnabled,
    toggleTimeSlotsFor,
    getTimeSlotsFor,
    loading,
    changeStartAt,
    changeEndAt,
    createNextTimeSlot,
    deleteTimeSlot,
    timeSlots,
  } = useMentorWorkingHours();
  return (
    <>
      <Spacer size={6} />
      <MentorWorkingHours
        isDayEnabled={isDayEnabled}
        toggleTimeSlotsFor={toggleTimeSlotsFor}
        getTimeSlotsFor={getTimeSlotsFor}
        loading={loading}
        changeStartAt={changeStartAt}
        changeEndAt={changeEndAt}
        createNextTimeSlot={createNextTimeSlot}
        deleteTimeSlot={deleteTimeSlot}
      />
      <Spacer size={6} />
      <AutoScheduleSetting
        isAutoAccepting={isAutoAccepting}
        timeSlots={loading ? undefined : timeSlots}
      />
    </>
  );
};

const UserSettings = ({ profile, missingDesiredFieldsList }: UserSettingsProps) => {
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(() => {
    return profile.profilePicture ?? defaultProfileImage;
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [missingFields, setMissingFields] = useState(missingDesiredFieldsList);
  const { state } = useLocation<StateType>();
  const isExternalCalendarSyncEnabled = useExternalCalendarSyncEnabled();

  const errorMessage =
    missingFields.length > 0
      ? `Your ${concatenateCommasAnd(replaceUnderscore(missingFields))} is missing.`
      : null;

  const currentUser = commonHooks.useCurrentUser();
  const showPaymentMethods = currentUser.utilities.isStudent && profile.stripeIdPresent;
  const userType = currentUser.userType;
  const isMentor = currentUser.utilities.isMentor;
  const isCounselor = currentUser.utilities.isCounselor;

  const calendarRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && state?.successMessage.includes('calendar')) {
        node?.scrollIntoView();
      }
    },
    [state],
  );

  const submitForm = (
    event: FormEvent<HTMLInputElement> | null,
    data: Partial<Profile>,
    partialUpdate: boolean,
  ) => {
    if (event) {
      event.preventDefault();
    }

    const payload = { ...data, partial: partialUpdate };

    marketplaceApi
      .updateUserProfile(payload, userType)
      .then(({ data }) => {
        if (data['timeZone']) {
          dispatch(
            commonReducers.userActions.updateUser({
              timeZoneMismatch: isClientTimezoneDifferent(payload.timeZone),
              timeZone: data['timeZone'] as string,
            }),
          );
        }
      })
      .catch(console.error);
  };

  const updateInfoHandler = () => {
    marketplaceApi
      .getUserInfo()
      .then(({ data }: { data: { missingDesiredFieldsList: string[] } }) => {
        setMissingFields(data.missingDesiredFieldsList);
        if (data.missingDesiredFieldsList.length > 0) {
          const fieldArray = replaceUnderscore(data.missingDesiredFieldsList);
          toast.error(`Your ${concatenateCommasAnd(fieldArray)} is missing.`);
          return;
        }
        toast.success('Your profile is complete!');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeError = (name: string) => {
    if (Array.isArray(missingFields) && missingFields.indexOf(name) > -1) {
      const updatedFieldList = missingFields.filter((error) => {
        return error !== name;
      });
      setMissingFields(updatedFieldList);
    }
  };

  const changeProfilePic = (newPicture: string) => {
    setProfilePicture(newPicture);
    if (editMode) {
      submitForm(null, { profilePicture: newPicture }, true);
      removeError('profile_picture');
      setUploadInProgress(false);
    }
  };

  const removeProfilePic = () => {
    if (profilePicture !== defaultProfileImage) {
      setProfilePicture(defaultProfileImage);
      setMissingFields((prevState: string[]) => {
        return ['profile_picture', ...prevState];
      });
      submitForm(null, { profilePicture: null }, true);
    }
  };

  const editModeOn = () => {
    setEditMode(true);
  };

  const editModeOff = () => {
    setEditMode(false);
    setUploadInProgress(true);
  };

  return (
    <div className="pb-10">
      {errorMessage && (
        <h6 className="alert alert-warning mt-7" role="alert">
          {errorMessage}
        </h6>
      )}
      <Spacer size={6} />
      {currentUser.timeZoneMismatch && (
        <Alert variant="warning" className="d-flex gap-5">
          <WarningIndicatorIcon className="position-static mt-2 mt-xl-0" />
          <Text size="medium" style={{ color: 'var(--grayscale-8)' }}>
            It seems that <span className="text-bold">Your time zone</span> might have changed. You
            can update below.
          </Text>
        </Alert>
      )}
      <Spacer size={6} />
      {currentUser.missingDemographyData && (
        <Alert variant="warning" className="d-flex gap-5">
          <WarningIndicatorIcon className="position-static mt-2 mt-xl-0" />
          <div>
            <Text size="medium" style={{ color: 'var(--grayscale-8)' }}>
              Please fill the <span className="text-bold">demographic data survey</span>!
            </Text>
            <Button
              variant="link-secondary"
              // @ts-expect-error using as=Link is fine
              as={Link}
              to="/student/demographic-data-survey"
              className="align-self-start ms-n7"
            >
              Fill the survey
            </Button>
          </div>
        </Alert>
      )}
      <Spacer size={6} />
      <Heading size="h3" alignment="left">
        Profile picture
      </Heading>
      <UploadImage
        changeProfilePic={changeProfilePic}
        removeProfilePic={removeProfilePic}
        profile_img={profilePicture}
        editModeOn={editModeOn}
        editModeOff={editModeOff}
        edit_mode_on={editMode}
        upload_in_progress={uploadInProgress}
        missingFields={missingFields}
      />
      <Spacer size={6} />
      {showPaymentMethods && (
        <>
          <Heading size="h3" alignment="left">
            Payment methods
          </Heading>
          <Spacer size={3} />
          <ManagePaymentMethodsButton>
            <Text size="medium" fontWeight="normal">
              Manage payment methods
            </Text>
          </ManagePaymentMethodsButton>
          <Spacer size={6} />
        </>
      )}
      <PersonalDetails profile={profile} removeError={removeError} submitForm={submitForm} />
      {isExternalCalendarSyncEnabled && (
        <>
          <Spacer size={6} />
          <ExternalCalendars ref={calendarRef} />
        </>
      )}
      {isMentor && (
        <>
          <SchedulingSettings isAutoAccepting={Boolean(profile.autoAcceptingSessionProposalsAt)} />
          <AiFeatureSettings isSessionSummaryGenerationEnabled={!profile.aiSessionSummaryOptOut} />
        </>
      )}
      {isCounselor && <CounselorNotificationSettings />}
      <Spacer size={8} />
      <div className="row">
        <div className="col-lg-8 mx-auto d-flex flex-column justify-content-center gap-3">
          <Button variant="primary" onClick={updateInfoHandler}>
            Update info
          </Button>
          <SocialAuthConnect />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default UserSettings;
