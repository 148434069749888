import type * as backendApi from '@polygence/common/api/backend';
import { Card, Button, InputField } from '@polygence/components';
import classnames from 'classnames';
import React, { useState } from 'react';

import styles from 'src/components/FormWithEmailInput/FormWithEmailInput.module.scss';
import { getFieldErrors } from 'src/utils';

const FormWithEmailInput = ({
  title,
  initialText,
  emailSentText,
  initialValue = '',
  disabled = false,
  onSubmit: externalHandleSubmit,
}: {
  title: string;
  initialText: string;
  emailSentText: string;
  initialValue?: string;
  disabled?: boolean;
  onSubmit: typeof backendApi.changeEmailRequest;
}) => {
  const status = {
    EMAIL_FORM: 'email_form',
    EMAIL_SENT: 'email_sent',
    EMAIL_CHANGED: 'email_changed',
    UNEXPECTED_RESPONSE: 'unexpected_response',
  };
  const [state, setState] = useState({
    page_status: status.EMAIL_FORM,
    email: initialValue,
    generic_error_message: '',
    email_error: '',
  });

  const {
    page_status: pageStatus,
    generic_error_message: genericErrorMessage,
    email_error: emailError,
    email,
  } = state;

  const handleChange: React.ComponentProps<typeof InputField>['onChange'] = ({
    target: { name, value },
  }) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
        [`${name}_error`]: '',
        generic_error_message: '',
      };
    });
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>, data: typeof state) {
    e.preventDefault();
    if (!data.email.trim()) {
      setState((prevState) => {
        return {
          ...prevState,
          email_error: 'Required field',
        };
      });
      return;
    }
    setState((prevState) => {
      return {
        ...prevState,
        generic_error_message: '',
      };
    });

    externalHandleSubmit(data)
      .then((res) => {
        const { email } = res.data;
        if (res.status === 200) {
          setState((prevState) => {
            return {
              ...prevState,
              email,
              page_status: status.EMAIL_CHANGED,
            };
          });
        } else {
          setState((prevState) => {
            return {
              ...prevState,
              page_status: status.EMAIL_SENT,
            };
          });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          const fieldErrorsObj = getFieldErrors(err.response.data);
          setState((prevState) => {
            const generic_error_message = fieldErrorsObj['0_error'] ?? '';
            const newState = { ...prevState, generic_error_message };
            return {
              ...newState,
              ...fieldErrorsObj,
            };
          });
        } else {
          setState((prevState) => {
            return {
              ...prevState,
              page_status: status.UNEXPECTED_RESPONSE,
            };
          });
        }
      });
  }

  const errorMessage = <div className="form-validation-error">{genericErrorMessage}</div>;
  let pageMessage;
  if (pageStatus === status.EMAIL_FORM) {
    // eslint-disable-next-line fp/no-mutation
    pageMessage = (
      <div>
        <Card>
          <div className="p-8 d-flex flex-column gap-4">
            <h4 className={classnames('text-center fw-normal m-0', styles['title'])}>{title}</h4>
            <div className={classnames('text-center', styles['text'])}>{initialText}</div>
            {errorMessage}
            <form
              onSubmit={(e) => {
                return handleSubmit(e, state);
              }}
              autoComplete="on"
              className="d-flex flex-column gap-4"
            >
              <InputField
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={handleChange}
                isInvalid={Boolean(emailError)}
                errorMessage={emailError}
                disabled={disabled}
                required
              />
              <Button type="submit" size="lg" variant="primary">
                Send email
              </Button>
            </form>
          </div>
        </Card>
      </div>
    );
  } else if (pageStatus === status.EMAIL_CHANGED) {
    // eslint-disable-next-line fp/no-mutation
    pageMessage = (
      <Card>
        <div className="p-8">
          <div className="user-overlay-text">{emailSentText}</div>
          <div className="text-center text-bold">{email}</div>
        </div>
      </Card>
    );
  } else if (pageStatus === status.EMAIL_SENT) {
    // eslint-disable-next-line fp/no-mutation
    pageMessage = (
      <Card>
        <div className="p-8">
          <div className="user-overlay-text">
            An email has been sent to:
            <br />
            {/* eslint-disable-next-line react/forbid-dom-props */}
            <span style={{ color: 'black', fontWeight: 'bold' }}>{email}</span> <br />
            {emailSentText}
          </div>
        </div>
      </Card>
    );
  } else if (pageStatus === status.UNEXPECTED_RESPONSE) {
    // eslint-disable-next-line fp/no-mutation
    pageMessage = (
      <Card>
        <div className="p-8">Something went wrong. Please try again!</div>
      </Card>
    );
  }

  return <div className={styles['cardWrapper']}>{pageMessage}</div>;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default FormWithEmailInput;
