import type { Meeting } from '../types/hermes';

function checkPermission(permissions: string[], permission: string) {
  return permissions.includes(permission);
}

function canSchedule(permissions: string[]) {
  return checkPermission(permissions, 'can_schedule');
}

function canScheduleLimited(permissions: string[]) {
  return checkPermission(permissions, 'can_schedule_limited');
}

function canPropose(permissions: string[]) {
  return checkPermission(permissions, 'can_propose');
}

function canSubmitSummary(permissions: string[]) {
  return checkPermission(permissions, 'can_submit_summary');
}

function canStudentFeedback(permissions: string[]) {
  return checkPermission(permissions, 'can_student_feedback');
}

function canViewMeetingRecording(permissions: string[], meeting: Meeting) {
  return (
    meeting &&
    meeting.zoomRecordings.length > 0 &&
    (checkPermission(permissions, 'can_view_recording') || meeting.sharingOn)
  );
}

function canSubmitFeedbackRequest(permissions: string[]) {
  return checkPermission(permissions, 'can_submit_feedback_request');
}

function canRequestDeadlineExtension(permissions: string[]) {
  return checkPermission(permissions, 'can_request_deadline_extension');
}

function canRespondDeadlineExtension(permissions: string[]) {
  return checkPermission(permissions, 'can_respond_deadline_extension');
}

function canRequestAdditionalSessions(permissions: string[]) {
  return checkPermission(permissions, 'can_request_additional_sessions');
}

function canRespondAdditionalSessions(permissions: string[]) {
  return checkPermission(permissions, 'can_respond_additional_sessions');
}

function canScheduleAtAvailableTimes(permissions: string[]) {
  return checkPermission(permissions, 'can_schedule_at_available_times');
}

export const permissions = {
  canPropose,
  canSchedule,
  canScheduleLimited,
  canSubmitSummary,
  canStudentFeedback,
  canViewMeetingRecording,
  canSubmitFeedbackRequest,
  canScheduleAtAvailableTimes,
  canRequestDeadlineExtension,
  canRespondDeadlineExtension,
  canRequestAdditionalSessions,
  canRespondAdditionalSessions,
};
