import { Text } from '@polygence/components';

import { ApplicationHubCard } from 'src/components/student/ApplicationHubPage/ApplicationHubCard';

export const ApplicationCompleteStep = ({
  partnerPaysWorkflow,
}: {
  partnerPaysWorkflow: boolean;
}) => {
  return (
    <ApplicationHubCard narrow step={1} status="completed">
      <Text size="medium" fontWeight="bold">
        {partnerPaysWorkflow ? 'Enrollment Form Complete' : 'Application Complete'}
      </Text>
      <Text size="medium">An email confirmation has been sent to you</Text>
    </ApplicationHubCard>
  );
};
