import { pluralize } from '@polygence/common/utils/pluralize';

import { listAsSentence } from 'src/components/aux/grammar';

const MINIMUM_SESSIONS_REMAINING = 2;
const MAXIMUM_SESSIONS_REMAINING = 6;

const getOnTrackLabel = (sessionNumber: number, remainingSessions: number) => {
  const numberOfRemainingSessionsBetween = Math.max(
    0,
    Math.round(remainingSessions - MINIMUM_SESSIONS_REMAINING - 1),
  );

  if (numberOfRemainingSessionsBetween === 0) {
    return '';
  }

  const remainingSessionWhereQuestionWillRecur = Array(numberOfRemainingSessionsBetween)
    .fill(null)
    .map((_, i) => {
      return sessionNumber + i + 1;
    });

  return `This question will recur after ${pluralize(
    'session',
    numberOfRemainingSessionsBetween,
  )} ${listAsSentence(remainingSessionWhereQuestionWillRecur)}`;
};

const getAdditionalSessions = (sessionNumber: number, remainingSessions: number) => {
  return [
    {
      component: 'SelectInput',
      labelVariables: ['__expectedEndAt'],
      props: {
        label:
          'Is your student on track to finish and showcase their project by the project deadline? Your project end-date is {{__expectedEndAt}}.',
        name: 'additionalSessions',
        small_font: getOnTrackLabel(sessionNumber, remainingSessions),
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
          { label: 'Not sure', value: 'not_sure' },
        ],
      },
      display: {
        $and: [
          { $not: { field: 'countAs', value: 'no_show' } },
          {
            $gt: {
              field: '__sessionsRemaining',
              value: MINIMUM_SESSIONS_REMAINING,
            },
          },
          {
            $lt: {
              field: '__sessionsRemaining',
              value: MAXIMUM_SESSIONS_REMAINING,
            },
          },
        ],
      },
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label: 'Any additional comments:',
        name: 'additionalSessionsComment',
        small_font:
          'We will send out this summary to the student and parent after submission. Please provide details of what you covered in the session.',
        placeholder: 'Comments...',
      },
      display: {
        $and: [
          { $is: { field: 'additionalSessions', value: 'yes' } },
          { $not: { field: 'countAs', value: 'no_show' } },
          {
            $gt: {
              field: '__sessionsRemaining',
              value: MINIMUM_SESSIONS_REMAINING,
            },
          },
          {
            $lt: {
              field: '__sessionsRemaining',
              value: MAXIMUM_SESSIONS_REMAINING,
            },
          },
        ],
      },
    },
  ] as const;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default getAdditionalSessions;
