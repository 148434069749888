import { HermesAIMessageThreadId, OpenAIMessageId } from '@polygence/common';
import { usePrefetch } from '@polygence/common/api/hermes-ai';
import { Button, Icon, cn } from '@polygence/components';
import { Link } from 'react-router-dom';

import { useHermesAIRouteUrl } from 'src/components/hermes-ai/useHermesAIRouteUrl';

export const OpenAIThreadButton = ({
  threadId,
  messageId,
  className,
}: {
  threadId: HermesAIMessageThreadId;
  messageId?: OpenAIMessageId;
  className?: string;
}) => {
  const url = useHermesAIRouteUrl();
  const prefetch = usePrefetch('getMessages');

  const threadUrl = `${url}/${threadId}` + (messageId ? `#${messageId}` : '');

  return (
    <Button
      // @ts-expect-error see BSH-5057
      as={Link}
      className={cn(
        'tw-relative tw-ml-3 tw-mr-3 tw-border-b-2 tw-p-1 tw-pl-4',
        'before:tw-absolute before:tw-left-0 before:tw-top-0 before:tw-isolate before:tw-h-[60%] before:tw-w-2 before:tw-rounded-bl before:tw-border-0 before:tw-border-b-2 before:tw-border-l-2 before:tw-border-solid before:tw-border-gray-500 before:tw-content-[""]',
        className,
      )}
      size="sm"
      to={threadUrl}
      variant="link"
      endIcon={<Icon id="chevron-right" />}
      onMouseEnter={() => prefetch(threadId)}
    >
      Open thread
    </Button>
  );
};
