import {
  commonHooks,
  commonReducers,
  isFinalStudentSurveyCompletedByLastSession,
  isPreviousFeedbackSubmitted,
  isSurveyRequiredForFinalZoomMeeting,
  isWorkspaceTerminated,
  Meeting,
  permissions,
  Project,
  ProjectType,
  Room,
  Session,
  StringUserType,
  UserState,
  UserType,
  Workspace,
} from '@polygence/common';
import { Icon, IconVariable, Text, Tooltip } from '@polygence/components';
import classnames from 'classnames';
import type React from 'react';
import type { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from 'src/components/hermes/RoomNavigation.module.sass';
import { ZoomJoin } from 'src/components/hermes/ZoomJoin';
import { RoomListLoungePicture, RoomListProfilePictures } from 'src/components/hermes/chat';
import {
  useOldestSessionThatNeedsStudentFeedback,
  useOldestSessionThatNeedsSummary,
} from 'src/components/hermes/hooks';
import { getOtherParticipants, getRoomName } from 'src/components/hermes/utils';
import { PARTNERSHIPS_EMAIL } from 'src/constants';

interface RoomNavigationButtonProps {
  icon: IconVariable;
  onClick?: React.MouseEventHandler<Element>;
  disabled: boolean;
  primary?: boolean;
  label?: string;
  tag?: React.ComponentType | 'button' | 'span';
  tooltip?: string;
}

const RoomNavigationTooltip = ({
  children,
  tooltip,
}: {
  tooltip?: string;
  children: ReactElement;
}) => {
  return tooltip ? (
    <Tooltip placement="bottom" tip={tooltip}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const RoomNavigationButton: React.FC<RoomNavigationButtonProps> = ({
  label,
  icon,
  onClick: handleClick,
  tag: Component = 'button',
  disabled,
  tooltip,
  primary,
}) => {
  const id = `hermes-nav-${icon}`;
  return (
    <RoomNavigationTooltip tooltip={tooltip}>
      <Component
        className={classnames(
          'h-chat-navigation-button h-xsmall mx-2 clickable btn',
          { disabled },
          { primary },
        )}
        onClick={!disabled ? handleClick : undefined}
        data-tip
        data-for={id}
      >
        <Icon id={icon} size="lg" />
        <div className={classnames(styles['button-label'])}>{label}</div>
      </Component>
    </RoomNavigationTooltip>
  );
};

const TOOLTIPS = {
  zoom: {
    enabled: 'Join your upcoming Session on Zoom',
    disabled: 'You can only join your meeting 15 minutes prior to the scheduled start time',
    notJoinable:
      'You cannot join this meeting at this time. Please feel free to reach out to our support team for assistance.',
    manuallyDisabled:
      'All remaining sessions must have a proposed or confirmed time to join the Zoom room.',
    studentFinalSurveyNotSubmitted:
      'You won’t be able to join your final session until your final survey is complete.',
    studentShouldSubmitFinalSurvey:
      "You won't be able to join your final session until your student completes their final survey",
    proposing: 'Session proposal has not yet been accepted.',
    mentorSessionFeedbackNotSubmitted:
      'You won’t be able to join your next session until your student submits their feedback',
    studentSessionFeedbackNotSubmitted:
      'You won’t be able to join your next session until you submit your feedback',
  },
  schedule: {
    enabled: 'Schedule your next session',
    disabled: 'All your sessions are scheduled',
  },
  feedback: {
    alreadySent: 'You have already sent your feedback. Thank you!',
    disabled: 'You will be able to provide us with feedback here after each session.',
  },
  feedbackRequest: {
    beforeSessionFiveASPProject:
      'You have unlimited writing feedback sessions! You can begin to request external writing feedback.',
    reviewsAvailable: 'Reviews remaining: ',
    noReviewAvailable: 'All reviews have been utilized.',
  },
};

function getZoomTooltip(
  currentMeeting: Meeting | undefined,
  currentWorkspace: Workspace,
  project: Project | undefined,
  currentUser: UserState,
  canJoin: boolean,
  currentSession: Session | undefined | null,
) {
  if (currentWorkspace.zoomDisabled) {
    return TOOLTIPS.zoom.manuallyDisabled;
  }
  if (!currentMeeting) {
    return TOOLTIPS.zoom.disabled;
  }
  if (
    project &&
    isSurveyRequiredForFinalZoomMeeting(project) &&
    !isFinalStudentSurveyCompletedByLastSession(project, currentSession)
  ) {
    if (currentUser.userType === 'student') {
      return TOOLTIPS.zoom.studentFinalSurveyNotSubmitted;
    }
    return TOOLTIPS.zoom.studentShouldSubmitFinalSurvey;
  }
  if (currentSession?.proposing) {
    return TOOLTIPS.zoom.proposing;
  }
  if (project && currentSession && !isPreviousFeedbackSubmitted(project, currentSession?.number)) {
    if (currentUser.userType === 'mentor') {
      return TOOLTIPS.zoom.mentorSessionFeedbackNotSubmitted;
    }
    if (currentUser.userType === 'student') {
      return TOOLTIPS.zoom.studentSessionFeedbackNotSubmitted;
    }
  }
  if (currentMeeting && !canJoin) {
    return TOOLTIPS.zoom.notJoinable;
  }

  return TOOLTIPS.zoom.enabled;
}

function getFeedbackTooltip(
  project: Project | undefined,
  sessionThatNeedsStudentFeedback: Session | undefined,
) {
  if (!project?.sessions?.length || project.sessionsCompleted === 0) {
    return TOOLTIPS.feedback.disabled;
  }
  if (!sessionThatNeedsStudentFeedback) {
    return TOOLTIPS.feedback.alreadySent;
  }
  return undefined;
}

function getFeedbackRequestTooltip(project: Project | undefined) {
  if (project && project.type === ProjectType.ADVANCED_SCHOLARS_PROGRAM) {
    return TOOLTIPS.feedbackRequest.beforeSessionFiveASPProject;
  } else {
    if (project && project.reviewsRemaining > 0) {
      return `${TOOLTIPS.feedbackRequest.reviewsAvailable}${project.reviewsRemaining}`;
    }
  }
  return TOOLTIPS.feedbackRequest.noReviewAvailable;
}

const DisabledText = ({
  projectRooms,
  userType,
}: {
  projectRooms: Room[];
  userType: StringUserType;
}) => {
  if (userType === UserType.MENTOR) {
    return (
      <>
        This room has been disabled <br />
        For any questions or concerns regarding your project(s), please contact{' '}
        <a href="mailto:mentors@polygence.org">mentors@polygence.org</a> or use the black Support
        button on the bottom left of the screen in the workspace with your student(s).
      </>
    );
  }

  if (userType === UserType.COUNSELOR) {
    return (
      <>
        This room has been disabled <br />
        For any questions or concerns regarding your student(s), please contact{' '}
        <a href={`mailto:${PARTNERSHIPS_EMAIL}`}>{PARTNERSHIPS_EMAIL}</a> or use the black Support
        button on the bottom left of the screen in this workspace.
      </>
    );
  }

  return (
    <>
      {projectRooms.length ? (
        <>
          This room has been disabled <br />
          For any questions or concerns regarding your project, please contact{' '}
          <a href="mailto:students@polygence.org">students@polygence.org</a>.
        </>
      ) : (
        <>
          This room has been disabled <br />
          For any questions or concerns regarding your application, please contact{' '}
          <a href="mailto:admissions@polygence.org">admissions@polygence.org</a>.
        </>
      )}
    </>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const RoomNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const oldestSessionThatNeedsSummary = useOldestSessionThatNeedsSummary();
  const sessionThatNeedsStudentFeedback = useOldestSessionThatNeedsStudentFeedback();
  const currentMeeting = commonHooks.useCurrentMeeting();
  const currentSession = commonHooks.useSessionByMeetingId(currentMeeting?.id);
  const currentWorkspace = commonHooks.useSelectedWorkspaceOrThrow();
  const userPermissions = commonHooks.usePermissions();
  const project = commonHooks.useSelectedProject();
  const projectRooms = commonHooks.useProjectRooms();
  const canMeet = !project || project.sessionsSchedulable > 0;
  const isTerminated = isWorkspaceTerminated(currentWorkspace, project);
  const currentUser = commonHooks.useCurrentUser();
  const lounge = commonHooks.useLoungeByWorkspaceId(currentWorkspace.id);
  const room = commonHooks.useSelectedRoomOrThrow();
  const otherParticipants = getOtherParticipants(room.participants, currentUser);
  const roomName = getRoomName(lounge, otherParticipants || [], currentUser);
  const projectEnablesWritingFeedback = project && project.reviewsRemaining > 0;
  const writingFeedbackEnabled = commonHooks.useWritingFeedbackEnabled(userPermissions);

  const canJoin = commonHooks.useMeetingJoinable(currentMeeting, currentSession);

  const finalStudentSurveyUrl = project?.finalStudentSurveyUrl?.[currentUser.id];

  if (isTerminated) {
    if (currentWorkspace.operationUser) {
      return (
        <div className="alert alert-danger text-center mb-0 rounded-0">
          <DisabledText projectRooms={projectRooms} userType={currentUser.userType} />
        </div>
      );
    }
    return (
      <div className="alert alert-danger text-center mb-0 rounded-0">
        This room has been disabled
      </div>
    );
  }

  return (
    <div className="h-chat-navigation p-3 p-sm-7">
      <div className="h-room-name">
        {lounge ? (
          <RoomListLoungePicture lounge={lounge} />
        ) : (
          <RoomListProfilePictures participants={otherParticipants} />
        )}
        <Text size="medium" as="span" className="ms-3">
          {roomName}
        </Text>
      </div>
      {writingFeedbackEnabled && (
        <RoomNavigationButton
          icon="edit-3"
          label="Writing feedback"
          onClick={() => {
            dispatch(commonReducers.hermesActions.openFeedbackRequest());
          }}
          disabled={!projectEnablesWritingFeedback}
          tooltip={getFeedbackRequestTooltip(project)}
        />
      )}
      {permissions.canSubmitSummary(userPermissions) && (
        <RoomNavigationButton
          icon="file-plus"
          label="Summary"
          onClick={() => {
            if (oldestSessionThatNeedsSummary) {
              dispatch(
                commonReducers.hermesActions.openSummarySubmitter({
                  sessionId: oldestSessionThatNeedsSummary.id,
                }),
              );
            }
          }}
          disabled={!oldestSessionThatNeedsSummary}
        />
      )}
      {(permissions.canSchedule(userPermissions) ||
        permissions.canScheduleLimited(userPermissions)) && (
        <RoomNavigationButton
          icon="calendar"
          label="Schedule"
          onClick={() => {
            dispatch(commonReducers.hermesActions.openScheduler({}));
          }}
          disabled={!canMeet}
          tooltip={canMeet ? TOOLTIPS.schedule.enabled : TOOLTIPS.schedule.disabled}
        />
      )}
      {permissions.canStudentFeedback(userPermissions) && (
        <RoomNavigationButton
          icon="file-plus"
          label="Session feedback"
          onClick={() => {
            if (sessionThatNeedsStudentFeedback) {
              dispatch(
                commonReducers.hermesActions.openSessionFeedback({
                  sessionId: sessionThatNeedsStudentFeedback.id,
                }),
              );
            }
          }}
          tooltip={getFeedbackTooltip(project, sessionThatNeedsStudentFeedback)}
          disabled={!sessionThatNeedsStudentFeedback}
        />
      )}
      {project &&
        currentUser.utilities.isStudent &&
        !isFinalStudentSurveyCompletedByLastSession(project, currentSession) && (
          <RoomNavigationButton
            icon="file-plus"
            label="Final survey"
            onClick={() => {
              if (finalStudentSurveyUrl) {
                const url = new URL(finalStudentSurveyUrl);
                history.push(url.pathname);
              }
            }}
            tooltip={
              !finalStudentSurveyUrl
                ? 'Final survey is not available. Please contact support!'
                : undefined
            }
            disabled={!finalStudentSurveyUrl}
          />
        )}
      <ZoomJoin meeting={currentMeeting} canJoin={canJoin} sourceLocation="chat">
        <RoomNavigationButton
          icon="video"
          primary
          disabled={!canJoin}
          tooltip={getZoomTooltip(
            currentMeeting,
            currentWorkspace,
            project,
            currentUser,
            canJoin,
            currentSession,
          )}
        />
      </ZoomJoin>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default RoomNavigation;
