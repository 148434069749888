import NiceModal from '@ebay/nice-modal-react';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { useLastProjectPauseRequest } from '@polygence/common/hooks/selectors/useLatestProjectPauseRequest';
import useSelectedProject from '@polygence/common/hooks/selectors/useSelectedProject';
import { Button } from '@polygence/components';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import MultiLineText from 'src/components/hermes/messages/MultiLineText';
import { ProjectPauseRequestModal } from 'src/components/hermes/pause-project/ProjectPauseRequestModal';
import {
  ProjectPauseRequestModalStatus,
  calculateModalStatus,
} from 'src/components/hermes/pause-project/utils';

interface ExtensionRequestProps {
  content: string;
  extra: {
    pauseUntil: string;
    reasonForPause: string;
    projectPauseRequestId: number;
  };
}

export const ProjectPauseRequest: React.FC<ExtensionRequestProps> = ({
  content,
  extra: { pauseUntil = '', reasonForPause = '', projectPauseRequestId = '' } = {},
}) => {
  const lastProjectPauseRequest = useLastProjectPauseRequest();
  const currentUser = useCurrentUser();
  const currentProject = useSelectedProject();
  const isLatest = projectPauseRequestId === lastProjectPauseRequest?.id;
  const modalStatus = calculateModalStatus(
    currentUser.userType,
    currentProject?.projectPauseRequests,
  );

  const isResponding = isLatest && modalStatus === ProjectPauseRequestModalStatus.MENTOR_RESPOND;

  return (
    <div className="h-extension-request-message">
      {content && (
        <div className="fw-bold">
          <MultiLineText>{content}</MultiLineText>
        </div>
      )}
      <p className="mb-2">Here are the details:</p>
      <p>Pause the project until: {formatDateAsLocal(pauseUntil)}</p>
      <p>
        Why do you need to pause the project?
        <br />
        {reasonForPause}
      </p>
      {isResponding && (
        <Button
          size="sm"
          variant="tertiary"
          className="mt-3"
          onClick={() => void NiceModal.show(ProjectPauseRequestModal)}
        >
          Respond
        </Button>
      )}
    </div>
  );
};
