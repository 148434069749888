import { Button, DropdownMenuTrigger, Icon } from '@polygence/components';
import classNames from 'classnames';

import styles from 'src/components/DropdownMenu/ThreeDotMenuButton.module.scss';

export const ThreeDotMenuButton = ({
  alignment = 'horizontal',
  className = '',
  onClick,
}: {
  alignment?: 'horizontal' | 'vertical';
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <DropdownMenuTrigger>
      <Button
        variant="link"
        startIcon={<Icon id={`more-${alignment}`} />}
        className={classNames('p-3', className, styles['menuButton'])}
        onClick={onClick}
      />
    </DropdownMenuTrigger>
  );
};
