import { UserType } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';

import { useStudentJourneyQuery } from 'src/reducers/marketplaceReducer';

export const useStudentJourney = () => {
  const { userType, profileId, otherInfo } = useCurrentUser();

  return useStudentJourneyQuery(
    userType === UserType.PARENT ? (otherInfo?.['studentProfileId'] as number) : profileId,
    {
      skip:
        (userType !== UserType.STUDENT && !profileId) ||
        (userType === UserType.PARENT && !otherInfo?.['studentProfileId']),
    },
  );
};
