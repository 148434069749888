import { Button, Icon } from '@polygence/components';
import { useState } from 'react';
import type { ReactNode } from 'react';
import ReactModal from 'react-modal';

interface SideModalProps {
  trigger: ReactNode;
  header: ReactNode;
  main: ReactNode;
}

export const SideModal = ({ trigger, header, main }: SideModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    return setIsOpen(true);
  };
  const close = () => {
    return setIsOpen(false);
  };

  return (
    <>
      <div
        className="d-flex justify-content-start justify-content-lg-center"
        onClickCapture={() => {
          open();
        }}
      >
        {trigger}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={close}
        overlayClassName="side-modal-overlay"
        className="side-modal-content"
      >
        <div className="side-modal-header d-flex">{header}</div>
        <Button
          onClick={() => {
            close();
          }}
          variant="link"
          className="mt-5 me-7 side-modal-close-button"
          endIcon={<Icon id="arrow-right" />}
        >
          Go back
        </Button>
        <div className="side-modal-main">{main}</div>
      </ReactModal>
    </>
  );
};
