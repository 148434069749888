import type { Room } from '@polygence/common';
import { debounce } from 'lodash';
import { Fragment, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import Message from 'src/components/hermes/messages/Message';

const Messages = ({ room, getNextMessages }: { room: Room; getNextMessages: () => void }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const messageFeed = useRef<HTMLDivElement>(null);
  const { messages = [], unsentMessages = [] } = room;

  useEffect(() => {
    if (messageFeed?.current) {
      messageFeed.current.scrollTop = scrollTop;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const updateScroll = useMemo(() => {
    return debounce((event: UIEvent<HTMLDivElement>) => {
      setScrollTop((event.target as HTMLDivElement)?.scrollTop ?? 0);
    }, 500);
  }, []);

  const handleWaypointReached = () => {
    setScrollTop(messageFeed?.current?.scrollTop ?? 0);
    getNextMessages();
  };

  return (
    <div className="h-chat-messages">
      <div className="h-chat-messages-inner" ref={messageFeed} onScroll={updateScroll}>
        {unsentMessages.map((messageItem) => {
          return <Message key={messageItem.id} {...messageItem} roomId={room.id} />;
        })}
        {messages.map((messageItem, idx, array) => {
          return (
            <Fragment key={messageItem.id}>
              {idx === array.length - 4 && <Waypoint onEnter={handleWaypointReached} />}
              <Message {...messageItem} roomId={room.id} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Messages;
