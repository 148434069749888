import {
  hermesAiActions,
  hermesAiSelectors,
} from '@polygence/common/store/reducers/hermesAiReducer';
import { externalLink } from '@polygence/common/utils/externalLink';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MessageContext } from 'src/components/hermes-ai/messages/MessageContext';
import { useAppDispatch, useAppSelector } from 'src/store';

const isExternalLink = (url: string) => url.startsWith('http');

const getTypeFromCitation = (citationLink: string) => {
  const decodedCitationLink = decodeURIComponent(citationLink);
  const indexOfSeparator = decodedCitationLink.indexOf('|');
  if (indexOfSeparator === -1) {
    return { type: 'web', url: decodedCitationLink };
  }

  return {
    type: decodedCitationLink.substring(0, indexOfSeparator),
    url: decodedCitationLink.substring(indexOfSeparator + 1),
  };
};

export const CitationLink = ({ title, citationLink }: { title: string; citationLink: string }) => {
  const { messageId } = useContext(MessageContext);
  const dispatch = useAppDispatch();
  const citations = useAppSelector((state) =>
    hermesAiSelectors.citationsLinksForMessage(state, messageId),
  );
  const { type, url } = getTypeFromCitation(citationLink);

  useEffect(() => {
    if (!messageId || !url) {
      return;
    }

    dispatch(hermesAiActions.registerCitation({ messageId, title, url, type }));

    return () => {
      dispatch(hermesAiActions.removeCitation({ messageId, url }));
    };
  }, [dispatch, messageId, type, url, title]);

  if (!messageId || !url || !citations.length) {
    return null;
  }

  const indexOfCitation = citations.indexOf(url);

  if (indexOfCitation === -1) {
    return null;
  }

  return isExternalLink(url) ? (
    <a
      className="tw-text-xs tw-font-semibold tw-text-primary-600"
      title={title}
      href={url}
      {...externalLink}
    >
      [{indexOfCitation + 1}]
    </a>
  ) : (
    <Link className="tw-text-xs tw-font-semibold tw-text-primary-600" title={title} to={url}>
      [{indexOfCitation + 1}]
    </Link>
  );
};
