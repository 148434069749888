import { commonReducers, commonHooks, permissions } from '@polygence/common';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { externalLinkPlugin } from 'src/components/aux/markdown/externalLinkPlugin';

interface StudentFirstSurveyMessageProps {
  content: string;
}

const StudentFirstSurveyReminderMessage: React.FC<StudentFirstSurveyMessageProps> = ({
  content,
}) => {
  const userPermissions = commonHooks.usePermissions();
  const dispatch = useDispatch();

  const selectedProject = commonHooks.useSelectedProject();
  const firstSession = selectedProject?.sessions?.[0];
  const handleClick = () =>
    dispatch(
      commonReducers.hermesActions.openSessionFeedback({ sessionId: firstSession?.id as number }),
    );
  return (
    <div className="h-user-action-message fw-bold">
      <ReactMarkdown rehypePlugins={externalLinkPlugin}>{content}</ReactMarkdown>
      {permissions.canStudentFeedback(userPermissions) && (
        <Button className="mt-n2 mb-2" variant="tertiary" onClick={handleClick}>
          Complete Survey
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentFirstSurveyReminderMessage;
