import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import {
  useGetMessagesQuery,
  useShareAssistantMessageMutation,
} from '@polygence/common/api/hermes-ai';
import { useOtherParticipants } from '@polygence/common/hooks/selectors/useOtherParticipants';
import { Button, Icon, Modal, Text, Tooltip } from '@polygence/components';
import { toast } from 'react-toastify';

import { LimitedHeightExpandable } from 'src/components/common/LimitedHeightExpandable';
import { MarkdownBase } from 'src/components/hermes-ai/messages/MarkdownBase';

const ShareMessageModal = NiceModal.create(
  ({ messageId, threadId }: { messageId: number; threadId: number }) => {
    const modal = useModal();
    const otherParticipants = useOtherParticipants();
    const [triggerShare, { isLoading }] = useShareAssistantMessageMutation();

    const { message } = useGetMessagesQuery(threadId, {
      selectFromResult: ({ data }) => {
        if (!data || !data.messages) {
          return { message: null };
        }

        return { message: data.messages.find((message) => message.id === messageId) };
      },
    });

    const hideModal = () => {
      modal.hide().catch(console.error);
    };

    const shareMessage = () => {
      if (isLoading) {
        return;
      }

      triggerShare({ messageId, threadId })
        .unwrap()
        .then(() => {
          return modal.hide();
        })
        .catch(() => {
          toast.error('Something went wrong while sharing the message.');
        });
    };

    if (!message) {
      return null;
    }

    return (
      <Modal {...bootstrapDialog(modal)}>
        <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-4 tw-p-1">
          <Text size="large" alignment="center" fontWeight="bold" className="tw-w-full">
            Share message with{' '}
            {otherParticipants
              .map(({ firstName }) => firstName)
              .join(', ')
              .replace(/, ([^,]*)$/, ' and $1')}
          </Text>
          <div className="tw-z-10 -tw-mb-10 tw-ml-px tw-flex tw-h-full tw-shrink-0 -tw-translate-x-1/2 tw-flex-col tw-justify-between tw-self-start">
            <div className="tw-border-1 tw-flex tw-size-11 tw-items-center tw-justify-center tw-rounded-full tw-bg-white">
              <Icon id="sparkles" className="tw-text-primary-500" size="lg" />
            </div>
          </div>

          <LimitedHeightExpandable maxHeight={600}>
            <div className="tw-w-full tw-rounded-md tw-rounded-l-none tw-border-0 tw-border-l-8 tw-border-solid tw-border-primary-500 tw-bg-gray-100 tw-p-4 tw-font-light [&>ol]:tw-flex [&>ol]:tw-flex-col [&>ol]:tw-gap-3 [&>p:last-child]:tw-mb-0 [&_li>p]:tw-mb-0">
              <MarkdownBase>{message.content}</MarkdownBase>
            </div>
          </LimitedHeightExpandable>
        </div>
        <div className="mt-auto tw-flex tw-justify-end tw-gap-3 tw-px-3 tw-pt-4">
          <Button variant="link" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="primary" endIcon={<Icon id="send" />} onClick={shareMessage}>
            Send message
          </Button>
        </div>
      </Modal>
    );
  },
);

export const ShareMessage = ({ messageId, threadId }: { messageId: number; threadId: number }) => {
  const showModal = () => {
    NiceModal.show(ShareMessageModal, { messageId, threadId }).catch(console.error);
  };

  return (
    <Tooltip tip="Share message in the main workspace chat" placement="bottom">
      <button
        type="button"
        className="tw-group/share tw-inline-flex tw-appearance-none tw-items-center tw-justify-start tw-gap-1 tw-rounded-md tw-border-none tw-bg-gray-200 tw-px-2 tw-py-1 tw-outline-none"
        onClick={showModal}
      >
        <Icon
          id="corner-up-left"
          size="xs"
          className="tw-text-gray-400 group-hover/share:tw-text-gray-500"
          strokeWidth={3}
        />
        <Text
          size="small"
          as="span"
          alignment="left"
          fontWeight="semibold"
          className="tw-text-gray-400 group-hover/share:tw-text-gray-500"
        >
          Share
        </Text>
      </button>
    </Tooltip>
  );
};
