import { Text } from '@polygence/components';

export const PreviousSessionSummarySection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="mb-6 pt-5 prev-session-summary-section">
      <Text size="medium" alignment="left" fontWeight="bold" className="primary-highlight">
        {title}
      </Text>
      {children}
    </div>
  );
};
