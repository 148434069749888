import { DotLottie, DotLottieReact } from '@lottiefiles/dotlottie-react';
import { commonHooks, commonReducers } from '@polygence/common';
import { useEffect, useState } from 'react';

import confetti from 'src/components/static/lottie/big-confetti.lottie';
import { useAppDispatch } from 'src/store';

export const ConfettiAnimation = () => {
  const dispatch = useAppDispatch();
  const [dotLottie, setDotLottie] = useState<DotLottie | null>(null);
  const { animationRunning } = commonHooks.useTodoItems();

  useEffect(() => {
    if (dotLottie && animationRunning) {
      dotLottie.play();
      void dispatch(commonReducers.hermesActions.stopTodoAnimation());
    }
  }, [animationRunning, dotLottie, dispatch]);

  return (
    <div className="tw-pointer-events-none tw-absolute tw-z-50 tw-scale-150">
      <DotLottieReact src={confetti} dotLottieRefCallback={setDotLottie} />
    </div>
  );
};
