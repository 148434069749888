import { commonHooks, HermesMobileTab, hermesMobileTabs } from '@polygence/common';
import { Icon } from '@polygence/components';
import { Link } from 'react-router-dom';

const getIconForTab = (tab: HermesMobileTab) => {
  switch (tab) {
    case HermesMobileTab.RoomList:
      return 'list';
    case HermesMobileTab.Chat:
      return 'message-square';
    case HermesMobileTab.Sessions:
      return 'book-open';
    default:
      return 'help-circle';
  }
};

const HermesMobileNavigationTab = ({ tab }: { tab: HermesMobileTab }) => {
  const activeTab = commonHooks.useMobileNavigationTab();

  return (
    <Link to={`#${tab}`}>
      <Icon
        id={getIconForTab(tab)}
        color={tab === activeTab ? 'var(--primary)' : 'var(--grayscale-5)'}
      />
    </Link>
  );
};

export const HermesMobileNavigation = () => {
  return (
    <nav className="hermes-mobile-nav">
      {hermesMobileTabs.map((tab) => {
        return <HermesMobileNavigationTab key={tab} tab={tab} />;
      })}
    </nav>
  );
};
