import { StringUserType, UserType, UserState } from '@polygence/common';

import { getCounselorTileNavItems } from 'src/utils/tile-nav-items/counselor';
import { getMentorTileNavItems } from 'src/utils/tile-nav-items/mentor';
import { getParentTileNavItems } from 'src/utils/tile-nav-items/parent';
import { getStudentTileNavItems } from 'src/utils/tile-nav-items/student';

interface getTileNavItemsByUserTypeProps {
  userType: StringUserType;
  hermesEnabled: boolean;
  studentJourneyTileSections: string[] | undefined;
  pathfindersEnabled: boolean;
  isInterestedInPod: boolean;
  admissionAdvisorEmail: string | null | undefined;
  scholarshipApplicant: boolean;
  enrolledAt: string | undefined;
  scholarPageEnabled: boolean;
  scholarPageSlug: string | undefined;
  approved: boolean;
  currentUser: UserState;
  screened: boolean;
  opportunitiesEnabled: boolean;
  experience: boolean;
}

export const getTileNavItemsByUserType = ({
  userType,
  hermesEnabled,
  studentJourneyTileSections,
  pathfindersEnabled,
  isInterestedInPod,
  admissionAdvisorEmail,
  scholarshipApplicant,
  enrolledAt,
  scholarPageEnabled,
  scholarPageSlug,
  approved,
  currentUser,
  screened,
  opportunitiesEnabled,
  experience,
}: getTileNavItemsByUserTypeProps) => {
  if (userType === UserType.STUDENT) {
    return getStudentTileNavItems({
      hermesEnabled,
      studentJourneyTileSections,
      pathfindersEnabled,
      isInterestedInPod,
      admissionAdvisorEmail,
      scholarshipApplicant,
      enrolledAt,
      scholarPageEnabled,
      scholarPageSlug,
    });
  }

  if (userType === UserType.MENTOR) {
    return getMentorTileNavItems({
      hermesEnabled,
      experience,
    });
  }

  if (userType === UserType.COUNSELOR) {
    return getCounselorTileNavItems({
      approved,
      currentUser,
    });
  }

  if (userType === UserType.PARENT) {
    return getParentTileNavItems({
      currentUser,
      screened,
      studentJourneyTileSections,
      opportunitiesEnabled,
    });
  }

  return [];
};
