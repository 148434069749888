import { commonReducers, commonHooks } from '@polygence/common';
import { Button, Icon, Text } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { SessionListReminder } from 'src/components/hermes/sessions/SessionListReminder';

export const WritingFeedbackReminder = () => {
  const project = commonHooks.useSelectedProject();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(commonReducers.hermesActions.openFeedbackRequest());
  };

  const projectFeedbackRequestEnabled =
    project && project.sessionsCompleted >= 5 && project.reviewsRemaining > 0;

  return (
    <SessionListReminder>
      {project?.reviewsRemaining === 2 && (
        <>
          <Text size="small" fontWeight="bold">
            Now is a great time to take advantage of <i>writing feedback</i>. You have 2 rounds of
            writing feedback included in your program. Please submit your most recent draft for
            feedback and take your writing to the next level!
          </Text>
          <Button
            size="sm"
            disabled={!projectFeedbackRequestEnabled}
            onClick={handleClick}
            endIcon={<Icon id="edit-3" />}
            variant="primary"
          >
            Submit first draft for Writing Feedback
          </Button>
        </>
      )}
      {project?.reviewsRemaining === 1 && (
        <>
          <Text size="small" fontWeight="bold">
            Let's refine what you've been working on. You have 1 more round of{' '}
            <i>writing feedback</i> included in your program. Submit your final draft to take your
            writing to the next level!
          </Text>
          <div className="align-self-center">
            <Button
              size="sm"
              disabled={!projectFeedbackRequestEnabled}
              onClick={handleClick}
              endIcon={<Icon id="edit-3" />}
              variant="primary"
            >
              Submit final draft for Writing Feedback
            </Button>
          </div>
        </>
      )}
    </SessionListReminder>
  );
};
