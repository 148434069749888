export const NoAccessPage = () => {
  return (
    <div className="container">
      <h1 className="mt-5">
        This resource is accessible to enrolled students as well as their parents, mentors &
        counselors.
      </h1>
      <div className="text-small mt-5">
        If you should have access to the database and do not, please email{' '}
        <a href="mailto:students@polygence.org">students@polygence.org</a> with your name and your
        mentor's name.
      </div>
    </div>
  );
};
