import { commonReducers, commonThunks, commonHooks } from '@polygence/common';
import { externalLink } from '@polygence/common';
import {
  Button,
  Heading,
  Icon,
  Text,
  Spacer,
  RadioButton,
  Modal,
  ModalBody,
} from '@polygence/components';
import classnames from 'classnames';
import { camelCase } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import SendReferralEmailForm from 'src/components/ReferralPage/SendReferralEmailForm';
import { SpinningWheel } from 'src/components/SpinningWheel';
import { Textarea } from 'src/components/Textarea';
import { formatDatetimeAsLocal } from 'src/components/aux/dateStamp';
import { CheckboxWithValue } from 'src/components/common/CheckboxWithValue';
import { ConfirmationModal } from 'src/components/common/ConfirmationModal';
import { isStudent } from 'src/components/getMyInfo';
import { useFileUploadWithCallback } from 'src/components/hermes/FileUpload';
import { MILESTONE_ACTIONS } from 'src/components/hermes/constants';
import {
  useAssigmentBlueprintBySessionNumber,
  useSelectedRoomOtherParticipants,
  useAttachments,
  useOpenMilestoneModalFromQueryParam,
} from 'src/components/hermes/hooks';
import {
  isResearchQuestionAndAbstractAssignment,
  getFilenameFromPath,
  getAttachmentIdByName,
} from 'src/components/hermes/utils';
import assigmentSubmissionSvg from 'src/components/static/images/assignment_submission.svg';
import { urls } from 'src/urls';
import { countWords } from 'src/utils';
import { dayjs } from 'src/utils/dayjsCustom';
import { getTracker } from 'src/utils/tracking/factory';

/* eslint-disable react/prop-types */
export const Milestone = ({ assignmentAction, sessionNumber, sessionId, assignmentDueDate }) => {
  const dispatch = useDispatch();

  const assignmentBlueprint = useAssigmentBlueprintBySessionNumber(sessionNumber);
  const otherParticipants = useSelectedRoomOtherParticipants();
  const assignMentBlueprintOrder = assignmentBlueprint?.order;
  const tooltipId = `hermes-assignment-instruction-${assignMentBlueprintOrder}-${sessionNumber}`;

  const openModal = useCallback(() => {
    return dispatch(
      commonReducers.hermesActions.openAssignmentModal({
        sessionNumber,
        selectedAssignmentAction: assignmentAction,
      }),
    );
  }, [assignmentAction, dispatch, sessionNumber]);

  useOpenMilestoneModalFromQueryParam(sessionId, openModal);

  if (!assignmentBlueprint) {
    return null;
  }

  const handleMilestoneSubmitButtonClick = () => {
    openModal();
    getTracker().track('Milestone submit action clicked', {
      assignmentBlueprintId: assignmentBlueprint.id,
      assignmentBlueprintName: assignmentBlueprint.name,
    });
  };

  return (
    <div
      className={classnames(
        assignmentBlueprint.displayBeforeSession
          ? 'h-assignment-container-top'
          : 'h-assignment-container',
        'd-flex justify-content-between fs-0_7 align-items-center',
      )}
    >
      <div>
        {assignmentAction === MILESTONE_ACTIONS.viewSubmittedAssignment ? (
          <span className="text-bold">
            Milestone #{assignMentBlueprintOrder} submitted by{' '}
            <span className="h-blue">
              {otherParticipants
                .map((participant) => {
                  return participant.firstName;
                })
                .join(', ')}
            </span>{' '}
            is now available
          </span>
        ) : (
          <>
            {assignmentBlueprint && assignmentDueDate && (
              <span className="text-bold d-block">
                Project milestone #{assignMentBlueprintOrder} and assignment due:{' '}
                {formatDatetimeAsLocal(assignmentDueDate)}
              </span>
            )}
            <>
              <ReactTooltip id={tooltipId} type="dark" place="top" effect="solid">
                View milestone submission
              </ReactTooltip>
              <div
                className="clickable underline"
                data-for={tooltipId}
                data-tip="open assignment modal"
                data-testid="open-assignment-modal"
                aria-hidden="true"
                onClick={openModal}
              >
                {`${assignmentBlueprint.name || 'Milestone'}`}
              </div>
            </>
          </>
        )}
      </div>
      <div className="d-flex align-items-center">
        <Button
          className="ms-2"
          size="sm"
          variant={assignmentAction.sessionCardButtonVariant}
          startIcon={
            assignmentAction === MILESTONE_ACTIONS.submittedAssignment ? <Icon id="check" /> : <></>
          }
          onClick={handleMilestoneSubmitButtonClick}
        >
          {assignmentAction.sessionCardButtonLabel}
        </Button>
      </div>
    </div>
  );
};

const SpecialMilestone = ({ disabled, minWordCount }) => {
  const dispatch = useDispatch();
  const project = commonHooks.useSelectedProject();
  const {
    milestoneSpecials: {
      symposium = project.details?.symposium === 'true' || false,
      publication = project.details?.publication || null,
      conference = project.details?.conference || null,
      competition = project.details?.competition || null,
      other = project.details?.other || null,
      noshare = project.details?.noshare === 'true' || false,
      highlight = project.details?.highlight ? project.details.highlight === 'true' : true,
      showcaseError = false,
      titleError = false,
      descriptionError = false,
      descriptionWordCount = countWords(project.description),
      wrotePaper = project.details?.wrotePaper || null,
      wrotePaperError = false,
      kindOfPaper = project.details?.kindOfPaper || null,
      kindOfPaperError = false,
    },
  } = useSelector((state) => {
    return state.hermes.assignment;
  });

  useEffect(() => {
    const milestoneSpecials = {
      symposium,
      publication,
      conference,
      competition,
      other,
      noshare,
      highlight,
      showcaseError,
      titleError,
      descriptionError,
      descriptionWordCount,
      wrotePaper,
      wrotePaperError,
      kindOfPaper,
      kindOfPaperError,
    };
    Object.keys(milestoneSpecials).forEach((key) => {
      dispatch(
        commonReducers.hermesActions.updateMilestoneSpecials({ [key]: milestoneSpecials[key] }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (value) {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ showcaseError: false }));
    }
    return dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [name]: value }));
  };
  const handleCheckboxChange = ({ target: { name, checked } }) => {
    if (name !== 'highlight') {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ showcaseError: false }));
    }
    return dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [name]: checked }));
  };

  return (
    <>
      <ResearchQuestionAndAbstract
        instruction="Please take a moment to update the title and description/abstract of your project"
        wordCount={minWordCount}
        titleLabel="Title"
        descLabel="Description/Abstract"
        disabled={disabled}
        titleError={titleError}
        descriptionError={descriptionError}
        minWordCount={minWordCount}
      />
      <h5>How will you be showcasing your project?</h5>
      <Form.Group>
        <Form.Check
          disabled={disabled}
          type="checkbox"
          checked={symposium}
          onChange={handleCheckboxChange}
          label="Polygence Symposium of Rising Scholars"
          name="symposium"
          id="check-symposium"
        />
      </Form.Group>
      <CheckboxWithValue
        disabled={disabled}
        name="publication"
        value={publication}
        onChange={handleChange}
        label="Submission for publication"
      />
      <CheckboxWithValue
        disabled={disabled}
        name="conference"
        value={conference}
        onChange={handleChange}
        label="Conference"
      />
      <CheckboxWithValue
        disabled={disabled}
        name="competition"
        value={competition}
        onChange={handleChange}
        label="Competition"
      />
      <CheckboxWithValue
        disabled={disabled}
        value={other}
        onChange={handleChange}
        name="other"
        label="Other"
      />
      <Form.Group>
        <Form.Check
          disabled={disabled}
          type="checkbox"
          label="I am not sharing my project publicly"
          name="noshare"
          checked={noshare}
          onChange={handleCheckboxChange}
          id="check-no_share"
        />
      </Form.Group>
      {showcaseError && <div className="text-danger small mb-3">This field is required.</div>}
      <h6 className="mt-3">
        We make an effort to highlight remarkable, interesting, or unique projects in our website,
        program pamphlets, and other resources. We’d be sure to give you credit detailing your name,
        hometown, and high school graduation year. Please uncheck the box below if you’d like to be
        excluded from these opportunities.
      </h6>
      <Form.Group>
        <Form.Check
          disabled={disabled}
          type="checkbox"
          label="I'd like my project to be considered for project highlights"
          name="highlight"
          checked={highlight}
          onChange={handleCheckboxChange}
          id="check-highlight"
        />
      </Form.Group>
      <Form.Group>
        <label className="mt-3" htmlFor="wrotePaper">
          Did you write a paper?
        </label>
        <Form.Select
          disabled={disabled}
          name="wrotePaper"
          value={wrotePaper}
          onChange={handleChange}
          isInvalid={wrotePaperError}
          id="wrotePaper"
        >
          <option disabled selected value />
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Form.Select>
        {wrotePaperError && <div className="text-danger small mb-3">This field is required.</div>}
      </Form.Group>
      {wrotePaper === 'yes' && (
        <>
          <Form.Group>
            <label className="mt-3" htmlFor="wrotePaper">
              Did you review and report on existing research or complete new or original data
              collection?
            </label>
            <Form.Select
              disabled={disabled}
              name="kindOfPaper"
              value={kindOfPaper}
              isInvalid={kindOfPaperError}
              onChange={handleChange}
              id="kindOfPaper"
            >
              <option disabled selected value />
              <option value="existing_research">Existing Research</option>
              <option value="original_data_collection">Original Data Collection</option>
            </Form.Select>
            {kindOfPaperError && (
              <div className="text-danger small mb-3">This field is required.</div>
            )}
          </Form.Group>
        </>
      )}
    </>
  );
};

const SuperSpecialMilestone = ({ disabled }) => {
  const project = commonHooks.useSelectedProject();
  const dispatch = useDispatch();
  const [reasonForProject, setReasonForProject] = useState(project.reasonForProject);
  const [studentSatisfiedWithTimeline, setStudentSatisfiedWithTimeline] = useState(
    project.details?.studentSatisfiedWithTimeline != null
      ? project.details?.studentSatisfiedWithTimeline
      : null,
  );
  const [reasonForTimelineDissatisfaction, setReasonForTimelineDissatisfaction] = useState(
    project.reasonForTimelineDissatisfaction,
  );

  const currentDate = dayjs();
  const expectedProjectEndDate = dayjs(project.expectedEndAt);

  const handleChange = ({ target: { value } }) => {
    setReasonForProject(value);
    dispatch(
      commonReducers.hermesActions.updateMilestoneSuperSpecials({ reasonForProject: value }),
    );
  };

  const handleSatisfactionChange = ({ target: { value } }) => {
    setStudentSatisfiedWithTimeline(value === 'yes');
    dispatch(
      commonReducers.hermesActions.updateMilestoneSuperSpecials({
        studentSatisfiedWithTimeline: value === 'yes',
      }),
    );
  };

  const handleDissatisfactionReasonChange = ({ target: { value } }) => {
    setReasonForTimelineDissatisfaction(value);
    dispatch(
      commonReducers.hermesActions.updateMilestoneSuperSpecials({
        reasonForTimelineDissatisfaction: value,
      }),
    );
  };

  return (
    <>
      <Text size="medium">
        Students like you complete Polygence projects for an assortment of reasons. Some are hoping
        to showcase their final project to demonstrate their passion and capabilities to the
        colleges they will apply to. Others are looking to learn more about a subject that has a
        personal impact on their lives, or on the lives of someone they love. Still others are
        hoping to deep-dive on a personal passion as a break from the pressures of school.
      </Text>
      <Spacer size={3} />
      <Text>
        The more we know about your goals, the better we can tailor this experience to meet your
        needs. So…
      </Text>
      <Spacer size={3} />
      <label htmlFor="reason-for-project">Why are you doing a Polygence project?</label>
      <Spacer size={3} />
      <textarea
        value={reasonForProject}
        disabled={disabled}
        className="form-control"
        name="reason_for_project"
        id="reason-for-project"
        onChange={handleChange}
      />
      <Spacer size={3} />
      <label>
        Your forecasted project delivery date is currently set at{' '}
        {expectedProjectEndDate.format('LL')}. This gives you{' '}
        {expectedProjectEndDate.diff(currentDate, 'week')} weeks to complete your project. Does this
        timeline support the goal you described above?
      </label>
      <Spacer size={3} />
      <RadioButton
        disabled={disabled}
        name="student_satisfied_with_timeline"
        label="Yes"
        value="yes"
        onChange={handleSatisfactionChange}
        {...(studentSatisfiedWithTimeline === null
          ? {}
          : { checked: studentSatisfiedWithTimeline })}
      />
      <RadioButton
        disabled={disabled}
        name="student_satisfied_with_timeline"
        label="No"
        value="no"
        onChange={handleSatisfactionChange}
        {...(studentSatisfiedWithTimeline === null
          ? {}
          : { checked: !studentSatisfiedWithTimeline })}
      />
      {studentSatisfiedWithTimeline === false && (
        <>
          <label htmlFor="reason-for-timeline-dissatisfaction">
            What adjustment needs to be made to the timeline to help meet your goal?
          </label>
          <Spacer size={3} />
          <textarea
            value={reasonForTimelineDissatisfaction}
            className="form-control"
            name="reason_for_timeline_dissatisfaction"
            id="reason-for-timeline-dissatisfaction"
            onChange={handleDissatisfactionReasonChange}
          />
        </>
      )}
    </>
  );
};

const AssignmentModalNavigation = ({
  page,
  assignmentAction,
  assignmentBlueprint,
  minWordCount,
  maxCharCount,
  sessionNumber,
}) => {
  const { milestoneSpecials, milestoneSuperSpecials } = useSelector((state) => {
    return state.hermes.assignment;
  });

  const project = commonHooks.useSelectedProject();
  const dispatch = useDispatch();

  const session = project.sessions?.find((session) => session.sessionNumber === sessionNumber);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getPrimaryButtonProps = () => {
    if (
      page === 'submission' &&
      isResearchQuestionAndAbstractAssignment(assignmentBlueprint) &&
      isStudent()
    ) {
      return {
        label: 'Confirm & Submit',
        handleClick: () => {
          dispatch(
            commonThunks.hermesThunks.uploadAssignment({
              projectId: project.id,
              title: milestoneSpecials?.title,
              description: milestoneSpecials?.description,
              researchQuestion: milestoneSpecials?.title,
              researchDescription: milestoneSpecials?.description,
              isMilestone: true,
              sessionId: session?.id,
            }),
          );
        },
      };
    }
    if (page === 'special') {
      return {
        label: 'Continue',
        handleClick: () => {
          const requiredShowCasingFields = [
            'symposium',
            'publication',
            'conference',
            'competition',
            'other',
            'noshare',
          ];
          const valid = requiredShowCasingFields.some((field) => {
            return (
              milestoneSpecials[field] != null &&
              milestoneSpecials[field] !== '' &&
              milestoneSpecials[field] !== false
            );
          });

          if (!valid) {
            dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ showcaseError: true }));
            return;
          }
          if (milestoneSpecials.descriptionWordCount < minWordCount) {
            dispatch(
              commonReducers.hermesActions.updateMilestoneSpecials({ descriptionError: true }),
            );
            return;
          }
          if (!milestoneSpecials.wrotePaper) {
            dispatch(
              commonReducers.hermesActions.updateMilestoneSpecials({ wrotePaperError: true }),
            );
            return;
          }
          if (milestoneSpecials.wrotePaper === 'yes' && !milestoneSpecials.kindOfPaper) {
            dispatch(
              commonReducers.hermesActions.updateMilestoneSpecials({ kindOfPaperError: true }),
            );
            return;
          }
          if (milestoneSpecials.titleError || milestoneSpecials.descriptionError) {
            return;
          }
          const milestoneSpecialsData = Object.fromEntries(
            Object.entries(milestoneSpecials)
              .filter(([, value]) => {
                if (value != null) {
                  return true;
                }
                return false;
              })
              .map(([key, value]) => {
                return [key, String(value)];
              }),
          );
          dispatch(
            commonThunks.hermesThunks.uploadAssignment({
              projectId: project.id,
              nextPage: 'submission',
              isMilestone: true,
              sessionId: session?.id,
              ...milestoneSpecialsData,
            }),
          );
          dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ showcaseError: false }));
        },
      };
    }
    if (page === 'superSpecial') {
      return {
        label: 'Submit',
        handleClick: () => {
          dispatch(
            commonThunks.hermesThunks.uploadAssignment({
              projectId: project.id,
              nextPage: 'success',
              isMilestone: true,
              sessionId: session?.id,
              milestoneZeroCompletedAt: dayjs().toISOString(),
              ...milestoneSuperSpecials,
            }),
          );
        },
        disabled:
          !(
            Object.prototype.hasOwnProperty.call(milestoneSuperSpecials, 'reasonForProject') &&
            milestoneSuperSpecials.reasonForProject !== ''
          ) ||
          !Object.prototype.hasOwnProperty.call(
            milestoneSuperSpecials,
            'studentSatisfiedWithTimeline',
          ),
      };
    }
    if (page === 'success') {
      return {
        label: 'Return to messages',
        handleClick: () => {
          return dispatch(commonReducers.hermesActions.closeAssignmentModal());
        },
      };
    }
    if (page === 'abstractMilestoneSpecial' && isStudent()) {
      return {
        label: 'Confirm & Submit',
        handleClick: () => {
          if (milestoneSpecials.shortDescriptionWordCount > maxCharCount) {
            dispatch(
              commonReducers.hermesActions.updateMilestoneSpecials({ shortDescriptionError: true }),
            );
            return;
          }
          if (milestoneSpecials.descriptionWordCount < minWordCount) {
            dispatch(
              commonReducers.hermesActions.updateMilestoneSpecials({ descriptionError: true }),
            );
            return;
          }
          if (milestoneSpecials.shortDescriptionError || milestoneSpecials.descriptionError) {
            return;
          }
          dispatch(
            commonThunks.hermesThunks.uploadAssignment({
              projectId: project.id,
              description: milestoneSpecials?.description,
              shortDescription: milestoneSpecials?.shortDescription,
              isMilestone: true,
              sessionId: session?.id,
            }),
          );
        },
      };
    }
    return {};
  };

  const primaryButtonProps = {
    ...getPrimaryButtonProps(),
  };

  const isGoBackButton = page === 'submission' && assignmentBlueprint.name === 'Final project';
  const buttonLabel = isGoBackButton ? 'Go Back' : 'Cancel';

  const secondaryButtonProps = {
    label: page !== 'success' ? buttonLabel : null,
    handleClick: isGoBackButton
      ? () => {
          return dispatch(commonReducers.hermesActions.setAssignmentModalToSpecialPage());
        }
      : () => {
          return dispatch(commonReducers.hermesActions.closeAssignmentModal());
        },
  };

  return (
    // eslint-disable-next-line react/forbid-dom-props
    <div className="d-flex flex-wrap justify-content-center justify-content-sm-end mt-auto gap-5">
      {secondaryButtonProps.label && (
        <Button
          variant="secondary"
          className={secondaryButtonProps.className}
          onClick={secondaryButtonProps.handleClick}
        >
          <span>{secondaryButtonProps.label}</span>
        </Button>
      )}
      {primaryButtonProps.label && (
        <Button
          variant="primary"
          className={primaryButtonProps.className}
          onClick={primaryButtonProps.handleClick}
          disabled={primaryButtonProps.disabled}
        >
          <span>{primaryButtonProps.label}</span>
        </Button>
      )}
    </div>
  );
};

export const MilestoneModal = () => {
  const dispatch = useDispatch();
  const { open, page, selectedSession, selectedAssignmentAction } = useSelector((state) => {
    return state.hermes.assignment;
  });
  const assignmentBlueprint = useAssigmentBlueprintBySessionNumber(selectedSession);

  const isMilestone = assignmentBlueprint?.id !== undefined;
  const minWordCount = 100;
  const maxCharCount = 150;

  useEffect(() => {
    if (assignmentBlueprint) {
      if (assignmentBlueprint.dueSessionNumber === 1 && assignmentBlueprint.displayBeforeSession) {
        dispatch(commonReducers.hermesActions.setAssignmentModalToSuperSpecialPage());
      } else if (assignmentBlueprint.dueSessionNumber === 9) {
        dispatch(commonReducers.hermesActions.setAssignmentModalToAbstractMilestoneSpecialPage());
      } else if (assignmentBlueprint.dueSessionNumber === 10) {
        dispatch(commonReducers.hermesActions.setAssignmentModalToSpecialPage());
      }
    }
  }, [dispatch, assignmentBlueprint]);

  if (!assignmentBlueprint) {
    return null;
  }

  return (
    <Modal
      key={`assignment-modal-${selectedSession}`}
      show={open}
      onHide={() => {
        return dispatch(commonReducers.hermesActions.closeAssignmentModal());
      }}
      closeButton
    >
      <ModalBody>
        <div className="d-flex flex-column flex-grow-1">
          {isMilestone && <h1 className="fs-1_3 text-center mb-7">Project Milestone Submission</h1>}
          {isMilestone && (
            <>
              <div className="d-flex flex-row justify-content-start mb-7">
                <div className="d-flex flex-column me-10 fs-0_9">
                  <span className="h-grey-1">Project milestone:</span>
                  <span>{assignmentBlueprint?.name}</span>
                </div>
                <div className="d-flex flex-column fs-0_9">
                  <span className="h-grey-1">Due date:</span>
                  <span>
                    {assignmentBlueprint.displayBeforeSession ? 'before' : 'after'} Session{' '}
                    {assignmentBlueprint.dueSessionNumber}
                  </span>
                </div>
              </div>
              {assignmentBlueprint.id === 3 && (
                <div className="mb-7">
                  Has your description of your project changed from Session 3? If so, make sure to
                  update it{' '}
                  <a href="/student/my-scholar-page" {...externalLink}>
                    here
                  </a>{' '}
                  so it can be displayed properly on your Scholar Page!
                </div>
              )}
            </>
          )}
          {page === 'special' && (
            <SpecialMilestone disabled={!isStudent()} minWordCount={minWordCount} />
          )}
          {page === 'superSpecial' && <SuperSpecialMilestone disabled={!isStudent()} />}
          {page === 'abstractMilestoneSpecial' && (
            <>
              <div className="mb-4">
                <AssignmentInstruction assignmentBlueprint={assignmentBlueprint} />
              </div>
              <ProjectAbstractMilestone
                disabled={!isStudent()}
                wordCount={minWordCount}
                charCount={maxCharCount}
              />
            </>
          )}
          {page === 'submission' && (
            <div className="d-flex flex-column flex-grow-1">
              <div className="h-assignment-submission-image d-flex justify-content-between">
                <AssignmentInstruction assignmentBlueprint={assignmentBlueprint} />
                <img
                  height="80px"
                  alt="assignment submission ornament"
                  src={assigmentSubmissionSvg}
                />
              </div>
              <div className="flex-grow-1">
                {isResearchQuestionAndAbstractAssignment(assignmentBlueprint) ? (
                  <ResearchQuestionAndAbstract
                    disabled={!isStudent()}
                    wordCount={minWordCount}
                    instruction={
                      selectedAssignmentAction?.researchQuestionAndAbstractUploadInstruction
                    }
                  />
                ) : (
                  <AssignmentUpload assignmentBlueprint={assignmentBlueprint} />
                )}
              </div>
            </div>
          )}
          {page === 'success' && <AssignmentUploadSuccess sessionNumber={selectedSession} />}
          <Spacer size={7} />
          <AssignmentModalNavigation
            page={page}
            assignmentAction={selectedAssignmentAction}
            assignmentBlueprint={assignmentBlueprint}
            minWordCount={minWordCount}
            sessionNumber={selectedSession}
            maxCharCount={maxCharCount}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

const ResearchQuestionAndAbstract = ({
  instruction,
  wordCount = 0,
  titleLabel = 'Research question:',
  descLabel = 'Project description:',
  disabled = false,
  titleError,
  descriptionError,
}) => {
  const dispatch = useDispatch();
  const project = commonHooks.useSelectedProject();
  const { title = '', description = '' } = useSelector((state) => {
    return state.hermes.assignment.milestoneSpecials;
  });

  const setTitle = (value) => {
    return dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ title: value }));
  };
  const setDescription = (value) => {
    dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ description: value }));
  };

  useEffect(() => {
    setTitle(project.details?.title || project.details?.researchQuestion || '');
    setDescription(project.details?.description || project.details?.researchDescription || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      commonReducers.hermesActions.updateMilestoneSpecials({
        descriptionWordCount: countWords(description),
      }),
    );
  }, [description, dispatch]);

  const handleBlur = ({ target: { name, value } }) => {
    if (!value.trim()) {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [`${name}Error`]: true }));
    } else {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [`${name}Error`]: false }));
    }
  };

  return (
    <div className="research-question-and-abstract d-flex flex-column h-100">
      <div className="mb-5">
        <span className="fs-0_9">{instruction}</span>
      </div>

      <div className="d-flex flex-column">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="assignment-research-question">{titleLabel}</label>
        <textarea
          id="assignment-research-question"
          name="title"
          className="mb-2 form-control"
          value={title}
          disabled={disabled}
          onChange={(event) => {
            if (event.target.value.trim()) {
              dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ titleError: false }));
            }
            return setTitle(event.target.value);
          }}
          onBlur={handleBlur}
        />
        {titleError && <div className="text-danger small mb-3">This field is required.</div>}
      </div>

      <div className="d-flex flex-column mb-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="assignment-research-abstract">{descLabel}</label>
        <Textarea
          id="assignment-research-abstract"
          name="description"
          value={description}
          rows={20}
          disabled={disabled}
          onChange={(event) => {
            if (event.target.value.trim()) {
              dispatch(
                commonReducers.hermesActions.updateMilestoneSpecials({ descriptionError: false }),
              );
            }
            return setDescription(event.target.value);
          }}
          onBlur={handleBlur}
          lengthCheck={{
            on: 'words',
            required: true,
            minimum: wordCount,
            maximum: 1000,
          }}
        />
        {descriptionError && <div className="text-danger small mb-3">This field is required.</div>}
      </div>
    </div>
  );
};

const ProjectAbstractMilestone = ({
  instruction,
  wordCount = 0,
  charCount = 0,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const project = commonHooks.useSelectedProject();
  const {
    description = '',
    shortDescription = '',
    shortDescriptionError = false,
    descriptionError = false,
  } = useSelector((state) => {
    return state.hermes.assignment.milestoneSpecials;
  });

  const setDescription = (value) => {
    dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ description: value }));
  };

  const setShortAbstract = (value) => {
    return dispatch(
      commonReducers.hermesActions.updateMilestoneSpecials({ shortDescription: value }),
    );
  };

  useEffect(() => {
    setDescription(project.details?.description || '');
    setShortAbstract(project.details?.shortDescription || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      commonReducers.hermesActions.updateMilestoneSpecials({
        descriptionWordCount: countWords(description),
        shortDescriptionWordCount: shortDescription.length,
      }),
    );
  }, [description, shortDescription, dispatch]);

  // eslint-disable-next-line sonarjs/no-identical-functions
  const handleBlur = ({ target: { name, value } }) => {
    if (!value.trim()) {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [`${name}Error`]: true }));
    } else {
      dispatch(commonReducers.hermesActions.updateMilestoneSpecials({ [`${name}Error`]: false }));
    }
  };

  return (
    <div className="research-question-and-abstract d-flex flex-column h-100">
      <div className="mb-5">
        <span className="fs-0_9">
          Please provide the abstract and it's summarized version of your project! You can update
          them at any time. Note: If your final outcome is not a research paper, this should be an
          updated description of your project.
        </span>
      </div>
      <div className="d-flex flex-column mb-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="assignment-research-abstract">Abstract</label>
        <Textarea
          id="assignment-research-abstract"
          name="description"
          value={description}
          rows={20}
          disabled={disabled}
          // eslint-disable-next-line sonarjs/no-identical-functions
          onChange={(event) => {
            if (event.target.value.trim()) {
              dispatch(
                commonReducers.hermesActions.updateMilestoneSpecials({ descriptionError: false }),
              );
            }
            return setDescription(event.target.value);
          }}
          onBlur={handleBlur}
          lengthCheck={{
            on: 'words',
            required: true,
            minimum: wordCount,
            maximum: 1000,
          }}
        />
        {descriptionError && <div className="text-danger small mb-3">This field is required.</div>}
      </div>
      <div className="d-flex flex-column mb-3">
        <label htmlFor="assignment-research-abstract">{`${charCount} character summary`}</label>
        <Textarea
          id="assignment-research-abstract"
          name="shortDescription"
          value={shortDescription}
          rows={3}
          disabled={disabled}
          onChange={(event) => {
            if (event.target.value.trim()) {
              dispatch(
                commonReducers.hermesActions.updateMilestoneSpecials({
                  shortDescriptionError: false,
                }),
              );
            }
            return setShortAbstract(event.target.value);
          }}
          onBlur={handleBlur}
          lengthCheck={{
            on: 'characters',
            required: true,
            minimum: 50,
            maximum: charCount,
          }}
        />
        {shortDescriptionError && (
          <div className="text-danger small mb-3">
            {`Abstract summary can't go over ${charCount} characters.`}
          </div>
        )}
      </div>
    </div>
  );
};

const AssignmentInstruction = ({ assignmentBlueprint }) => {
  const isMilestone = assignmentBlueprint?.id !== undefined;

  return (
    isMilestone &&
    assignmentBlueprint.instruction && (
      <div className="mt-7">
        <Button
          size="sm"
          variant="primary"
          startIcon={<Icon id="external-link" />}
          href={urls.notionPage(assignmentBlueprint?.instruction)}
          {...externalLink}
        >
          Instruction link
        </Button>
      </div>
    )
  );
};

const AssignmentFileUploaded = ({ assignmentBlueprint }) => {
  let uploadedAssignmentName;
  const dispatch = useDispatch();
  const workspaceId = commonHooks.useSelectedWorkspaceId();
  const project = commonHooks.useSelectedProject();
  const attachments = useAttachments();
  const [, removeFile] = useFileUploadWithCallback(
    urls.uploadHermesAttachment(workspaceId),
    urls.removeHermesAttachment(),
  );

  if (assignmentBlueprint.assignmentNames && Array.isArray(assignmentBlueprint.assignmentNames)) {
    // eslint-disable-next-line fp/no-mutation
    uploadedAssignmentName = project.details[camelCase(assignmentBlueprint.assignmentNames[0])];
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="mb-2">
        <span className="fs-0_9">The uploaded assignment:</span>
      </div>
      <div className="d-flex flex-row">
        <a className="fs-0_9 underline" href={uploadedAssignmentName} {...externalLink}>
          {getFilenameFromPath(uploadedAssignmentName)}
        </a>
        {isStudent() && (
          <ConfirmationModal
            trigger={
              <Icon
                id="delete"
                size="md"
                color="var(--grayscale-6)"
                className="ms-3"
                onClick={() => {
                  return removeFile({
                    idToRemove: getAttachmentIdByName(attachments, uploadedAssignmentName),
                    callback: () => {
                      return dispatch(
                        commonThunks.hermesThunks.uploadAssignment({
                          projectId: project.id,
                          [assignmentBlueprint.assignmentNames[0]]: '',
                        }),
                      );
                    },
                  });
                }}
              />
            }
            skipConfirmation={false}
            confirmButtonText="Yes, delete"
          >
            <span>Are you sure you want to delete this milestone?</span>
          </ConfirmationModal>
        )}
      </div>
      <div className="mt-5">
        <Button
          variant="primary"
          href={uploadedAssignmentName}
          download={getFilenameFromPath(uploadedAssignmentName)}
          {...externalLink}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

const AssignmentWebsiteUploaded = ({ assignmentBlueprint, website }) => {
  const dispatch = useDispatch();
  const project = commonHooks.useSelectedProject();
  const assignmentWebsiteKey = camelCase(assignmentBlueprint?.assignmentNames?.[1]);
  return (
    <div className="d-flex flex-column h-100">
      <div className="mb-2">
        <span className="fs-0_9">The uploaded assignment:</span>
      </div>
      <div className="d-flex flex-row">
        <a className="fs-0_9 underline" href={website} {...externalLink}>
          {website}
        </a>
        {isStudent() && (
          <ConfirmationModal
            trigger={
              <Icon
                id="delete"
                size="md"
                color="var(--grayscale-6)"
                className="clickable ms-3"
                onClick={() => {
                  return dispatch(
                    commonThunks.hermesThunks.uploadAssignment({
                      projectId: project.id,
                      [assignmentWebsiteKey]: '',
                    }),
                  );
                }}
              />
            }
            skipConfirmation={false}
            confirmButtonText="Yes, delete"
          >
            <span>Are you sure you want to delete this milestone?</span>
          </ConfirmationModal>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const AssignmentUpload = ({ assignmentBlueprint }) => {
  let uploadedAssignmentName;
  const assignmentWebsiteKey = camelCase(assignmentBlueprint?.assignmentNames?.[1]);
  const dispatch = useDispatch();
  const workspaceId = commonHooks.useSelectedWorkspaceId();
  const project = commonHooks.useSelectedProject();
  const [website, setWebsite] = useState(project.details[assignmentWebsiteKey] || '');
  const { error } = useSelector((state) => {
    return state.hermes.assignment;
  });
  const [fileUpload, , uploading] = useFileUploadWithCallback(
    urls.uploadHermesAttachment(workspaceId),
    urls.removeHermesAttachment(),
  );

  useEffect(() => {
    dispatch(commonThunks.attachmentThunks.getAttachmentsForWorkspace({ workspaceId }));
  }, [workspaceId, dispatch]);

  if (assignmentBlueprint.assignmentNames && Array.isArray(assignmentBlueprint.assignmentNames)) {
    // eslint-disable-next-line fp/no-mutation
    uploadedAssignmentName = project.details[camelCase(assignmentBlueprint.assignmentNames[0])];
  }

  if (uploadedAssignmentName) {
    return <AssignmentFileUploaded assignmentBlueprint={assignmentBlueprint} />;
  }

  if (project.details[assignmentWebsiteKey]) {
    return (
      <AssignmentWebsiteUploaded
        website={project.details[assignmentWebsiteKey]}
        assignmentBlueprint={assignmentBlueprint}
      />
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="mb-5">
        <span className="fs-0_9">Please upload a file of your milestone below.</span>
      </div>
      {uploading ? (
        <div className="h-assignment-file-submission">
          <div className="h-file-submission-area d-flex justify-content-center align-items-center">
            <SpinningWheel width="24px" />
            <span className="fs-0_8 h-blue text-center mx-3">Uploading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles) => {
              return fileUpload({
                target: { files: acceptedFiles },
                callback: ({ content }) => {
                  return dispatch(
                    commonThunks.hermesThunks.uploadAssignment({
                      projectId: project.id,
                      [assignmentBlueprint.assignmentNames[0]]: content,
                    }),
                  );
                },
                errorHandler: () => {
                  return dispatch(commonReducers.hermesActions.assignmentUploadFailure());
                },
              });
            }}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div className="h-assignment-file-submission clickable">
                  <div
                    className="h-file-submission-area d-flex justify-content-center align-items-center"
                    {...getRootProps()}
                  >
                    <span className="fs-0_8 h-blue text-center mx-3">
                      Click here to find your local file or drag and drop your file here.
                    </span>
                    <input multiple={false} {...getInputProps()} />
                  </div>
                </div>
              );
            }}
          </Dropzone>
          {assignmentWebsiteKey && (
            <Form.Group className="mb-5 mt-5 fs-0_9" controlId="project_final_url">
              <Form.Label>Or submit a website URL</Form.Label>
              <Form.Control
                type="text"
                value={website}
                inline
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
              />
              <Button
                variant="primary"
                className="mt-3"
                onClick={() => {
                  dispatch(
                    commonThunks.hermesThunks.uploadAssignment({
                      projectId: project.id,
                      [assignmentWebsiteKey]: website,
                    }),
                  );
                }}
              >
                Submit
              </Button>
            </Form.Group>
          )}
        </div>
      )}
      {error && (
        <div className="h-assignment-upload-failure d-flex justify-content-end mt-7 text-danger">
          <Icon
            id="alert-triangle"
            size="md"
            style={{
              marginRight: '9px',
              marginBottom: '2px',
            }}
          />
          <span className="fs-0_8 text-bold">Upload failed. Please try again.</span>
        </div>
      )}
    </div>
  );
};

const AssignmentUploadSuccess = ({ sessionNumber }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-end h-assignment-submission-image">
        <img height="80px" alt="assignment submission ornament" src={assigmentSubmissionSvg} />
      </div>
      <Spacer size={6} />
      <Heading as="h2" size="h4">
        You did it! Congrats on submitting your milestone!
      </Heading>
      <Spacer size={8} />
      {sessionNumber > 1 && <MilestoneReferral />}
    </div>
  );
};

const MilestoneReferral = () => {
  return (
    <>
      <Text alignment="center" size="large" fontWeight="bold">
        Know a friend who can also benefit from Polygence?
      </Text>
      <Spacer size={6} />
      <Text alignment="center">
        Spread the joy of passion projects and mentorship by nominating your friends and classmates!
        We are looking for other students just like you to join our academic community. Nominated
        applications will be prioritized for admission.
      </Text>
      <Spacer size={8} />
      <SendReferralEmailForm />
    </>
  );
};
