import { featureDecisions } from '@polygence/common/feature-toggles/featureDecisions';
import { Heading, Text, ToggleSwitch } from '@polygence/components';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useUpdateMentorProfileMutation } from 'src/reducers/marketplaceReducer';

export interface AiFeatureSettingsProps {
  isSessionSummaryGenerationEnabled: boolean;
}

export const AiFeatureSettings = ({
  isSessionSummaryGenerationEnabled: isSessionSummaryGenerationEnabledDefault,
}: AiFeatureSettingsProps) => {
  const [updateMentorProfile] = useUpdateMentorProfileMutation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isSessionSummaryGenerationEnabled, setIsSessionSummaryGenerationEnabled] = useState(
    isSessionSummaryGenerationEnabledDefault,
  );

  useEffect(() => {
    featureDecisions
      .getInstance()
      .then((instance) => setVisible(instance.aiSessionSummaryEnabled()))
      .catch(console.error);
  }, []);

  const handleSummaryGenerationChange = useCallback(
    (checked: boolean) => {
      setLoading(true);
      updateMentorProfile({ aiSessionSummaryOptOut: !checked })
        .unwrap()
        .then(() => {
          const message = checked
            ? 'Enabled AI session summary generation.'
            : 'Disabled AI session summary generation.';

          setIsSessionSummaryGenerationEnabled(checked);
          toast.success(message);
        })
        .catch(() => toast.error('Something went wrong please try again.'))
        .finally(() => setLoading(false));
    },
    [updateMentorProfile],
  );

  if (!visible) {
    return null;
  }

  return (
    <div className="tw-mt-8 tw-flex tw-flex-col">
      <Heading as="h2" size="h3" alignment="left" className="tw-mb-2">
        AI Features
      </Heading>
      <Heading as="h3" size="h5" alignment="left">
        Session Summary Generation
      </Heading>
      <Text size="medium" className="mt-2 tw-text-gray-400">
        By default, AI will automatically generate session summaries based on your session
        transcripts, saving you time and effort. You can still manually review and edit the
        generated summaries if you request it in the session summary form.
      </Text>
      <div className="tw-mt-4 tw-flex tw-items-center tw-gap-2">
        <ToggleSwitch
          aria-label="AI session summary generation enabled"
          checked={isSessionSummaryGenerationEnabled}
          disabled={loading}
          onCheckedChange={handleSummaryGenerationChange}
        />
        <Text size="medium" className="tw-text-black">
          Enable this feature
        </Text>
      </div>
    </div>
  );
};
