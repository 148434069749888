import { commonHooks, permissions } from '@polygence/common';
import { Badge } from '@polygence/components';

import { Loader } from 'src/components/Loader';
import { Milestone } from 'src/components/hermes/Milestone';
import {
  useHalfSessionRemaining,
  useMilestoneAssignmentActionForSession,
} from 'src/components/hermes/hooks';
import FinalStudentSurveyReminder from 'src/components/hermes/sessions/FinalStudentSurveyReminder';
import { PremiumShowcasingReminder } from 'src/components/hermes/sessions/PremiumShowcasingReminder';
import StudentFeedbackReminder from 'src/components/hermes/sessions/StudentFeedbackReminder';
import { WritingFeedbackReminder } from 'src/components/hermes/sessions/WritingFeedbackReminder';
import { DisabledProposeState } from 'src/components/hermes/sessions/actions/DisabledProposeState';
import { DisabledScheduleState } from 'src/components/hermes/sessions/actions/DisabledScheduleState';
import { NoActionState } from 'src/components/hermes/sessions/actions/NoActionState';

const NotAvailableSession = ({
  sessionNumber,
  isLast,
  reminderProps,
  feedbackReminderProps,
}: {
  sessionNumber: number;
  isLast: boolean;
  reminderProps: React.ComponentProps<typeof FinalStudentSurveyReminder>;
  feedbackReminderProps: React.ComponentProps<typeof StudentFeedbackReminder>;
}) => {
  const { isBeforePenultSession } = commonHooks.useSession({ sessionNumber });
  const user = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();
  const assignmentAction = useMilestoneAssignmentActionForSession({
    sessionNumber,
  });
  const userPermissions = commonHooks.usePermissions();
  const writingFeedbackEnabled = commonHooks.useWritingFeedbackEnabled(userPermissions);
  const halfSessionRemaining = useHalfSessionRemaining();
  const premiumShowcasingSupportPurchased = Boolean(project?.premiumShowcasingSupportPurchasedAt);

  if (!project) {
    return <Loader />;
  }
  const isFlagship = project.type === 'flagship';

  return (
    <>
      {sessionNumber === 2 && <StudentFeedbackReminder {...feedbackReminderProps} />}
      {user.utilities.isStudent &&
        isFlagship &&
        premiumShowcasingSupportPurchased &&
        isBeforePenultSession() && <PremiumShowcasingReminder userType={user.userType} />}

      {writingFeedbackEnabled &&
        ((sessionNumber === 7 && project.reviewsRemaining === 2) ||
          (sessionNumber === 10 && project.reviewsRemaining === 1)) && <WritingFeedbackReminder />}

      {isLast &&
        project.finalStudentSurveyEnabled &&
        project.finalStudentSurveyRequiredForZoomJoin && (
          <FinalStudentSurveyReminder {...reminderProps} />
        )}

      <article className="d-block">
        <section className="d-flex">
          <NotAvailableSessionState isStudent={user.utilities.isStudent} />
          <div className="h-details">
            <h4>
              <span>Session {sessionNumber}</span>
              {halfSessionRemaining && isLast && (
                <Badge className="m-3" variant="secondary" pill>
                  Half-Session
                </Badge>
              )}
            </h4>
            <p className="text-muted text-small">
              All prior sessions need to be scheduled to activate this session.
            </p>
          </div>
        </section>
        <section>
          {assignmentAction && (
            // @ts-expect-error missing types for Milestone
            <Milestone assignmentAction={assignmentAction} sessionNumber={sessionNumber} />
          )}
        </section>
      </article>
    </>
  );
};

const NotAvailableSessionState = ({ isStudent }: { isStudent: boolean }) => {
  const userPermissions = commonHooks.usePermissions();
  if (isStudent && permissions.canPropose(userPermissions)) {
    return <DisabledProposeState />;
  }
  return permissions.canSchedule(userPermissions) ||
    permissions.canScheduleLimited(userPermissions) ? (
    <DisabledScheduleState />
  ) : (
    <NoActionState />
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default NotAvailableSession;
