import {
  Nullable,
  ProductId,
  ProjectId,
  commonReducers,
  useCommonSelector,
} from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import {
  PREMIUM_SHOWCASING_MULTIMEDIA,
  PREMIUM_SHOWCASING_PRESENTING,
  PREMIUM_SHOWCASING_PUBLISHING,
} from '@polygence/common/constants/productNames';
import { Button, Checkbox, Heading, Modal, ModalBody, Skeleton, Text } from '@polygence/components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { RedirectNotification } from 'src/components/RedirectNotification';
import { useProductsQuery } from 'src/reducers/productReducer';

export const PurchaseShowcasingAddonModal = () => {
  const SUPPORTED_PSS_VARIANTS = [
    PREMIUM_SHOWCASING_PUBLISHING,
    PREMIUM_SHOWCASING_MULTIMEDIA,
    PREMIUM_SHOWCASING_PRESENTING,
  ];

  const { open, projectId }: { open: boolean; projectId: Nullable<ProjectId> } = useCommonSelector(
    (state) => {
      return state.showcasingAddon.showcasingPurchaseModal;
    },
  );
  const {
    data: products,
    isLoading: isProductsLoading,
    isSuccess: isProductsSuccess,
  } = useProductsQuery({ group: 'pss' });
  const [selectedVariants, setSelectedVariants] = useState<ProductId[]>([]);
  const [loading, setLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState<string | null>(null);

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(commonReducers.showcasingAddonActions.closePurchaseModal());
  };

  const handlePurchasePremiumShowcasingSupport = () => {
    if (!projectId) {
      return;
    }
    setLoading(true);
    hermesApi
      .postPurchaseShowcasingSupportAddon(projectId, selectedVariants)
      .then(({ data }) => {
        toast.success('Redirecting to payment page...');
        if ('invoiceUrl' in data) {
          setInvoiceUrl(data.invoiceUrl);
        }
      })
      .catch(() => {
        toast.error('Could not process this request.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVariantToggle = (variantId: ProductId) => {
    setSelectedVariants((prev) =>
      prev.includes(variantId) ? prev.filter((id) => id !== variantId) : [...prev, variantId],
    );
  };

  return (
    <Modal show={open} onHide={closeModal} closeButton>
      <ModalBody>
        {invoiceUrl && <RedirectNotification url={invoiceUrl} />}
        {!invoiceUrl && (
          <>
            <Heading alignment="left" size="h4">
              Premium Showcasing Support
            </Heading>
            <Text size="small" fontWeight="normal" className="tw-text-gray-600">
              Enhance your core project with these powerful add-ons. Select one or more to
              supercharge your journey.
            </Text>
            <div className="tw-grid tw-gap-4 tw-py-4">
              {isProductsLoading &&
                SUPPORTED_PSS_VARIANTS.map((variant) => (
                  <Skeleton key={variant} height="120px" width="100%" />
                ))}
              {isProductsSuccess &&
                products
                  .filter((product) => SUPPORTED_PSS_VARIANTS.includes(product.name))
                  .map((product) => (
                    <div key={product.id} className="tw-flex tw-p-4">
                      <div className="tw-flex-1">
                        <Checkbox
                          label={
                            <>
                              <Text size="medium" fontWeight="bold" className="tw-mb-1">
                                {product.publicName}
                              </Text>
                              <Text size="medium">{product.publicDetails}</Text>
                            </>
                          }
                          name="productIds"
                          alignment="start"
                          onChange={() => handleVariantToggle(product.id)}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  ))}
            </div>
            <div className="tw-text-right">
              <Button
                variant="primary"
                disabled={loading || isProductsLoading || selectedVariants.length === 0}
                onClick={handlePurchasePremiumShowcasingSupport}
              >
                Proceed to Checkout
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
