import { Alert } from '@polygence/components';
import { Link } from 'react-router-dom';

import { urls } from 'src/urls';

interface ProjectStartWarningProps {
  projectStartingDate: string;
  daysRemaining: number;
  userType: string;
}

const ProjectStartWarning = ({
  projectStartingDate,
  daysRemaining,
  userType,
}: ProjectStartWarningProps) => {
  return (
    <Alert variant="success" className="m-3">
      <div className="project-start-warning">
        <p className="mb-2">
          Project start date: <span>{projectStartingDate}</span> ({daysRemaining} days remaining)
        </p>
        {userType === 'mentor' && (
          <p className="mb-0">
            Students may not be checking this platform until closer to their start date. Look out
            for emails from the Mentor Team about other opportunities to get involved with Polygence
            before the project start date.
          </p>
        )}
        {userType === 'student' && (
          <p className="mb-0">
            Mentors may not be checking this platform until closer to your project start date. In
            the meantime, you&apos;re encouraged to start reading the{' '}
            <Link to={urls.notionPage('student-guidebook')}>Student Guidebook</Link>. If you have
            any questions, please don't hesitate to reach out to the Polygence team.
          </p>
        )}
      </div>
    </Alert>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProjectStartWarning;
