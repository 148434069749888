import { commonHooks, hermesMobileTabs } from '@polygence/common';
import { useHistory } from 'react-router';
import { useSwipeable } from 'react-swipeable';

export const useHermesMobileNavigationGestures = () => {
  const activeTab = commonHooks.useMobileNavigationTab();
  const history = useHistory();

  const onNextIndex = (change: -1 | 1) => {
    const activeTabIndex = hermesMobileTabs.indexOf(activeTab);
    const nextIndex = activeTabIndex + change;
    const isNextInBounds = 0 <= nextIndex && nextIndex <= hermesMobileTabs.length - 1;

    if (isNextInBounds) {
      history.push({ ...history.location, hash: hermesMobileTabs[nextIndex] });
    }
  };

  return useSwipeable({
    onSwipedLeft: () => onNextIndex(1),
    onSwipedRight: () => onNextIndex(-1),
    preventScrollOnSwipe: true,
    trackMouse: false,
    trackTouch: true,
  });
};
