import { type Session, commonThunks } from '@polygence/common';
import {
  AtLeast,
  commonHooks,
  isFeedbackRelevantForSession,
  lastCompletedSession,
  permissions,
} from '@polygence/common';
import { Badge, Button, Icon } from '@polygence/components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Icon as OldIcon } from 'src/components/Icon';
import { Milestone } from 'src/components/hermes/Milestone';
import { ASSIGNMENT_ACTIONS } from 'src/components/hermes/constants';
import {
  useAssigmentBlueprintBySessionNumber,
  useHalfSessionRemaining,
  useMilestoneAssignmentActionForSession,
} from 'src/components/hermes/hooks';
import { BlinkingCircle } from 'src/components/hermes/sessions/BlinkingCircle/BlinkingCircle';
import FinalStudentSurveyReminder from 'src/components/hermes/sessions/FinalStudentSurveyReminder';
import { MeetingTime } from 'src/components/hermes/sessions/MeetingTime';
import { PremiumShowcasingReminder } from 'src/components/hermes/sessions/PremiumShowcasingReminder';
import StudentFeedbackReminder from 'src/components/hermes/sessions/StudentFeedbackReminder';
import { WritingFeedbackReminder } from 'src/components/hermes/sessions/WritingFeedbackReminder';
import { states as actionButtons } from 'src/components/hermes/sessions/actions';
import { ProposeClickableState } from 'src/components/hermes/sessions/actions/ProposeClickableState';
import { PreviousSessionSummary } from 'src/components/hermes/sessions/submitted-session-summary/PreviousSessionSummary';
import { getAssignmentAction } from 'src/components/hermes/utils';
import useIsPastDateTime from 'src/hooks/useIsPastDateTime';
import { useAppDispatch } from 'src/store';
import { urls } from 'src/urls';

// eslint-disable-next-line sonarjs/cognitive-complexity
const SessionListItem = ({ session }: { session: AtLeast<Session, 'sessionNumber'> }) => {
  const {
    number,
    sessionNumber: sn,
    countAs,
    meetingId,
    summary,
    id,
    proposing,
    assignments: assignmentIds,
  } = session;
  const sessionNumber = number || sn;
  const currentSessionIndex = sessionNumber - 1;
  const dispatch = useAppDispatch();
  const {
    openEditor,
    isLastSession,
    isBeforePenultSession,
    feedback,
    proposedByUser,
    sessionAction,
    canEdit,
    actionRequired,
  } = commonHooks.useSession(session);
  const project = commonHooks.useSelectedProject();
  const currentSession = project?.sessions?.[currentSessionIndex];
  const workspaceId = project?.workspaceId;
  const currentUser = commonHooks.useCurrentUser();
  const userPermissions = commonHooks.usePermissions();
  const meeting = commonHooks.useMeetingById(meetingId);
  const { data: todoItems } = commonHooks.useTodoItems();

  // used to trigger a re-render after the meeting scheduled time
  useIsPastDateTime({ dateTime: meeting?.scheduledAt });

  const assignments = commonHooks.useAssignmentsByIds(assignmentIds);
  const milestone = useAssigmentBlueprintBySessionNumber(sessionNumber);
  const milestoneAction = useMilestoneAssignmentActionForSession({ sessionNumber });
  const ActionComponent = actionButtons[sessionAction];
  const assignmentAction = getAssignmentAction(assignments);

  const showBlinkingCircle =
    project && project.showFeedbackDot && ActionComponent === ProposeClickableState;

  const halfSessionRemaining = useHalfSessionRemaining();

  const sessionLabel =
    countAs === 'late_cancelled' ? 'Late Cancelled Session' : `Session ${sessionNumber}`;
  const isFlagship = project?.type === 'flagship';
  const premiumShowcasingSupportPurchased = Boolean(project?.premiumShowcasingSupportPurchasedAt);
  const writingFeedbackEnabled = commonHooks.useWritingFeedbackEnabled(userPermissions);
  const prevCompletedSession = lastCompletedSession(project, sessionNumber);

  const handleCheckSubmittedSummaryClick = () => {
    if (sessionNumber !== 1) {
      return;
    }

    const todo = todoItems.find(
      (item) =>
        item.blueprint.type === 'check_submitted_summary_1st_session' &&
        item.status !== 'completed',
    );

    if (todo) {
      void dispatch(
        commonThunks.hermesThunks.updateTodoItem({
          id: todo.id,
          payload: { status: 'completed' },
        }),
      );
    }
  };

  return (
    <>
      {(project?.surveysRequiredFor1StSessionForZoomJoin ||
        project?.surveysRequiredFor2NdSessionForZoomJoin) &&
        currentSession &&
        prevCompletedSession &&
        isFeedbackRelevantForSession(project, sessionNumber) && (
          <StudentFeedbackReminder
            sessionId={prevCompletedSession.id}
            sessionNumber={sessionNumber}
            userType={currentUser.userType}
            project={project}
          />
        )}

      {isLastSession() &&
        project?.finalStudentSurveyRequiredForZoomJoin &&
        project.finalStudentSurveyEnabled && (
          <FinalStudentSurveyReminder
            completed={project.finalStudentSurveyCompleted}
            url={project.finalStudentSurveyUrl?.[currentUser.id] || ''}
            userType={currentUser.userType}
          />
        )}

      {currentUser.utilities.isStudent &&
        isFlagship &&
        premiumShowcasingSupportPurchased &&
        isBeforePenultSession() && <PremiumShowcasingReminder userType={currentUser.userType} />}

      {writingFeedbackEnabled &&
        ((sessionNumber === 7 && project?.reviewsRemaining === 2) ||
          (sessionNumber === 10 && project?.reviewsRemaining === 1)) && <WritingFeedbackReminder />}

      <article
        data-cy={`session-list-item-${sessionNumber}`}
        className="d-flex flex-column session-list-item"
      >
        {actionRequired && (
          <div className="h-action-required">
            <OldIcon name="info" color="white" style={{ verticalAlign: 'bottom' }} size="20px" />
            Action required
          </div>
        )}
        {milestone && milestone.displayBeforeSession && (
          <section>
            <Milestone
              assignmentAction={milestoneAction}
              sessionNumber={sessionNumber}
              sessionId={id}
              assignmentDueDate={summary?.extras?.['dueDate']}
            />
          </section>
        )}
        <section className="d-flex flex-row">
          {ActionComponent && <ActionComponent sessionId={id} meeting={meeting} />}
          <div className="h-details">
            {countAs === 'half_session' && (
              <>
                <ReactTooltip id="half-session-tooltip" effect="solid">
                  This was a half session
                </ReactTooltip>
                <OldIcon
                  name="half"
                  color="#666"
                  size="14px"
                  className="me-2"
                  data-tip
                  data-for="half-session-tooltip"
                  style={{ marginBottom: '-1px' }}
                />
              </>
            )}
            <h4 className="d-inline-block">
              {showBlinkingCircle && <BlinkingCircle className="me-3" />}
              <span>{sessionLabel}</span>
            </h4>
            {halfSessionRemaining && isLastSession() && (
              <Badge className="m-3" variant="secondary" pill>
                Half-Session
              </Badge>
            )}
            <p className="text-primary">
              {proposing && `${proposedByUser?.firstName || 'User'} proposed:`}
            </p>
            {meeting ? (
              <MeetingTime
                sessionId={id}
                projectId={project?.id}
                meetingId={meeting.id}
                timeZone={currentUser.timeZone}
                handleEdit={canEdit ? openEditor : undefined}
                cancellable={canEdit}
              />
            ) : (
              <p className="text-muted">
                {sessionNumber === 1
                  ? 'Schedule your first session!'
                  : 'Schedule within 3 weeks of the last scheduled session.'}
              </p>
            )}
            {summary && (
              <PreviousSessionSummary
                studentTimeZone={currentUser?.timeZone}
                sessionNumber={sessionNumber}
                scheduledAt={meeting?.scheduledAt}
                summary={summary}
                researchJournal={feedback?.content?.['researchJournal'] as string | undefined}
                assignments={assignments}
                projectType={project?.type}
                trigger={
                  <Button
                    size="sm"
                    variant="link-secondary"
                    endIcon={<Icon id="chevrons-right" className="mx-auto" />}
                    onClick={handleCheckSubmittedSummaryClick}
                  >
                    Submitted Summary
                  </Button>
                }
              />
            )}
            <div>
              {meeting &&
                permissions.canViewMeetingRecording(userPermissions, meeting) &&
                ActionComponent === actionButtons.SubmitFeedbackClickableState && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        You need to submit the session feedback to access the session recording.
                        Once it&lsquo;s submitted, the recording becomes available under Previous
                        sessions.
                      </Tooltip>
                    }
                  >
                    {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
                    <div className="text-danger text-small" style={{ cursor: 'pointer' }}>
                      <span className="me-2">Video recording available</span>
                      <Icon id="camera" size="sm" />
                    </div>
                  </OverlayTrigger>
                )}
            </div>
          </div>
        </section>
        <section>
          {milestone && !milestone.displayBeforeSession && (
            <Milestone
              assignmentAction={milestoneAction}
              sessionNumber={sessionNumber}
              sessionId={id}
              assignmentDueDate={summary?.extras?.['dueDate']}
            />
          )}
          {workspaceId && id && assignments && assignments.length > 0 && (
            <div className="h-assignment-container d-flex justify-content-between fs-0_7 align-items-center">
              <Link
                to={urls.assignmentForSession(workspaceId, id)}
                className="clickable underline"
                aria-hidden="true"
              >
                Assignments
              </Link>
              <div className="d-flex align-items-center">
                <Button
                  // @ts-expect-error fixable components in monorepo
                  as={Link}
                  size="sm"
                  // @ts-expect-error fixable components in monorepo
                  variant={assignmentAction.sessionCardButtonVariant}
                  className="ms-2"
                  startIcon={
                    assignmentAction === ASSIGNMENT_ACTIONS.submittedAssignment ? (
                      <Icon id="check" />
                    ) : (
                      <></>
                    )
                  }
                  to={urls.assignmentForSession(workspaceId, id)}
                >
                  {assignmentAction.sessionCardButtonLabel}
                </Button>
              </div>
            </div>
          )}
        </section>
      </article>
    </>
  );
};

export { SessionListItem };
