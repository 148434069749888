import { Text } from '@polygence/components';

export const RecordingOptOutConfirmationMessage = () => {
  return (
    <Text size="small">
      This is a reminder that you have opted out of recording this session. Please note that we
      encourage all sessions to be recorded for safety and liability reasons. Are you sure you want
      to proceed?
    </Text>
  );
};
