const isPositiveRating = {
  $or: [{ $is: { field: 'rating', value: '4' } }, { $is: { field: 'rating', value: '5' } }],
};

export const researchCoachQuestions = [
  {
    component: 'Heading',
    props: {
      alignment: 'left',
      className: 'tw-mb-2',
      size: 'h5',
      text: 'Please complete your session feedback',
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'This feedback is confidential. We do not share it with your research coach.',
    },
  },
  {
    component: 'StarRating',
    props: {
      label: 'How useful was your session? (1 - not very helpful, 5 - incredibly helpful)',
      name: 'rating',
      starCount: 5,
      size: '40px',
      required: true,
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: `What was positive about this session (select all that apply)? My research coach was:`,
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Prepared/Organized',
      name: 'preparedOrganized',
      id: 'preparedOrganized',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Supportive/Encouraging',
      name: 'supportiveEncouraging',
      id: 'supportiveEncouraging',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Very knowledgeable',
      name: 'knowledgeable',
      id: 'knowledgeable',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Great listener',
      name: 'greatListener',
      id: 'greatListener',
    },
    display: isPositiveRating,
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Guidance/provided direction',
      name: 'guidance',
      id: 'guidance',
    },
    display: isPositiveRating,
  },
  {
    component: 'FormInput',
    labelVariables: ['__mentorFirstName'],
    props: {
      label: `If you have any highlights or examples of the qualities you selected above, share them here! We love to get this feedback and to celebrate {{__mentorFirstName}}'s moments of positive impact with you.`,
      name: 'mentorStarNote',
      inputType: 'input',
    },
    display: isPositiveRating,
  },
  {
    component: 'FormInput',
    props: {
      label: 'How could we have improved your experience of the session today?',
      name: 'content',
      inputType: 'textarea',
    },
  },
];
