import { externalLink } from '@polygence/common';
import { isFetchBaseQueryError } from '@polygence/common/api/fetch';
import { dayjs } from '@polygence/common/utils/dayjsCustom';
import { Alert, Button, Heading, Icon, Text } from '@polygence/components';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams } from 'use-query-params';

import { PageLoad } from 'src/components/PageLoad';
import { DateTimeFormat } from 'src/components/aux/dateWrapper';
import MarkdownTextEditor from 'src/components/common/MarkdownTextEditor';
import { STUDENT_TEAM_EMAIL } from 'src/constants';
import {
  useApproveMeetingAISummaryMutation,
  useReviewMeetingAISummaryMutation,
  useMeetingToReviewQuery,
} from 'src/reducers/hermesMeetingAISummaryReducer';

interface ParamsProps {
  params: { meetingId: string; wid: string };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AISummaryReview = () => {
  const {
    params: { meetingId, wid: workspaceId },
  }: ParamsProps = useRouteMatch();

  const { data: meeting, isLoading, error } = useMeetingToReviewQuery(parseInt(meetingId ?? '-1'));
  const [summaryContent, setSummaryContent] = useState('');
  const [approveAISummary, { isLoading: isApproveLoading, isSuccess: isApproveSuccess }] =
    useApproveMeetingAISummaryMutation();
  const [reviewAISummary, { isLoading: isReviewLoading, isSuccess: isReviewSuccess }] =
    useReviewMeetingAISummaryMutation();
  const [query] = useQueryParams({ action: StringParam });
  const action = query.action ?? '';

  useEffect(() => {
    if (!meeting) {
      return;
    }

    setSummaryContent(meeting.summaryContent);
  }, [meeting]);

  useEffect(() => {
    if (!meeting) {
      return;
    }

    if (action === 'approve' && !meeting.emailQueuedAt) {
      approveAISummary({
        meetingId: meeting.id,
      })
        .unwrap()
        .catch(() => toast.error('Something went wrong'));
    }
  }, [action, meeting, approveAISummary]);

  const areInitialRequestsLoading = isLoading || isApproveLoading;
  const meetingFound = meetingId && meeting;
  const isSubmitSuccess = isApproveSuccess || isReviewSuccess;

  if (isFetchBaseQueryError(error) && error.status === 403) {
    return (
      <div className="tw-p-8">
        <Alert variant="danger" className="tw-text-center">
          You do not have permission to view this page. If you believe this to be a mistake, please
          reach out to us at{' '}
          <a href={`mailto:${STUDENT_TEAM_EMAIL}`} {...externalLink}>
            {STUDENT_TEAM_EMAIL}
          </a>
        </Alert>
      </div>
    );
  }

  if (areInitialRequestsLoading) {
    return <PageLoad />;
  }

  if (!meetingFound) {
    return (
      <div>
        <Text size="medium">Meeting not found</Text>
      </div>
    );
  }

  if (isSubmitSuccess) {
    return (
      <div className="tw-p-8">
        <Alert variant="success" className="tw-text-center">
          Thank you for reviewing the meeting summary. It will be sent out shortly.
        </Alert>
      </div>
    );
  }

  return (
    <div className="tw-p-5">
      <Heading size="h3" as="h1" className="tw-mb-4">
        AI Summary Review
      </Heading>
      <Button
        // @ts-expect-error see BSH-5057
        as={Link}
        variant="link"
        startIcon={<Icon id="chevron-left" />}
        to={workspaceId ? `/dashboard/hermes/${workspaceId}` : '/dashboard/hermes'}
        className="tw-pl-1"
      >
        Back to Project
      </Button>
      {meeting.emailQueuedAt && (
        <Alert variant="warning">The summary for this meeting has already been sent</Alert>
      )}
      <Heading size="h5" as="h2" alignment="left">
        Meeting details:
      </Heading>
      <div className="tw-ml-3">
        {meeting.projectTitle && (
          <Text size="medium" className="tw-mt-3">
            Project title: {meeting.projectTitle}
          </Text>
        )}
        <Text size="medium">Students: {meeting.studentNames.join(', ')}</Text>
        <Text size="medium">Session number: {meeting.sessionNumber}</Text>
        <Text size="medium">
          Scheduled at: {dayjs(meeting.scheduledAt).format(DateTimeFormat.FORMAT_1)}
        </Text>
        {meeting.emailQueuedAt && (
          <Text size="medium">
            Email queued at: {dayjs(meeting.emailQueuedAt).format(DateTimeFormat.FORMAT_1)}
          </Text>
        )}
      </div>
      <MarkdownTextEditor
        label="Summary"
        name="summary-review"
        defaultValue={meeting.summaryContent}
        onChange={setSummaryContent}
        required
        disabled={Boolean(meeting.emailQueuedAt)}
      />
      {!meeting.emailQueuedAt && (
        <Button
          variant="primary"
          type="submit"
          disabled={isLoading || isReviewLoading}
          onClick={() => {
            reviewAISummary({ meetingId: meeting.id, summaryContent })
              .unwrap()
              .catch(() => toast.error('Something went wrong'));
          }}
        >
          Submit
        </Button>
      )}
    </div>
  );
};
