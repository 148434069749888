import { Nullable } from '@polygence/common';
import { Badge, Icon, Text } from '@polygence/components';
import classNames from 'classnames';

interface CollapsibleJourneyTitleProps {
  title: string;
  sessionData: Nullable<{ completedSessions: number; totalSessions: number }>;
  dashed?: boolean;
}

export const CollapsibleJourneyTitle = ({
  title,
  sessionData,
  dashed = false,
}: CollapsibleJourneyTitleProps) => {
  return (
    <div
      className={classNames(
        'tw-flex tw-flex-row tw-items-center tw-gap-2',
        dashed && 'tw-justify-center',
      )}
    >
      <Text size="large" fontWeight="bold">
        {title}
      </Text>
      {sessionData && (
        <Badge
          variant={
            sessionData?.completedSessions === sessionData?.totalSessions ? 'primary' : 'light'
          }
        >
          {sessionData?.completedSessions} / {sessionData?.totalSessions}
        </Badge>
      )}
      {Number(sessionData?.completedSessions) === Number(sessionData?.totalSessions) && (
        <Icon id="check" size="md" strokeWidth={3} color="#0F7570" />
      )}
    </div>
  );
};
