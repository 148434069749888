import { cn } from '@polygence/components';
import type { FC } from 'react';

import LexicalEditor from 'src/components/LexicalEditor';
import type { LexicalTextEditorProps } from 'src/components/LexicalEditor/LexicalEditor';
import type { RichTextInputPluginProps } from 'src/components/LexicalEditor/plugins/RichTextInputPlugin';
import RichTextInputPlugin from 'src/components/LexicalEditor/plugins/RichTextInputPlugin';
import BlockTypeSelect from 'src/components/LexicalEditor/plugins/toolbar/BlockTypeSelect';
import Bold from 'src/components/LexicalEditor/plugins/toolbar/Bold';
import Code from 'src/components/LexicalEditor/plugins/toolbar/Code';
import CodeBlock from 'src/components/LexicalEditor/plugins/toolbar/CodeBlock';
import Divider from 'src/components/LexicalEditor/plugins/toolbar/Divider';
import Italic from 'src/components/LexicalEditor/plugins/toolbar/Italic';
import OrderedList from 'src/components/LexicalEditor/plugins/toolbar/OrderedList';
import Strikethrough from 'src/components/LexicalEditor/plugins/toolbar/StrikeThrough';
import ToolbarPlugin from 'src/components/LexicalEditor/plugins/toolbar/ToolbarPlugin';
import UnorderedList from 'src/components/LexicalEditor/plugins/toolbar/UnorderedList';
import 'src/components/common/MarkdownTextEditor/MarkdownTextEditor.sass';

export interface MarkdownTextEditorProps extends RichTextInputPluginProps, LexicalTextEditorProps {
  name: string;
  required?: boolean;
  value?: string;
  label?: string;
  labelClassName?: string;
  small_font?: string;
  displayError?: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const MarkdownTextEditor: FC<MarkdownTextEditorProps> = ({
  defaultValue,
  onChange,
  placeholder,
  name,
  required = false,
  value = defaultValue,
  label,
  labelClassName,
  small_font: smallFont,
  displayError,
  disabled = false,
  className,
}) => {
  return (
    <div
      data-cy="md-editor"
      className={cn(className, 'position-relative mb-5 markdown-editor-wrapper')}
    >
      {label && <span className={cn(labelClassName, 'mt-7 d-block', { required })}>{label}</span>}
      {smallFont && <div className="text-muted small mb-5">{smallFont}</div>}
      <LexicalEditor onChange={onChange} defaultValue={defaultValue} readOnly={disabled}>
        <ToolbarPlugin>
          <Bold />
          <Italic />
          <Strikethrough />
          <Code />
          <Divider />
          <BlockTypeSelect />
          <Divider />
          <UnorderedList />
          <OrderedList />
          <CodeBlock />
        </ToolbarPlugin>
        <RichTextInputPlugin placeholder={placeholder} />
      </LexicalEditor>
      {displayError && <p className="text-danger small">{displayError}</p>}
      <textarea
        className="hidden-textarea"
        value={value}
        required={required}
        id={name}
        name={name}
        onChange={() => null}
        disabled={disabled}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MarkdownTextEditor;
