import { commonHooks, FileUploadFile, isWorkspaceTerminated, Room } from '@polygence/common';
import { useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';

import { AttachmentList, useMultipleFileUpload } from 'src/components/hermes/FileUpload';
import ChatMessageEditor from 'src/components/hermes/chat/ChatMessageEditor';
import GifSelector from 'src/components/hermes/chat/GifSelector';
import { useIsWebSocketConnected } from 'src/components/hermes/hooks';

const EditorWrapper = ({
  room,
  sendMessage,
  sendGif,
}: {
  room: Room;
  sendMessage: (message: string, files: FileUploadFile[]) => void;
  sendGif: (gif: string) => void;
}) => {
  const [message, setMessage] = useState('');
  const [gifTerm, setGifTerm] = useState('');
  const fileInput = useRef<HTMLInputElement>(null);
  const isWebSocketConnected = useIsWebSocketConnected();
  const currentWorkspace = commonHooks.useSelectedWorkspaceOrThrow();
  const currentProject = commonHooks.useSelectedProject();
  const isTerminated = isWorkspaceTerminated(currentWorkspace, currentProject);

  const [fileUpload, removeFile, files, uploading, clearFiles] = useMultipleFileUpload(
    `/hermes/workspaces/${room.workspaceId}/attachments/`,
    `/hermes/attachments/`,
  );

  const isMessageEmpty = message === '' && !uploading && files.length === 0;
  const isMessageSendDisabled = isMessageEmpty || uploading;

  const handleMessageSend = useCallback(
    (message: string) => {
      sendMessage(message, files);

      clearFiles();
      if (fileInput.current) {
        fileInput.current.value = '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, fileInput.current, room.id],
  );
  return !isTerminated ? (
    <>
      <GifSelector gifTerm={gifTerm} setGifTerm={setGifTerm} sendGif={sendGif} />
      <Dropzone
        onDrop={(acceptedFiles) => {
          fileUpload({ target: { files: acceptedFiles } });
        }}
      >
        {({ getRootProps }) => {
          return (
            <>
              {!isWebSocketConnected && (
                <span className="ms-3 red-text fade-in-2s">
                  Connection lost. Please refresh the page.
                </span>
              )}
              <div {...getRootProps()}>
                <ChatMessageEditor
                  defaultValue={message}
                  onChange={setMessage}
                  setGifTerm={setGifTerm}
                  fileUpload={fileUpload}
                  fileInput={fileInput}
                  workspace={{ workspaceId: room.workspaceId }}
                  listFiles={false}
                  isMessageSendDisabled={isMessageSendDisabled}
                  isEditorDisabled={isTerminated}
                  handleMessageSend={handleMessageSend}
                />
              </div>
            </>
          );
        }}
      </Dropzone>
      <AttachmentList handleRemove={removeFile} files={files} />
    </>
  ) : null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default EditorWrapper;
