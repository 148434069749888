import { commonHooks, commonReducers } from '@polygence/common';
import { Button, Modal, ModalBody, Spacer, Text } from '@polygence/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EXTENSION_REQUEST_ACTIONS } from 'src/components/hermes/constants';
import { useExtensionRequestAction } from 'src/components/hermes/hooks';
import { dayjs } from 'src/utils/dayjsCustom';

export const ProjectOverdueModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const selectedProject = commonHooks.useSelectedProject();
  const extensionRequestAction = useExtensionRequestAction();

  const handleClick = () => {
    dispatch(commonReducers.projectExtensionActions.openProjectExtensionModal());
    setIsOpen(false);
  };

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    const isOverdue =
      selectedProject?.expectedEndAt && dayjs().isAfter(selectedProject.expectedEndAt);
    const isActionAvailable =
      extensionRequestAction === EXTENSION_REQUEST_ACTIONS.request ||
      extensionRequestAction === EXTENSION_REQUEST_ACTIONS.respond ||
      extensionRequestAction === EXTENSION_REQUEST_ACTIONS.extend_deadline;

    const isActiveProject = selectedProject?.status === 'active';
    if (isOverdue && isActionAvailable && isActiveProject) {
      setTimeoutId(
        setTimeout(() => {
          setIsOpen(true);
        }, 2000),
      );
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- adding timeoutId would result in infinite loop
  }, [selectedProject?.id, selectedProject?.expectedEndAt, extensionRequestAction]);

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      contentClassName="p-7 p-md-10"
      centered
      closeButton
    >
      <ModalBody>
        <Text size="medium">
          Your project is overdue. In order to schedule your remaining sessions, you need to update
          your deadline.{' '}
        </Text>
        <Spacer size={4} />
        <Text size="medium">
          If you have concerns or if your project is actually already complete. Please reach out to
          Team Polygence using the Support button or by contacting{' '}
          <a href="mailto:students@polygence.org">students@polygence.org</a> or{' '}
          <a href="mailto:mentors@polygence.org">mentors@polygence.org</a>
        </Text>
        <Spacer size={6} />
        <div className="d-flex justify-content-center">
          <Button onClick={handleClick} variant="primary">
            Update Deadline
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
