import { useGetMessagesQuery } from '@polygence/common/api/hermes-ai';
import { hermesAiSelectors } from '@polygence/common/store/reducers/hermesAiReducer';

import { useAppSelector } from 'src/store';

export const useGetMessageCount = ({ forMessageId }: { forMessageId?: number } = {}) => {
  const messageThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  return useGetMessagesQuery(Number(messageThreadId), {
    skip: !messageThreadId,
    selectFromResult: ({ data }) => {
      if (!data || !data.messages) {
        return { messageCount: null, maxNumberOfMessages: null };
      }
      const messageIndex = data.messages.findIndex((message) => message.id === forMessageId);

      const messages =
        messageIndex !== -1
          ? data.messages.slice(messageIndex, data.messages.length)
          : data.messages;

      const messageCount = messages.filter((message) => message.role === 'user').length;

      return {
        messageCount: Math.min(messageCount, data.maxNumberOfMessages),
        maxNumberOfMessages: data.maxNumberOfMessages,
      };
    },
  });
};
