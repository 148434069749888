import { commonHooks } from '@polygence/common';
import { Icon, Text } from '@polygence/components';
import { useHistory } from 'react-router-dom';

import type { ActionStateProps } from 'src/components/hermes/sessions/actions/ActionStateProps';
import { BaseState } from 'src/components/hermes/sessions/actions/State';
import { urls } from 'src/urls';

const MeetingInsightState = ({ sessionId }: ActionStateProps) => {
  const project = commonHooks.useSelectedProject();
  const history = useHistory();

  const navigateToFeedback = () => {
    if (!project || !sessionId) {
      return;
    }
    history.push(urls.teachingFeedbackWithRecording(project.uuid, sessionId));
  };

  return (
    <BaseState variant="info" onClick={navigateToFeedback}>
      <Icon id="bar-chart-2" />
      <Text size="small">Insights</Text>
    </BaseState>
  );
};

export { MeetingInsightState };
