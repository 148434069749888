import { commonHooks, SharedResource } from '@polygence/common';
import { StyledCollapsible } from '@polygence/components';

import { SharedResourceEditModal } from 'src/components/hermes/SharedResource/SharedResourceEditModal';
import { SharedResourceItem } from 'src/components/hermes/SharedResource/SharedResourceItem';

export const SharedResourceList = () => {
  const resources = commonHooks.useSharedResources();
  const { activeResources, archivedResources } = resources.reduce(
    (acc: { activeResources: SharedResource[]; archivedResources: SharedResource[] }, resource) => {
      if (resource.archived) {
        acc.archivedResources.push(resource);
      } else {
        acc.activeResources.push(resource);
      }

      return acc;
    },
    {
      activeResources: [],
      archivedResources: [],
    },
  );

  return (
    <div className="d-flex flex-column flex-grow-1 justify-content-between">
      <div className="d-flex flex-column p-3 gap-4">
        {activeResources.map((resource) => (
          <SharedResourceItem key={resource.id} resource={resource} />
        ))}
      </div>
      {archivedResources.length > 0 && (
        <StyledCollapsible summary={`Archived (${archivedResources.length})`}>
          <div className="d-flex flex-column gap-4">
            {archivedResources.map((resource) => (
              <SharedResourceItem key={resource.id} resource={resource} />
            ))}
          </div>
        </StyledCollapsible>
      )}
      <SharedResourceEditModal />
    </div>
  );
};
