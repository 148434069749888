import { UserId } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Icon, Text, cn } from '@polygence/components';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { SyntaxHighlighter } from 'src/components/aux/markdown/SyntaxHighlighter';
import { LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import { OpenAIThreadButton } from 'src/components/hermes/chat/OpenAIThreadButton';

const components = { a: LinkRenderer, pre: SyntaxHighlighter };

export const HermesAIAssistantMessage = ({
  content,
  sentBy: { id: userId, firstName },
  extra: { threadId, messageId },
}: {
  content: string;
  sentBy: {
    id: UserId;
    firstName: string;
  };
  extra: Record<string, string>;
}) => {
  const currentUser = useCurrentUser();
  const isOwn = currentUser.id === userId;

  return (
    <div className={cn('tw-mb-2 tw-flex tw-flex-col tw-items-start', isOwn && 'tw-items-end')}>
      <Text size="small" fontWeight="semibold" className="tw-flex tw-items-center tw-text-gray-500">
        <Icon id="sparkles" size="md" className="tw-mx-2 tw-text-primary-500" />
        {firstName} has shared a message from Project Assistant.
      </Text>
      <div className="h-simple-message tw-relative tw-flex tw-max-w-[80%] tw-flex-col tw-items-start tw-gap-1 tw-bg-transparent tw-p-4 tw-pb-2">
        <div className="tw-border-0 tw-border-l-8 tw-border-solid tw-border-primary-500 tw-px-2 [&>p:last-child]:tw-mb-0 [&_a:active]:tw-text-primary-500 [&_a:hover]:tw-text-primary-500 [&_a]:tw-text-primary-600">
          <ReactMarkdown components={components}>{content}</ReactMarkdown>
        </div>
        {threadId && (
          <OpenAIThreadButton
            className="tw-mr-0 tw-self-end"
            threadId={Number(threadId)}
            messageId={Number(messageId)}
          />
        )}
      </div>
    </div>
  );
};
