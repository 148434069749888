import { UserSnippet } from '@polygence/common';
import { Button, Icon, cn } from '@polygence/components';
import { ComponentProps, forwardRef } from 'react';

type UserSnippetButtonProps = ComponentProps<typeof Button> & {
  snippet: UserSnippet;
};

export const UserSnippetButton = forwardRef<HTMLButtonElement, UserSnippetButtonProps>(
  ({ snippet, className, ...props }, ref) => {
    return (
      <Button
        size="sm"
        variant="link"
        className={cn('tw-w-56 tw-justify-start tw-overflow-hidden', className)}
        startIcon={<Icon className="tw-text-gray-600" id="file-text" />}
        ref={ref}
        {...props}
      >
        <span className="tw-overflow-hidden tw-text-ellipsis">
          {snippet.content.substring(0, 50) || 'Empty snippet'}
        </span>
      </Button>
    );
  },
);
