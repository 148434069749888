import { useEffect, useState } from 'react';

import { FeatureDecisions, featureDecisions } from './featureDecisions';

export const useFeatureToggle = (feature: FeatureDecisions) => {
  const [featureEnabled, setFeatureEnabled] = useState(false);

  useEffect(() => {
    featureDecisions
      .getInstance()
      .then((instance) => setFeatureEnabled(!!instance[feature]?.()))
      .catch(console.error);
  }, [feature]);

  return featureEnabled;
};
