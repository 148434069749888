import { commonThunks, commonHooks, Meeting, Nullable } from '@polygence/common';
import { useEffect, useState } from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { MeetingEditor } from 'src/components/hermes/scheduler/MeetingEditor';
import { SuccessConfirmation } from 'src/components/hermes/scheduler/SuccessConfirmation';
import { useAppDispatch, useAppSelector } from 'src/store';

type MeetingWithoutZoom = Omit<Meeting, 'zoom'>;

export const MeetingScheduler = () => {
  const dispatch = useAppDispatch();
  const { meetingId, sending, success } = useAppSelector((state) => {
    return state.hermes.scheduler;
  });
  const selectedMeeting = useAppSelector((state) => {
    if (meetingId) {
      return state.hermes.meetings.byId[meetingId];
    }
    return undefined;
  });
  const [meeting, setMeeting] = useState<Nullable<MeetingWithoutZoom>>(null);
  const otherParticipants = commonHooks.useOtherParticipants();
  const selectedWorkspaceId = commonHooks.useSelectedWorkspaceId();
  useEffect(() => {
    if (selectedMeeting) {
      const { zoom, ...current } = selectedMeeting;
      setMeeting(current);
    } else {
      setMeeting({
        // @ts-expect-error hmm
        scheduledAt: new Date(),
        recordingOn: true,
        sharingOn: true,
      });
    }
  }, [selectedMeeting]);

  const handleChange = <T extends keyof MeetingWithoutZoom>({
    target: { name, value },
  }: {
    target: { name: T; value: MeetingWithoutZoom[T] };
  }) => {
    return setMeeting((prevMeeting) => {
      if (prevMeeting) {
        return { ...prevMeeting, [name]: value };
      }
      return null;
    });
  };

  const handleSubmit = () => {
    if (selectedWorkspaceId && meeting) {
      void dispatch(
        commonThunks.hermesThunks.saveMeeting({
          meetingId,
          // @ts-expect-error workspace is overwritten by meeting.workspace, not gonna touch this
          workspace: selectedWorkspaceId,
          ...meeting,
        }),
      );
    }
  };

  if (!meeting) {
    return <SpinningWheel />;
  }
  return (
    <>
      <h2 className="h5">
        {meetingId ? 'Reschedule' : 'Schedule'} meeting with{' '}
        {otherParticipants
          .map(({ firstName }) => {
            return firstName;
          })
          .join(', ')}
      </h2>
      {success ? (
        <SuccessConfirmation />
      ) : (
        <>
          <p className="text-muted text-small">
            The date/time you set is automatically converted to the other participants&apos; own
            time zone.
          </p>
          {/* @ts-expect-error follow-up ticket */}
          <MeetingEditor
            meeting={meeting}
            sending={sending}
            otherParticipants={otherParticipants}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};
