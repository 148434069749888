import { externalLink } from '@polygence/common';
import { InputField } from '@polygence/components';
import { FC, useEffect, useRef } from 'react';

import { Icon } from 'src/components/Icon';

const actionIconStyle = {
  size: '14px',
  color: 'var(--h-grey-1)',
  role: 'button',
};

interface AssignmentLinkProps {
  link: string;
  removeLink: (link: string) => void;
  editable: boolean;
}

const AssignmentLink: FC<AssignmentLinkProps> = ({ link, removeLink, editable }) => {
  const linkWithProtocol = link.startsWith('http') ? link : `https://${link}`;

  return (
    <div className="h-assignment-file">
      <a href={linkWithProtocol} {...externalLink} className="h-assignment-file__icon">
        <Icon size="16px" color="#fff" name="globe" local />
      </a>
      <div className="h-assignment-file__details">
        <strong>Link</strong>
        <span>{link}</span>
      </div>
      <div className="h-assignment-file__action">
        {editable ? (
          <Icon
            local
            name="cross"
            onClick={() => {
              removeLink(link);
            }}
            {...actionIconStyle}
          />
        ) : (
          <a href={linkWithProtocol} {...externalLink}>
            <Icon local name="link" {...actionIconStyle} />
          </a>
        )}
      </div>
    </div>
  );
};

interface AssignmentLinkInputProps {
  addLink: (link: string) => void;
}

const AssignmentLinkInput: FC<AssignmentLinkInputProps> = ({ addLink }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return () => undefined;
    }

    el.focus();

    const onBlur = () => {
      addLink(el.value);
    };

    const onKeyPress = (e: KeyboardEvent) => {
      const ENTER = 13;
      const ESCAPE = 27;

      if (e.keyCode === ENTER || e.keyCode === ESCAPE) {
        el.blur();
      }
    };

    el.addEventListener('blur', onBlur);
    el.addEventListener('keydown', onKeyPress);

    return () => {
      el.removeEventListener('blur', onBlur);
      el.removeEventListener('keydown', onKeyPress);
    };
  }, [addLink]);

  return <InputField ref={ref} type="text" name="linkinput" label="Link" />;
};

export { AssignmentLink, AssignmentLinkInput };
