import { commonThunks, commonHooks } from '@polygence/common';
import { InputField } from '@polygence/components';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import type { Dispatch, ChangeEvent } from 'react';

import { useAppDispatch } from 'src/store';

const updateProject = debounce(
  (name: string, value: string, projectId: number | undefined, dispatch: Dispatch<unknown>) => {
    if (!projectId) {
      return;
    }
    return dispatch(commonThunks.hermesThunks.updateProject({ projectId, [name]: value }));
  },
  300,
);

export const EditableProjectDetails = () => {
  const project = commonHooks.useSelectedProject();
  const projectId = project?.id;
  const details = { title: project?.title, description: project?.description };
  const dispatch = useAppDispatch();

  const [title, setTitle] = useState(details?.title);
  const [description, setDescription] = useState(details?.description);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'title') {
      setTitle(value);
    }

    if (name === 'description') {
      setDescription(value);
    }

    updateProject(name, value, projectId, dispatch);
  };

  useEffect(() => {
    setTitle(details?.title);
    setDescription(details?.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <InputField
        name="title"
        label="Project Title"
        floating={false}
        value={title}
        onChange={onChangeHandler}
      />
      <InputField
        className="project-desc-input"
        name="description"
        label="Project Description"
        type="textarea"
        floating={false}
        value={description}
        onChange={onChangeHandler}
      />
    </div>
  );
};
