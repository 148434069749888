import type {
  ConfigurationId,
  FeedbackReviewer,
  Nullable,
  Paginated,
  ProjectTypes,
} from '@polygence/common';
import { SimpleUser, toOptions } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type {
  MentorProfileStaffList,
  MentorRequestCard,
  StudentDetailsAdmin,
} from '@polygence/common/types/analytics';
import type { Role } from '@polygence/common/types/backend';
import type {
  UciGatiApplication,
  UciGatiApplicationWithCountry,
} from '@polygence/common/types/college-credits';
import type {
  AdmissionAdvisorModalId,
  CaseStudyId,
  CounselorProfileId,
  DateTime,
  FeedbackRequestId,
  JSONObject,
  JSONValue,
  LeadId,
  MentorProfileId,
  MentorRequestId,
  PartnerCompanyId,
  ProjectId,
  ProspectiveUserId,
  ResearchPaperId,
  SalesCommissionId,
  SelectOption,
  StudentApplicationId,
  StudentProfileId,
  TagId,
  UUID,
  UserId,
} from '@polygence/common/types/common';
import type {
  EmptyResponse,
  MentorQueryParams,
  MentorRequestsSearchParams,
  SearchParams,
  StudentPipelineApplicationsSearchParams,
  UciGatiApplicationSearchParams,
  UserSearchParams,
} from '@polygence/common/types/data/common';
import type {
  CounselorDiscountUpdateSerializer,
  CounselorOverviewSerializer,
  PaymentIntentAdminFilterParams,
  PremiumShowcasingSupportMatchingSerializer,
  PremiumShowcasingSupportMentorProposingSerializer,
  WritingFeedbackMentorProposingSerializer,
} from '@polygence/common/types/data/zeus';
import type {
  MentorProfileMinimal,
  Note,
  NoteOrdering,
  NoteType,
  StudentDetails,
  Tag,
} from '@polygence/common/types/marketplace';
import type { Cart, CartAdmin, PaymentIntentReadOnlyAdmin } from '@polygence/common/types/payment';
import type {
  Lead,
  LeadCommunication,
  LeadListItem,
  SalesCommission,
} from '@polygence/common/types/sales';
import type {
  AdmissionsReviewStatusType,
  StudentApplicationPipeline,
} from '@polygence/common/types/studentApplication';
import type { UserWithProfileIdAndPicture } from '@polygence/common/types/user';
import type {
  AdminProject,
  AdmissionAdvisorModalAdmin,
  CaseStudyUpdate,
  Configuration,
  CounselorOverview,
  CounselorProfile,
  DiscountAdmin,
  FeedbackRequestList,
  Institution,
  Keyword,
  ManualPriceAdjustmentPayload,
  PartnerChannel,
  PaymentIntent,
  PaymentIntentReadOnlyAdminList,
  PremiumShowcasingSupportMatching,
  ProjectOverview,
  ProspectiveCounselor,
  ResearchPaperAdmin,
  ScriptLog,
  ShowcasingSpecialistMentor,
  StudentPipelineRegistration,
  StudentProfileOverview,
  SymposiumPresentersBulkUploadValues,
} from '@polygence/common/types/zeus';
import type { OptionType } from '@polygence/components';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { CamelCasedPropertiesDeep } from 'type-fest';

import type { ProductWithQuantity } from 'src/components/product/useCartManager';
import { paymentApi } from 'src/reducers/paymentReducer';
import type { SilentRoomUserReportSearchParams } from 'src/types/analytics';
import type { SilentRoomUserReport } from 'src/types/hermes';
import type { ListOptions, MentorAttributes } from 'src/types/zeus';

export const adminApiBase = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'adminApi',
  endpoints: () => {
    return {};
  },
});

export interface PartnerChannels {
  byId: {
    [key: number]: PartnerChannel;
  };
  byTag: {
    [key: string]: PartnerChannel;
  };
  allIds: number[];
  allTags: string[];
}

const adminApiWithTags = adminApiBase.enhanceEndpoints({
  addTagTypes: [
    'Configuration',
    'AdmissionAdvisorModal',
    'Cart',
    'StudentNote',
    'ProspectiveCounselor',
    'PremiumShowcasingSupportMatchingRequest',
    'Snooze',
    'Lead',
    'FeedbackRequests',
    'RevenueDetail',
    'PaymentIntent',
    'CounselorProfile',
    'UciGatiApplication',
    'ResearchPaper',
    'StudentOverview',
  ],
});

// This should enable code-splitting, so we don't include admin API route info in the main bundle
export const adminApi = adminApiWithTags.injectEndpoints({
  endpoints: (build) => {
    return {
      getStudentProfile: build.query<StudentProfileOverview, StudentProfileId>({
        query: (studentProfileId) => {
          return `/zeus/students/${studentProfileId}/`;
        },
      }),
      getPaymentIntent: build.query<PaymentIntent, { uuid: UUID }>({
        query: ({ uuid }) => {
          return `/zeus/payment-intent/${uuid}/`;
        },
        providesTags: (_, __, { uuid }) => {
          return [{ type: 'PaymentIntent', id: uuid }];
        },
      }),
      getPaymentIntents: build.query<
        Paginated<PaymentIntentReadOnlyAdminList>,
        PaymentIntentAdminFilterParams
      >({
        query: (params) => {
          return {
            url: '/zeus/payment-intents/',
            method: 'get',
            params,
          };
        },
      }),
      enableFeatureForUser: build.mutation<void, { featureName: string; userId: UserId }>({
        query: ({ featureName, userId }) => {
          return {
            url: `/zeus/feature/${featureName}/user/${userId}/enable/`,
            method: 'post',
          };
        },
        invalidatesTags: ['PaymentIntent'],
      }),
      sendPaymentIntentToStudent: build.mutation<
        EmptyResponse,
        {
          paymentIntentUUID: UUID;
          studentProfileId: StudentProfileId;
        }
      >({
        query: ({ paymentIntentUUID, studentProfileId }) => {
          return {
            url: `/zeus/payment-intent/${paymentIntentUUID}/send_student_payment_intent/?student_id=${studentProfileId}`,
            method: 'post',
          };
        },
      }),
      triggerRevenueShare: build.mutation<EmptyResponse, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/trigger-revenue-share/`,
            method: 'post',
          };
        },
        invalidatesTags: (_, __, { uuid }) => {
          return [{ type: 'PaymentIntent', id: uuid }];
        },
      }),
      createRevenueSharePaymentRequest: build.mutation<EmptyResponse, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/create-revenue-share-payment-request/`,
            method: 'post',
          };
        },
        invalidatesTags: () => {
          return [{ type: 'StudentOverview' }];
        },
      }),
      convertPaymentIntentSubscriptionToOneTime: build.mutation<PaymentIntent, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/convert-to-one-time/`,
            method: 'PATCH',
          };
        },
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(paymentApi.util.invalidateTags(['PaymentIntent']));
        },
      }),
      closeAndMarkPaid: build.mutation<void, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/close-and-mark-paid/`,
            method: 'PATCH',
          };
        },
        invalidatesTags: (_, __, { uuid }) => {
          return [{ type: 'PaymentIntent', id: uuid }];
        },
      }),
      cancelPaymentIntent: build.mutation<void, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/manual_cancel/`,
            method: 'PATCH',
          };
        },
        invalidatesTags: (_, __, { uuid }) => {
          return [{ type: 'PaymentIntent', id: uuid }];
        },
      }),
      reopenPaymentIntent: build.mutation<void, { uuid: UUID }>({
        query: ({ uuid }) => {
          return {
            url: `/zeus/payment-intent/${uuid}/manual_reopen/`,
            method: 'PATCH',
          };
        },
        invalidatesTags: (_, __, { uuid }) => {
          return [{ type: 'PaymentIntent', id: uuid }];
        },
      }),
      usherers: build.query<OptionType[], void>({
        query: () => {
          return '/analytics/usherers/';
        },
      }),
      updateUsherer: build.mutation<
        { success: true },
        { userId: UserId; payload: Partial<{ value: UserId }> }
      >({
        query: ({ userId, payload }) => {
          return {
            url: `/analytics/users/${userId}/change_usherer/`,
            method: 'patch',
            data: payload,
          };
        },
      }),
      channels: build.query<OptionType[], void>({
        query: () => {
          return '/analytics/channels/';
        },
        transformResponse: (response: string[] = []) => {
          return toOptions(response) as OptionType[];
        },
      }),
      updateChannel: build.mutation<
        { success: true },
        { userId: UserId; payload: Partial<{ channel: string }> }
      >({
        query: ({ userId, payload }) => {
          return {
            url: `/analytics/users/${userId}/change_channel/`,
            method: 'patch',
            data: payload,
          };
        },
      }),
      searchUsers: build.query<Paginated<UserWithProfileIdAndPicture>, UserSearchParams>({
        query: (params) => {
          return { url: '/zeus/users/', method: 'get', params: params };
        },
      }),
      getUser: build.query<UserWithProfileIdAndPicture, UserId>({
        query: (id) => {
          return { url: `/zeus/users/${id}/`, method: 'get' };
        },
      }),
      mentorSubjectTagsAndKeywords: build.query<
        CamelCasedPropertiesDeep<{ subject_tags: Tag[]; keywords: Keyword[] }>,
        MentorProfileId
      >({
        query: (mentorProfileId) => {
          return {
            url: `/zeus/mentors/${mentorProfileId}/subject-tags-and-keywords/`,
            method: 'get',
          };
        },
      }),
      mentors: build.query<
        Paginated<MentorProfileMinimal>,
        { search: string; onlyPublic?: boolean; projectType?: ProjectTypes }
      >({
        query: ({ search, onlyPublic = false, projectType }) => {
          return {
            url: '/zeus/mentor-selector/',
            method: 'get',
            params: { q: search, only_public: onlyPublic, project_type: projectType },
          };
        },
      }),
      partnerChannels: build.query<PartnerChannels, void>({
        query: () => {
          return '/zeus/partner_channels';
        },
        transformResponse: (response: PartnerChannel[]) => {
          return {
            byId: response.reduce((acc, partner) => {
              return { ...acc, [partner.pk]: partner };
            }, {}),
            byTag: response.reduce((acc, partner) => {
              return { ...acc, [partner.tag]: partner };
            }, {}),
            allIds: response.map((partner) => partner.pk),
            allTags: response.map((partner) => partner.tag),
          };
        },
      }),
      partnerUsherers: build.query<SelectOption<number>[], void>({
        query: () => {
          return '/zeus/partner-usherers';
        },
      }),
      admissionAdvisors: build.query<SimpleUser[], void>({
        query: () => {
          return '/zeus/roles/?role=admission_advisor';
        },
      }),
      partnershipManagers: build.query<SimpleUser[], void>({
        query: () => {
          return '/zeus/roles/?role=partnership_manager';
        },
      }),
      polyPilotMentors: build.query<SimpleUser[], SearchParams>({
        query: (params) => ({
          url: '/zeus/roles/?role=polypilot_mentor',
          method: 'GET',
          params: params,
        }),
      }),
      options: build.query<MentorAttributes, void>({
        query: () => {
          return '/zeus/options/';
        },
        transformResponse: (response: ListOptions): MentorAttributes => {
          return {
            mentorEngagement: response.mentorEngagement.map(([value, label]) => ({ value, label })),
            mentorPerformance: response.mentorPerformance.map(([value, label]) => ({
              value,
              label,
            })),
          };
        },
      }),
      adminRoles: build.query<OptionType[], void>({
        query: () => {
          return '/zeus/admin-role/';
        },
        transformResponse: (response: Role[]) => {
          return response.map((role) => ({ label: role.name, value: role.id }));
        },
      }),
      discounts: build.query<
        DiscountAdmin[],
        { partner_discounts?: boolean; counselor_discounts?: boolean }
      >({
        query: (params) => {
          return {
            url: '/zeus/discounts/',
            method: 'get',
            params,
          };
        },
      }),
      showcasingSpecialists: build.query<OptionType[], { project_type: ProjectTypes }>({
        query: (params) => {
          return {
            url: '/zeus/premium-showcasing-support/showcasing-specialists/',
            method: 'get',
            params,
          };
        },
        transformResponse(response: ShowcasingSpecialistMentor[]) {
          return response.map((mentor) => ({
            value: mentor.id,
            label: `${mentor.firstName} ${mentor.lastName} (${mentor.email})`,
            profilePicture: mentor.profilePicture,
          }));
        },
      }),
      configurations: build.query<Paginated<Configuration>, SearchParams>({
        providesTags: [
          {
            type: 'Configuration',
          },
        ],
        query: (params) => {
          return {
            url: '/zeus/configurations/',
            method: 'get',
            params: params,
          };
        },
      }),
      updateConfiguration: build.mutation<
        Configuration,
        { configurationId: ConfigurationId; payload: { value: JSONObject } }
      >({
        query: ({ configurationId, payload }) => {
          return {
            url: `/zeus/configuration/${configurationId}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'Configuration',
          },
        ],
      }),
      premiumShowcasingSupportMatchingRequest: build.query<
        Paginated<PremiumShowcasingSupportMatching>,
        SearchParams
      >({
        providesTags: ['PremiumShowcasingSupportMatchingRequest'],
        query: (params) => {
          return {
            url: '/zeus/premium-showcasing-support/',
            method: 'get',
            params: params,
          };
        },
      }),
      getProposableMentorForWritingFeedback: build.query<
        CamelCasedPropertiesDeep<WritingFeedbackMentorProposingSerializer>[],
        { writingFeedbackUuid: UUID }
      >({
        query: ({ writingFeedbackUuid }) => {
          return {
            url: `/zeus/feedback-request/${writingFeedbackUuid}/proposable-mentors/`,
            method: 'get',
          };
        },
      }),
      getProposableMentorsForPremiumShowcasingSupportMatchingRequest: build.query<
        CamelCasedPropertiesDeep<PremiumShowcasingSupportMentorProposingSerializer>[],
        { premiumShowcasingSupportMatchingUuid: UUID }
      >({
        query: ({ premiumShowcasingSupportMatchingUuid }) => {
          return {
            url: `/zeus/premium-showcasing-support/${premiumShowcasingSupportMatchingUuid}/proposable-mentors/`,
            method: 'get',
          };
        },
      }),
      updatePremiumShowcasingSupportMatchingRequest: build.mutation<
        PremiumShowcasingSupportMatchingSerializer,
        {
          premiumShowcasingSupportMatchingUuid: UUID;
          payload: Partial<CamelCasedPropertiesDeep<PremiumShowcasingSupportMatchingSerializer>>;
        }
      >({
        invalidatesTags: ['PremiumShowcasingSupportMatchingRequest'],
        query: ({ premiumShowcasingSupportMatchingUuid, payload }) => {
          return {
            url: `/zeus/premium-showcasing-support/${premiumShowcasingSupportMatchingUuid}/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      updatePremiumShowcasingSupportMatchingRequestTag: build.mutation<
        PremiumShowcasingSupportMatchingSerializer,
        {
          premiumShowcasingSupportMatchingUuid: UUID;
          payload: { mustHaveTags: TagId[] };
        }
      >({
        invalidatesTags: ['PremiumShowcasingSupportMatchingRequest'],
        query: ({ premiumShowcasingSupportMatchingUuid, payload }) => {
          return {
            url: `/zeus/premium-showcasing-support/${premiumShowcasingSupportMatchingUuid}/tags/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      cancelPremiumShowcasingSupportMatchingRequest: build.mutation<
        PremiumShowcasingSupportMatchingSerializer,
        UUID
      >({
        invalidatesTags: ['PremiumShowcasingSupportMatchingRequest'],
        query: (uuid) => {
          return {
            url: `/zeus/premium-showcasing-support/${uuid}/cancel-proposal/`,
            method: 'PATCH',
          };
        },
      }),
      feedbackRequests: build.query<Paginated<FeedbackRequestList>, SearchParams>({
        providesTags: ['FeedbackRequests'],
        query: (params) => {
          return {
            url: `/zeus/feedback-requests/`,
            method: 'GET',
            params,
          };
        },
      }),
      updateFeedbackRequestTag: build.mutation<
        void,
        {
          feedbackRequestId: FeedbackRequestId;
          payload: { mustHaveTags: TagId[] };
        }
      >({
        invalidatesTags: ['FeedbackRequests'],
        query: ({ feedbackRequestId, payload }) => {
          return {
            url: `/zeus/feedback-requests/${feedbackRequestId}/tags/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      silentRoomUserReports: build.query<
        Paginated<SilentRoomUserReport>,
        SilentRoomUserReportSearchParams
      >({
        query: (params) => {
          return {
            url: '/analytics/silent_room_user_reports/',
            method: 'get',
            params: params,
          };
        },
      }),
      mentorRequests: build.query<Paginated<MentorRequestCard>, MentorRequestsSearchParams>({
        query: (params) => {
          return {
            url: '/analytics/mentor_requests/',
            method: 'get',
            params: params,
          };
        },
      }),
      updateMentorRequest: build.mutation<
        StudentDetails,
        { mentorRequestId: MentorRequestId; payload: Partial<StudentDetails> }
      >({
        query: ({ mentorRequestId, payload }) => {
          return {
            url: `/analytics/mentor_requests/${mentorRequestId}/`,
            method: 'patch',
            data: payload,
          };
        },
      }),
      getMentors: build.query<Paginated<Partial<MentorProfileStaffList>>, MentorQueryParams>({
        query: (queryParams) => {
          const urlParams = new URLSearchParams();
          queryParams?.fields?.forEach((field) => {
            urlParams.append('fields', field);
          });
          urlParams.append('limit', queryParams?.limit?.toString() || '');
          urlParams.append('search', queryParams?.search || '');
          return {
            url: '/analytics/mentors/',
            method: 'get',
            params: urlParams,
          };
        },
      }),
      studentPipelineApplications: build.query<
        Paginated<StudentApplicationPipeline>,
        StudentPipelineApplicationsSearchParams
      >({
        query: (params) => {
          return {
            url: '/zeus/student-pipeline/applications/',
            method: 'get',
            params: params,
          };
        },
      }),
      keywords: build.query<Keyword[], string>({
        query: (search) => {
          return { url: '/zeus/keywords/', method: 'get', params: { q: search } };
        },
      }),
      admissionAdvisorModals: build.query<AdmissionAdvisorModalAdmin[], void>({
        providesTags: [
          {
            type: 'AdmissionAdvisorModal',
          },
        ],
        query: () => {
          return '/zeus/admission-advisor-modals/';
        },
      }),
      updateAdmissionAdvisorModal: build.mutation<
        AdmissionAdvisorModalAdmin,
        { admissionAdvisorModalId: AdmissionAdvisorModalId; payload: JSONValue }
      >({
        query: ({ admissionAdvisorModalId, payload }) => {
          return {
            url: `/zeus/admission-advisor-modals/${admissionAdvisorModalId}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'AdmissionAdvisorModal',
          },
        ],
      }),
      massAssignAdmissionAdvisorModal: build.mutation<
        AdmissionAdvisorModalAdmin,
        { id: AdmissionAdvisorModalId; reAssignmentType: string; newAaId?: UserId }
      >({
        query: ({ id, reAssignmentType, newAaId }) => {
          return {
            url: `/zeus/admission-advisor-modals/${id}/reassign/`,
            method: 'POST',
            data: {
              reAssignmentType,
              newAaId,
            },
          };
        },
        invalidatesTags: [
          {
            type: 'AdmissionAdvisorModal',
          },
        ],
      }),
      cartManualAdjustment: build.mutation<
        { cart: CartAdmin },
        { cartUuid: Cart['uuid']; payload: ManualPriceAdjustmentPayload }
      >({
        query: ({ cartUuid, payload }) => {
          return {
            url: `/zeus/cart/${cartUuid}/manual-adjustment/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      removeCartManualAdjustment: build.mutation<{ cart: CartAdmin }, { cartUuid: Cart['uuid'] }>({
        query: ({ cartUuid }) => {
          return {
            url: `/zeus/cart/${cartUuid}/remove-manual-adjustment/`,
            method: 'PATCH',
          };
        },
      }),
      refundRiskPremium: build.mutation<{ cart: CartAdmin }, { cartUuid: Cart['uuid'] }>({
        query: ({ cartUuid }) => {
          return {
            url: `/zeus/cart/${cartUuid}/refund-risk-premium/`,
            method: 'PATCH',
          };
        },
      }),
      registeredStudentProfiles: build.query<Paginated<StudentPipelineRegistration>, SearchParams>({
        query: (params) => {
          return {
            url: '/zeus/student-pipeline/registrations/',
            method: 'GET',
            params,
          };
        },
      }),
      getCartAdmin: build.query<CartAdmin, UUID>({
        query: (uuid) => {
          return `/zeus/cart/${uuid}/`;
        },
        providesTags: [
          {
            type: 'Cart',
          },
        ],
      }),
      addProductToCartAdmin: build.mutation<{ cart: CartAdmin }, { uuid: UUID; productId: number }>(
        {
          query: ({ uuid, productId }) => {
            return {
              url: `/zeus/cart/${uuid}/add-product-to-cart/`,
              method: 'PATCH',
              data: { productId },
            };
          },
          onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
            try {
              const { data } = await queryFulfilled;
              dispatch(
                adminApi.util.updateQueryData('getCartAdmin', uuid, (draft) => {
                  Object.assign(draft, data.cart);
                }),
              );
              dispatch(paymentApi.util.invalidateTags(['PaymentIntent']));
            } catch {
              dispatch(adminApi.util.invalidateTags(['Cart']));
            }
          },
        },
      ),
      removeProductFromCartAdmin: build.mutation<
        { cart: CartAdmin },
        { uuid: UUID; productId: number }
      >({
        query: ({ uuid, productId }) => {
          return {
            url: `/zeus/cart/${uuid}/remove-product-from-cart/`,
            method: 'PATCH',
            data: { productId },
          };
        },
        /* eslint-disable sonarjs/no-identical-functions */
        onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              adminApi.util.updateQueryData('getCartAdmin', uuid, (draft) => {
                Object.assign(draft, data.cart);
              }),
            );
            dispatch(paymentApi.util.invalidateTags(['PaymentIntent']));
          } catch {
            dispatch(adminApi.util.invalidateTags(['Cart']));
          }
        },
      }),
      getScriptNames: build.query<string[], void>({
        query: () => {
          return { url: '/zeus/script/list/', method: 'get' };
        },
      }),
      runsScript: build.mutation<void, { scriptName: string; params: string[] }>({
        query: ({ scriptName, params }) => {
          return {
            url: `/zeus/script/run/${scriptName}/`,
            method: 'POST',
            data: { params },
          };
        },
      }),
      getScriptLogs: build.query<Paginated<ScriptLog>, { pageSize?: number }>({
        query: ({ pageSize }) => {
          return {
            url: `/zeus/script/logs/`,
            method: 'GET',
            params: { page_size: pageSize },
          };
        },
      }),
      getLeads: build.query<Paginated<LeadListItem>, SearchParams>({
        query: (params) => {
          return { url: '/zeus/leads/', method: 'get', params: params };
        },
        providesTags: ['Lead'],
      }),
      getSingleLead: build.query<Lead, { leadId: LeadId }>({
        query: ({ leadId }) => {
          return { url: `/zeus/leads/${leadId}/`, method: 'get' };
        },
        providesTags: [
          {
            type: 'Lead',
          },
        ],
      }),
      getAllLeadCommunications: build.query<Paginated<LeadCommunication>, { leadId: LeadId }>({
        query: ({ leadId }) => {
          return {
            url: `/zeus/leads/${leadId}/communications/`,
            method: 'get',
          };
        },
      }),
      leadDataForApplicationCompleted: build.query<
        {
          studentProfileId: StudentProfileId;
          studentApplicationId: StudentApplicationId;
          applicationCompletedAt: DateTime;
          admissionsReviewStatus: AdmissionsReviewStatusType;
        },
        { leadId: LeadId }
      >({
        query: ({ leadId }) => {
          return {
            url: `/zeus/leads/${leadId}/dynamic-sections/application-completed/`,
            method: 'get',
          };
        },
      }),
      leadDataForApplicationAccepted: build.query<
        {
          acceptedAt: DateTime;
          disableAutoClose: boolean;
          daysUntilAutoClose: Nullable<number>;
          mentorRequestId: MentorRequestId;
          paymentIntentUuid: UUID;
          studentProfileId: StudentProfileId;
        },
        { leadId: LeadId }
      >({
        query: ({ leadId }) => {
          return {
            url: `/zeus/leads/${leadId}/dynamic-sections/application-accepted/`,
            method: 'get',
          };
        },
      }),
      getRematchStatistics: build.query<
        CamelCasedPropertiesDeep<{
          core_project_rematches: [string, number][];
          pss_rematches: [string, number][];
        }>,
        { aggregation: string; days: number }
      >({
        query: (params) => {
          return { url: '/analytics/rematch-statistics/', method: 'get', params: params };
        },
      }),
      studentNotes: build.query<
        Note[],
        {
          studentProfileId: StudentProfileId;
          types?: NoteType[];
          ordering?: NoteOrdering;
        }
      >({
        query: ({ studentProfileId, types, ordering }) => {
          return {
            url: `/analytics/students/${studentProfileId}/notes/`,
            method: 'get',
            params: {
              types,
              ordering,
            },
          };
        },
        providesTags: (_, __, { studentProfileId }) => {
          return [{ type: 'StudentNote', id: studentProfileId }] as const;
        },
      }),
      createStudentNote: build.mutation<
        Note,
        { studentProfileId: StudentProfileId; note: string; type: NoteType; source: string }
      >({
        query: ({ studentProfileId, note, type, source }) => {
          return {
            url: `/analytics/students/${studentProfileId}/notes/`,
            method: 'post',
            data: { note, type, source },
          };
        },
        invalidatesTags: (_, __, { studentProfileId }) => {
          return [{ type: 'StudentNote', id: studentProfileId }] as const;
        },
      }),
      admissionAdvisorGoals: build.query<
        CamelCasedPropertiesDeep<{
          estimated_pipeline_value: number;
          monthly_revenue_target: number;
          monthly_revenue_achieved: number;
        }>,
        { userId: UserId; params: { monthlyRevenueDateFilter?: Nullable<string> } }
      >({
        query: ({ userId, params }) => {
          return {
            url: `/zeus/lead-priority-system/${userId}/revenue/`,
            method: 'get',
            params,
          };
        },
        providesTags: ['RevenueDetail'],
      }),
      getMonthlyRevenueDetails: build.query<
        Paginated<SalesCommission>,
        { monthlyRevenueDateFilter: string } & SearchParams
      >({
        query: (params) => {
          return {
            url: '/zeus/lead-priority-system/sales-commissions/',
            method: 'get',
            params,
          };
        },
        providesTags: ['RevenueDetail'],
      }),
      admissionAdvisorGoalsTotal: build.query<
        CamelCasedPropertiesDeep<{
          estimated_pipeline_value: number;
          monthly_revenue_target: number;
          monthly_revenue_achieved: number;
        }>,
        { monthlyRevenueDateFilter?: Nullable<string> }
      >({
        query: (params) => {
          return {
            url: '/zeus/lead-priority-system/total-revenue/',
            method: 'get',
            params,
          };
        },
        providesTags: ['RevenueDetail'],
      }),
      admissionAdvisorMonthlyRevenueDetails: build.query<
        CamelCasedPropertiesDeep<{
          monthly_sales_commissions: SalesCommission[];
          admission_advisor_name: string;
        }>,
        { userId: UserId; params: { monthlyRevenueDateFilter: Nullable<string> } }
      >({
        query: ({ userId, params }) => {
          return {
            url: `/zeus/lead-priority-system/${userId}/revenue/details/`,
            method: 'get',
            params,
          };
        },
        providesTags: ['RevenueDetail'],
      }),
      createCustomCart: build.mutation<
        CamelCasedPropertiesDeep<{
          cart: CartAdmin;
          paymentIntent: PaymentIntentReadOnlyAdmin;
        }>,
        {
          products: ProductWithQuantity[];
          studentProfileId: StudentProfileId;
          paymentIntentOptions: { featureTypeChangeByUser: boolean };
          partnerCompanyId: Nullable<PartnerCompanyId>;
          counselorProfileId: Nullable<CounselorProfileId>;
          tokenWorkflowEnabled: boolean;
        }
      >({
        query: (data) => {
          return {
            url: `/zeus/cart/create-custom/`,
            method: 'POST',
            data: data,
          };
        },
      }),
      createProspectiveCounselor: build.mutation<
        CamelCasedPropertiesDeep<EmptyResponse | { data: { error: string } }>,
        {
          usherer: number;
          firstName: string;
          lastName: string;
          phoneNumber: string;
          email: string;
          userType: string;
          partnerCompany: string;
          channel: string;
          companyName: string;
          hasGlobalVisibility: boolean;
        }
      >({
        query: (data) => {
          return {
            url: `/zeus/users/create-prospective-counselor/`,
            method: 'POST',
            data: data,
          };
        },
        invalidatesTags: [
          {
            type: 'ProspectiveCounselor',
          },
        ],
      }),
      prospectiveCounselors: build.query<Paginated<ProspectiveCounselor>, SearchParams>({
        query: (params) => {
          return {
            url: `/zeus/prospective-counselors/`,
            method: 'GET',
            params: params,
          };
        },
        providesTags: [
          {
            type: 'ProspectiveCounselor',
          },
        ],
      }),
      updateProspectiveCounselor: build.mutation<
        CamelCasedPropertiesDeep<{
          success: boolean;
          message: string;
        }>,
        ProspectiveUserId
      >({
        query: (prospectiveUserId) => {
          return {
            url: `/zeus/prospective-counselor/${prospectiveUserId}/`,
            method: 'PATCH',
          };
        },
      }),
      updateCounselorDiscounts: build.mutation<
        CounselorDiscountUpdateSerializer,
        { counselorProfileId: CounselorProfileId; payload: CounselorDiscountUpdateSerializer }
      >({
        query: ({ counselorProfileId, payload }) => {
          return {
            url: `/zeus/counselors/${counselorProfileId}/discounts/`,
            method: 'patch',
            data: payload,
          };
        },
      }),
      counselors: build.query<
        Paginated<CounselorProfile>,
        {
          owner__is_verified?: boolean;
          partner_company__tag?: string;
          partner_company__company_name?: string;
          profile_approved?: boolean;
          profile_complete?: boolean;
        } & SearchParams
      >({
        query: (params) => {
          return {
            url: '/zeus/counselors/',
            method: 'get',
            params,
          };
        },
        providesTags: ['CounselorProfile'],
      }),
      counselorReview: build.mutation<
        EmptyResponse,
        {
          counselorId: CounselorProfileId;
          action: 'approve' | 'decline';
          partnerCompanyId: PartnerCompanyId | null;
        }
      >({
        query: ({ counselorId, ...data }) => {
          return {
            url: `/zeus/counselors/${counselorId}/review/`,
            method: 'post',
            data,
          };
        },
        invalidatesTags: (_, __, { counselorId }) => {
          return [{ type: 'CounselorProfile', counselorProfileId: counselorId }] as const;
        },
      }),
      getCounselor: build.query<CounselorOverview, CounselorProfileId>({
        query: (counselorProfileId) => {
          return {
            url: `/zeus/counselors/${counselorProfileId}/`,
            method: 'get',
          };
        },
        providesTags: (_, __, counselorProfileId) => {
          return [{ type: 'CounselorProfile', counselorProfileId: counselorProfileId }] as const;
        },
      }),
      updateCounselor: build.mutation<
        CounselorOverviewSerializer,
        {
          counselorProfileId: CounselorProfileId;
          payload: Partial<CounselorOverview>;
        }
      >({
        query: ({ counselorProfileId, payload }) => {
          return {
            url: `/zeus/counselors/${counselorProfileId}/`,
            method: 'patch',
            data: payload,
          };
        },
        invalidatesTags: (_, __, counselorProfileId) => {
          return [{ type: 'CounselorProfile', counselorProfileId: counselorProfileId }] as const;
        },
      }),
      bulkUploadSymposiumPresenters: build.mutation<SymposiumPresentersBulkUploadValues, null>({
        query: () => {
          return {
            url: `/zeus/latest-symposium/bulk-upload-presenters/`,
            method: 'PATCH',
          };
        },
      }),
      feedbackRequestAction: build.mutation<
        { feedbackReviewer: FeedbackReviewer },
        { feedbackRequestId: FeedbackRequestId; action: string; reviewerId?: UserId }
      >({
        query: ({ feedbackRequestId, action, reviewerId }) => {
          const payload: { reviewerId?: UserId } = {};
          if (reviewerId) {
            // eslint-disable-next-line fp/no-mutation
            payload['reviewerId'] = reviewerId;
          }

          return {
            url: `/zeus/feedback-requests/${feedbackRequestId}/${action}/`,
            method: 'POST',
            data: payload,
          };
        },
        invalidatesTags: ['FeedbackRequests'],
      }),
      deleteProspectiveCounselor: build.mutation<EmptyResponse, ProspectiveUserId>({
        query: (prospectiveUserId) => {
          return {
            url: `/zeus/prospective-counselor/${prospectiveUserId}/`,
            method: 'DELETE',
          };
        },
        invalidatesTags: [
          {
            type: 'ProspectiveCounselor',
          },
        ],
      }),
      cartsByUser: build.query<Cart[], UserId>({
        query: (userId) => {
          return {
            url: `/zeus/carts/${userId}/`,
            method: 'GET',
          };
        },
      }),
      snoozeLead: build.mutation<
        { snoozedUntil: Nullable<DateTime> },
        { leadId: LeadId; payload: { number_of_days: Nullable<number> } }
      >({
        query: ({ leadId, payload }) => {
          return {
            url: `/zeus/leads/${leadId}/snooze/`,
            method: 'POST',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'Lead',
          },
        ],
      }),
      markLeadLost: build.mutation<EmptyResponse, LeadId>({
        query: (leadId) => {
          return {
            url: `/zeus/leads/${leadId}/mark-lost/`,
            method: 'PUT',
          };
        },
        invalidatesTags: [
          {
            type: 'Lead',
          },
        ],
      }),
      getAdminActiveProjects: build.query<Paginated<AdminProject>, SearchParams>({
        query: (params) => {
          return {
            url: '/zeus/active-projects/',
            method: 'GET',
            params: params,
          };
        },
      }),
      updateSalesCommission: build.mutation<
        SalesCommission,
        {
          salesCommissionId: SalesCommissionId;
          payload: Pick<SalesCommission, 'excludedFromCommission' | 'excludedFromCommissionReason'>;
        }
      >({
        query: ({ salesCommissionId, payload }) => {
          return {
            url: `/zeus/lead-priority-system/sales-commission/${salesCommissionId}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'RevenueDetail',
          },
        ],
      }),
      getInstitutionListByLabel: build.query<
        { results: Institution[] },
        { search: string; limit: number }
      >({
        query: (params) => {
          return {
            url: '/zeus/institutions/',
            method: 'GET',
            params: params,
          };
        },
      }),
      migrateInstitution: build.mutation<
        { message: string },
        {
          from: number;
          to: number;
        }
      >({
        query: (params) => {
          return {
            url: `/zeus/institutions/`,
            method: 'PATCH',
            params: params,
          };
        },
      }),
      deleteInstitution: build.mutation<
        { message: string },
        {
          id: number;
        }
      >({
        query: (params) => {
          return {
            url: `/zeus/institutions/`,
            method: 'DELETE',
            params: params,
          };
        },
      }),
      updateUciGatiApplicationAdmin: build.mutation<
        UciGatiApplication,
        { uuid: UUID; payload: Partial<Omit<UciGatiApplication, 'uuid'>> }
      >({
        query: ({ uuid, payload }) => {
          return {
            url: `/zeus/uci-gati-application/${uuid}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: [
          {
            type: 'UciGatiApplication',
          },
        ],
      }),
      enrollUciGatiApplication: build.mutation<UciGatiApplication, { uuid: UUID }>({
        query: ({ uuid }) => ({
          url: `/zeus/uci-gati-application/${uuid}/enroll/`,
          method: 'POST',
        }),
        invalidatesTags: [
          {
            type: 'UciGatiApplication',
          },
        ],
      }),
      uciGatiApplications: build.query<
        Paginated<UciGatiApplication>,
        UciGatiApplicationSearchParams
      >({
        query: (params) => {
          return {
            url: `/zeus/uci-gati-applications/`,
            method: 'GET',
            params: params,
          };
        },
        providesTags: [{ type: 'UciGatiApplication' }],
      }),
      uciGatiApplicationsWithCountry: build.query<
        Paginated<UciGatiApplicationWithCountry>,
        UciGatiApplicationSearchParams
      >({
        query: (params) => {
          return {
            url: `/zeus/uci-gati-application-with-country/`,
            method: 'GET',
            params: params,
          };
        },
        providesTags: [{ type: 'UciGatiApplication' }],
      }),
      updateCaseStudy: build.mutation<
        CaseStudyUpdate,
        { id: CaseStudyId; payload: Partial<CaseStudyUpdate> }
      >({
        query: ({ id, payload }) => {
          return {
            url: `/zeus/case-studies/${id}/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      rejectCaseStudy: build.mutation<
        { message: string },
        { id: CaseStudyId; payload: { reason: string | undefined } }
      >({
        query: ({ id, payload }) => {
          return {
            url: `/zeus/case-studies/${id}/reject/`,
            method: 'PATCH',
            data: payload,
          };
        },
      }),
      patchProject: build.mutation<
        ProjectOverview,
        { id: ProjectId; data: Pick<ProjectOverview, 'pausedUntil'> }
      >({
        query: ({ id, data }) => {
          return {
            url: `/zeus/projects/${id}/`,
            method: 'PATCH',
            data,
          };
        },
      }),
      reactivateTerminatedProject: build.mutation<EmptyResponse, ProjectId>({
        query: (projectId) => {
          return {
            url: `/zeus/projects/${projectId}/reactivate/`,
            method: 'POST',
          };
        },
      }),
      completeProject: build.mutation<EmptyResponse, ProjectId>({
        query: (projectId) => {
          return {
            url: `/zeus/projects/${projectId}/complete/`,
            method: 'POST',
          };
        },
      }),
      terminateProject: build.mutation<
        EmptyResponse,
        { projectId: ProjectId; sendMentorFinalSurvey: boolean }
      >({
        query: ({ projectId, sendMentorFinalSurvey }) => {
          return {
            url: `/zeus/projects/${projectId}/terminate/`,
            method: 'POST',
            data: {
              sendMentorFinalSurvey,
            },
          };
        },
      }),
      researchPapers: build.query<Paginated<ResearchPaperAdmin>, SearchParams>({
        query: (params) => ({
          url: '/zeus/research-papers/',
          method: 'GET',
          params: params,
        }),
        providesTags: ['ResearchPaper'],
      }),
      updateResearchPaper: build.mutation<
        ResearchPaperAdmin,
        { id: ResearchPaperId; payload: Partial<ResearchPaperAdmin> }
      >({
        query: ({ id, payload }) => {
          return {
            url: `/zeus/research-papers/${id}/`,
            method: 'PATCH',
            data: payload,
          };
        },
        invalidatesTags: ['ResearchPaper'],
      }),
      studentOverview: build.query<StudentDetailsAdmin, { studentProfileId: StudentProfileId }>({
        query: ({ studentProfileId }) => ({
          url: `/analytics/students/${studentProfileId}/`,
          method: 'GET',
        }),
        providesTags: ['StudentOverview'],
      }),
    };
  },
  overrideExisting: false,
});

export const {
  useGetStudentProfileQuery,
  useLazyStudentNotesQuery,
  useCreateStudentNoteMutation,
  useGetPaymentIntentQuery,
  useGetPaymentIntentsQuery,
  useConvertPaymentIntentSubscriptionToOneTimeMutation,
  useCloseAndMarkPaidMutation,
  useCancelPaymentIntentMutation,
  useReopenPaymentIntentMutation,
  useSendPaymentIntentToStudentMutation,
  useTriggerRevenueShareMutation,
  useCreateRevenueSharePaymentRequestMutation,
  useUsherersQuery,
  useUpdateUshererMutation,
  useChannelsQuery,
  useUpdateChannelMutation,
  useAdmissionAdvisorsQuery,
  usePartnershipManagersQuery,
  useOptionsQuery,
  usePartnerChannelsQuery,
  usePartnerUsherersQuery,
  useAdminRolesQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useLazyGetUserQuery,
  useShowcasingSpecialistsQuery,
  usePremiumShowcasingSupportMatchingRequestQuery,
  useGetProposableMentorForWritingFeedbackQuery,
  useGetProposableMentorsForPremiumShowcasingSupportMatchingRequestQuery,
  useSilentRoomUserReportsQuery,
  useLazyMentorsQuery,
  useEnableFeatureForUserMutation,
  useMentorRequestsQuery,
  useMentorSubjectTagsAndKeywordsQuery,
  useUpdateMentorRequestMutation,
  useLazyGetMentorsQuery,
  useStudentPipelineApplicationsQuery,
  useLazyKeywordsQuery,
  useConfigurationsQuery,
  useDiscountsQuery,
  useUpdateConfigurationMutation,
  useAdmissionAdvisorModalsQuery,
  useUpdateAdmissionAdvisorModalMutation,
  useMassAssignAdmissionAdvisorModalMutation,
  useCartManualAdjustmentMutation,
  useRemoveCartManualAdjustmentMutation,
  useRegisteredStudentProfilesQuery,
  useGetCartAdminQuery,
  useAddProductToCartAdminMutation,
  useRemoveProductFromCartAdminMutation,
  useGetScriptLogsQuery,
  useGetScriptNamesQuery,
  useRunsScriptMutation,
  useGetLeadsQuery,
  useGetSingleLeadQuery,
  useLeadDataForApplicationCompletedQuery,
  useLeadDataForApplicationAcceptedQuery,
  useGetRematchStatisticsQuery,
  useAdmissionAdvisorGoalsQuery,
  useAdmissionAdvisorGoalsTotalQuery,
  useAdmissionAdvisorMonthlyRevenueDetailsQuery,
  useCreateCustomCartMutation,
  useCreateProspectiveCounselorMutation,
  useProspectiveCounselorsQuery,
  useUpdateProspectiveCounselorMutation,
  useUpdatePremiumShowcasingSupportMatchingRequestMutation,
  useUpdatePremiumShowcasingSupportMatchingRequestTagMutation,
  useCancelPremiumShowcasingSupportMatchingRequestMutation,
  useBulkUploadSymposiumPresentersMutation,
  useFeedbackRequestActionMutation,
  useDeleteProspectiveCounselorMutation,
  useUpdateFeedbackRequestTagMutation,
  useLazyCartsByUserQuery,
  useFeedbackRequestsQuery,
  useSnoozeLeadMutation,
  useMarkLeadLostMutation,
  useUpdateCounselorDiscountsMutation,
  useUpdateCounselorMutation,
  useLazyCounselorsQuery,
  useCounselorsQuery,
  useCounselorReviewMutation,
  useGetCounselorQuery,
  useUpdateSalesCommissionMutation,
  useGetMonthlyRevenueDetailsQuery,
  useLazyGetInstitutionListByLabelQuery,
  useMigrateInstitutionMutation,
  useDeleteInstitutionMutation,
  useLazyPolyPilotMentorsQuery,
  useGetAdminActiveProjectsQuery,
  useUciGatiApplicationsQuery,
  useUciGatiApplicationsWithCountryQuery,
  useUpdateUciGatiApplicationAdminMutation,
  useEnrollUciGatiApplicationMutation,
  useUpdateCaseStudyMutation,
  useRejectCaseStudyMutation,
  usePatchProjectMutation,
  useReactivateTerminatedProjectMutation,
  useCompleteProjectMutation,
  useTerminateProjectMutation,
  useResearchPapersQuery,
  useGetAllLeadCommunicationsQuery,
  useUpdateResearchPaperMutation,
  useRefundRiskPremiumMutation,
  useStudentOverviewQuery,
} = adminApi;
