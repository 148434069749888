import { RevenueSharePaymentRequestPartnerDashboard } from '@polygence/common';
import { dayjs, SelectOption } from '@polygence/common';
import { Select } from '@polygence/components';
import { useState } from 'react';
import { useParams } from 'react-router';
import { StringParam } from 'use-query-params';

import {
  FilterableRtkPaginator,
  FilterComponentProps,
} from 'src/components/admin/components/aux/FilterableRtkPaginator';
import { RtkPaginator } from 'src/components/admin/components/aux/RtkPaginator';
import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import { DateFormat } from 'src/components/aux/dateWrapper';
import { DatePickerWithLabel } from 'src/components/common/DatePickerWithLabel';
import { ProfilePicture } from 'src/components/common/ProfilePicture/ProfilePicture';
import { UserCardAction } from 'src/components/common/UserCard/UserCardAction';
import { UserCardTrigger } from 'src/components/common/UserCard/UserCardTrigger';
import { useQueryParamsForFilter } from 'src/hooks/useQueryParamsForFilter';
import { useRevenueSharePaymentRequestsQuery } from 'src/reducers/partnerDashboardReducers';
import { urls } from 'src/urls';

const orderingOptions: SelectOption[] = [
  { value: 'created_at', label: '⬆Date' },
  { value: '-created_at', label: '⬇Date' },
];

const statusOptions: SelectOption[] = [
  { value: 'requested', label: 'Requested' },
  { value: 'pending', label: 'Pending' },
  { value: 'success', label: 'Success' },
  { value: 'failed', label: 'Failed' },
  { value: 'manual', label: 'Needs manual payment' },
];

const Header = () => {
  return (
    <tr>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Student
      </th>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Student Paid
      </th>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Revenue Share
      </th>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Created At
      </th>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Completion Time
      </th>
      <th
        scope="col"
        className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wider tw-text-gray-500"
      >
        Status
      </th>
    </tr>
  );
};

const RevenueSharePaymentRequest = ({
  result,
}: {
  result: RevenueSharePaymentRequestPartnerDashboard;
}) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'success':
        return 'tw-bg-green-100 tw-text-green-800';
      case 'failed':
        return 'tw-bg-red-100 tw-text-red-800';
      case 'pending':
      case 'manual':
        return 'tw-bg-yellow-100 tw-text-yellow-800';
      default:
        return 'tw-bg-gray-100 tw-text-gray-800';
    }
  };

  const getCompletedAt = () => {
    if (result.successfulAt) {
      return formatDateAsLocal(result.successfulAt);
    }
    if (result.failedAt) {
      return formatDateAsLocal(result.failedAt);
    }
    return '-';
  };

  return (
    <tr className="hover:tw-bg-gray-50">
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4">
        <UserCardTrigger
          userId={result.student.ownerId}
          actions={
            <UserCardAction
              url={urls.counselorStudentOverview(result.student.id)}
              label="Open overview"
              variant="link"
              action={undefined}
              openInNewTab
            />
          }
        >
          <div className="tw-flex tw-items-center">
            <div className="tw-h-10 tw-w-10 tw-flex-shrink-0">
              <ProfilePicture user={result.student} size={40} />
            </div>
            <div className="tw-ml-4">
              <div className="tw-text-sm tw-font-medium tw-text-gray-900">
                {result.student.firstName} {result.student.lastName}
              </div>
            </div>
          </div>
        </UserCardTrigger>
      </td>
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-text-sm tw-text-gray-500">
        ${result.incomingAmountInCents / 100}
      </td>
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-text-lg tw-text-gray-900">
        ${result.amountToPayInCent / 100}
      </td>
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-text-sm tw-text-gray-500">
        {formatDateAsLocal(result.createdAt)}
      </td>
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4 tw-text-sm tw-text-gray-500">
        {getCompletedAt()}
      </td>
      <td className="tw-whitespace-nowrap tw-px-6 tw-py-4">
        <span
          className={`tw-inline-flex tw-rounded-full tw-px-2 tw-text-xs tw-font-semibold tw-leading-5 ${getStatusColor(result.status)}`}
        >
          {result.status.charAt(0).toUpperCase() + result.status.slice(1)}
        </span>
      </td>
    </tr>
  );
};

const RevenueSharePaymentRequestsFilter = ({ filters, setFilters }: FilterComponentProps) => {
  const { query, setFiltersInQuery } = useQueryParamsForFilter(
    {
      status: StringParam,
      ordering: StringParam,
      created_at__gt: StringParam,
      created_at__lt: StringParam,
    },
    setFilters,
  );

  const [ordering, setOrdering] = useState<SelectOption | undefined>(() => {
    if (orderingOptions[0]) {
      setFiltersInQuery({ ...filters, ordering: orderingOptions[0].value });
    }
    return orderingOptions[0];
  });

  const [status, setStatus] = useState<SelectOption | undefined>(
    statusOptions.find((option) => option.value === query['status']),
  );

  const [createdAtGt, setCreatedAtGt] = useState<string | null | undefined>(
    query['created_at__gt'] as string | undefined,
  );
  const [createdAtLt, setCreatedAtLt] = useState<string | null | undefined>(
    query['created_at__lt'] as string | undefined,
  );

  return (
    <div className="w-100 tw-grid tw-grid-cols-3 tw-items-center tw-gap-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5">
      <Select
        name="ordering"
        label="Sort by"
        options={orderingOptions}
        onChange={(selectedOption) => {
          setOrdering(selectedOption as SelectOption);
          setFiltersInQuery({
            ...filters,
            ordering: (selectedOption?.value ?? null) as string | null,
          });
        }}
        value={ordering}
        size="small"
      />
      <Select
        name="status"
        label="Status"
        isClearable
        value={status}
        onChange={(selectedOption) => {
          setStatus(selectedOption as SelectOption);
          setFiltersInQuery({
            ...filters,
            status: (selectedOption?.value ?? null) as string | null,
          });
        }}
        options={statusOptions}
        size="small"
      />
      <DatePickerWithLabel
        id="createdAtGt"
        name="createdAtGt"
        label="Date After"
        labelClassName="tw-mb-2 tw-font-light"
        inputClassName="form-control form-control-md tw-rounded-full tw-py-2 tw-px-3 tw-text-md"
        className="form-group tw-mt-0"
        value={createdAtGt}
        onChange={({ target: { value } }) => {
          setCreatedAtGt(value);
          setFiltersInQuery({
            ...filters,
            created_at__gt: value ? dayjs(value).format(DateFormat.FORMAT_3) : null,
          });
        }}
        placeholder="No value set"
        minDate={null}
      />
      <DatePickerWithLabel
        id="createdAtLt"
        name="createdAtLt"
        label="Date Before"
        labelClassName="tw-mb-2 tw-font-light"
        inputClassName="form-control form-control-md tw-rounded-full tw-py-2 tw-px-3 tw-text-md"
        className="form-group tw-mt-0"
        value={createdAtLt}
        onChange={({ target: { value } }) => {
          setCreatedAtLt(value);
          setFiltersInQuery({
            ...filters,
            created_at__lt: value ? dayjs(value).format(DateFormat.FORMAT_3) : null,
          });
        }}
        placeholder="No value set"
        minDate={null}
      />
    </div>
  );
};

export const RevenueSharePaymentRequests = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="tw-mx-5 tw-max-w-7xl tw-rounded-lg tw-bg-white tw-px-4 tw-shadow sm:tw-px-6 lg:tw-px-8">
      <FilterableRtkPaginator
        title="Revenue Share Payment Requests"
        FilterComponent={RevenueSharePaymentRequestsFilter}
      >
        <RtkPaginator
          query={useRevenueSharePaymentRequestsQuery}
          paginationArgs={{ partnerCompanyId: id }}
        >
          {(queryResult) => {
            return (
              <div className="tw-overflow-x-auto">
                <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
                  <thead className="tw-bg-gray-50">
                    <Header />
                  </thead>
                  <tbody className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                    {queryResult?.currentData?.results?.map((result) => (
                      <RevenueSharePaymentRequest result={result} key={result.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            );
          }}
        </RtkPaginator>
      </FilterableRtkPaginator>
    </div>
  );
};
