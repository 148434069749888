import {
  commonHooks,
  isApplicationReadOnly,
  StudentApplication,
  StudentApplicationStatuses,
  UserType,
} from '@polygence/common';
import { useMediaQuery } from '@polygence/common/hooks/useMediaQuery';
import type { StudentProfileWithApplication } from '@polygence/common/types/marketplace';
import { Alert, Button, Icon } from '@polygence/components';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { PageLoad } from 'src/components/PageLoad';
import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import { FormSection } from 'src/components/mentor/FormSection';
import { ApplicationFieldTypes } from 'src/constants/application-field-types';
import { type ApplicationSectionId } from 'src/constants/application-sections';
import { useSubmitApplicationMutation } from 'src/reducers/whiteLabelReducer';
import styles from 'src/students/StudentApplicationForm.module.sass';
import { StudentProfileCongratulationPage } from 'src/students/StudentProfileCongratulationPage';
import {
  filterPagesBasedOnDisplay,
  singlePageWhiteLabelApplicationFields,
} from 'src/students/fieldUtils';
import MultiPageForm from 'src/students/form/MultiPageForm';
import { ApplicationComponentSection, ComponentDisplay } from 'src/students/student-application';
import 'src/students/StudentApplicationForm.sass';
import {
  isSectionCompleted,
  uniqueCompletedSections,
} from 'src/students/studentApplicationCompletedSections';
import {
  trackApplicationCompleted,
  trackApplicationInput,
  trackStudentApplicationGoToNextPage,
  trackStudentApplicationGoToPreviousPage,
} from 'src/students/studentApplicationTracking';
import { useStudentApplication } from 'src/students/useStudentApplication';
import { useStudentProfileForApplication } from 'src/students/useStudentProfileForApplication';
import {
  getApplicationInitialPageInfo,
  setCachePartially,
  validateApplicationDataBeforeSubmit,
} from 'src/students/utils';
import { urls } from 'src/urls';
import { studentApplicationLocalStorageHandler } from 'src/utils/localStorageManager';
import { studentApplicationWhiteLabelSections } from 'src/white-label/student-application/sections/white-label-student-application-sections';

interface PathParams {
  profileId: string;
  applicationId: string;
}

const ERROR_MESSAGE =
  'Something went wrong. If the issue persists after reloading the page, please contact us at students@polygence.org.';

export const WhiteLabelStudentApplicationForm = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { profileId, applicationId } = useParams<PathParams>();
  const currentUser = commonHooks.useCurrentUser();
  const {
    studentProfile,
    studentApplication,
    formData,
    submitted,
    handleChange,
    handleUpdate,
    handleSubmit,
  } = useComponentStateWithCaching({
    profileId: parseInt(profileId),
    applicationId: parseInt(applicationId),
  });

  const submitLabel = currentUser.isVerified ? 'Submit' : 'Next';
  const applicationSections = studentApplicationWhiteLabelSections;

  const handleValidationBeforeSubmit = validateApplicationDataBeforeSubmit(
    applicationSections,
    formData,
  );

  const initialPage = useMemo(() => {
    return getApplicationInitialPageInfo(
      applicationSections as ApplicationComponentSection[],
      studentProfile.completedApplicationStages as ApplicationSectionId[],
      formData,
    );
  }, [studentProfile.completedApplicationStages, formData, applicationSections]);

  useEffect(() => {
    if (!containerRef.current || !isMobile) {
      return;
    }

    containerRef.current.scrollIntoView();
  }, [isMobile, containerRef, hash]);

  if (isEmpty(studentProfile) || isEmpty(studentApplication)) {
    return <PageLoad />;
  }

  if (
    submitted ||
    (studentApplication.completedAt &&
      [StudentApplicationStatuses.COMPLETED, StudentApplicationStatuses.ACCEPTED].includes(
        // @ts-expect-error likely temporary code and supposedly fixed in higher ts version
        studentApplication.status,
      ))
  ) {
    return <StudentProfileCongratulationPage studentApplication={studentApplication} />;
  }

  if (isApplicationReadOnly(studentApplication)) {
    return <ReadOnlyStudentApplication state={formData} />;
  }

  return (
    <div ref={containerRef} className={styles['container']}>
      <MultiPageForm
        data={formData}
        handleChange={handleChange}
        pages={applicationSections}
        initialPage={initialPage.pageIndex}
        addIdsToRouteFragments
        submitLabel={submitLabel}
        onBeforeNext={handleUpdate}
        onBeforeSubmit={handleValidationBeforeSubmit}
        onSubmit={handleSubmit}
        onNext={trackStudentApplicationGoToNextPage(
          studentProfile,
          applicationId,
          studentApplication.scholarshipApplicant,
        )}
        onPrevious={trackStudentApplicationGoToPreviousPage(applicationId)}
      />
    </div>
  );
};

const ReadOnlyStudentApplication = ({
  state,
}: {
  state: Partial<StudentProfileWithApplication>;
}) => {
  const { userType, otherInfo } = commonHooks.useCurrentUser();
  const isReapplyAllowed =
    userType === UserType.STUDENT && (otherInfo['isReapplyAllowed'] as boolean);
  const sections = singlePageWhiteLabelApplicationFields();

  return (
    <div className="container">
      <Alert className="mt-5 text-center" variant="primary">
        This is a read only view of your submitted application.
        {isReapplyAllowed && (
          <Button
            startIcon={<Icon id="rotate-ccw" />}
            variant="secondary"
            className="ms-5"
            href={urls.studentReapplication()}
          >
            Reapply
          </Button>
        )}
      </Alert>
      {sections
        .filter((section: ApplicationComponentSection) =>
          filterPagesBasedOnDisplay(state)(section as { display: ComponentDisplay }),
        )
        .map(
          (
            section: { id: string; elements: any[] }, // eslint-disable-line @typescript-eslint/no-explicit-any
          ) => (
            <FormSection
              className="mx-auto"
              key={section.id}
              profile={state}
              fields={section.elements}
              onChange={() => null}
              readOnly
            />
          ),
        )}
    </div>
  );
};

const parseEventValue = (event: ChangeEvent<HTMLInputElement>) => {
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

const getSetCacheByApplicationId = setCachePartially<StudentApplication>(
  studentApplicationLocalStorageHandler,
);

const useComponentStateWithCaching = ({
  profileId,
  applicationId,
}: {
  profileId: number;
  applicationId: number;
}) => {
  const currentUser = commonHooks.useCurrentUser();
  const [submitted, setSubmitted] = useState(false);
  const isProcessingSubmit = useRef(false);

  const setCache = getSetCacheByApplicationId(applicationId);
  const [submitApplication] = useSubmitApplicationMutation();

  const {
    studentProfile,
    getStudentProfile,
    updateStudentProfileState,
    updateStudentProfileForCoreApp,
  } = useStudentProfileForApplication();

  const {
    studentApplication,
    getStudentApplication,
    updateStudentApplication,
    updateStudentApplicationState,
  } = useStudentApplication();

  const formData = useMemo(
    () => ({
      ...studentProfile,
      ...omit(studentApplication, ['id']),
      studentApplicationId: Number(applicationId),
    }),
    [studentProfile, studentApplication, applicationId],
  );

  useEffect(() => {
    getStudentProfile()
      .then(() => getStudentApplication(profileId, applicationId))
      .then(() => {
        const cachedState = studentApplicationLocalStorageHandler.get(`${applicationId}`);
        const extraFieldsForValidation = {
          ownEmail: currentUser.email,
          ownPhoneNumber: currentUser.phoneNumber,
        };
        const cachedProfile = cachedState.studentProfile ?? {};
        const cachedApplication = cachedState.application ?? {};

        updateStudentProfileState({ ...cachedProfile, ...extraFieldsForValidation });
        updateStudentApplicationState(cachedApplication);
      })
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCompletedSectionsWith = (section: ApplicationSectionId) => {
    const completedSections = uniqueCompletedSections(studentApplication.completedSections ?? []);

    if (isSectionCompleted(completedSections, section)) {
      return;
    }

    updateStudentApplicationState({
      completedSections: [
        ...completedSections,
        { section, completedAt: DateWrapper.utc().format(DateTimeFormat.FORMAT_18) },
      ],
    });
  };

  const trackSectionCompleted = (index: number, sectionId: ApplicationSectionId) => {
    trackStudentApplicationGoToNextPage(
      studentProfile,
      `${applicationId}`,
      studentApplication.scholarshipApplicant,
    )({ index }, sectionId);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    tag: (typeof ApplicationFieldTypes)[keyof typeof ApplicationFieldTypes],
    pageId: number,
  ) => {
    const eventName = event.target.name;
    const eventValue = parseEventValue(event);

    updateStudentProfileState({ [eventName]: eventValue });
    setCache({ partialProfile: { [eventName]: eventValue } });

    if (tag === ApplicationFieldTypes.APPLICATION) {
      updateStudentApplicationState({ [eventName]: eventValue });
      setCache({ partialApplication: { [eventName]: eventValue } });
    }

    trackApplicationInput(eventName, { pageId, inputValue: eventValue });
  };

  const handleUpdate = (_: { index: number }, sectionId: ApplicationSectionId) => {
    updateCompletedSectionsWith(sectionId);

    return updateStudentProfileForCoreApp(sectionId)
      .then(() => updateStudentApplication(profileId, applicationId))
      .then(() => {
        studentApplicationLocalStorageHandler.remove(`${applicationId}`);
      })
      .catch((error) => {
        toast.error(ERROR_MESSAGE);
        return Promise.reject(error);
      });
  };

  const handleSubmit = ({ index }: { index: number }, sectionId: ApplicationSectionId) => {
    if (isProcessingSubmit.current) {
      return Promise.resolve();
    }

    isProcessingSubmit.current = true;

    updateCompletedSectionsWith(sectionId);
    trackSectionCompleted(index, sectionId);

    return submitApplication({ applicationId })
      .then(() => {
        trackApplicationCompleted({
          index,
          sectionName: sectionId,
          applicationId: `${applicationId}`,
          scholarshipApplicant: studentApplication.scholarshipApplicant,
        });
        studentApplicationLocalStorageHandler.remove(`${applicationId}`);
        setSubmitted(true);
      })
      .catch((error) => {
        toast.error(ERROR_MESSAGE);
        return Promise.reject(error);
      });
  };

  return {
    studentProfile,
    studentApplication,
    formData,
    submitted,
    handleChange,
    handleUpdate,
    handleSubmit,
  };
};
