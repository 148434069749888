import { useEffect } from 'react';

export const DisableNumberInputScroll = () => {
  const handleScrollEvent = () => {
    const activeElement = document.activeElement as HTMLInputElement;
    if (activeElement?.type === 'number') {
      activeElement?.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', handleScrollEvent);
    return () => {
      document.removeEventListener('wheel', handleScrollEvent);
    };
  }, []);

  return null;
};
