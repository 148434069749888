import { commonHooks, permissions } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Text, Alert, Button } from '@polygence/components';
import React, { useEffect, useState } from 'react';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { useSelectedProjectId } from 'src/components/hermes/hooks';
import { MeetingOverview } from 'src/components/hermes/scheduler/MeetingOverview';
import { MeetingTimePicker } from 'src/components/hermes/scheduler/MeetingTimePicker';
import {
  processBusyTimeSlots,
  fetchBusyTimeSlots,
} from 'src/components/hermes/scheduler/busyHoursUtils';
import { urls } from 'src/urls';
import { clientTimezoneOffset } from 'src/utils/clientTimezoneOffest';
import {
  getTimezoneOffsetFromMinutes,
  isClientTimezoneDifferent,
} from 'src/utils/compareTimezones';
import { profileTimezoneOffset } from 'src/utils/profileTimezoneOffset';

export const MeetingEditor = ({
  meeting,
  sending,
  otherParticipants,
  handleChange,
  handleSubmit,
  proposing = false,
  recordingOptionDisabled,
  session,
  maxDate,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [enabledTimeSlots, setEnabledTimeSlots] = useState();
  const [isTimezoneWarningSkipped, setIsTimezoneWarningSkipped] = useState(false);
  const [busyTimeSlots, setBusyTimeSlots] = useState({});
  const [error, setError] = useState(false);
  const projectId = useSelectedProjectId();
  const userPermissions = commonHooks.usePermissions();
  const currentUser = useCurrentUser();
  const isTimezoneDifferent = isClientTimezoneDifferent(currentUser?.timeZone);

  const handleAvailabilityResponse = ({ data }) => {
    const firstMentor = otherParticipants.find((user) => {
      return user.userType === 'mentor';
    });
    const slotsPerDay = data[firstMentor?.id] ?? {};
    const hasTimeSlotsSet = Object.values(slotsPerDay).some((slots) => slots.length > 0);
    if (hasTimeSlotsSet) {
      setEnabledTimeSlots(slotsPerDay);
    }
  };

  const handleCalendarFreebusyResponse = ({ data }) => {
    const firstMentor = otherParticipants.find((user) => {
      return user.userType === 'mentor';
    });
    const busyTimeSlots = data['busy']?.[firstMentor?.id];
    if (busyTimeSlots) {
      setBusyTimeSlots(processBusyTimeSlots(busyTimeSlots));
    }
  };

  const handleMonthChange = (newTime) => {
    fetchBusyTimeSlots(projectId, newTime).then((response) => {
      handleCalendarFreebusyResponse(response);
    });
  };

  useEffect(() => {
    if (permissions.canScheduleAtAvailableTimes(userPermissions)) {
      Promise.all([
        hermesApi.getUserAvailabilityForProject(projectId),
        fetchBusyTimeSlots(projectId, meeting.scheduledAt),
      ])
        .then((response) => {
          handleAvailabilityResponse(response[0]);
          handleCalendarFreebusyResponse(response[1]);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- autodisabled
  }, [JSON.stringify(userPermissions), projectId]);

  if (!loaded) {
    return <SpinningWheel />;
  }

  if (error) {
    return (
      <Alert variant="danger">
        <Text size="medium">Something went wrong. Please refresh the page and try again.</Text>
      </Alert>
    );
  }

  if (isTimezoneDifferent && !isTimezoneWarningSkipped) {
    const isSkippable = currentUser.utilities.isMentor;
    const issueText = isSkippable
      ? 'Note that this can cause issues with scheduling. Make sure to double-check the scheduled time of your sessions.'
      : 'To avoid potential issues, scheduling has been disabled.';

    return (
      <div>
        <Alert variant="danger">
          <Text size="medium">
            We detected that the time zone setting of your device differs from the time zone set in
            your profile. Please update your time zone in the{' '}
            <a href={urls.settingsPage()}>settings</a> page. {issueText}{' '}
            <ul>
              <li>
                Your current timezone in your settings: GMT
                {getTimezoneOffsetFromMinutes(profileTimezoneOffset(currentUser?.timeZone))} (
                {currentUser?.timeZone})
              </li>
              <li>Detected timezone: GMT{getTimezoneOffsetFromMinutes(clientTimezoneOffset())}</li>
            </ul>
          </Text>
        </Alert>
        {isSkippable && (
          <div className="d-flex justify-content-center">
            <Button variant="primary" onClick={() => setIsTimezoneWarningSkipped(true)}>
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="d-flex gap-5 flex-column flex-md-row">
      <MeetingTimePicker
        onChange={handleChange}
        scheduledAt={meeting.scheduledAt}
        maxDate={maxDate}
        enabledTimeSlots={enabledTimeSlots}
        busyTimeSlots={busyTimeSlots}
        handleMonthChange={handleMonthChange}
      />
      <MeetingOverview
        meeting={meeting}
        otherParticipants={otherParticipants}
        handleChange={handleChange}
        disabled={sending}
        proposing={proposing}
        recordingOptionDisabled={recordingOptionDisabled}
        onSubmit={handleSubmit}
        session={session}
      />
    </div>
  );
};
