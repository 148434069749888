import { HermesAIMessageThreadId, OpenAIMessageId } from '@polygence/common';
import { useGetMessagesQuery, useReactToMessageMutation } from '@polygence/common/api/hermes-ai';
import { MessageReaction } from '@polygence/common/types/data/openai';
import { cn, Icon, Tooltip } from '@polygence/components';
import { toast } from 'react-toastify';

const TOOLTIP: Record<MessageReaction, string> = {
  'thumbs-up': 'Good response',
  'thumbs-down': 'Bad response',
};

export const ReactToMessage = ({
  messageId,
  reaction,
  threadId,
}: {
  messageId: OpenAIMessageId;
  threadId: HermesAIMessageThreadId;
  reaction: MessageReaction;
}) => {
  const [updateReaction] = useReactToMessageMutation();
  const { message } = useGetMessagesQuery(threadId ?? 0, {
    skip: !threadId,
    selectFromResult: ({ data }) => ({
      message: data?.messages.find((m) => m.id === messageId),
    }),
  });

  if (!message) {
    return null;
  }

  const isActive = message.reaction === reaction;

  const handleUpdate = () => {
    if (!message.id) {
      return;
    }

    const newReaction = isActive ? null : reaction;

    updateReaction({ messageId: message.id, reaction: newReaction, messageThreadId: threadId })
      .unwrap()
      .catch(() => {
        toast.error('Something went wrong.');
      });
  };

  return (
    <Tooltip tip={TOOLTIP[reaction]} placement="bottom">
      <button
        type="button"
        className="tw-group/thumbs-up tw-inline-flex tw-appearance-none tw-items-center tw-justify-start tw-gap-1 tw-rounded-md tw-border-none tw-bg-gray-200 tw-px-2 tw-py-1 tw-outline-none"
        onClick={handleUpdate}
      >
        <Icon
          id={reaction}
          size="xs"
          className={cn(
            'tw-text-gray-400 group-hover/thumbs-up:tw-text-gray-500',
            isActive && 'tw-text-gray-500',
          )}
          strokeWidth={3}
          {...(isActive ? { fill: 'white' } : {})}
        />
      </button>
    </Tooltip>
  );
};
