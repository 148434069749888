import { commonReducers, commonThunks } from '@polygence/common';
import { Button, Alert } from '@polygence/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationModal } from 'src/components/common/ConfirmationModal';
import DeclineProposal from 'src/components/hermes/DeclineProposal';
import { RecordingOptOutConfirmationMessage } from 'src/components/hermes/RecordingOptOutConfirmationMessage';
import { useSelectedProjectId } from 'src/components/hermes/hooks';
import { ErrorAlert } from 'src/components/hermes/scheduler/ErrorAlert';
import { RecordingOption } from 'src/components/hermes/scheduler/RecordingOption';
import { PartnerDetails } from 'src/components/hermes/sidebar/PartnerDetails';
import { dayjs } from 'src/utils/dayjsCustom';
import { WhiteLabelWrapper } from 'src/white-label/WhiteLabelWrapper';

export const MeetingOverview = ({
  meeting,
  otherParticipants,
  handleChange,
  openEditor,
  disabled,
  onSubmit,
  proposing = false,
  recordingOptionDisabled,
  session,
}) => {
  const dispatch = useDispatch();
  const projectId = useSelectedProjectId();
  const sessionId = useSelector((state) => {
    return state.hermes.scheduler.sessionId;
  });
  const isMeetingTimeValid = useSelector((state) => {
    return state.hermes.scheduler.isSelectedTimeValid;
  });
  const [proposalDeclineReason, setProposalDeclineReason] = useState('');
  const [sharingError, setSharingError] = useState(false);
  const meetingError = useSelector((state) => {
    return state.hermes.scheduler.error?.meeting;
  });
  const scheduledAt = dayjs(meeting.scheduledAt);
  const canDecline = () => {
    return meeting?.status === 'scheduled' && !meeting?.zoom?.url;
  };

  const handleSubmit = (...args) => {
    if (meeting.recordingOn && meeting.sharingOn === null) {
      setSharingError(true);
      return;
    }
    onSubmit(...args);
  };
  const handleDecline = () => {
    dispatch(
      commonThunks.hermesThunks.cancelSession({
        projectId,
        sessionId,
        declined: true,
      }),
    );
    dispatch(commonReducers.hermesActions.closeScheduler());
  };

  useEffect(() => {
    if (!meeting.recordingOn || meeting.sharingOn !== null) {
      setSharingError(false);
    }
  }, [meeting]);

  const respondingToProposal = Boolean(session?.extras?.proposedBy);
  return (
    <div>
      {proposing && (
        <Alert variant="warning">
          Proposing a new session time does not automatically change it. The session time change is
          only finalized when your mentor accepts your proposal.
        </Alert>
      )}
      <div className="h-participant-timestamps">
        <PartnerDetails
          partners={otherParticipants}
          displayedTime={meeting.scheduledAt}
          showNames
          shortFormat
        />
      </div>
      {meetingError?.scheduledAt && <ErrorAlert messages={meetingError.scheduledAt} />}
      {!proposing && (
        <WhiteLabelWrapper
          renderElement={({ resourceRecordingPolicy }) => (
            <RecordingOption
              recordingOn={meeting.recordingOn}
              sharingOn={meeting.sharingOn}
              sharingError={sharingError}
              meetingError={meetingError}
              onChange={handleChange}
              disabled={disabled}
              recordingOptionDisabled={recordingOptionDisabled}
              sessionScheduling={!!session}
              resourceRecordingPolicy={resourceRecordingPolicy}
            />
          )}
        />
      )}
      <div className="d-flex justify-content-center text-center">
        {canDecline() && !respondingToProposal && (
          <ConfirmationModal
            skipConfirmation={!projectId && !sessionId}
            trigger={
              <Button
                variant="danger"
                className="me-5"
                onClick={() => {
                  handleDecline();
                }}
              >
                Decline
              </Button>
            }
            confirmButtonText="Yes, decline"
          >
            <div>
              <span>Are you sure you want to decline this proposed session?</span>
            </div>
          </ConfirmationModal>
        )}
        {canDecline() && respondingToProposal && (
          <ConfirmationModal
            canConfirm
            confirmButtonText="Submit"
            confirmButtonVariant="primary"
            canCancel="false"
            trigger={
              <Button
                variant="danger"
                className="me-5"
                onClick={() => {
                  dispatch(
                    commonThunks.hermesThunks.cancelSession({
                      projectId,
                      sessionId,
                      proposalDeclineReason,
                      declined: true,
                    }),
                  );
                  dispatch(commonReducers.hermesActions.closeScheduler());
                }}
              >
                Decline proposal
              </Button>
            }
          >
            <DeclineProposal
              onChange={(event) => setProposalDeclineReason(event.target.value)}
              name="proposalDeclineReason"
              value={proposalDeclineReason}
            />
          </ConfirmationModal>
        )}
        <ConfirmationModal
          trigger={
            <Button
              variant="primary"
              disabled={new Date(scheduledAt) < Date.now() || !isMeetingTimeValid || disabled}
              onClick={handleSubmit}
            >
              {proposing && 'Propose session time'}
              {!proposing && respondingToProposal && 'Accept'}
              {!proposing && !respondingToProposal && 'Schedule session'}
            </Button>
          }
          skipConfirmation={meeting.recordingOn || !session}
        >
          <RecordingOptOutConfirmationMessage />
        </ConfirmationModal>
      </div>
    </div>
  );
};
