const NON_USER_MESSAGE_TYPES = [undefined, null, '', 'system', 'student_final_survey_reminder'];

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function isUserMessage(message, participants) {
  const messageType = message?.messageType;
  const sentBy = message?.sentBy;

  const isNotSystemMessage = !NON_USER_MESSAGE_TYPES.includes(messageType);

  const isSentByRoomParticipant =
    Array.isArray(participants) &&
    participants.some(({ id }) => {
      return sentBy?.id === id;
    });

  return isNotSystemMessage && isSentByRoomParticipant;
}
