import { commonHooks, HermesMobileTab } from '@polygence/common';
import { useEffect } from 'react';

import ChatEditorWrapper from 'src/components/hermes/chat/ChatEditorWrapper';
import ChatMessages from 'src/components/hermes/chat/ChatMessages';

const Room = ({ roomId }: { roomId: number }) => {
  const useChat = commonHooks.useChat(roomId);
  const activeTab = commonHooks.useMobileNavigationTab();
  const isUserOnline = commonHooks.useIsUserOnlineHermes();

  useEffect(() => {
    if (!useChat) {
      return;
    }
    if (window.matchMedia('(max-width: 767px)').matches && activeTab !== HermesMobileTab.Chat) {
      return;
    }
    if (isUserOnline) {
      useChat.sendMessagesSeen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, useChat?.room?.messages?.length, activeTab, isUserOnline]);

  if (!useChat) {
    return null;
  }
  const { room, getNextMessages, sendMessage, sendGif } = useChat;

  return (
    <div className="h-chat-container">
      {room && (
        <>
          <ChatMessages room={room} getNextMessages={getNextMessages} />
          <ChatEditorWrapper
            key={room.id}
            room={room}
            sendMessage={sendMessage}
            sendGif={sendGif}
          />
        </>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Room;
