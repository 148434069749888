import { Text } from '@polygence/components';
import classNames from 'classnames';
import { useState } from 'react';

import styles from 'src/components/Tiles/TeamMemberTile.module.sass';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

interface TeamMemberTileProps {
  imageUrl: string | undefined;
  title: string | undefined;
  role?: string | undefined;
  children: JSX.Element;
}

const TeamMemberTile = ({ children, ...props }: TeamMemberTileProps) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <div className={classNames(tileStyles['tile'], 'tw-flex tw-justify-between tw-px-8 tw-py-7')}>
      <div className="tw-flex tw-flex-col">
        <Text className="tw-mb-5" fontWeight="bold" size="large">
          {props.title}
        </Text>
        <Text size="medium">{props.role}</Text>
        <Text size="medium">{children}</Text>
      </div>
      <div className="tw-flex" id={styles['advisorImageContainer']}>
        <img
          alt="Advisor illustration"
          src={props.imageUrl}
          onLoad={() => {
            setImageLoaded(true);
          }}
          className={classNames({
            'd-none': !imageLoaded,
          })}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default TeamMemberTile;
