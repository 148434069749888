import {
  commonReducers,
  lastCompletedSession,
  isPreviousFeedbackSubmitted,
  getFeedbackReminder,
  Project,
} from '@polygence/common';
import { Button, Text } from '@polygence/components';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { SessionListReminder } from 'src/components/hermes/sessions/SessionListReminder';

interface StudentFeedbackReminderProps {
  sessionId: number;
  sessionNumber: number;
  userType: string;
  project: Project;
}

const StudentFeedbackReminder = ({
  sessionId,
  sessionNumber,
  userType,
  project,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
StudentFeedbackReminderProps) => {
  const dispatch = useDispatch();
  const handleClick = () =>
    dispatch(commonReducers.hermesActions.openSessionFeedback({ sessionId }));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- autodisabled
  const completedSession = lastCompletedSession(project, sessionNumber);
  const canSubmitFeedback = completedSession && new Date(completedSession.scheduledAt) < new Date();
  if (
    !completedSession ||
    !canSubmitFeedback ||
    completedSession.sessionNumber !== sessionNumber - 1
  ) {
    return null;
  }

  const completedSessionNumber = completedSession.sessionNumber;
  const afterCompletedSessionNumber = completedSessionNumber + 1;

  const flag = getFeedbackReminder(project, sessionNumber);
  const firstReminder =
    flag &&
    project?.surveysRequiredFor1StSessionForZoomJoin &&
    flag === 'surveysRequiredFor1StSessionForZoomJoin';

  const secondReminder =
    flag &&
    project?.surveysRequiredFor2NdSessionForZoomJoin &&
    flag === 'surveysRequiredFor2NdSessionForZoomJoin';

  if (!firstReminder && !secondReminder) {
    return null;
  }

  const congratulationText = firstReminder ? 'Congratulations on kicking off your project! ' : '';
  const completed = isPreviousFeedbackSubmitted(project, sessionNumber);

  return (
    <SessionListReminder>
      {completed ? (
        <>
          <Text size="small" fontWeight="bold">
            Student feedback has been submitted. You and your{' '}
            {userType === 'student' ? 'mentor' : 'student'} can access the next session from now on.
          </Text>
        </>
      ) : (
        <>
          <Text
            size="small"
            fontWeight="bold"
            className={classnames({
              'text-muted': userType === 'student' && !canSubmitFeedback,
            })}
          >
            {userType === 'student'
              ? `${congratulationText}Before you meet for session ${afterCompletedSessionNumber}, you need to fill out the feedback form for your previous session. You’ll not be able to join the Zoom for session ${afterCompletedSessionNumber} until your feedback has been submitted.`
              : `${congratulationText}Before you meet your student for session ${afterCompletedSessionNumber}, your student needs to fill out the feedback form for your previous session. You’ll not be able to join the Zoom for session ${afterCompletedSessionNumber} until their feedback has been submitted.`}
          </Text>
          {userType === 'student' && (
            <Button
              variant="primary"
              size="sm"
              disabled={!canSubmitFeedback}
              onClick={
                canSubmitFeedback
                  ? handleClick
                  : () => {
                      return null;
                    }
              }
            >
              Complete Session {completedSessionNumber} Feedback
            </Button>
          )}
        </>
      )}
    </SessionListReminder>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentFeedbackReminder;
