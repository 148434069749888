import { commonHooks, commonReducers, permissions } from '@polygence/common';
import { Text } from '@polygence/components';
import { useCallback, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import { BUSY_HOUR_FORMAT } from 'src/components/hermes/scheduler/busyHoursUtils';
import { dayjs, dayJsNumberToDayMap, numberToDayMap } from 'src/utils/dayjsCustom';

interface MeetingTimePickerProps {
  scheduledAt: Date;
  onChange: (arg: { target: { name: string; value: Date } }) => void;
  maxDate: Date;
  enabledTimeSlots: Record<string, string[]>;
  busyTimeSlots: Record<string, string[]>;
  handleMonthChange: (date: Date) => void;
}

export type NumberOfDays = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type NameOfDays =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

function getMidnight() {
  const midnight = new Date();
  midnight.setHours(23);
  midnight.setMinutes(59);
  return midnight;
}

export const MeetingTimePicker = ({
  scheduledAt,
  onChange,
  maxDate,
  enabledTimeSlots,
  busyTimeSlots,
  handleMonthChange,
}: MeetingTimePickerProps) => {
  const dispatch = useDispatch();
  const midnight = getMidnight();
  const todaySelected = dayjs(scheduledAt).isSame(dayjs(), 'day');
  const dayAfterMaxDate = maxDate ? dayjs(maxDate).add(1, 'day').toDate() : null;
  const showEndDateWarning =
    maxDate &&
    (dayjs(maxDate).subtract(2, 'weeks').isBefore(dayjs()) ||
      dayjs(maxDate).subtract(2, 'weeks').isBefore(dayjs(scheduledAt)));
  const numberOfDay = dayjs(scheduledAt).day();
  const selectedDay = dayJsNumberToDayMap[numberOfDay as NumberOfDays];
  const enabledTimeSlotsForSelectedDay = enabledTimeSlots && enabledTimeSlots[selectedDay];
  const showSomeTimeSlotsWarning =
    enabledTimeSlotsForSelectedDay && enabledTimeSlotsForSelectedDay.length > 0;
  const showNoTimeSlotsWarning =
    enabledTimeSlotsForSelectedDay && enabledTimeSlotsForSelectedDay.length === 0;
  const isSelectedTimeInThePast = new Date(scheduledAt) < new Date(Date.now());
  const userPermissions = commonHooks.usePermissions();
  const currentUser = commonHooks.useCurrentUser();
  const studentCanSchedule =
    currentUser.utilities.isStudent && permissions.canScheduleLimited(userPermissions);
  const limitedSchedulingAfter = dayjs().add(1, 'day').toDate();

  const filterPassedTime = (time: Date) => {
    const dayJsTime = dayjs(time);

    if (dayJsTime.isBetween(dayjs('2024-12-30 7:59 UTC'), dayjs('2024-12-30 14:00 UTC'))) {
      return false;
    }

    if (!enabledTimeSlots) {
      return true;
    }
    if (busyTimeSlots[dayjs(time).format(BUSY_HOUR_FORMAT)]) {
      return false;
    }
    const dayJsNumber = dayJsTime.day() as NumberOfDays;
    const currentDay = dayJsNumberToDayMap[dayJsNumber];
    const currentSlot = dayJsTime.format('HH:mm');
    const enabledTimeSlotsForCurrentDay = enabledTimeSlots[currentDay];
    const isValidTimeSlot =
      (enabledTimeSlotsForCurrentDay && enabledTimeSlotsForCurrentDay.includes(currentSlot)) ??
      false;
    const isSchedulingAllowedIn24h = studentCanSchedule
      ? dayJsTime.isAfter(limitedSchedulingAfter)
      : true;
    return isValidTimeSlot && isSchedulingAllowedIn24h;
  };

  const getExcludedDays = useCallback(() => {
    if (!enabledTimeSlots) {
      return [];
    }
    const excludedDays: string[] = [];
    const days: NameOfDays[] = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    days.forEach((day: NameOfDays) => {
      if (!enabledTimeSlots[day]?.length) {
        excludedDays.push(numberToDayMap[day].toString());
      }
    });
    return excludedDays;
  }, [enabledTimeSlots]);

  const excludedDates = useMemo(() => {
    const excludedDays = getExcludedDays();
    const arr = [];
    for (
      let currentDate = new Date();
      currentDate <= new Date(maxDate);
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      if (excludedDays.includes(currentDate.getDay().toString())) {
        arr.push(new Date(currentDate));
      }
    }
    return arr;
  }, [maxDate, getExcludedDays]);

  return (
    <div>
      {showEndDateWarning && (
        <div className="alert alert-warning">
          The selected date is near the project deadline. Please note that you won&apos;t be able to
          schedule the session after the project&apos;s deadline: {formatDateAsLocal(maxDate)}
        </div>
      )}
      {showSomeTimeSlotsWarning && (
        <Text className="text-muted" size="small">
          Your mentor is available to meet on this day. Only available time slots are shown (times
          when the mentor is unavailable are hidden).
        </Text>
      )}
      {showNoTimeSlotsWarning && (
        <Text className="text-danger" size="small">
          Your mentor is not available to meet on this day.
        </Text>
      )}
      <ReactDatePicker
        inline
        showTimeSelect
        minDate={new Date()}
        maxDate={dayAfterMaxDate}
        minTime={todaySelected ? new Date() : undefined}
        maxTime={todaySelected ? midnight : undefined}
        filterTime={filterPassedTime}
        timeIntervals={15}
        excludeDates={excludedDates}
        name="scheduledAt"
        selected={dayjs(scheduledAt).toDate()}
        onChange={(value) => {
          if (value) {
            dispatch(
              commonReducers.hermesActions.setSchedulerMeetingTimeValidity({
                isValid: filterPassedTime(value),
              }),
            );
          }
          return value ? onChange({ target: { name: 'scheduledAt', value } }) : null;
        }}
        onMonthChange={handleMonthChange}
        fixedHeight
      />
      <div className="text-center">
        <p className={isSelectedTimeInThePast ? 'red-text m-0' : 'invisible m-0'}>
          You cannot schedule a past date.
        </p>
      </div>
    </div>
  );
};
