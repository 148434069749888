import type { StudentProfileWithApplication } from '@polygence/common/types/marketplace';
import TimeMe from 'timeme.js';

import createInputTracker from 'src/components/aux/inputTracker';
import { ApplicationSectionId, ApplicationSectionIds } from 'src/constants/application-sections';
import { getTracker } from 'src/utils/tracking/factory';

export const trackingCategory = 'student_application_v2';

const tracker = getTracker();

const getNonScholarshipPrefix = (
  completedSections: ApplicationSectionId[],
  currentSection: ApplicationSectionId | number,
  isScholarshipApplication?: boolean,
) => {
  if (
    completedSections.includes(ApplicationSectionIds.FINANCIAL_AID_SECTION_ID) ||
    currentSection === ApplicationSectionIds.FINANCIAL_AID_SECTION_ID
  ) {
    return isScholarshipApplication ? '' : ' non-scholarship';
  }

  return '';
};

const getActionNameForSection = (
  completedSections: ApplicationSectionId[],
  currentSection: ApplicationSectionId,
  sectionName: ApplicationSectionId | number,
  isScholarshipApplication?: boolean,
) => {
  return `Section ${sectionName} Completed${getNonScholarshipPrefix(
    completedSections,
    currentSection,
    isScholarshipApplication,
  )}`;
};

const trackStudentApplicationStep = ({
  action,
  pageIndex,
  sectionName,
  applicationId,
}: {
  action: string;
  pageIndex: number;
  sectionName: string;
  applicationId: string;
}) => {
  tracker.track(action, {
    category: trackingCategory,
    value: pageIndex,
    sectionName,
    applicationId,
  });
};

const trackActiveTimeSpentOnPage = ({
  action,
  pageIndex,
  sectionName,
  applicationId,
}: {
  action: string;
  pageIndex: number;
  sectionName: string;
  applicationId: string;
}) => {
  const activeTime = Math.round(TimeMe.getTimeOnPageInSeconds(`Section-${pageIndex}`));
  tracker.track(action, {
    category: trackingCategory,
    pageIndex,
    time: activeTime,
    sectionName,
    applicationId,
  });
};

export const trackStudentApplicationGoToNextPage =
  (
    studentProfile: Partial<StudentProfileWithApplication>,
    applicationId: string,
    isScholarshipApplication: boolean | undefined,
  ) =>
  ({ index }: { index: number }, sectionName: ApplicationSectionId) => {
    const completedSections = studentProfile.completedApplicationStages as ApplicationSectionId[];
    if (!completedSections.includes(sectionName)) {
      trackStudentApplicationStep({
        action: getActionNameForSection(
          completedSections,
          sectionName,
          sectionName,
          isScholarshipApplication,
        ),
        pageIndex: index,
        sectionName,
        applicationId,
      });

      if (
        sectionName === ApplicationSectionIds.FINANCIAL_AID_SECTION_ID ||
        (!studentProfile.showScholarship && sectionName === ApplicationSectionIds.OUTPUT_SECTION_ID)
      ) {
        trackStudentApplicationStep({
          action: getActionNameForSection(
            completedSections,
            sectionName,
            6,
            isScholarshipApplication,
          ),
          pageIndex: index,
          sectionName,
          applicationId,
        });
      }
    }

    trackStudentApplicationStep({
      action: 'Go to next page',
      pageIndex: index,
      sectionName,
      applicationId,
    });

    trackActiveTimeSpentOnPage({
      action: `Active time spent On Section ${sectionName}`,
      pageIndex: index,
      sectionName,
      applicationId,
    });
  };

export const trackStudentApplicationGoToPreviousPage =
  (applicationId: string) =>
  ({ index }: { index: number }, sectionName: ApplicationSectionId) => {
    trackStudentApplicationStep({
      action: 'Go to previous page',
      pageIndex: index,
      sectionName,
      applicationId,
    });
  };

export const trackApplicationStarted = (applicationId: string) => {
  trackStudentApplicationStep({
    action: 'Application started',
    pageIndex: 0,
    sectionName: '',
    applicationId,
  });
};

export const trackApplicationReopened = (applicationId: string) => {
  trackStudentApplicationStep({
    action: 'Application reopened',
    pageIndex: 0,
    sectionName: '',
    applicationId,
  });
};

export const trackApplicationReapply = (copyPreviousApplication: boolean) =>
  tracker.track('Application reapply', {
    category: trackingCategory,
    copyPreviousApplication,
  });

export const trackApplicationCompleted = ({
  index,
  sectionName,
  scholarshipApplicant,
  applicationId,
}: {
  index: number;
  sectionName: string;
  scholarshipApplicant?: boolean;
  applicationId: string;
}) => {
  trackStudentApplicationStep({
    action: `Application completed${scholarshipApplicant ? '' : ' non-scholarship'}`,
    pageIndex: index,
    sectionName,
    applicationId,
  });
};

export const trackApplicationInput = createInputTracker(trackingCategory, 'form_field');
