import { Button, Heading, Modal, ModalBody } from '@polygence/components';
import { useState } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';

interface ConfirmMentorNoShowModalProps {
  isOpen: boolean;
  onConfirm: (comment: string) => void;
  onClose: VoidFunction;
}

export const ConfirmMentorNoShowModal = ({
  isOpen,
  onConfirm,
  onClose,
}: ConfirmMentorNoShowModalProps) => {
  const [comment, setComment] = useState('');

  return (
    <Modal show={isOpen} onHide={onClose}>
      <ModalBody>
        <Heading alignment="left" size="h4" as="h1">
          We're so sorry to hear your mentor did not arrive on time for your session. <br />
          This session will not count against your total session count.
        </Heading>
        <p className="text-muted">
          Please add any relevant details below and we'll reach out to them to find out what
          happened.
        </p>
        <FormInput
          inputType="textarea"
          type="textarea"
          name="comment"
          value={comment}
          className="tw-h-[140px]"
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="d-flex justify-content-end mt-7 gap-3">
          <Button variant="secondary" onClick={onClose}>
            Go back
          </Button>
          <Button variant="primary" onClick={() => onConfirm(comment)}>
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
