import { externalLink } from '@polygence/common';
import type { StudentJourneyLaunchpadReflection } from '@polygence/common/types/marketplace';
import { Spacer, Text, Tooltip } from '@polygence/components';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { LookingForMentorAvatar } from 'src/components/Tiles/StudentJourney/LookingForMentorAvatar';
import { StudentJourneyTileContext } from 'src/components/Tiles/StudentJourney/StudentJourneyTile';

export const UnderMatchingLaunchpadReflectionJourneyTile = ({
  data,
}: {
  data: StudentJourneyLaunchpadReflection;
}) => {
  const { showWorkspaceLinks } = useContext(StudentJourneyTileContext);

  if (!data || data.status !== 'inactive') {
    return null;
  }

  return (
    <Tooltip
      tip="After completing all 3 mentor sessions, you'll be prompted to schedule a Reflection Session."
      className={data.calendlyUrl ? 'd-none' : 'd-inline-block'}
    >
      <div className="d-flex flex-row align-items-center">
        <LookingForMentorAvatar />
        <Spacer size={3} />
        <Text alignment="center" size="small">
          Polygence Educator
        </Text>
        {!!data.calendlyUrl && showWorkspaceLinks && (
          <>
            <Spacer size={6} />
            <Button size="sm" variant="primary" as="a" href={data.calendlyUrl} {...externalLink}>
              Schedule
            </Button>
          </>
        )}
      </div>
    </Tooltip>
  );
};
