import { commonHooks } from '@polygence/common';
import * as backendApi from '@polygence/common/api/backend';

import FormWithEmailInput from 'src/components/FormWithEmailInput';
import BackButton from 'src/components/auth/BackButton';

export const PasswordResetRequestForm = () => {
  const currentUser = commonHooks.useCurrentUser();
  const isLoggedIn = currentUser.loggedIn;

  const initialText = isLoggedIn
    ? 'You can send a password reset email to your registered address'
    : 'Please enter your email address for instructions to reset your password.';

  return (
    <div className="d-flex flex-column gap-5 align-items-center">
      <FormWithEmailInput
        title={isLoggedIn ? 'Reset Password' : 'Forgot Password'}
        initialText={initialText}
        emailSentText="Please click on the link provided in the email to reset password."
        onSubmit={backendApi.passwordReset}
        initialValue={currentUser.email}
        disabled={isLoggedIn}
      />
      {!isLoggedIn && <BackButton text="Back to Login" href="/user/login" />}
    </div>
  );
};
