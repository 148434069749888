import type { EmptyResponse, UUID } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { StudentApplicationId } from '@polygence/common/types/common';
import type { Tenant } from '@polygence/common/types/white-label';
import { createApi } from '@reduxjs/toolkit/query/react';

export const whiteLabelApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'whiteLabel',
  endpoints: (build) => {
    return {
      getTenant: build.query<Tenant, Pick<Tenant, 'domain'>>({
        query: ({ domain }) => {
          return {
            url: `/morpheus/tenants/${domain}/`,
            method: 'GET',
          };
        },
      }),
      createStudent: build.mutation<
        EmptyResponse,
        {
          domain: string;
          payload: { firstName: string; lastName: string; email: string };
        }
      >({
        query: ({ domain, payload }) => {
          return {
            url: `/morpheus/tenants/${domain}/register-student/`,
            method: 'POST',
            data: payload,
          };
        },
      }),
      verifyAccount: build.mutation<
        { access?: string; refresh?: string },
        {
          domain: string;
          payload: { uuid: UUID; password: string; timeZone: string };
        }
      >({
        query: ({ domain, payload }) => {
          return {
            url: `/morpheus/tenants/${domain}/verify-account/`,
            method: 'POST',
            data: payload,
          };
        },
      }),
      submitApplication: build.mutation<
        EmptyResponse,
        {
          applicationId: StudentApplicationId;
        }
      >({
        query: ({ applicationId }) => {
          return {
            url: `/morpheus/complete-application/`,
            method: 'POST',
            data: {
              applicationId,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetTenantQuery,
  useCreateStudentMutation,
  useSubmitApplicationMutation,
  useVerifyAccountMutation,
} = whiteLabelApi;
