import type { DateTime } from '@polygence/common/types/common';
import type {
  Assignment,
  AssignmentSolution,
  ProjectTypes,
  Summary,
} from '@polygence/common/types/hermes';
import type { Nullable } from '@polygence/common/types/utils';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Badge, Heading, Icon, StyledCollapsible, Text } from '@polygence/components';

import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { LinkRenderer } from 'src/components/aux/markdown/markdownHelperFunctions';
import { SideModal } from 'src/components/common/SideModal';
import { AssignmentHeader } from 'src/components/hermes/assignment/AssignmentHeader';
import { PreviousSessionSummarySection } from 'src/components/hermes/sessions/submitted-session-summary/PreviousSessionSummarySection';
import { SummaryPublicQuestions } from 'src/components/hermes/sessions/submitted-session-summary/SummaryPublicQuestions';
import {
  getFilenameFromPath,
  getPositiveFeedbackBadges,
  getPositiveFeedbackNote,
  getSummaryContent,
} from 'src/components/hermes/utils';

const AssignmentSubmissions = (props: { assignmentSolution?: Nullable<AssignmentSolution> }) => {
  const { assignmentSolution } = props;
  const { submittedAt, files, links } = assignmentSolution ?? {};
  if (!submittedAt) {
    return null;
  }

  return (
    <div className="mt-4 vstack gap-3">
      <Text size="small" fontWeight="bold">
        Submissions:
      </Text>
      {files?.map(({ content, id }) => {
        return (
          <a
            className="d-flex align-items-center"
            href={content}
            {...externalLink}
            download
            key={id}
          >
            <Icon className="me-2" color="var(--h-grey-1)" id="file" />
            {getFilenameFromPath(content)}
          </a>
        );
      })}
      {links?.map((link) => {
        return (
          // eslint-disable-next-line react/jsx-key -- autodisabled
          <a className="d-flex align-items-center" href={link} {...externalLink}>
            <Icon className="me-2" color="var(--h-grey-1)" id="link" />
            {link}
          </a>
        );
      })}
    </div>
  );
};

interface PreviousSessionSummaryProps {
  trigger: React.ReactNode | React.ReactNode[];
  studentTimeZone: string | undefined;
  sessionNumber: number;
  scheduledAt?: DateTime;
  researchJournal?: string;
  summary?: Summary;
  assignments?: Assignment[];
  projectType: ProjectTypes | undefined;
}

export const PreviousSessionSummary = ({
  trigger,
  studentTimeZone,
  sessionNumber,
  scheduledAt,
  researchJournal,
  assignments,
  projectType,
  summary,
}: PreviousSessionSummaryProps) => {
  const positiveFeedbackBadges = getPositiveFeedbackBadges(summary);
  const positiveFeedbackNote = getPositiveFeedbackNote(summary);
  const summaryContent = getSummaryContent(summary);

  if (!summary) {
    return null;
  }

  return (
    <SideModal
      trigger={trigger}
      header={
        <div className="d-flex flex-column">
          <Heading as="h1" size="h3" alignment="left">
            Session {sessionNumber}
          </Heading>
          {scheduledAt && (
            <Text size="small" fontWeight="light" className="text-muted" alignment="left">
              {new DateWrapper(scheduledAt)
                .setTimezone(studentTimeZone, 'default')
                .format(DateTimeFormat.FORMAT_7)}
            </Text>
          )}
        </div>
      }
      main={
        <div data-testid="previous-session-summary-modal">
          {positiveFeedbackBadges?.length > 0 && (
            <div className="d-flex mb-5 gap-4">
              {positiveFeedbackBadges.map(({ key, label }) => (
                <Badge key={key} variant="primary">
                  ★ {label}
                </Badge>
              ))}
            </div>
          )}
          {!!positiveFeedbackNote && (
            <Text size="small" alignment="left" className="text-muted mb-5" fontWeight="light">
              {String(positiveFeedbackNote)}
            </Text>
          )}
          {assignments && assignments.length > 0 && (
            <PreviousSessionSummarySection title="Assignments">
              <div className="vstack gap-5">
                {assignments?.map((assignment) => {
                  return (
                    <StyledCollapsible
                      key={assignment.id}
                      summary={
                        <AssignmentHeader
                          title={assignment.title}
                          assignmentSolution={assignment.assignmentSolution}
                          size="medium"
                        />
                      }
                    >
                      <ReactMarkdown components={{ a: LinkRenderer }}>
                        {assignment.description}
                      </ReactMarkdown>
                      <AssignmentSubmissions assignmentSolution={assignment.assignmentSolution} />
                    </StyledCollapsible>
                  );
                })}
              </div>
            </PreviousSessionSummarySection>
          )}
          {!!summaryContent && (
            <PreviousSessionSummarySection title="Summary">
              <ReactMarkdown components={{ a: LinkRenderer }}>
                {String(summaryContent)}
              </ReactMarkdown>
            </PreviousSessionSummarySection>
          )}
          {projectType && <SummaryPublicQuestions summary={summary} projectType={projectType} />}
          {researchJournal && (
            <PreviousSessionSummarySection title="Research Journal">
              {researchJournal}
            </PreviousSessionSummarySection>
          )}
        </div>
      }
    />
  );
};
