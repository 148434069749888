import { commonHooks } from '@polygence/common';
import { Text } from '@polygence/components';
import classNames from 'classnames';

import { formatDatetimeAsLocal } from 'src/components/aux/dateStamp';
import { isMentor, isStudent } from 'src/components/getMyInfo';
import type {
  ExtraProps,
  ProposedSessionProps,
} from 'src/components/hermes/messages/ProposedSession';
import { concatenateCommasAnd } from 'src/utils';

const SessionProposalResponse = ({
  sentBy,
  extra: {
    sessionId,
    scheduledAt: scheduledAtFallback,
    sessionNumber: sessionNumberFallback,
    proposalDeclineReason,
  } = {} as ExtraProps,
}: ProposedSessionProps) => {
  const students = commonHooks.useOtherParticipants();
  const studentNames = students.flatMap((participant) =>
    isStudent(participant.userType) ? participant.firstName : [],
  );

  const project = commonHooks.useSelectedProject();
  const session = project?.sessions?.find(({ id }) => {
    return id === sessionId;
  });

  const meeting = commonHooks.useMeetingById(session?.meetingId);
  const scheduledAt = meeting?.scheduledAt || scheduledAtFallback;
  const sessionNumber = session?.sessionNumber || sessionNumberFallback;
  const sessionScheduled = session && !session.proposing;
  const sessionCanceled = !session;

  const formattedDate = formatDatetimeAsLocal(scheduledAt);

  if (!(scheduledAt && sessionNumber)) {
    return null;
  }

  const getStudentMessage = () => {
    if (!isStudent()) {
      return null;
    }
    if (sessionScheduled) {
      return `Your mentor has scheduled the session at a new time and it is now officially scheduled at ${formattedDate}. If this does not work for you, please work with your mentor to reschedule.`;
    }
    if (sessionCanceled) {
      const message = `${sentBy.firstName} says your proposed time for Session ${sessionNumber} does not work. Work with them to find another time to meet.`;
      if (proposalDeclineReason) {
        return `${message}\n\nYour mentor has declined your proposal with this note: ${proposalDeclineReason}`;
      }
      return message;
    }
    return `Session ${sessionNumber} scheduled at ${formattedDate} has been cancelled.`;
  };

  const getMentorMessage = () => {
    if (!isMentor()) {
      return null;
    }
    if (sessionScheduled) {
      return `Thanks for scheduling Session ${sessionNumber} with ${concatenateCommasAnd(
        studentNames,
      )}. The session is now officially scheduled at ${formattedDate}.`;
    }
    if (sessionCanceled) {
      return `We let your student know that the proposed time does not work. Please work with your student to find a new date/time for Session ${sessionNumber}.`;
    }
    return `Session ${sessionNumber} scheduled at ${formattedDate} has been cancelled.`;
  };

  return (
    <div data-cy="session-proposal-response" className={classNames('h-proposed-session-message')}>
      <Text size="small" fontWeight="bold">
        {getStudentMessage()}
        {getMentorMessage()}
      </Text>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SessionProposalResponse;
