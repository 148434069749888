import { commonReducers, UserType, commonHooks } from '@polygence/common';
import { Button } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import { EXTENSION_REQUEST_ACTIONS } from 'src/components/hermes/constants';
import { useExtensionRequestAction, useLastExtensionRequest } from 'src/components/hermes/hooks';
import MultiLineText from 'src/components/hermes/messages/MultiLineText';

interface ExtensionRequestProps {
  content: string;
  extra: {
    originalProjectDeadline: string;
    requestedProjectDeadline: string;
    extendedDeadlineBenefit: string;
    extendedDeadlineActions: string;
    extensionRequestId: number;
  };
}

const ExtensionRequest: React.FC<ExtensionRequestProps> = ({
  content,
  extra: {
    originalProjectDeadline = '',
    requestedProjectDeadline = '',
    extendedDeadlineBenefit = '',
    extendedDeadlineActions = '',
    extensionRequestId = '',
  } = {},
}) => {
  const dispatch = useDispatch();
  const lastExtensionRequest = useLastExtensionRequest();
  const extensionRequestAction = useExtensionRequestAction();
  const isLatestExtensionRequest = extensionRequestId === lastExtensionRequest?.id;
  const extensionRequest = commonHooks.useExtensionRequestById(Number(extensionRequestId));
  const isResponding =
    extensionRequestAction === EXTENSION_REQUEST_ACTIONS.respond && isLatestExtensionRequest;

  // The extension request platform message used to contain this line at the end,
  // we need to remove it to avoid duplications in old messages.
  const text = content.split('Here are the details:')[0];

  return (
    <div className="h-extension-request-message">
      {text && (
        <div className="fw-bold">
          <MultiLineText>{text}</MultiLineText>
        </div>
      )}
      <p className="mb-2">Here are the details:</p>
      <p>
        Original Project Deadline: {formatDateAsLocal(originalProjectDeadline)}
        <br />
        {extensionRequest?.requestedByUserType === UserType.MENTOR ? 'Updated' : 'Requested'}{' '}
        Project Deadline: {formatDateAsLocal(requestedProjectDeadline)}
      </p>
      <p>
        Why would this project benefit from an extended deadline?
        <br />
        {extendedDeadlineBenefit}
      </p>
      <p className="mb-0">
        What will be done over the extended period to ensure the project is completed by the new
        deadline?
        <br />
        {extendedDeadlineActions}
      </p>
      {isResponding && (
        <Button
          size="sm"
          variant="tertiary"
          className="mt-3"
          onClick={() => {
            dispatch(commonReducers.projectExtensionActions.openProjectExtensionModal());
          }}
        >
          Respond
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ExtensionRequest;
