import { EditableProjectDetails } from 'src/components/hermes/EditableProjectDetails';

export const ProjectDetails = () => {
  return (
    <section className="h-project">
      <p>
        Add a working title and project description no later than by the end of session 3. You can
        update the title/description at any time, even after session 3.
      </p>
      <p>
        <em>Those fields are editable by both student and mentor.</em>
      </p>
      <EditableProjectDetails />
    </section>
  );
};
