import { useGetUserSnippetsQuery } from '@polygence/common/api/hermes-ai';
import { HermesAIMessageThreadId } from '@polygence/common/types/common';
import { Text } from '@polygence/components';
import { useState } from 'react';

import { UserSnippetButton } from 'src/components/hermes-ai/user-snippet/UserSnippetButton';
import { UserSnippetEditor } from 'src/components/hermes-ai/user-snippet/UserSnippetEditor';

export const UserSnippetList = ({
  messageThreadId,
}: {
  messageThreadId?: HermesAIMessageThreadId | null;
}) => {
  const { data, isLoading } = useGetUserSnippetsQuery(messageThreadId ?? 0, {
    skip: !messageThreadId,
  });
  const [selectedSnippetId, setSelectedSnippetId] = useState<number | undefined>();

  if (isLoading) {
    return null;
  }

  if (!data || data.length === 0) {
    return (
      <div className="tw-grid tw-place-items-center tw-gap-1 tw-px-5 tw-py-3">
        <Text size="medium" fontWeight="semibold" alignment="center">
          You don't have any snippets shared yet.
        </Text>
        <Text size="small" alignment="center" textWrap="balance" className="tw-max-w-[40ch]">
          Paste any text into the chat, and it will be attached as an editable snippet.
        </Text>
      </div>
    );
  }

  if (selectedSnippetId) {
    return <UserSnippetEditor userSnippetId={selectedSnippetId} aiThreadId={messageThreadId} />;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-1 tw-py-3">
      {data.map((snippet) => (
        <UserSnippetButton
          key={snippet.id}
          snippet={snippet}
          onClick={() => setSelectedSnippetId(snippet.id)}
        />
      ))}
    </div>
  );
};
