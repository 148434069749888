import { OUTPUT_TYPE_OPTIONS } from 'src/components/hermes/constants';

const display = {
  $and: [
    {
      $or: [
        {
          $and: [
            { $is: { field: '__sessionsRemaining', value: 1 } },
            { $not: { field: 'countAs', value: 'half_session' } },
          ],
        },
        { $is: { field: '__sessionsRemaining', value: 0.5 } },
      ],
    },
    { $not: { field: '__endOfProgramSurveyCompleted', value: 'true' } },
  ],
} as const;

export function getFinalMentorSurveyQuestions(defaultDisplay: typeof display) {
  return [
    {
      component: 'Heading',
      props: {
        alignment: 'left',
        className: 'text-muted tw-mb-2',
        size: 'h4',
        text: 'End of Program Survey',
      },
      display: defaultDisplay,
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label:
          'Instead of a session summary, please take a few minutes to write your student a final review of their project and mentorship experience.',
        name: 'endOfProgramFinalReview',
        required: true,
        htmlRequired: true,
        placeholder:
          'Here are a few prompts to guide your review\n- What did your student accomplish during the project?\n- What has your student done well during the project?\n- How do you evaluate your student’s readiness for college?\n- What’s one area of improvement your student could focus on?\n- What abilities of your student are you especially impressed with?',
      },
      display: defaultDisplay,
    },
    {
      component: 'Checkbox',
      props: {
        label:
          'My student has completed their project and reached their intended project goal (or only needs minor revisions).',
        name: 'endOfProgramGoalReached',
        id: 'endOfProgramGoalReached',
      },
      display: defaultDisplay,
    },
    {
      component: 'SelectInput',
      labelVariables: ['__expectedOutputType'],
      props: {
        label:
          'After session 3, you said your student was targeting `{{__expectedOutputType}}` outcome, did they achieve it?',
        name: 'endOfProgramOutputTypeReached',
        required: true,
        htmlRequired: true,
        options: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      display: { ...defaultDisplay, $not: { field: '__expectedOutputType', value: '' } },
    },
    {
      component: 'SelectInput',
      props: {
        label: 'What type of project did your student achieve?',
        name: 'actualOutputType',
        inputType: 'textarea',
        options: OUTPUT_TYPE_OPTIONS,
        required: true,
        htmlRequired: true,
      },
      display: { ...defaultDisplay, $is: { field: 'endOfProgramOutputTypeReached', value: 'no' } },
    },
    {
      component: 'FormInput',
      props: {
        label: 'Please write down the type of the project here:',
        name: 'actualOutputTypeOther',
        inputType: 'text',
        required: true,
        htmlRequired: true,
      },
      display: {
        $is: { field: 'actualOutputType', value: 'other' },
      },
    },
    {
      component: 'NumberSelector',
      props: {
        label:
          'Now that you’ve completed the program with this student, how would you rate this match on a scale from 0-10?',
        name: 'endOfProgramMatchRating',
        required: true,
        startFrom: 0,
        numberCount: 11,
      },
      display: defaultDisplay,
    },
    {
      component: 'NumberSelector',
      props: {
        label:
          'How likely are you to recommend mentoring with Polygence to your friends / colleagues?',
        name: 'endOfProgramFriendRecommendationNps',
        required: true,
        startFrom: 0,
        numberCount: 11,
      },
      display: defaultDisplay,
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label: 'Why or why not?',
        name: 'endOfProgramFriendRecommendationNpsReason',
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'SelectInput',
      props: {
        label: 'Was your experience at Polygence what you expected it to be?',
        name: 'endOfProgramMentorExpectations',
        required: true,
        htmlRequired: true,
        options: [
          { label: 'Better than expected', value: 'Better than expected' },
          { label: 'Same as expected', value: 'Same as expected' },
          { label: 'Worse than expected', value: 'Worse than expected' },
        ],
      },
      display: defaultDisplay,
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label: 'What aspects of the program met, exceeded, or fell short of your expectations?',
        name: 'endOfProgramMentorExpectationDetails',
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'SelectInput',
      props: {
        label: 'Do you intend to take on another Polygence student in the future?',
        name: 'endOfProgramContinueMentoring',
        required: true,
        htmlRequired: true,
        options: [
          { label: 'Yes, as soon as possible', value: 'Yes' },
          {
            // eslint-disable-next-line sonarjs/no-duplicate-string -- autodisabled
            label: 'Yes, but not right away',
            value: 'Yes, but not right away',
          },
          {
            label: "Maybe / Haven't decided",
            value: "Maybe / Haven't decided",
          },
          { label: 'I no longer wish to mentor with Polygence', value: 'No' },
        ],
      },
      display: defaultDisplay,
    },
    {
      component: 'DatePickerWithLabel',
      props: {
        label: 'When will you have more bandwidth to mentor?',
        name: 'endOfProgramAvailabilityDate',
      },
      display: {
        ...defaultDisplay,
        $is: {
          field: 'endOfProgramContinueMentoring',
          value: 'Yes, but not right away',
        },
      },
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'text',
        label: 'With regard to the last question: Why or why not?',
        placeholder: 'Please explain...',
        name: 'endOfProgramContinueMentoringReason',
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label:
          'What kind of support, structure, and resources did you receive from the Polygence admin team? We’d love to know what is working!',
        name: 'endOfProgramReceivedSupport',
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'FormInput',
      props: {
        inputType: 'textarea',
        label: 'What could we do to improve the Polygence mentorship experience?',
        name: 'endOfProgramImprovementSuggestion',
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'SelectInput',
      props: {
        label:
          'We might like to quote parts of your feedback in our outreach materials for new mentors, including your first name and home institution. Would that be OK with you?',
        name: 'endOfProgramAllowUsingFeedback',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ],
        required: true,
        htmlRequired: true,
      },
      display: defaultDisplay,
    },
    {
      component: 'RadioButton',
      props: {
        name: 'endOfProgramSurveyCompleted',
        options: [
          {
            label: '',
            value: true,
            default: true,
          },
        ],
        hidden: true,
      },
      display: defaultDisplay,
    },
  ] as const;
}

// eslint-disable-next-line import/no-default-export -- autodisabled
export default getFinalMentorSurveyQuestions(display);
