import type { Nullable } from '@polygence/common';
import React, { useState } from 'react';

import SilentReportButton from 'src/components/hermes/SilentReportButton';
import SilentReportFeedback from 'src/components/hermes/SilentReportButton/SilentReportFeedback';
import MessageTimestamp from 'src/components/hermes/messages/MessageTimestamp';
import MultiLineText from 'src/components/hermes/messages/MultiLineText';
import type { YesAndNo } from 'src/types/common';

interface SimpleMessageProps {
  messageId: number;
  content: string;
  createdAt?: string;
  showSilentReport?: boolean;
}

const SimpleMessage: React.FC<SimpleMessageProps> = ({
  messageId,
  content,
  createdAt,
  showSilentReport = false,
}) => {
  const [reportAnswer, setReportAnswer] = useState<Nullable<YesAndNo>>(null);

  return (
    <div className="d-flex flex-column">
      <p className="h-simple-message">
        <MultiLineText>{content}</MultiLineText>
      </p>
      <div className="message-box-footer">
        {reportAnswer && <SilentReportFeedback answer={reportAnswer} />}
        {showSilentReport && (
          <SilentReportButton
            messageId={messageId}
            onReport={(answer: Nullable<YesAndNo>) => {
              setReportAnswer(answer);
            }}
          />
        )}
        {createdAt && <MessageTimestamp timestamp={createdAt} />}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SimpleMessage;
