import { externalLink } from '@polygence/common';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { BooleanParagraph } from 'src/components/BooleanParagraph';
import { Icon } from 'src/components/Icon';
import { PageLoad } from 'src/components/PageLoad';
import { authFetch } from 'src/components/customFetch';

const Url = ({ value: href, text }) => {
  if (!href) {
    return null;
  }

  return (
    <p className="me-7">
      <a href={href} {...externalLink}>
        {text}
        <Icon
          name="outArrow"
          local
          size="14px"
          className="ms-5"
          style={{ verticalAlign: 'middle' }}
        />
      </a>
    </p>
  );
};

Url.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
};

Url.defaultProps = {
  value: '',
  text: '',
};

const OptionalSpan = ({ text, value }) => {
  if (!value) {
    return null;
  }

  return (
    <p className="m-0">
      <strong>{text}</strong>: {value}
    </p>
  );
};

OptionalSpan.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
};

OptionalSpan.defaultProps = {
  value: '',
  text: '',
};

const OptionalParagraph = ({ value, text }) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <h6 className="mb-2">{text}</h6>
      <p className="mb-5">{value}</p>
    </>
  );
};

OptionalParagraph.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
};

OptionalParagraph.defaultProps = {
  value: '',
  text: '',
};

const Publications = ({ value: showcasingOutcomes, text }) => {
  if (!showcasingOutcomes?.length) {
    return null;
  }

  return (
    <div className="mt-8">
      <h6 className="mb-2">{text}</h6>
      {showcasingOutcomes.map((showcasingOutcome) => {
        return (
          <div className="mb-2" key={showcasingOutcome.url}>
            <span className="me-3">{showcasingOutcome.students}:</span>
            <a href={`${showcasingOutcome.url}`} {...externalLink}>
              {showcasingOutcome.url}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const Keywords = ({ value, text }) => {
  if (!value) {
    return null;
  }

  return (
    <p className="m-0">
      <strong>{text}</strong>: {value.join(', ')}
    </p>
  );
};

Keywords.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
};

Keywords.defaultProps = {
  value: '',
  text: '',
};

const links = [
  {
    name: 'general_website',
    text: 'General website',
    Component: Url,
  },
  {
    name: 'submission_details',
    text: 'Submission website',
    Component: Url,
  },
];

const basic = [
  {
    name: 'type',
    text: 'Type',
    Component: OptionalSpan,
  },
  {
    name: 'keywords',
    text: 'Keywords',
    Component: Keywords,
  },
];

const questions = [
  {
    name: 'deadline',
    text: 'Deadline',
    Component: BooleanParagraph,
  },
  {
    name: 'submission_fee',
    text: 'Submission fee',
    Component: BooleanParagraph,
  },
  {
    name: 'review_paper',
    text: 'Review paper',
    Component: BooleanParagraph,
  },
  {
    name: 'original_research',
    text: 'Experimental research',
    Component: BooleanParagraph,
  },
  {
    name: 'peer_reviewed',
    text: 'Peer reviewed',
    Component: BooleanParagraph,
  },
];

const longs = [
  {
    name: 'general_notes',
    text: 'General notes',
    Component: OptionalParagraph,
  },
  {
    name: 'mentor_coauthor',
    text: 'Mentor co-author',
    Component: OptionalParagraph,
  },
];

const publications = [
  {
    name: 'showcasing_outcomes',
    text: 'Student publications',
    Component: Publications,
  },
];

const OpportunityContext = createContext({});

function Items({ items }) {
  const opportunity = useContext(OpportunityContext);
  return items.map(({ Component, name, text }) => {
    return <Component value={opportunity[name]} text={text} key={name} />;
  });
}

const ShowcasingOpportunity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [opportunity, setOpportunity] = useState({});
  const {
    params: { identifier },
  } = useRouteMatch();

  useEffect(() => {
    setLoading(true);
    authFetch(`/showcasing/opportunity/${identifier}/`)
      .then(setOpportunity)
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [identifier]);

  if (loading) {
    return <PageLoad />;
  }

  if (error) {
    return <span className="text-danger">Could not find item.</span>;
  }

  return (
    <OpportunityContext.Provider value={opportunity}>
      <div className="container read-only-div my-10 showcasing-opportunity-item">
        <div className="title py-5">
          <h1>{opportunity.name}</h1>
          <div className="d-flex links">
            <Items items={links} />
          </div>
        </div>
        <div className="row my-10">
          <div className="col">
            <Items items={basic} />
          </div>
          <div className="col questions">
            <Items items={questions} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Items items={longs} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Items items={publications} />
          </div>
        </div>
      </div>
    </OpportunityContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ShowcasingOpportunity;
