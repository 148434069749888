import MessageTimestamp from 'src/components/hermes/messages/MessageTimestamp';

interface GifMessageProps {
  content: string;
  createdAt?: string;
}

const GifMessage: React.FC<GifMessageProps> = ({ content, createdAt }) => {
  return (
    <div className="d-flex flex-column w-50">
      <video className="w-100" autoPlay muted controls={false} loop>
        <source src={content} type="video/webm" />
      </video>
      <div className="message-box-footer">
        {createdAt && <MessageTimestamp timestamp={createdAt} />}
      </div>
    </div>
  );
};
// eslint-disable-next-line import/no-default-export -- autodisabled
export default GifMessage;
