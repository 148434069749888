import { Message, Participant, UserState } from '@polygence/common';

import isUserMessage from 'src/components/hermes/SilentReportButton/isUserMessage';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function getLastMessageFromOther(
  currentUser: UserState | undefined,
  messages: Message[] | undefined,
  participants: Participant[] | undefined,
) {
  if (Array.isArray(messages) && currentUser) {
    return messages.find(({ sentBy, messageType }) => {
      return (
        isUserMessage({ sentBy, messageType }, participants) &&
        sentBy?.id !== currentUser?.id &&
        sentBy?.userType !== currentUser?.userType
      );
    });
  }

  return undefined;
}
