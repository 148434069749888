import { externalLink } from '@polygence/common';
import PropTypes from 'prop-types';
import React from 'react';

import { dateStamp } from 'src/components/aux/dateStamp';
import { SeparatorInserterCaptain } from 'src/components/common/SeparatorInserterCaptain';
import { urls } from 'src/urls';

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function ProjectListItem({ item }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <li>
      {item.title}{' '}
      <span className="text-muted">
        with mentor{' '}
        <SeparatorInserterCaptain>
          {item.mentors.map((mentor) => {
            return (
              <a
                key={mentor.id}
                {...externalLink}
                href={urls.dashboardMentorProfile(mentor.userId)}
              >
                {mentor.firstName}
              </a>
            );
          })}
        </SeparatorInserterCaptain>{' '}
        ({item.status === 'complete' ? dateStamp(item.completedAt) : 'Working project'})
      </span>
    </li>
  );
}

ProjectListItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    mentors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        firstName: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    completedAt: PropTypes.string,
  }),
};
