import { InputField, Select, Text } from '@polygence/components';
import { useState } from 'react';

import { DateFormat } from 'src/components/aux/dateWrapper';
import { dayjs } from 'src/utils/dayjsCustom';

interface MemorableDateInputProps {
  name: string;
  legend: string;
  sublegend: string;
  value: string | null;
  onChange: ({
    target: { name, value },
  }: {
    target: { name: string; value: string | null };
  }) => void;
  required?: boolean;
}

export const MemorableDateInput = ({
  name,
  legend,
  sublegend,
  value,
  onChange: handleChange,
  required,
}: MemorableDateInputProps) => {
  const DATE_FORMAT_INTERNAL = DateFormat.FORMAT_15;
  const DATE_FORMAT_EXTERNAL = DateFormat.FORMAT_3;
  const MONTH_OPTIONS = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ] as const;

  const getInitialValue = (format: string) => {
    const obj = dayjs(value, DATE_FORMAT_EXTERNAL);
    const isValid = obj.isValid();

    if (!isValid) {
      return '';
    }

    return obj.format(format);
  };

  const [year, setYear] = useState(getInitialValue('YYYY'));
  const [month, setMonth] = useState(getInitialValue('MM'));
  const [day, setDay] = useState(getInitialValue('D'));

  const handleChangeInternal = () => {
    const dateISOString = `${year}-${month}-${day}`;
    const date = dayjs(dateISOString, DATE_FORMAT_INTERNAL);
    const isValid = date.isValid();

    const output = isValid ? date.format(DATE_FORMAT_EXTERNAL) : null;
    handleChange({ target: { name, value: output } });
  };

  return (
    <fieldset>
      <div className="tw-ps-2">
        <Text size="medium" fontWeight="normal" as="legend">
          {legend}
        </Text>
        <Text size="small">{sublegend}</Text>
      </div>
      <div className="tw-grid tw-grid-cols-2 tw-grid-rows-2 tw-gap-3 min-[500px]:tw-grid-cols-10 min-[500px]:tw-grid-rows-1">
        <div className="tw-col-span-2 min-[500px]:tw-col-span-5">
          <Select
            name="date-month"
            label="Month"
            options={MONTH_OPTIONS}
            isSearchable={false}
            value={MONTH_OPTIONS.find((option) => option.value === month)}
            onChange={(value) => {
              if (value && typeof value.value === 'string') {
                setMonth(value.value);
              } else {
                setMonth('');
              }
              handleChangeInternal();
            }}
            required={required}
          />
        </div>
        <div className="tw-col-span-1 min-[500px]:tw-col-span-2">
          <InputField
            name="date-day"
            label="Day"
            floating={false}
            value={day}
            onChange={({ target: { value } }) => setDay(value)}
            onBlur={handleChangeInternal}
            maxLength={2}
            inputMode="numeric"
            required={required}
          />
        </div>
        <div className="tw-col-span-1 min-[500px]:tw-col-span-3">
          <InputField
            name="date-year"
            label="Year"
            floating={false}
            value={year}
            onChange={({ target: { value } }) => setYear(value)}
            onBlur={handleChangeInternal}
            minLength={4}
            maxLength={4}
            inputMode="numeric"
            required={required}
          />
        </div>
      </div>
    </fieldset>
  );
};
