import { useEffect, useState } from 'react';

import { dayjs } from 'src/utils/dayjsCustom';

const MAX_32BIT_INT = Math.pow(2, 31);

const isInPast = (dateTime?: string) => {
  return dayjs(dateTime).isBefore(dayjs());
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useIsPastDateTime({ dateTime }: { dateTime?: string }) {
  const [value, setValue] = useState(() => isInPast(dateTime));

  useEffect(() => {
    if (!dateTime) {
      return () => {};
    }

    const timeOutMs = Math.max(dayjs(dateTime).diff(dayjs()), 0);

    // setTimeout stores timeout value in 32bit integer,
    // if it's bigger than that do nothing since it would overflow
    if (timeOutMs >= MAX_32BIT_INT) {
      return () => {};
    }

    if (timeOutMs === 0) {
      setValue(true);

      return () => {};
    }

    const timeOut = setTimeout(() => {
      setValue(true);
    }, timeOutMs);

    return () => {
      clearTimeout(timeOut);
      setValue(false);
    };
  }, [dateTime]);

  return value;
}
