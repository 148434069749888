import { InputField, Spacer, Text } from '@polygence/components';

interface DeclineProposalProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
}

const DeclineProposal: React.FC<DeclineProposalProps> = ({ onChange: handleChange, name }) => {
  return (
    <div data-cy="decline-proposal">
      <Text size="medium" fontWeight="bold" className="mb-5">
        Please let your student know if they should propose another time or if you'll propose a time
        (optional).
      </Text>
      <InputField type="textarea" label="" name={name} onChange={handleChange} />
      <Spacer size={5} />
      <Text size="medium" italic fontWeight="bold" style={{ color: 'var(--grayscale-7)' }}>
        *What you submit here will be shared with your student.
      </Text>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DeclineProposal;
