import { UserSnippetId } from '@polygence/common';
import {
  useGetUnsentUserSnippetQuery,
  useDeleteUserSnippetMutation,
} from '@polygence/common/api/hermes-ai';
import {
  hermesAiSelectors,
  hermesAiActions,
} from '@polygence/common/store/reducers/hermesAiReducer';
import { Button, Icon } from '@polygence/components';
import type { MouseEvent } from 'react';
import { toast } from 'react-toastify';

import { UserSnippetPopover } from 'src/components/hermes-ai/user-snippet/UserSnippetPopover';
import { useAppDispatch, useAppSelector } from 'src/store';

const UnsentUserSnippet = ({ id }: { id: UserSnippetId }) => {
  const { data } = useGetUnsentUserSnippetQuery(id);
  const [deleteSnippet] = useDeleteUserSnippetMutation();
  const dispatch = useAppDispatch();

  const handleDeleteSnippet = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    deleteSnippet(id)
      .unwrap()
      .then(() => {
        dispatch(hermesAiActions.removeUnsentSnippet(id));
      })
      .catch(() => {
        toast.error("Couldn't delete snippet");
      });
  };

  if (!data) {
    return null;
  }

  return (
    <UserSnippetPopover id={id}>
      <Button
        className="tw-w-48 tw-justify-start tw-overflow-hidden"
        size="sm"
        startIcon={<Icon className="tw-text-gray-600" id="file-text" />}
        endIcon={
          <Icon
            className="!-tw-m-2 !-tw-mr-2 tw-rounded-full tw-p-[6px] tw-text-red-700 hover:tw-bg-gray-100 hover:tw-text-red-800"
            strokeWidth={2}
            size="xs"
            id="x"
            onClick={handleDeleteSnippet}
          />
        }
      >
        <span className="tw-overflow-hidden tw-text-ellipsis">{data.content.substring(0, 50)}</span>
      </Button>
    </UserSnippetPopover>
  );
};

export const UnsentUserSnippets = () => {
  const unsentSnippets = useAppSelector(hermesAiSelectors.unsentSnippets) ?? [];

  return (
    <div className="tw-tw-justify-start tw-absolute tw-left-4 tw-top-0 tw-mx-auto tw-flex tw-w-4/5 -tw-translate-y-full tw-flex-wrap tw-gap-4">
      {unsentSnippets.map((id) => (
        <UnsentUserSnippet key={id} id={id} />
      ))}
    </div>
  );
};
