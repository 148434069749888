import type { SharedResource } from '@polygence/common';
import { Icon } from '@polygence/components';

const resourceIcons = {
  file: 'file',
  link: 'link',
} as const;

export const SharedResourceIcon = ({ resource }: { resource: SharedResource }) => {
  if (resource.type === 'google') {
    return <img src={resource.googleIconUrl} alt="Google Drive file" />;
  }

  return <Icon size="sm" id={resourceIcons[resource.type]} />;
};
