import type { AdmissionAdvisorModal } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import {
  StudentApplicationStatuses,
  StudentApplication,
} from '@polygence/common/types/studentApplication';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Badge, Text, Icon, Button, Spacer } from '@polygence/components';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import { ApplicationHubCard } from 'src/components/student/ApplicationHubPage/ApplicationHubCard';
import styles from 'src/components/student/ApplicationHubPage/ApplicationHubCard.module.scss';
import { useStudentApplication } from 'src/students/useStudentApplication';
import { getAdmissionAdvisorCalendlyUrl } from 'src/utils/calendlyUtils';

export const InterviewStep = ({
  admissionAdvisor,
  partnerPaysWorkflow,
}: {
  admissionAdvisor: AdmissionAdvisorModal;
  partnerPaysWorkflow: boolean;
}) => {
  const [status, setStatus] = useState('unavailable');
  const { studentApplication } = useStudentApplication();
  const disabled = status === 'unavailable' || !admissionAdvisor?.calendlyUrl;
  const {
    firstName,
    lastName,
    email,
    otherInfo: { isPartnerPaysWorkflow },
  } = useCurrentUser();

  const isAlreadyScheduled = (studentApplication: Partial<StudentApplication>) => {
    return studentApplication.aaCallScheduledAt && !studentApplication.aaCallCanceled;
  };

  useEffect(() => {
    if (studentApplication && studentApplication.status === StudentApplicationStatuses.COMPLETED) {
      setStatus('due');
    }

    if (
      (studentApplication?.id &&
        studentApplication.status === StudentApplicationStatuses.ACCEPTED) ||
      isAlreadyScheduled(studentApplication)
    ) {
      setStatus('completed');
    }
  }, [studentApplication]);

  return (
    <ApplicationHubCard wide step={2} status={status}>
      <div className="d-flex flex-row gap-4">
        <Col xs={3} className="d-flex flex-column align-items-center">
          <img
            src={admissionAdvisor.imageUrl}
            alt={admissionAdvisor.fullName}
            className={classNames(styles['aaImage'], {
              // @ts-expect-error fix conditional css module style in TS files
              [styles['gray']]: status === 'unavailable',
            })}
          />
          <Text size="small" alignment="center">
            <Text
              as="span"
              size="small"
              fontWeight="bold"
              className={classNames({ 'text-muted': disabled })}
            >
              {admissionAdvisor.fullName}
            </Text>
            <br />
            {admissionAdvisor.role}
          </Text>
        </Col>
        <div className="d-flex flex-column gap-3">
          <Text size="medium" fontWeight="bold" className={classNames({ 'text-muted': disabled })}>
            Schedule your {partnerPaysWorkflow ? 'informational call' : 'interview'} with{' '}
            {admissionAdvisor.firstName}
          </Text>
          <Text size="medium" className={classNames({ 'text-muted': disabled })}>
            <Text
              as="span"
              size="medium"
              fontWeight="bold"
              className={classNames({ 'text-muted': disabled })}
            >
              {admissionAdvisor.firstName}
            </Text>{' '}
            will discuss your {partnerPaysWorkflow ? 'enrollment' : 'application'} with you and{' '}
            <Text
              as="span"
              size="medium"
              fontWeight="bold"
              className={classNames({ 'text-muted': disabled })}
            >
              your parent.
            </Text>
          </Text>
          <Spacer size={8} />
          {admissionAdvisor.calendlyUrl && status === 'due' && (
            <div className="d-flex flex-row">
              <Button
                variant="primary"
                href={getAdmissionAdvisorCalendlyUrl(
                  admissionAdvisor,
                  email,
                  firstName,
                  lastName,
                  Boolean(isPartnerPaysWorkflow),
                )}
                {...externalLink}
              >
                Schedule {partnerPaysWorkflow ? 'call' : 'interview'}
              </Button>
            </div>
          )}
          {status === 'completed' && isAlreadyScheduled(studentApplication) && (
            <Badge
              variant={studentApplication.aaCallEndAt ? 'light' : 'primary'}
              size="medium"
              className="mt-auto align-self-start d-flex align-items-center gap-2"
            >
              <Icon id="calendar" size="sm" />
              {formatDateAsLocal(studentApplication.aaCallScheduledAt)}
              {!studentApplication.aaCallEndAt && studentApplication.aaCallReschedulingUrl && (
                <a
                  href={studentApplication.aaCallReschedulingUrl}
                  className={classNames('ms-3', styles['button'], styles['completed'])}
                  {...externalLink}
                >
                  Reschedule
                </a>
              )}
            </Badge>
          )}
        </div>
      </div>
    </ApplicationHubCard>
  );
};
