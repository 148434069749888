import { commonReducers, commonThunks, commonHooks } from '@polygence/common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { useSchedulingSession } from 'src/components/hermes/hooks';
import { MeetingEditor } from 'src/components/hermes/scheduler/MeetingEditor';
import { SuccessConfirmation } from 'src/components/hermes/scheduler/SuccessConfirmation';

const titles = {
  proposing: {
    create: 'Propose a date and time for ',
    modify: 'Propose a modified date and time for ',
  },
  scheduling: {
    create: 'Schedule ',
    modify: 'Reschedule ',
  },
};

export const SessionScheduler = () => {
  const dispatch = useDispatch();
  const otherParticipants = commonHooks.useOtherParticipants();
  const selectedProject = commonHooks.useSelectedProject();
  const {
    meeting: originalMeeting,
    model,
    sending,
    success,
    proposing,
    responding,
  } = useSchedulingSession();
  const [session] = useState(model);
  const [meeting, setMeeting] = useState(originalMeeting);
  const rescheduling = session.id !== -1;
  const modifying = rescheduling && !responding;
  const showForm = !responding && rescheduling && !session.proposing;

  const handleChange = ({ target: { name, value } }) => {
    return setMeeting({ ...meeting, [name]: value });
  };

  const handleSubmit = () => {
    const mentorRescheduling = showForm && !proposing;
    const payload = {
      projectId: selectedProject.id,
      sessionId: model.id,
      extras: {},
      meeting,
      proposing,
    };

    if (rescheduling) {
      // eslint-disable-next-line fp/no-mutation
      payload.extras = {
        ...payload.extras,
        originalMeeting,
      };
    }

    if (mentorRescheduling || responding) {
      // eslint-disable-next-line fp/no-mutation
      payload.meeting = { ...payload.meeting, status: 'rescheduled' };
    }

    dispatch(commonThunks.hermesThunks.saveSessionForProject(payload))
      .unwrap()
      .catch(() => {
        dispatch(commonReducers.hermesActions.resetScheduler());
      });
  };

  const recordingOptionDisabled = session.number === 1 || !selectedProject.recordingOptOut;

  const proposedBy = otherParticipants.find(({ id }) => {
    return id === session.proposedBy;
  });

  if (!session) {
    return <SpinningWheel />;
  }

  return (
    <>
      <h2 className="h5">
        {titles[proposing ? 'proposing' : 'scheduling'][modifying ? 'modify' : 'create']}
        Session {session.number} with{' '}
        {otherParticipants
          .map(({ firstName }) => {
            return firstName;
          })
          .join(', ')}
      </h2>
      {success ? (
        <SuccessConfirmation proposing={proposing} />
      ) : (
        <>
          <>
            <p className="text-muted text-small">
              The date/time you set is automatically converted to the other participants&apos; own
              time zone.
            </p>
            {responding && (
              <p className="text-center text-primary">{proposedBy?.firstName} proposed:</p>
            )}
            <MeetingEditor
              meeting={meeting}
              sending={sending}
              proposing={proposing}
              recordingOptionDisabled={recordingOptionDisabled}
              otherParticipants={otherParticipants}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              session={session}
              maxDate={new Date(selectedProject.expectedEndAt)}
            />
          </>
        </>
      )}
    </>
  );
};
