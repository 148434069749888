import { UserState } from '@polygence/common';

import { CollegeAdmissionsResultsTile } from 'src/components/Tiles/Counselor/CollegeAdmissionsResultsTile';
import { EventsTile } from 'src/components/Tiles/Counselor/EventsTile';
import { HighlightedStudentProject } from 'src/components/Tiles/Counselor/HighlightedStudentProject';
import { PamsTile } from 'src/components/Tiles/Counselor/PamsTile';
import { ProgramResourcesTile } from 'src/components/Tiles/Counselor/ProgramResources';
import { ProjectIdeaGeneratorTile } from 'src/components/Tiles/Counselor/ProjectIdeaGeneratorTile';
import { ResellPartnerInvitationTile } from 'src/components/Tiles/Counselor/ResellPartnerInvitationTile';
import { StudentInvitationTile } from 'src/components/Tiles/Counselor/StudentInvitationTile';
import { TileNavigationItem } from 'src/utils/navigation';

interface getCounselorNavItemsProps {
  approved: boolean;
  currentUser: UserState;
}

export const getCounselorTileNavItems = ({
  approved,
  currentUser,
}: getCounselorNavItemsProps): TileNavigationItem[] => {
  return [
    {
      name: 'pams',
      TileComponent: <PamsTile />,
      cols: 5,
      rows: 1,
      show: approved,
    },
    {
      name: 'project-idea-generator',
      TileComponent: <ProjectIdeaGeneratorTile />,
      cols: 5,
      rows: 1,
      show: approved,
    },
    {
      name: 'core-program-resources',
      TileComponent: <ProgramResourcesTile />,
      cols: 5,
      rows: 3,
      show: approved,
    },
    {
      name: 'highlighted-project',
      TileComponent: <HighlightedStudentProject />,
      cols: 3,
      rows: 1,
      show: approved,
    },
    {
      name: 'resell-partner',
      TileComponent: <ResellPartnerInvitationTile />,
      cols: 3,
      rows: 2,
      show: approved && (currentUser.otherInfo['isPartnerPaysWorkflow'] as boolean),
    },
    {
      name: 'student-invitation',
      TileComponent: <StudentInvitationTile />,
      cols: 3,
      rows: 2,
      show: approved && !(currentUser.otherInfo['isPartnerPaysWorkflow'] as boolean),
    },
    {
      name: 'college-admissions-result',
      TileComponent: <CollegeAdmissionsResultsTile />,
      cols: 3,
      rows: 1,
      show: approved,
    },
    {
      name: 'events-and-webinars',
      TileComponent: <EventsTile />,
      cols: 3,
      rows: 2,
      show: approved,
    },
  ];
};
