import { Text } from '@polygence/components';

import { ProgressBar } from 'src/components/common/ProgressBar';
import { ProjectPlanButton } from 'src/components/hermes/project-plan/ProjectPlanButton';

interface ProgressSectionProps {
  title?: string;
  eventText?: string;
  pastEventNum?: number;
  totalEventNum?: number;
  indicatorColor?: string;
}

export const ProgressSection = ({
  title = 'Progress',
  eventText = '',
  pastEventNum = 0,
  totalEventNum = 0,
  indicatorColor = 'var(--primary)',
}: ProgressSectionProps) => {
  return (
    <div className="h-progress-section">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
        <Text alignment="left" size="small" fontWeight="bold">
          {title}
        </Text>
        <ProjectPlanButton />
      </div>
      <ProgressBar
        completed={(pastEventNum / Math.max(totalEventNum, 1)) * 100}
        height="5px"
        indicatorColor={indicatorColor}
      />
      <Text alignment="center" size="small">
        {pastEventNum}/{totalEventNum} {eventText}
      </Text>
    </div>
  );
};
