import { commonReducers } from '@polygence/common';
import { Button, Text } from '@polygence/components';
import { useDispatch } from 'react-redux';

import type { SessionProps } from 'src/components/hermes/messages/ProposedSession';

interface MentorMessageProps extends SessionProps {
  studentName: string;
  sessionId: number;
  sessionCanceled: boolean;
}

const MentorProposalMessage = ({
  studentName,
  scheduledAt,
  sessionId,
  sessionNumber,
  sessionScheduled,
  sessionCanceled,
}: MentorMessageProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <Text size="small" fontWeight="bold">
        {sessionScheduled
          ? `You have accepted the proposal. Session ${sessionNumber} with ${studentName} is now officially scheduled at ${scheduledAt}.`
          : `${studentName} proposed ${scheduledAt} for Session ${sessionNumber}. Please accept or decline and suggest a new time at your earliest convenience.`}
      </Text>
      {!sessionScheduled && !sessionCanceled && (
        <Button
          className="mt-3"
          variant="tertiary"
          onClick={() => {
            return dispatch(
              commonReducers.hermesActions.openScheduler({ sessionId, responding: true }),
            );
          }}
        >
          Respond
        </Button>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MentorProposalMessage;
