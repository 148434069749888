import { Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from 'src/components/student/ApplicationHubPage/ApplicationHubCard.module.scss';

interface ApplicationHubCardProps {
  step: number;
  narrow?: boolean;
  wide?: boolean;
  status?: string;
  children: ReactNode;
}
export const ApplicationHubCard = ({
  children,
  narrow,
  wide,
  step,
  status = 'unavailable',
}: ApplicationHubCardProps) => {
  return (
    <div
      className={classNames('p-6 pt-8 d-flex flex-column gap-3', styles['box'], {
        // @ts-expect-error fix conditional css module style in TS files
        [styles['completed']]: status === 'completed',
        // @ts-expect-error fix conditional css module style in TS files
        [styles['due']]: status === 'due',
        // @ts-expect-error fix conditional css module style in TS files
        [styles['narrowCard']]: narrow,
        // @ts-expect-error fix conditional css module style in TS files
        [styles['wideCard']]: wide,
      })}
    >
      <div className={styles['indicator']}>
        <div
          className={classNames(styles['inner'], {
            // @ts-expect-error fix conditional css module style in TS files
            [styles['completed']]: status === 'completed',
            // @ts-expect-error fix conditional css module style in TS files
            [styles['due']]: status === 'due',
          })}
        >
          {status === 'completed' ? (
            <Icon id="check" strokeWidth={4} />
          ) : (
            <Text size="medium" fontWeight="bold">
              {step}
            </Text>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
