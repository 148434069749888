import { Tooltip } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  DOCUMENT,
  SPREADSHEET,
  PRESENTATION,
  COLAB,
} from 'src/components/GoogleDriveWidget/mimeTypeConstants';
import { Icon } from 'src/components/Icon';
import { authFetch } from 'src/components/customFetch';

const buttonStyle = {
  border: 0,
  marginTop: '0px',
  marginLeft: '0px',
};

const createButtonStyle = {
  ...buttonStyle,
  background: 'none',
};

const iconStyle = { verticalAlign: 'middle', height: '24px', width: '24px' };

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function GoogleDriveCreateFiles({
  workspace: { workspaceId } = {},
  reload = () => {
    return null;
  },
  disabled = false,
}) {
  const [showIcons, setShowIcons] = useState(false);
  const [creating, setCreating] = useState(false);

  const createFile = (mimeType) => {
    return async () => {
      setCreating(true);
      const result = await authFetch('/google/v2/files/', {
        method: 'POST',
        body: JSON.stringify({
          mime_type: mimeType,
          workspace_id: workspaceId,
        }),
      });
      const { webViewLink } = result;
      window.open(webViewLink, '_blank', 'noopener');
      reload();
      setCreating(false);
    };
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <>
      <button
        type="button"
        className="p-0 toolbar-item"
        // eslint-disable-next-line react/forbid-dom-props -- autodisabled
        style={buttonStyle}
        onClick={
          disabled
            ? null
            : () => {
                setShowIcons(!showIcons && !creating);
              }
        }
      >
        <Icon local name="googleDrive" style={iconStyle} />
      </button>
      {showIcons && (
        <>
          <Tooltip tip="Create a shared Colab document">
            <button
              type="button"
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              style={createButtonStyle}
              onClick={() => {
                createFile(COLAB)();
                setShowIcons(false);
              }}
              data-tip
              data-for="colab"
            >
              <Icon local name="colab" color="#e87109" style={iconStyle} />
            </button>
          </Tooltip>
          <Tooltip tip="Create a shared Google Doc">
            <button
              type="button"
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              style={createButtonStyle}
              onClick={() => {
                createFile(DOCUMENT)();
                setShowIcons(false);
              }}
              data-tip
              data-for="document"
            >
              <Icon local name="document" color="#4285f3" style={iconStyle} />
            </button>
          </Tooltip>
          <Tooltip tip="Create a shared Google Sheet">
            <button
              type="button"
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              style={createButtonStyle}
              onClick={() => {
                createFile(SPREADSHEET)();
                setShowIcons(false);
              }}
              data-tip
              data-for="spreadsheet"
            >
              <Icon local name="spreadsheet" color="#33a852" style={iconStyle} />
            </button>
          </Tooltip>
          <Tooltip tip="Create a shared Google Slide">
            <button
              type="button"
              // eslint-disable-next-line react/forbid-dom-props -- autodisabled
              style={createButtonStyle}
              onClick={() => {
                createFile(PRESENTATION)();
                setShowIcons(false);
              }}
              data-tip
              data-for="presentation"
            >
              <Icon local name="presentation" color="#fbbd04" style={iconStyle} />
            </button>
          </Tooltip>
        </>
      )}
      {creating && <span>Creating shared file...</span>}
    </>
  );
}

GoogleDriveCreateFiles.propTypes = {
  workspace: PropTypes.shape({ workspaceId: PropTypes.number }),
  reload: PropTypes.func,
  disabled: PropTypes.bool,
};
