import 'src/components/common/ProfilePicture/DefaultProfilePicture.sass';

export const DefaultProfilePicture = ({
  monogram = '',
  size = 140,
}: {
  monogram: string;
  size: number;
}) => {
  return (
    // eslint-disable-next-line react/forbid-dom-props
    <div className="default-profile-picture-background" style={{ width: size, height: size }}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <span className="default-profile-picture-monogram" style={{ fontSize: size * 0.5 }}>
        {monogram}
      </span>
    </div>
  );
};
