/* eslint-disable sonarjs/no-duplicate-string */
import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  spaceStationIllustration,
  contentSpacer,
  headingProps,
  headingSpacer,
  textFieldSpacer,
} from 'src/students/student-application/common-section-components';
import type { ApplicationComponentSection } from 'src/students/student-application/student-application-components';

export const EXPERIMENT_SECTIONS_WHITE_LABEL: ApplicationComponentSection[] = [
  {
    id: ApplicationSectionIds.EXPERIMENT_SECTION_ID,
    elements: [
      {
        component: 'ThreeColumnLayout',
        background: 'background-white',
        props: {
          children: [
            null,
            [
              spaceStationIllustration,
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: `For the project you are envisioning, do you plan on doing a hands-on experiment or a science lab project?`,
                  ...headingProps,
                },
              },
              contentSpacer,
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'intendToDoSciFair',
                  label: "No, I don't plan to do a hands-on experiment or a science lab project.",
                  value: false,
                },
                validation: {
                  required: true,
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'intendToDoSciFair',
                  label: 'Yes, I want to do a hands-on experiment or a science lab project.',
                  value: true,
                },
              },
            ],
          ],
        },
      },
    ],
  },
  {
    id: ApplicationSectionIds.EXPERIMENT_DETAIL_SECTION_ID,
    display: {
      $is: { field: 'intendToDoSciFair', value: true },
    },
    elements: [
      {
        component: 'ThreeColumnLayout',
        background: 'background-white',
        props: {
          children: [
            [{ component: 'AdmissionAdvisorModalInfo' }],
            [
              spaceStationIllustration,
              headingSpacer,
              {
                component: 'ComponentsHeading',
                props: {
                  children: 'Tell us about the experiment you plan to do.',
                  ...headingProps,
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: 10,
                },
              },
              {
                component: 'LabelWithTooltip',
                props: {
                  children: 'Can you conduct your experiment(s) at home?',
                  tooltip: `Your mentor will not be able to give you access to their lab or do your experiments for you.`,
                },
              },
              textFieldSpacer,
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'scienceExperimentCanConductFromHome',
                  label: 'Yes.',
                  value: true,
                },
                validation: {
                  correctOptionSelected: { correctValue: true },
                },
              },
              {
                component: 'ComponentsRadioButton',
                tag: 'application',
                props: {
                  name: 'scienceExperimentCanConductFromHome',
                  label: 'No.',
                  value: false,
                },
              },
              {
                component: 'Alert',
                props: {
                  children: `At this time we cannot support a project that cannot be conducted at home. If you would like to modify your project idea, please return to the previous screen and select “No, I don’t plan to do a hands-on experiment or science lab project” or reconsider doing your experiment at home.`,
                  variant: 'warning',
                },
                display: {
                  $is: {
                    field: 'scienceExperimentCanConductFromHome',
                    value: false,
                  },
                },
              },
              {
                component: 'Box',
                display: {
                  $is: {
                    field: 'scienceExperimentCanConductFromHome',
                    value: true,
                  },
                },
                props: {
                  helpColumn: false,
                  children: [
                    textFieldSpacer,
                    {
                      component: 'LabelWithTooltip',
                      props: {
                        children: `How will you obtain the necessary materials for your experiments?`,
                        tooltip: `Your mentor will not be able to provide you with physical materials for your experiment.`,
                      },
                    },
                    textFieldSpacer,
                    {
                      component: 'InputField',
                      tag: 'application',
                      props: {
                        type: 'textarea',
                        name: 'scienceExperimentMaterials',
                      },
                      validation: {
                        required: true,
                        wordCount: {
                          minimum: 5,
                        },
                      },
                    },
                    {
                      component: 'Spacer',
                      props: {
                        size: 9,
                      },
                    },
                    {
                      component: 'ComponentsText',
                      props: {
                        children: `Would you consider conducting a project that does not require an experiment?`,
                        size: {
                          base: 'medium',
                          lg: 'large',
                        },
                      },
                    },
                    textFieldSpacer,
                    {
                      component: 'ComponentsRadioButton',
                      tag: 'application',
                      props: {
                        name: 'scienceExperimentConsidersOtherOption',
                        label: "Yes, I'm open to doing other projects.",
                        value: true,
                      },
                      validation: {
                        required: true,
                      },
                    },
                    {
                      component: 'ComponentsRadioButton',
                      tag: 'application',
                      props: {
                        name: 'scienceExperimentConsidersOtherOption',
                        label: `No, I'm not interested in doing a project if I can't conduct an experiment or a science fair project.`,
                        value: false,
                      },
                    },
                  ],
                },
              },
              {
                component: 'Spacer',
                props: {
                  size: 9,
                },
              },
              {
                component: 'ComponentsText',
                props: {
                  children: `Explain what your experiment will be about (between 70 to 300 words) and be as specific as possible in your description. We recommend avoiding vague statements such as “I want to do an experiment with cells and microscopes!“`,
                  size: 'medium',
                },
              },
              textFieldSpacer,
              {
                component: 'ResponsiveDetails',
                props: {
                  summary: 'Example',
                  detail: `“I would like to do an experiment around sunlight and its effects on plant growth. I want to observe how putting my plants in different rooms changes their growth and health by measuring the amount of light and humidity they get.”`,
                },
              },
              headingSpacer,
              {
                component: 'InputField',
                tag: 'application',
                props: {
                  type: 'textarea',
                  name: 'scienceExperimentDescription',
                  showWordCount: true,
                  rows: 10,
                },
                validation: {
                  required: true,
                  wordCount: {
                    minimum: 70,
                    maximum: 300,
                  },
                },
              },
            ],
          ],
        },
      },
    ],
  },
];
