import NiceModal from '@ebay/nice-modal-react';
import { Project, ProjectPlan, UserType, commonHooks, useFeatureToggle } from '@polygence/common';
import { useProjectPlanQuery } from '@polygence/common/api/hermes-project';
import { Button, Icon } from '@polygence/components';

import { ProjectPlanModal } from 'src/components/hermes/project-plan/ProjectPlanModal';
import { getTracker } from 'src/utils/tracking/factory';

const tracker = getTracker();

const useShowProjectPlanButton = (
  project: Project | undefined,
  projectPlan: ProjectPlan | undefined,
) => {
  const isProjectPlanEnabled = useFeatureToggle('projectPlanEnabled');
  const currentUser = commonHooks.useCurrentUser();

  if (!project || !projectPlan) {
    return false;
  }

  if (!projectPlan.contentJson) {
    return false;
  }

  if (projectPlan.approvedAt) {
    return true;
  }

  return currentUser.isAdmin || (currentUser.userType === UserType.MENTOR && isProjectPlanEnabled);
};

export const ProjectPlanButton = () => {
  const project = commonHooks.useSelectedProject();

  const { data: projectPlan, isLoading } = useProjectPlanQuery(project?.id ?? 0, {
    skip: !project?.id,
  });

  const showProjectPlanButton = useShowProjectPlanButton(project, projectPlan);

  const openPlanModal = () => {
    if (!project) {
      return;
    }

    NiceModal.show(ProjectPlanModal, { projectId: project.id }).catch(console.error);

    tracker.track('Project Plan button clicked', {
      category: 'project_plan',
      projectId: project.id,
    });
  };

  if (isLoading || !showProjectPlanButton) {
    return null;
  }

  return (
    <Button
      variant="accent"
      startIcon={<Icon id="sparkles" className="tw-text-yellow-200" />}
      size="sm"
      onClick={openPlanModal}
    >
      Project Plan
    </Button>
  );
};
