import NiceModal from '@ebay/nice-modal-react';
import { commonHooks } from '@polygence/common';
import { Button } from '@polygence/components';

import { ProjectPauseRequestModal } from 'src/components/hermes/pause-project/ProjectPauseRequestModal';
import {
  calculateModalStatus,
  buttonTexts,
  ProjectPauseRequestModalStatus,
} from 'src/components/hermes/pause-project/utils';

export const RespondToProjectPauseRequest = () => {
  const handleClick = () => {
    void NiceModal.show(ProjectPauseRequestModal);
  };
  const { userType } = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();
  const modalStatus = calculateModalStatus(userType, project?.projectPauseRequests);
  const buttonText = buttonTexts[modalStatus];
  const isMentorRespond = modalStatus === ProjectPauseRequestModalStatus.MENTOR_RESPOND;

  if (!isMentorRespond) {
    return null;
  }

  return (
    <Button type="button" size="sm" variant="primary" onClick={handleClick}>
      {buttonText}
    </Button>
  );
};
