import {
  commonReducers,
  commonHooks,
  UserType,
  useCommonSelector,
  Nullable,
  ExtensionRequestCreatePayload,
} from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { Modal, ModalBody } from '@polygence/components';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import type { DatePickerChangeTarget } from 'src/components/common/DatePickerWithLabel';
import { EXTENSION_REQUEST_ACTIONS } from 'src/components/hermes/constants';
import { ProjectDeadlineExtensionForm } from 'src/components/hermes/extension-request/ProjectDeadlineExtensionForm';
import { useLastExtensionRequest, useSelectedProjectId } from 'src/components/hermes/hooks';
import { getExtensionRequestAction } from 'src/components/hermes/utils';

export const ProjectDeadlineExtensionModal = () => {
  const PAGE_FORM = 'page_form';
  const PAGE_SUCCESS = 'page_success';
  const PAGE_RESPOND_SUCCESS = 'page_respond_success';

  const dispatch = useDispatch();
  const currentUser = commonHooks.useCurrentUser();
  const selectedProjectId = useSelectedProjectId();
  const lastExtensionRequest = useLastExtensionRequest();

  const extensionRequestAction = getExtensionRequestAction(lastExtensionRequest, currentUser);
  const [data, setData] = useState<Nullable<ExtensionRequestCreatePayload>>(null);
  const [page, setPage] = useState<string>(PAGE_FORM);

  useEffect(() => {
    if (lastExtensionRequest?.status === 'requested') {
      setData(lastExtensionRequest);
    }
  }, [lastExtensionRequest]);

  const { isOpen }: { isOpen: boolean } = useCommonSelector((state) => {
    return state.projectExtension.modal;
  });

  const handleChange = ({ target: { name, value } }: DatePickerChangeTarget) => {
    setData((prevData: Nullable<ExtensionRequestCreatePayload>) => {
      return {
        ...(prevData ?? {}),
        [name as keyof ExtensionRequestCreatePayload]: value,
      } as ExtensionRequestCreatePayload;
    });
  };

  const createExtensionRequest = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (data && selectedProjectId) {
      hermesApi
        .createProjectExtensionRequest({ ...data, project: selectedProjectId })
        .then(() => {
          setPage(PAGE_SUCCESS);
        })
        .catch(() => {
          toast.error('Could not process extension request.');
        });
    }
  };

  const respondWith = <T extends string>(status: T) => {
    return (
      event: T extends 'accepted' ? FormEvent<HTMLFormElement> : FormEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
      if (lastExtensionRequest) {
        hermesApi
          .respondToProjectExtensionRequest(lastExtensionRequest.id, status)
          .then(() => {
            setPage(PAGE_RESPOND_SUCCESS);
          })
          .catch(() => {
            toast.error('Something went wrong.');
          });
      }
    };
  };

  const closeModal = () => {
    dispatch(commonReducers.projectExtensionActions.closeProjectExtensionModal());
  };

  const handleSubmit =
    extensionRequestAction === EXTENSION_REQUEST_ACTIONS.respond
      ? respondWith('accepted')
      : createExtensionRequest;

  const handleDecline =
    extensionRequestAction === EXTENSION_REQUEST_ACTIONS.respond
      ? respondWith('declined')
      : closeModal;

  return (
    <Modal show={isOpen} onHide={closeModal} closeButton>
      <ModalBody>
        {page === PAGE_FORM && (
          <ProjectDeadlineExtensionForm
            data={data}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onCancel={handleDecline}
            action={extensionRequestAction}
          />
        )}
        {page === PAGE_SUCCESS && currentUser.userType === UserType.MENTOR && (
          <div className="p-5">Project deadline updated!</div>
        )}
        {page === PAGE_SUCCESS && currentUser.userType === UserType.STUDENT && (
          <div className="p-5">Your request has been submitted!</div>
        )}
        {page === PAGE_RESPOND_SUCCESS && (
          <div className="p-5">Your response has been recorded.</div>
        )}
      </ModalBody>
    </Modal>
  );
};
