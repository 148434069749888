import type { AssignmentSolution } from '@polygence/common';
import { commonHooks } from '@polygence/common';
import { Button, Icon } from '@polygence/components';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';

import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import { authFetch } from 'src/components/customFetch';
import { useMultipleFileUpload } from 'src/components/hermes/FileUpload';
import AssignmentFile from 'src/components/hermes/assignment/AssignmentFile';
import {
  AssignmentLink,
  AssignmentLinkInput,
} from 'src/components/hermes/assignment/AssignmentLink';
import { urls } from 'src/urls';

interface AddAdditionalThingsProps {
  thingName: string;
  addThings: () => void;
}

const AddAdditionalThings: FC<AddAdditionalThingsProps> = ({ thingName, addThings }) => {
  return (
    <Button variant="secondary" size="sm" startIcon={<Icon id="plus" />} onClick={addThings}>
      add additional {thingName}
    </Button>
  );
};

interface AssignmentSubmissionProps {
  onUpdate: (fields: Partial<AssignmentSolution>) => void;
  editable: boolean;
  solution: AssignmentSolution;
  assignmentId: number;
}

/* eslint-disable react-hooks/exhaustive-deps */
const AssignmentSubmission: FC<AssignmentSubmissionProps> = ({
  onUpdate,
  editable,
  solution: { files = [], links = [], submittedAt },
  assignmentId,
}) => {
  const [isEditingLink, setIsEditingLink] = useState(false);
  const addMoreFilesRef = useRef<HTMLInputElement>(null);
  const user = commonHooks.useCurrentUser();
  const [uploadFiles, , uploadedFiles, uploading, clearFiles] = useMultipleFileUpload(
    urls.uploadSessionAssignmentFile(),
    urls.removeSessionAssignmentFile(),
    { assignmentId },
  );

  useEffect(() => {
    if (uploadedFiles?.length > 0 && !uploading) {
      onUpdate({ files: [...files, ...uploadedFiles] });
      clearFiles();
    }
  }, [uploadedFiles, uploading]);

  useLayoutEffect(() => {
    const fileInput = addMoreFilesRef.current;
    if (!fileInput) {
      return () => undefined;
    }

    const onChange = () => {
      const newFiles = fileInput.files;
      if (newFiles && newFiles.length > 0) {
        uploadFiles({ target: { files: newFiles } });
        // eslint-disable-next-line fp/no-mutation
        fileInput.value = '';
      }
    };

    fileInput.addEventListener('change', onChange);
    return () => {
      fileInput.removeEventListener('change', onChange);
    };
  });

  const removeFile = (id: number) => {
    authFetch(`${urls.removeSessionAssignmentFile()}${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        onUpdate({
          files: [
            ...files.filter((file) => {
              return id !== file.id;
            }),
          ],
        });
      })
      .catch(() => null);
  };

  const addLink = (link: string) => {
    setIsEditingLink(false);
    if (link) {
      onUpdate({ links: [...links, link] });
    }
  };

  const addMoreLink = () => {
    setIsEditingLink(true);
  };

  const removeLink = (link: string) => {
    onUpdate({
      links: [
        ...links.filter((l) => {
          return l !== link;
        }),
      ],
    });
  };

  const addMoreFiles = () => {
    const fileInput = addMoreFilesRef.current;
    if (!fileInput) {
      return;
    }

    fileInput.click();
  };

  return (
    <>
      {editable && !files.length && !uploadedFiles.length && (
        <Dropzone
          multiple
          onDrop={(acceptedFiles) => {
            uploadFiles({
              target: { files: acceptedFiles },
            });
          }}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div className="h-assignment__file-submission-area" {...getRootProps()}>
                <span className="fs-0_8 text-center mx-3">
                  Drop your file(s) here or click here to add file(s)
                </span>
                <input {...getInputProps()} />
              </div>
            );
          }}
        </Dropzone>
      )}
      {files.map(({ id, content }) => {
        return (
          <AssignmentFile
            key={id}
            id={id}
            url={content}
            loading={false}
            editable={editable}
            removeFile={removeFile}
          />
        );
      })}
      {uploadedFiles.map(({ name, loading }) => {
        return <AssignmentFile key={name} url={name} loading={loading} />;
      })}
      {editable && (files.length > 0 || uploadedFiles.length > 0) && (
        <>
          <input ref={addMoreFilesRef} className="d-none" type="file" multiple />
          <AddAdditionalThings thingName="files" addThings={addMoreFiles} />
        </>
      )}
      {editable && <p className="text-center text-bold my-5">or add your link below</p>}
      {links.map((link) => {
        return (
          <AssignmentLink key={link} link={link} editable={editable} removeLink={removeLink} />
        );
      })}
      {isEditingLink && <AssignmentLinkInput addLink={addLink} />}
      {editable && <AddAdditionalThings thingName="links" addThings={addMoreLink} />}
      {submittedAt && (
        <p className="h-assignment-submitted-text">
          Submitted:&nbsp;
          {new DateWrapper(submittedAt)
            .setTimezone(user?.timeZone || '')
            .format(DateTimeFormat.FORMAT_14)}
        </p>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AssignmentSubmission;
