import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';

interface MessageTimestampProps {
  timestamp: string;
}

const MessageTimestamp: React.FC<MessageTimestampProps> = ({ timestamp }) => {
  return (
    <time className="h-chat-time h-small">
      {new DateWrapper(timestamp).format(DateTimeFormat.FORMAT_1)}
    </time>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MessageTimestamp;
