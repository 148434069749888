/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default [
  {
    component: 'StarRating',
    props: {
      label: 'How much do you enjoy working with your student?',
      name: 'midProgramSurveyEnjoyment',
      starCount: 5,
    },
    display: {
      $and: [
        { $not: { field: 'countAs', value: 'no_show' } },
        { $is: { field: '__sessionNumber', value: 6 } },
      ],
    },
  },
] as const;
