import { commonHooks, externalLink } from '@polygence/common';
import { Button, Icon } from '@polygence/components';
import PropTypes from 'prop-types';
import React from 'react';

import finalSurveyCompletedSvg from 'src/components/static/images/final_survey_completed.svg';
import finalSurveyPlatformReminderSvg from 'src/components/static/images/final_survey_platform_reminder.svg';

const StudentFinalSurveyReminderMessage = ({ content, extra }) => {
  const user = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();
  const currentStudentUrl = extra[user.id];

  if (!project || !project.finalStudentSurveyEnabled) {
    return null;
  }

  return (
    <div className="h-student-final-survey-reminder-message">
      <div className="mb-3">
        {project.finalStudentSurveyCompleted &&
          'Thank you for completing the final survey. You are now able to join the final session Zoom room, if the session is scheduled.'}
        {!project.finalStudentSurveyCompleted &&
          currentStudentUrl &&
          'You’re almost there! Before you meet for your final session, you need to fill out the Final Survey. You’ll not be able to join the Zoom room until the survey has been submitted.'}
        {!project.finalStudentSurveyCompleted && !currentStudentUrl && content}
      </div>{' '}
      <div className="h-student-final-survey-reminder-image">
        <img
          src={
            project.finalStudentSurveyCompleted
              ? finalSurveyCompletedSvg
              : finalSurveyPlatformReminderSvg
          }
          alt={
            project.finalStudentSurveyCompleted ? 'Final survey completed' : 'Final survey reminder'
          }
        />
        {!project.finalStudentSurveyCompleted && currentStudentUrl && (
          <Button
            as="a"
            href={currentStudentUrl}
            {...externalLink}
            variant="primary"
            startIcon={project.finalStudentSurveyCompleted && <Icon id="check" />}
            className="mt-3 px-8 mb-8"
          >
            Complete Final Survey
          </Button>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentFinalSurveyReminderMessage;

StudentFinalSurveyReminderMessage.propTypes = {
  content: PropTypes.string.isRequired,
  extra: PropTypes.shape({ [PropTypes.number]: PropTypes.string }).isRequired,
};
