import { Button, Icon, Tooltip } from '@polygence/components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from 'src/components/ReferralPage/CopyReferralUrlButton.module.scss';

const CopyReferralUrlButton = ({ referralUrl = '' }) => {
  return (
    <>
      {referralUrl && (
        <Tooltip
          className={styles['copy-button']}
          placement="auto"
          tip="Referral URL copied!"
          trigger={['click', 'focus']}
        >
          <CopyToClipboard text={referralUrl}>
            <Button variant="secondary" startIcon={<Icon id="copy" />}>
              Copy link
            </Button>
          </CopyToClipboard>
        </Tooltip>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default CopyReferralUrlButton;
