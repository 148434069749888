/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default [
  {
    component: 'SelectInput',
    props: {
      label:
        'We encourage all students to showcase their work in some way. How will your student do so?',
      name: 'showCaseType',
      inputType: 'textarea',
      small_font: "This is for internal use only - we don't share it with anybody.",
      options: [
        {
          value: 'submit_to_publication',
          label: 'Submitting for publication to student journal',
        },
        { value: 'create_own_blog', label: 'Creating their own blog' },
        { value: 'podcast', label: 'Podcast on iTunes/Spotify/etc.' },
        { value: 'video', label: 'Video(s) on YouTube' },
        {
          value: 'not_public',
          label: 'My student does not want to make his/her project available to the public',
        },
        { value: 'other', label: 'Other' },
        {
          value: 'not_sure_will_discuss',
          label: "I'm not sure. We will discuss this in our next session.",
        },
      ],
      defaultValueKey: '__showCaseType',
      required: true,
      htmlRequired: true,
    },
    display: {
      $and: [
        {
          $gt: { field: '__sessionNumber', value: 3 },
          $lt: { field: '__sessionNumber', value: 10 },
        },
      ],
      $is: { field: '__showCaseType', value: 'not_sure_will_discuss' },
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
  {
    component: 'SelectInput',
    props: {
      label:
        'We encourage all students to showcase their work in some way. How will your student do so?',
      name: 'showCaseType',
      inputType: 'textarea',
      small_font: "This is for internal use only - we don't share it with anybody.",
      options: [
        {
          value: 'submit_to_publication',
          label: 'Submitting for publication to student journal',
        },
        { value: 'create_own_blog', label: 'Creating their own blog' },
        { value: 'podcast', label: 'Podcast on iTunes/Spotify/etc.' },
        { value: 'video', label: 'Video(s) on YouTube' },
        {
          value: 'not_public',
          label: 'My student does not want to make his/her project available to the public',
        },
        { value: 'other', label: 'Other' },
      ],
      defaultValueKey: '__showCaseType',
      required: true,
      htmlRequired: true,
    },
    display: {
      $gte: { field: '__sessionNumber', value: 10 },
      $is: { field: '__showCaseType', value: 'not_sure_will_discuss' },
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
] as const;
