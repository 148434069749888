import type { OpportunityPublic } from '@polygence/common/types/showcasing';
import { Heading, Badge, Icon } from '@polygence/components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from 'src/components/showcasing/ShowcasingPage/OpportunityCard/OpportunityCard.module.scss';

interface OpportunityCardHeaderProps
  extends Pick<OpportunityPublic, 'type' | 'slug' | 'name' | 'logo'> {
  addOrRemoveOpportunityLike: () => void;
  isLiked: boolean;
}

export const OpportunityCardHeader = ({
  type,
  slug,
  name,
  addOrRemoveOpportunityLike,
  isLiked,
  logo,
}: OpportunityCardHeaderProps) => {
  return (
    <div className="d-flex align-items-start gap-5">
      <div className="d-flex flex-column flex-md-row gap-5">
        {logo && (
          <img
            src={logo}
            alt={name}
            width={55}
            height={55}
            className="align-self-start rounded-circle"
          />
        )}
        <div>
          <Badge variant="secondary" size="small" className="mb-1">
            {type}
          </Badge>
          <Link to={`/dashboard/showcasing-opportunities/${slug}`}>
            <Heading
              as="h2"
              size="h5"
              alignment="left"
              className={classNames('d-flex gap-3 align-items-start', styles['heading'])}
            >
              {name}
            </Heading>
          </Link>
        </div>
      </div>
      <button
        type="button"
        className={classNames('p-6 rounded-circle ms-auto', styles['likeButtonWrapper'])}
        onClick={addOrRemoveOpportunityLike}
        title={isLiked ? 'Remove from favorites' : 'Add to favorites'}
      >
        <Icon
          id="heart"
          color={isLiked ? 'var(--primary-not-so-dark)' : 'var(--grayscale-6)'}
          fill={isLiked ? 'var(--primary)' : 'transparent'}
        />
      </button>
    </div>
  );
};
