import { Icon, Text } from '@polygence/components';
import classNames from 'classnames';
import { KeyboardEventHandler, RefObject, useContext, useRef } from 'react';

import { StudentSnapshotContext } from 'src/components/common/StudentSnapshot/StudentSnapshot';
import styles from 'src/components/common/StudentSnapshot/StudentSnapshot.module.scss';
import { useValidateValue } from 'src/hooks/useValidateValue';
import type { StudentSnapshotPageTab } from 'src/reducers/marketplaceReducer';
import { isValidIconName } from 'src/utils/isValidIconName';
import { getTracker } from 'src/utils/tracking/factory';

type StudentSnapshotSidebarTabProps = Pick<
  StudentSnapshotPageTab,
  'title' | 'icon' | 'description'
> & {
  containerRef: RefObject<HTMLDivElement>;
  index: number;
};

const StudentSnapshotSidebarTab = ({
  title,
  icon,
  description,
  containerRef,
  index,
}: StudentSnapshotSidebarTabProps) => {
  const { selectedTabIndex, setSelectedTabIndex, workspaceId, studentId } =
    useContext(StudentSnapshotContext);
  const iconName = useValidateValue(
    icon,
    isValidIconName,
    'Wrong icon id was passed to StudentSnapshotSidebarTab!',
  );
  const tabRef = useRef<HTMLDivElement>(null);

  const selectTab = () => {
    if (setSelectedTabIndex && selectedTabIndex !== index) {
      setSelectedTabIndex(index);
      scrollTabsOnMobile();
      getTracker().track('Student Snapshot Sidebar Tab Changed', {
        tab: title,
        workspaceId: workspaceId ?? null,
        studentId: studentId ?? null,
      });
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      selectTab();
    }
  };

  const scrollTabsOnMobile = () => {
    if (
      containerRef.current &&
      tabRef.current &&
      containerRef.current.scrollWidth > containerRef.current.clientWidth
    ) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerPos = containerRect.left;
      const tabRefPos =
        containerRef.current.scrollLeft + tabRef.current.getBoundingClientRect().left;
      const maxScroll = containerRef.current.scrollWidth - containerRect.width;

      containerRef.current.scrollTo({
        left: Math.min(tabRefPos - containerPos - 40, maxScroll),
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      ref={tabRef}
      className={classNames('d-flex p-5 gap-5', styles['studentSnapshotSidebarTab'], {
        [styles['studentSnapshotSidebarTab--active'] as string]: index === selectedTabIndex,
      })}
      role="button"
      tabIndex={0}
      onClick={selectTab}
      onKeyDown={handleKeyDown}
    >
      {iconName ? <Icon id={iconName} /> : <div />}
      <div className="d-flex flex-column">
        <Text size="medium" fontWeight="bold">
          {title}
        </Text>
        {description && (
          <Text size="small" fontWeight="light" className={styles['sideBarTabDescription']}>
            {description}
          </Text>
        )}
      </div>
    </div>
  );
};

export const StudentSnapshotSidebar = ({ tabs }: { tabs: StudentSnapshotPageTab[] }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles['studentSnapshotSidebar']} ref={containerRef}>
      {tabs.map(({ title, description, icon }, index) => {
        return (
          <StudentSnapshotSidebarTab
            key={`project_info_${title}`}
            title={title}
            description={description}
            icon={icon}
            index={index}
            containerRef={containerRef}
          />
        );
      })}
    </div>
  );
};
