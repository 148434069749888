import { externalLink } from '@polygence/common';

import MessageTimestamp from 'src/components/hermes/messages/MessageTimestamp';

interface GoogleDriveFileMessageProps {
  createdAt?: string;
  extra: {
    webViewLink: string;
  };
}
const GoogleDriveFileMessage: React.FC<GoogleDriveFileMessageProps> = ({ createdAt, extra }) => {
  return (
    <div className="d-flex flex-column">
      <p className="h-simple-message">
        <a href={extra.webViewLink} {...externalLink}>
          Created a file on Google Drive
        </a>
      </p>
      <div className="message-box-footer">
        {createdAt && <MessageTimestamp timestamp={createdAt} />}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default GoogleDriveFileMessage;
