import type { AdditionalSession } from '@polygence/common';
import { Button } from '@polygence/components';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { LabelWithTooltip } from 'src/components/common/LabelWithTooltip';
import { NumberSelector } from 'src/components/mentor/FormElements';

const MAX_ADDITIONAL_SESSIONS = 5;

const ADDITIONAL_SESSIONS_NUMBER = 'additionalSessionsNumber';
const ADDITIONAL_SESSIONS_NUMBER_LABEL = 'How many sessions would you like to add?';

const REASON_FOR_SESSIONS = 'reasonForSessions';
const REASON_FOR_SESSIONS_LABEL = 'Why would you like additional sessions?';

const PROPOSED_PLAN = 'proposedPlan';
const PROPOSED_PLAN_LABEL = 'What do you plan to accomplish in the additional sessions?';

const DEADLINE_CONSIDERATION = 'deadlineConsideration';

const DeadlineConsiderationLabel = ({
  expectedEndAt,
  additionalSessionsNumber,
}: {
  expectedEndAt: string;
  additionalSessionsNumber: number;
}) => {
  return (
    <LabelWithTooltip
      labelFor={DEADLINE_CONSIDERATION}
      tooltip={`
        Please note if this new timeline does not match your current project deadline,
        you must request an extension using the "Request Extension" button as well.`}
    >
      Your current deadline is {new DateWrapper(expectedEndAt).format(DateFormat.FORMAT_2)}. If you
      successfully enroll in {additionalSessionsNumber} additional sessions, your project deadline
      will automatically extend by {additionalSessionsNumber * 2} weeks.
    </LabelWithTooltip>
  );
};

interface AdditionalSessionFormProps {
  data?: AdditionalSession;
  expectedEndAt: string;
  onSubmit: (data: { [k: string]: FormDataEntryValue }) => void;
  onClose: () => void;
  acceptText?: string;
  cancelText?: string;
  readOnly?: boolean;
  loading?: boolean;
  actionsVisible?: boolean;
}

const AdditionalSessionForm: React.FC<AdditionalSessionFormProps> = ({
  data = {},
  expectedEndAt,
  onSubmit,
  onClose,
  acceptText = '',
  cancelText = '',
  readOnly = false,
  loading = false,
  actionsVisible = true,
}) => {
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const additionalSessionsData = Object.fromEntries(formData.entries());

    onSubmit(additionalSessionsData);
  }
  const [additionalSessionsCount, setAdditionalSessionsCount] = useState(
    isEmpty(data) ? 1 : data[ADDITIONAL_SESSIONS_NUMBER] ?? 1,
  );

  function handleSessionNumberChange({ target: { value } }: { target: { value: number } }) {
    setAdditionalSessionsCount(value);
  }

  return (
    <Form name="additional-session-form" onSubmit={handleSubmit}>
      <h1 className="fs-1_3">Additional sessions</h1>

      <Form.Group controlId={ADDITIONAL_SESSIONS_NUMBER}>
        <Form.Label>{ADDITIONAL_SESSIONS_NUMBER_LABEL}</Form.Label>
        <NumberSelector
          name={ADDITIONAL_SESSIONS_NUMBER}
          numberCount={MAX_ADDITIONAL_SESSIONS}
          defaultValue={additionalSessionsCount}
          disabled={readOnly}
          onChange={handleSessionNumberChange}
          label={undefined}
          className={undefined}
          value={undefined}
          required={undefined}
        />
      </Form.Group>
      <FormInput
        name={REASON_FOR_SESSIONS}
        label={REASON_FOR_SESSIONS_LABEL}
        inputType="textarea"
        type="textarea"
        value={data[REASON_FOR_SESSIONS]}
        isDisabled={readOnly}
        htmlRequired
      />

      <FormInput
        name={PROPOSED_PLAN}
        label={PROPOSED_PLAN_LABEL}
        inputType="textarea"
        type="textarea"
        value={data[PROPOSED_PLAN]}
        isDisabled={readOnly}
        htmlRequired
      />

      <DeadlineConsiderationLabel
        expectedEndAt={expectedEndAt}
        additionalSessionsNumber={additionalSessionsCount}
      />

      {actionsVisible && (
        <div className="d-flex justify-content-end gap-5">
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            {cancelText}
          </Button>
          <Button type="submit" variant="primary" disabled={loading}>
            {acceptText}
          </Button>
        </div>
      )}
    </Form>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AdditionalSessionForm;
