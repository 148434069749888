import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { useProjectPlanQuery } from '@polygence/common/api/hermes-project';
import { Modal } from '@polygence/components';

import { ProjectPlanComponent } from 'src/components/hermes/project-plan/ProjectPlan';
import { useCanEditProjectPlan } from 'src/components/hermes/project-plan/useCanEditProjectPlan';

export const ProjectPlanModal = NiceModal.create(({ projectId }: { projectId: number }) => {
  const modal = useModal();
  const { currentData: projectPlan } = useProjectPlanQuery(projectId);
  const canEdit = useCanEditProjectPlan(projectPlan);

  const hideModal = () => {
    void modal.hide();
  };

  const onEscapeKeyDown = (e: KeyboardEvent) => {
    e.preventDefault();
  };

  if (!projectPlan) {
    return null;
  }

  return (
    <Modal
      {...bootstrapDialog(modal)}
      body
      closeButton
      {...(canEdit && { backdrop: 'static', onEscapeKeyDown })}
    >
      {projectPlan && <ProjectPlanComponent projectPlan={projectPlan} onClose={hideModal} />}
    </Modal>
  );
});
