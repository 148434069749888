import { ProductType } from '@polygence/common';

import { AdmissionAdvisorTile } from 'src/components/Tiles/AdmissionAdvisorTile';
import { ProjectIdeaGeneratorTile } from 'src/components/Tiles/Counselor/ProjectIdeaGeneratorTile';
import { MyProjects } from 'src/components/Tiles/Hermes/MyProjects';
import { MentorTile } from 'src/components/Tiles/Mentors/MentorsTile';
import MyScholarPageTile from 'src/components/Tiles/MyScholarPageTile';
import { CoreApplicationTile } from 'src/components/Tiles/Products/CoreApplicationTile';
import { PathfindersTile } from 'src/components/Tiles/Products/PathfindersTile';
import { PodsTile } from 'src/components/Tiles/Products/PodsTile';
import { StudentJourneyTile } from 'src/components/Tiles/StudentJourney/StudentJourneyTile';
import StudentTeamMemberTile from 'src/components/Tiles/StudentTeamMemberTile';
import { isCartCookieProductValid } from 'src/utils/cart';
import { TileNavigationItem } from 'src/utils/navigation';

interface getStudentTileNavItemsProps {
  hermesEnabled: boolean;
  studentJourneyTileSections: string[] | undefined;
  pathfindersEnabled: boolean;
  isInterestedInPod: boolean;
  admissionAdvisorEmail: string | null | undefined;
  scholarshipApplicant: boolean;
  enrolledAt: string | undefined;
  scholarPageEnabled: boolean;
  scholarPageSlug: string | undefined;
}

export const getStudentTileNavItems = ({
  hermesEnabled,
  studentJourneyTileSections,
  pathfindersEnabled,
  isInterestedInPod,
  admissionAdvisorEmail,
  scholarshipApplicant,
  enrolledAt,
  scholarPageEnabled,
  scholarPageSlug,
}: getStudentTileNavItemsProps): TileNavigationItem[] => {
  const studentHasJourneySections =
    !!studentJourneyTileSections && studentJourneyTileSections.length > 0;

  return [
    {
      name: 'my-projects',
      TileComponent: <MyProjects isActive={hermesEnabled} />,
      cols: 4,
    },
    {
      name: 'student-journey',
      TileComponent: (
        <StudentJourneyTile sections={studentJourneyTileSections} title="My Polygence Journey" />
      ),
      cols: 4,
      rows: studentJourneyTileSections && studentJourneyTileSections.length === 3 ? 4 : 3,
      show: studentHasJourneySections,
    },
    {
      name: 'core-application',
      TileComponent: <CoreApplicationTile />,
      cols: 4,
      show: !studentHasJourneySections,
    },
    {
      name: 'project-idea-generator',
      TileComponent: <ProjectIdeaGeneratorTile />,
      cols: 5,
      rows: 1,
      show: !studentHasJourneySections,
    },
    {
      name: 'pathfinders',
      TileComponent: <PathfindersTile />,
      cols: 4,
      show:
        !studentHasJourneySections &&
        (pathfindersEnabled || isCartCookieProductValid(ProductType.PATHFINDERS)),
    },
    {
      name: 'pods',
      TileComponent: <PodsTile />,
      cols: 4,
      show: isInterestedInPod && !studentHasJourneySections,
    },
    {
      name: 'admission-advisor',
      TileComponent: <AdmissionAdvisorTile email={admissionAdvisorEmail ?? null} />,
      cols: 4,
      show: !scholarshipApplicant && admissionAdvisorEmail !== undefined && enrolledAt == null,
    },
    {
      name: 'student-team-member',
      TileComponent: <StudentTeamMemberTile />,
      cols: 4,
      show: Boolean(enrolledAt),
    },
    {
      name: 'scholarpage',
      show: studentHasJourneySections && scholarPageEnabled,
      TileComponent: (
        <MyScholarPageTile scholarPageSlug={scholarPageSlug ?? ''} illustration="scholar-page" />
      ),
      cols: 4,
    },
    {
      name: 'mentors',
      TileComponent: <MentorTile />,
      cols: 2,
      show: !studentHasJourneySections,
    },
  ];
};
