const imageExtensions = ['jpg', 'jpeg', 'png'];
const videoExtensions = ['mp4', 'm4v', 'mov', 'ogv', 'flv', 'webm', 'asf', 'avi'];

export const getMediaTypeFrom = (fileName: string) => {
  const fileNameParts = fileName.split('.');
  const extension = fileNameParts[fileNameParts.length - 1];
  const isImage = extension == null ? false : imageExtensions.includes(extension);
  const isVideo = extension == null ? false : videoExtensions.includes(extension);

  return { isImage, isVideo, extension };
};

export const getShortenedFileName = (fileName: string) => {
  if (fileName.length <= 30) {
    return fileName;
  }

  const { extension } = getMediaTypeFrom(fileName);
  const shortenedName = fileName.slice(0, 25);
  return `${shortenedName}...${extension ? extension : ''}`;
};
