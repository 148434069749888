import { commonReducers } from '@polygence/common';
import { useDispatch } from 'react-redux';

import { ClickableState } from 'src/components/hermes/sessions/actions/State';

const ProposeClickableState = () => {
  const dispatch = useDispatch();
  const handleClick = () =>
    dispatch(commonReducers.hermesActions.openScheduler({ proposing: true }));

  return (
    <ClickableState iconName="add_date" variant="info" label="Propose" onClick={handleClick} />
  );
};

export { ProposeClickableState };
