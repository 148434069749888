import { Icon } from '@polygence/components';

import { BaseState } from 'src/components/hermes/sessions/actions/State';

const NoActionState = () => {
  return (
    <BaseState variant="no-action">
      <Icon id="calendar" />
    </BaseState>
  );
};

export { NoActionState };
