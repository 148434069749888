export const listAsSentence = (array: number[]) => {
  if (array.length === 0) {
    return '';
  }
  if (array.length === 1) {
    return array[0];
  }
  const leftSide = array.slice(0, array.length - 1).join(', ');
  return `${leftSide} and ${array[array.length - 1]}`;
};
