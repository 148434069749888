import { commonHooks } from '@polygence/common/hooks';
import { Tenant } from '@polygence/common/types/white-label';

import NavigationBar from 'src/components/NavigationBar';
import { WhiteLabelSiteNavigationBarBrand } from 'src/white-label/WhiteLabelSiteNavigationBar/WhiteLabelSiteNavigationBarBrand';
import { getMyNavItems } from 'src/white-label/whiteLabel.navigation';

interface WhiteLabelSiteNavigationBarProps {
  tenant: Tenant;
  hasActiveProject?: boolean;
  opportunitiesEnabled?: boolean;
  onLogout: VoidFunction;
}

export const WhiteLabelSiteNavigationBar = ({
  tenant,
  hasActiveProject,
  opportunitiesEnabled,
  onLogout,
}: WhiteLabelSiteNavigationBarProps) => {
  const { loggedIn, userType, otherInfo } = commonHooks.useCurrentUser();
  const items = getMyNavItems({
    userType,
    hasActiveProject,
    opportunitiesEnabled,
    enrolledAt: otherInfo['enrolledAt'] as string | undefined,
    onLogout,
  });

  return (
    <NavigationBar
      fluid
      brand={<WhiteLabelSiteNavigationBarBrand brandLogo={tenant.logo} brandName={tenant.name} />}
      navItems={loggedIn ? items.mainNav : []}
    />
  );
};
