import { Icon } from '@polygence/components';

export const BooleanParagraph = ({ value, text }: { value: boolean | null; text: string }) => {
  if (value === null) {
    return value;
  }

  return (
    <p>
      <strong>{text}:</strong>{' '}
      {value ? (
        <Icon id="check" style={{ color: '#02E0C0' }} />
      ) : (
        <Icon id="x" style={{ color: '#36f' }} />
      )}
    </p>
  );
};
