import { commonHooks } from '@polygence/common';

// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useNextSession(session) {
  const selectedProject = commonHooks.useSelectedProject();
  if (!session || !selectedProject) {
    return undefined;
  }

  const sessions = selectedProject.sessions || [];
  return sessions.find((s) => s.sessionNumber === session.sessionNumber + 1);
}
