import type { Nullable } from '@polygence/common';
import { commonHooks, MessageType, websocketApi } from '@polygence/common';
import { Button, Text } from '@polygence/components';
import classNames from 'classnames';

import { SpinningWheel } from 'src/components/SpinningWheel';
import { ProfilePicture } from 'src/components/common/ProfilePicture/ProfilePicture';
import { UserCardTrigger } from 'src/components/common/UserCard/UserCardTrigger';
import isSilentReportAvailable from 'src/components/hermes/SilentReportButton/isSilentReportAvailable';
import isUserMessage from 'src/components/hermes/SilentReportButton/isUserMessage';
import AdditionalSessionMessage from 'src/components/hermes/messages/AdditionalSessionMessage';
import AttachmentMessage from 'src/components/hermes/messages/AttachmentMessage';
import ExtensionRequest from 'src/components/hermes/messages/ExtensionRequest';
import GifMessage from 'src/components/hermes/messages/GifMessage';
import GoogleDriveFileMessage from 'src/components/hermes/messages/GoogleDriveFileMessage';
import { HermesAIAssistantMessage } from 'src/components/hermes/messages/HermesAIAssistantMessage';
import { HermesAIThreadMessage } from 'src/components/hermes/messages/HermesAIThreadMessage';
import MarkdownMessage from 'src/components/hermes/messages/MarkdownMessage';
import MentorRescheduledProposedSession from 'src/components/hermes/messages/MentorRescheduledProposedSession';
import { ProjectPauseRequest } from 'src/components/hermes/messages/ProjectPauseRequest';
import ProposedSession from 'src/components/hermes/messages/ProposedSession';
import SessionProposalResponse from 'src/components/hermes/messages/SessionProposalResponse';
import { ShowcasingMatchingSurveyReminderMessage } from 'src/components/hermes/messages/ShowcasingMatchingSurveyReminderMessage';
import SimpleMessage from 'src/components/hermes/messages/SimpleMessage';
import StudentFinalSurveyReminderMessage from 'src/components/hermes/messages/StudentFinalSurveyReminderMessage';
import StudentFirstSurveyReminderMessage from 'src/components/hermes/messages/StudentFirstSurveyReminderMessage';
import { SystemMessage } from 'src/components/hermes/messages/SystemMessage';
import UserActionMessage from 'src/components/hermes/messages/UserActionMessage';

const MESSAGE_TYPES = {
  simple: SimpleMessage,
  markdown: MarkdownMessage,
  has_attachment: AttachmentMessage,
  google_drive_file: GoogleDriveFileMessage,
  system: SystemMessage,
  proposed_session: ProposedSession,
  declined_proposed_session: SessionProposalResponse,
  mentor_rescheduled_proposed_session: MentorRescheduledProposedSession,
  extension_request: ExtensionRequest,
  user_action: UserActionMessage,
  additional_session: AdditionalSessionMessage,
  student_first_survey_reminder: StudentFirstSurveyReminderMessage,
  student_final_survey_reminder: StudentFinalSurveyReminderMessage,
  showcasing_matching_survey_reminder: ShowcasingMatchingSurveyReminderMessage,
  project_pause_request: ProjectPauseRequest,
  gif: GifMessage,
  hermes_ai_thread: HermesAIThreadMessage,
  hermes_ai_assistant_message: HermesAIAssistantMessage,
} satisfies Record<MessageType, unknown>;

interface MessageProps {
  id: number;
  content: string;
  extra: Record<string, unknown>;
  status?: 'sending' | 'sent' | 'failed';
  localMessageId?: Nullable<string>;
  roomId: number;
  sentBy?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  createdAt?: string;
  messageType: keyof typeof MESSAGE_TYPES;
  silentReport: Nullable<number>;
  aiMessageThread?: Nullable<number>;
  aiMessageThreadTitle?: Nullable<string>;
}

const Message: React.FC<MessageProps> = ({
  id,
  content,
  extra,
  status = 'sent',
  localMessageId,
  roomId,
  sentBy = {
    id: -1,
    firstName: '',
    lastName: '',
  },
  createdAt,
  messageType,
  silentReport,
  aiMessageThread,
  aiMessageThreadTitle,
}: MessageProps) => {
  const currentRoom = commonHooks.useSelectedRoom();
  const currentWorkspace = commonHooks.useSelectedWorkspace();
  const currentProject = commonHooks.useSelectedProject();
  const currentUser = commonHooks.useCurrentUser();

  const Component = MESSAGE_TYPES[messageType];

  const { id: userId, firstName, lastName } = sentBy;
  const currentMessage = { id, sentBy, silentReport, messageType, createdAt };

  const showSilentReport = isSilentReportAvailable(
    currentWorkspace,
    currentProject,
    currentUser,
    currentMessage,
    currentRoom,
  );

  const ownMessage = currentUser.id === userId;

  return (
    <div
      className={classNames('h-chat-message-list-item', {
        'h-own': ownMessage,
      })}
    >
      {isUserMessage(currentMessage, currentRoom?.participants) && (
        <UserCardTrigger userId={userId} workspaceId={currentWorkspace?.id}>
          <ProfilePicture user={{ firstName, lastName }} size={40} />
        </UserCardTrigger>
      )}
      <div className="h-chat-message">
        {!ownMessage && (
          <UserCardTrigger userId={userId} workspaceId={currentWorkspace?.id}>
            <Text className="mb-1" fontWeight="bold" size="small">
              {firstName} {lastName}
              {!lastName || lastName?.length > 1 ? '' : '.'}
            </Text>
          </UserCardTrigger>
        )}
        <Component
          messageId={id}
          content={content ?? ''}
          extra={extra}
          createdAt={createdAt}
          showSilentReport={showSilentReport}
          sentBy={sentBy}
          aiMessageThread={aiMessageThread}
          aiMessageThreadTitle={aiMessageThreadTitle}
        />
        {status === 'sending' && <SpinningWheel width={24} className="mt-2" />}{' '}
        {status === 'failed' && localMessageId && (
          <>
            <Text className={classNames('text-danger', 'mb-3')} size="small">
              Your message has not been sent.
            </Text>
            <Button
              size="sm"
              type="button"
              variant="danger"
              onClick={() => {
                return websocketApi.sendMessage({
                  localMessageId,
                  message: content,
                  messageType: MessageType.MARKDOWN,
                  resend: true,
                  roomId,
                  sentBy,
                  attachments: [],
                });
              }}
            >
              Resend
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default Message;
