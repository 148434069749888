import { isStaging, isOndemand, isDevelopment } from '@polygence/common/utils/environment';
import { Badge } from '@polygence/components';

const STAGING = isStaging() || isOndemand();
const DEV = isDevelopment();

interface WhiteLabelSiteNavigationBarBrandProps {
  brandLogo: string;
  brandName: string;
}

export const WhiteLabelSiteNavigationBarBrand = ({
  brandLogo,
  brandName,
}: WhiteLabelSiteNavigationBarBrandProps) => {
  return (
    <div className="tw-relative tw-flex tw-items-center">
      <img src={brandLogo} alt={brandName} className="tw-h-[50px]" />
      {DEV && (
        <Badge
          variant={STAGING ? 'danger' : 'warning'}
          className="tw-absolute tw-left-0 tw-top-4 tw-border tw-border-solid tw-uppercase sm:tw-static sm:tw-ml-4"
        >
          {STAGING ? 'Staging' : 'Development'}
        </Badge>
      )}
    </div>
  );
};
