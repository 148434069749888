import { useGetMessagesQuery } from '@polygence/common/api/hermes-ai';
import { hermesAiSelectors } from '@polygence/common/store/reducers/hermesAiReducer';
import { getConnection } from '@polygence/common/websocket/hermes-ai';

import { useAppSelector } from 'src/store';

export const useHermesAIStatus = (): 'ready' | 'disabled' => {
  const messageThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  const wsConnection = getConnection();

  const { status } = useGetMessagesQuery(messageThreadId ?? 0, {
    selectFromResult: ({ data }) => {
      if (data && (data.messages.length === 0 || data.messages.at(0)?.id != null)) {
        return { status: 'ready' } as const;
      }
      return { status: 'disabled' } as const;
    },
    skip: !messageThreadId,
  });

  if (!messageThreadId) {
    return 'ready';
  }

  if (wsConnection.readyState !== wsConnection.OPEN) {
    return 'disabled';
  }

  return status;
};
