import { commonHooks, type LoginData, UserType } from '@polygence/common';
import { Route, Switch, useRouteMatch } from 'react-router';

import { PasswordResetRequestForm } from 'src/components/auth/PasswordResetRequestForm';
import { PublicRoute } from 'src/components/authenticatedRoutes';
import { WhiteLabelLoginForm } from 'src/white-label/WhiteLabelLoginForm';
import { WhiteLabelVerifyAccount } from 'src/white-label/WhiteLabelVerifyAccount';
import { WhiteLabelWrapper } from 'src/white-label/WhiteLabelWrapper';

interface WhiteLabelUserRoutesProps {
  onLogin: (data: Pick<LoginData, 'access' | 'refresh'>, callback?: () => void) => void;
}

export const WhiteLabelUserRoutes = ({ onLogin }: WhiteLabelUserRoutesProps) => {
  const match = useRouteMatch();

  const { loggedIn, userType } = commonHooks.useCurrentUser();

  const userTypeEnumMap: Record<typeof userType, UserType> = {
    student: UserType.STUDENT,
    mentor: UserType.MENTOR,
    counselor: UserType.COUNSELOR,
    parent: UserType.PARENT,
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <Switch>
        <PublicRoute
          path={`${match.url}/login`}
          component={WhiteLabelLoginForm}
          logged_in={loggedIn}
          user_type={userTypeEnumMap[userType]}
          onLogin={onLogin}
        />
        <Route path={`${match.url}/password-reset`}>
          <PasswordResetRequestForm />
        </Route>
        <Route path={`${match.url}/verify-account/:uuid`}>
          <WhiteLabelWrapper
            renderElement={({ domain }) => (
              <WhiteLabelVerifyAccount domain={domain} onLogin={onLogin} />
            )}
          />
        </Route>
      </Switch>
    </div>
  );
};
