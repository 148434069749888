import { useGetMessagesQuery } from '@polygence/common/api/hermes-ai';
import { OpenAIMessage } from '@polygence/common/types/data/openai';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Loader } from 'src/components/Loader';
import { AssistantMessage } from 'src/components/hermes-ai/messages/AssistantMessage';
import { UserMessage } from 'src/components/hermes-ai/messages/UserMessage';

const getKeyForMessage = ({ clientId, id }: Pick<OpenAIMessage, 'clientId' | 'id'>) => {
  if (!id) {
    if (clientId) {
      return `localMessage-${clientId}`;
    }

    return uuidv4();
  }

  return String(id);
};

const MESSAGE_COMPONENTS = {
  user: UserMessage,
  assistant: AssistantMessage,
  system: Fragment,
} satisfies Record<OpenAIMessage['role'], unknown>;

export const HermesAIMessageList = ({ aiThreadId }: { aiThreadId: number }) => {
  const { data, isLoading } = useGetMessagesQuery(Number(aiThreadId));

  if (isLoading) {
    return (
      <div className="tw-grid tw-w-full tw-grow tw-place-items-center">
        <Loader />
      </div>
    );
  }

  const messages = data?.messages ?? [];

  return (
    <div className="tw-flex tw-w-full tw-grow tw-flex-col-reverse tw-gap-4 tw-overflow-y-auto tw-pt-3">
      <div className="tw-mb-auto" />
      {messages.map((message, index) => {
        const Component = MESSAGE_COMPONENTS[message.role];

        const key = getKeyForMessage({ clientId: message.clientId, id: message.id });

        return (
          <Component
            key={key}
            isLast={index === 0}
            message={message.content}
            userSnippets={message.extras?.userSnippets}
            toolCalls={message.toolCalls}
            id={message.id}
          />
        );
      })}
    </div>
  );
};
