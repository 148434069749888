import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_HIGH, CLEAR_EDITOR_COMMAND } from 'lexical';
import { useEffect } from 'react';

import { SEND_MESSAGE_COMMAND } from 'src/components/LexicalEditor/plugins/SendMessagePlugin';

const GifSearchPlugin = ({ setGifTerm }: { setGifTerm: (term: string) => void }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerCommand<string>(
      SEND_MESSAGE_COMMAND,
      (message) => {
        if (message.startsWith('/gif ')) {
          const query = message.substring(5);
          setGifTerm(query);
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [editor, setGifTerm]);
  return null;
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default GifSearchPlugin;
