import { commonReducers, commonHooks, StringUserType } from '@polygence/common';
import { Button, Heading, Icon, Text } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { isShowcasingMatchingFormEnabled } from 'src/components/hermes/premium-showcasing-support/isShowcasingMatchingFormEnabled';
import { SessionListReminder } from 'src/components/hermes/sessions/SessionListReminder';

export const PremiumShowcasingReminder = ({ userType }: { userType: StringUserType }) => {
  const dispatch = useDispatch();
  const project = commonHooks.useSelectedProject();

  const handleClick = () => {
    if (!project) {
      return null;
    }
    return dispatch(commonReducers.showcasingAddonActions.openMatchingModal(project.id));
  };

  const premiumShowcasingFormEnabled = isShowcasingMatchingFormEnabled(project, userType);

  const completed = project?.showcasingMatchingSurveyCompleted;

  const handleButtonEnabling = (premiumShowcasingFormEnabled: boolean, handleClick: () => void) => {
    return premiumShowcasingFormEnabled
      ? handleClick
      : () => {
          return null;
        };
  };

  if (!project?.premiumShowcasingSupportPurchasedAt) {
    return null;
  }

  return (
    <SessionListReminder>
      {completed ? (
        <>
          <Text size="small" fontWeight="bold">
            Thank you for submitting the matching survey. <br />
            No further action is required.
          </Text>
        </>
      ) : (
        <>
          <Heading alignment="left" size="h4">
            Premium Showcasing Support
          </Heading>
          <Text size="small" fontWeight="bold">
            It’s time to think about showcasing your project! <br />
            Get matched with your dedicated showcasing specialist now.
          </Text>
          <Button
            size="sm"
            disabled={completed || !premiumShowcasingFormEnabled}
            onClick={handleButtonEnabling(premiumShowcasingFormEnabled, handleClick)}
            endIcon={<Icon id="chevron-right" />}
            variant="primary"
          >
            Get Matched
          </Button>
        </>
      )}
    </SessionListReminder>
  );
};
