import { commonReducers, commonHooks } from '@polygence/common';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { externalLinkPlugin } from 'src/components/aux/markdown/externalLinkPlugin';
import { isShowcasingMatchingFormEnabled } from 'src/components/hermes/premium-showcasing-support/isShowcasingMatchingFormEnabled';

interface ShowcasingMatchingSurveyMessageProps {
  content: string;
}

export const ShowcasingMatchingSurveyReminderMessage: React.FC<
  ShowcasingMatchingSurveyMessageProps
> = ({ content }) => {
  const dispatch = useDispatch();
  const currentUser = commonHooks.useCurrentUser();

  const selectedProject = commonHooks.useSelectedProject();
  const premiumShowcasingFormEnabled = isShowcasingMatchingFormEnabled(
    selectedProject,
    currentUser.userType,
  );
  const completed = selectedProject?.showcasingMatchingSurveyCompleted;
  const handleClick = () => {
    if (!selectedProject) {
      return null;
    }
    return dispatch(commonReducers.showcasingAddonActions.openMatchingModal(selectedProject.id));
  };

  return (
    <>
      {premiumShowcasingFormEnabled && !completed && (
        <div className="h-showcasing-matching-survey-reminder fw-bold">
          <ReactMarkdown rehypePlugins={externalLinkPlugin}>{content}</ReactMarkdown>
          <Button className="mt-n2 mb-2" variant="tertiary" onClick={handleClick}>
            Fill out Form
          </Button>
        </div>
      )}
    </>
  );
};
