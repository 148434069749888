import { MarkdownBase } from 'src/components/hermes-ai/messages/MarkdownBase';
import { UserSnippet } from 'src/components/hermes-ai/user-snippet/UserSnippet';

export const UserMessage = ({
  message,
  userSnippets,
}: {
  message: string;
  userSnippets?: number[];
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-1 tw-self-end">
      <div className="tw-rounded-2xl tw-border tw-border-gray-200 tw-bg-white tw-px-5 tw-py-2 tw-font-light tw-shadow-md [&_:last-child]:tw-mb-0">
        <MarkdownBase>{message}</MarkdownBase>
      </div>
      {!!userSnippets && (
        <div className="tw-flex tw-gap-1">
          {userSnippets.map((userSnippetId) => (
            <UserSnippet key={userSnippetId} id={userSnippetId} />
          ))}
        </div>
      )}
    </div>
  );
};
