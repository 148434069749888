import type { User } from '@polygence/common';
import classNames from 'classnames';

import { ProfilePicture } from 'src/components/common/ProfilePicture/ProfilePicture';

const RoomListProfilePictures: React.FC<{ participants: User[] }> = ({ participants }) => {
  const firstParticipant = participants[0];

  if (!firstParticipant) {
    return null;
  }

  return (
    <div className={classNames('h-profile-pictures py-3')}>
      <ProfilePicture key={firstParticipant.id} user={firstParticipant} size={52} />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default RoomListProfilePictures;
