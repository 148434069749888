import { Nullable } from '@polygence/common/types/utils';
import { Tenant } from '@polygence/common/types/white-label';
import { useEffect } from 'react';

import { useGetTenantQuery } from 'src/reducers/whiteLabelReducer';
import { extractTenantDomainFromUrl } from 'src/white-label/whiteLabel.utils';

interface TenantQueryTriggerProps {
  onQueryFinished: (tenant: Nullable<Tenant>) => void;
}

export const TenantQueryTrigger = ({ onQueryFinished }: TenantQueryTriggerProps) => {
  const {
    isLoading: isLoadingTenant,
    data: tenant,
    error: tenantError,
  } = useGetTenantQuery({ domain: extractTenantDomainFromUrl() });

  useEffect(() => {
    if ((!isLoadingTenant && tenant) || tenantError) {
      onQueryFinished(tenant ?? null);
    }
  }, [isLoadingTenant, tenant, tenantError, onQueryFinished]);

  return null;
};
