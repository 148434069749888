import { commonHooks } from '@polygence/common';
import {
  PREMIUM_SHOWCASING_PUBLISHING,
  PREMIUM_SHOWCASING_MULTIMEDIA,
  PREMIUM_SHOWCASING_PRESENTING,
} from '@polygence/common/constants/productNames';
import { Icon, Text } from '@polygence/components';
import { Link } from 'react-router-dom';

import { Loader } from 'src/components/Loader';
import { ShowcasingMatchingSurvey } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingSurvey';
import { useGetProductsPurchasedQuery } from 'src/reducers/paymentReducer';

export const ShowcasingMatchingSurveyPage = () => {
  const project = commonHooks.useSelectedProject();
  const { data, isLoading } = useGetProductsPurchasedQuery();

  const purchasedPremiumShowcasing =
    data?.purchasedProducts.includes(PREMIUM_SHOWCASING_PUBLISHING) ||
    data?.purchasedProducts.includes(PREMIUM_SHOWCASING_MULTIMEDIA) ||
    data?.purchasedProducts.includes(PREMIUM_SHOWCASING_PRESENTING);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="container vstack gap-3 py-5">
      <Link to="./" className="hstack gap-4 fw-light fs-7">
        <Icon id="chevron-left" />
        Back to Project
      </Link>
      {purchasedPremiumShowcasing ? (
        <ShowcasingMatchingSurvey projectId={project ? project?.id : null} />
      ) : (
        <div className="tw-mt-32 tw-flex tw-justify-center tw-align-middle">
          <Text size="large" fontWeight="bold">
            You need to purchase the Premium Showcasing product to access this page.
          </Text>
        </div>
      )}
    </div>
  );
};
