import type { Assignment } from '@polygence/common';
import { Text, Icon } from '@polygence/components';
import type { FC } from 'react';

type AssignmentHeaderProps = Pick<Assignment, 'title' | 'assignmentSolution'> & {
  size?: 'small' | 'medium' | 'large';
};

export const AssignmentHeader: FC<AssignmentHeaderProps> = ({
  title,
  assignmentSolution,
  size = 'large',
}) => {
  const isSubmitted = !!assignmentSolution?.submittedAt;

  return (
    <Text className="d-flex align-items-center" size={size} fontWeight="bold">
      {isSubmitted ? (
        <Icon className="text-success me-3" id="check-circle" strokeWidth={3} size="md" />
      ) : (
        <Icon className="text-danger me-3" id="alert-circle" strokeWidth={3} size="md" />
      )}
      {title}
    </Text>
  );
};
