import { commonHooks, externalLink, Meeting, ProjectType } from '@polygence/common';
import { useTrackZoomJoinMutation } from '@polygence/common/api/hermes-project';
import * as siteContentApi from '@polygence/common/api/site-content';
import { useState, useEffect } from 'react';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { ENTER } from 'src/components/hermes/utils';
import { OldModal } from 'src/components/useModal';
import { getTracker } from 'src/utils/tracking/factory';

interface SessionIntroPopupProps {
  title: string;
  description: string;
  startButton: string;
  startUrl: string;
  handleStart: () => void;
}

const SessionIntroPopup = ({
  title,
  description,
  startButton,
  startUrl,
  handleStart,
}: SessionIntroPopupProps) => {
  return (
    <div className="p-3">
      <h3 className="mb-5">{title}</h3>
      <ReactMarkdown>{description}</ReactMarkdown>
      <div className="d-flex justify-content-center">
        <a className="mt-5 btn btn-primary" href={startUrl} {...externalLink} onClick={handleStart}>
          {startButton}
        </a>
      </div>
    </div>
  );
};

interface ZoomJoinProps {
  meeting?: Meeting;
  canJoin: boolean;
  sourceLocation: 'chat' | 'session_list';
  children: React.ReactNode;
}

export const ZoomJoin = ({ meeting, canJoin, sourceLocation, children }: ZoomJoinProps) => {
  const [isSessionIntroOpen, setIsSessionIntroOpen] = useState(false);
  const [sessionIntroPopup, setSessionIntroPopup] = useState<{
    title: string;
    description: string;
    startButton: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  const currentUser = commonHooks.useCurrentUser();
  const selectedProject = commonHooks.useSelectedProject();
  const [trackZoomJoin, { isLoading }] = useTrackZoomJoinMutation();
  const session = (selectedProject?.sessions || []).find((s) => {
    return s.meetingId === meeting?.id;
  });

  const closeSessionIntro = () => {
    return setIsSessionIntroOpen(false);
  };
  const openSessionIntro = () => {
    return setIsSessionIntroOpen(true);
  };
  const trackJoin = () => {
    if (!meeting) {
      return;
    }
    getTracker().track('User Clicked Join Zoom Button', {
      userId: currentUser?.id,
      sessionId: session?.id || null,
      meetingId: meeting.id,
      zoomUrl: meeting.zoom.url,
      sourceLocation: sourceLocation,
    });
    if (!isLoading) {
      trackZoomJoin({ meetingId: meeting.id, sourceLocation }).catch(console.error);
    }
  };

  useEffect(() => {
    if (session) {
      setLoading(true);
      siteContentApi
        .getSessionIntroPopup(
          session.number,
          selectedProject?.type ? selectedProject.type : ProjectType.FLAGSHIP,
          selectedProject?.id,
        )
        .then((result) => {
          const { data } = result;
          if ('title' in data) {
            setSessionIntroPopup(data);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [meeting?.id, session, selectedProject]);

  function handleKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.keyCode === ENTER) {
      openSessionIntro();
    }
  }

  return (
    <>
      {sessionIntroPopup && canJoin && meeting && (
        <>
          <OldModal
            isOpen={isSessionIntroOpen}
            close={closeSessionIntro}
            overlayClassName="ReactModal__Overlay"
            className="modal-overlay session-overlay session-overlay-text session-style"
            contentLabel="popup window for starting zoom session"
            shouldCloseOnOverlayClick
            ariaHideApp={false}
          >
            <SessionIntroPopup
              handleStart={closeSessionIntro}
              startUrl={meeting.zoom.url}
              {...sessionIntroPopup}
            />
          </OldModal>
          <div
            className="d-inline-block"
            onClick={() => {
              trackJoin();
              openSessionIntro();
            }}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            {children}
          </div>
        </>
      )}
      {!loading && !sessionIntroPopup && canJoin && (
        <a href={meeting?.zoom?.url} onClick={trackJoin} {...externalLink}>
          {children}
        </a>
      )}
      {!canJoin && children}
    </>
  );
};
