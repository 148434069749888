import { DropdownMenu, DropdownMenuContent } from '@polygence/components';
import type { DropdownMenuItem } from '@polygence/components';
import { noop } from 'lodash-es';

import type { DropdownMenuProps } from 'src/components/DropdownMenu';
import { ThreeDotMenuButton } from 'src/components/DropdownMenu';

export interface SharedResourceMenuProps {
  sharedResourceActions?: DropdownMenuProps['items'];
}

export const SharedResourceMenu = ({ sharedResourceActions = [] }: SharedResourceMenuProps) => {
  const simpleItems: DropdownMenuItem[] = sharedResourceActions.map(
    ({ label, iconId, onClick }) => ({
      type: 'button',
      label: label || 'unknown',
      onSelect: onClick || noop,
      iconId,
    }),
  );

  return (
    <DropdownMenu>
      <ThreeDotMenuButton alignment="vertical" />
      <DropdownMenuContent menuItems={simpleItems} />
    </DropdownMenu>
  );
};
