import { commonReducers, commonHooks } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { Button, Icon, Tooltip } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import getLastMessageFromOther from 'src/components/hermes/SilentReportButton/getLastMessageFromOther';
import REPORT_ANSWER from 'src/components/hermes/SilentReportButton/reportAnwser';

const SilentReportButton = ({ messageId, onReport }) => {
  const dispatch = useDispatch();
  const selectedWorkspaceId = commonHooks.useSelectedWorkspaceId();
  const currentUser = commonHooks.useCurrentUser();
  const currentRoom = commonHooks.useSelectedRoom();
  const lastMessageFromOther = getLastMessageFromOther(
    currentUser,
    currentRoom.messages,
    currentRoom.participants,
  );
  const [loading, setLoading] = useState(false);

  const updateMessageWithSilentReport = (reportId) => {
    dispatch(
      commonReducers.hermesActions.updateMessageSilentReport({
        roomId: currentRoom.id,
        messageId,
        reportId,
      }),
    );
  };

  const getReportPayload = (reportedAnswer) => {
    return {
      lastMessageFromOther: lastMessageFromOther?.id,
      message: messageId,
      reportedAnswer,
      reportedBy: currentUser.id,
      workspace: selectedWorkspaceId,
    };
  };

  const handleReport = (reportAnswer) => {
    return () => {
      if (loading) {
        return;
      }

      setLoading(true);
      hermesApi
        .reportSilentRoomUser(selectedWorkspaceId, getReportPayload(reportAnswer))
        .then(({ error, data }) => {
          if (error) {
            toast.error('Something went wrong.');
          } else {
            updateMessageWithSilentReport(data.id);
            onReport(reportAnswer);
            setTimeout(() => {
              onReport(null);
            }, 15000);
          }
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    };
  };

  return (
    /* eslint-disable-next-line react/jsx-filename-extension, react/forbid-dom-props -- autodisabled, autodisabled */
    <div className="d-flex align-items-center small" style={{ gap: 6 }}>
      <Icon id="help-circle" size="sm" color="var(--danger-dark)" />
      {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
      <p className="m-0 h-small" style={{ color: 'var(--danger-dark)', paddingTop: 2 }}>
        Still waiting for a response...?
      </p>
      <Tooltip
        placement="top"
        tip={`
          Let us know if you are still looking for a response.
          We'll follow-up with ${lastMessageFromOther?.sentBy?.firstName} to make sure you get what you need!
        `}
      >
        <Button
          size="sm"
          variant="link-danger"
          style={{
            padding: '3px 9px',
            fontSize: 8,
          }}
          onClick={handleReport(REPORT_ANSWER.STILL_WAITING)}
        >
          Yes
        </Button>
      </Tooltip>
      <Button
        size="sm"
        variant="link-danger"
        style={{
          padding: '3px 9px',
          fontSize: 8,
        }}
        onClick={handleReport(REPORT_ANSWER.IRRELEVANT)}
      >
        No
      </Button>
    </div>
  );
};

SilentReportButton.propTypes = {
  messageId: PropTypes.number.isRequired,
  onReport: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SilentReportButton;
