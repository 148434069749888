import { externalLink } from '@polygence/common';

import TeamMemberTile from 'src/components/Tiles/TeamMemberTile';
import studentTeamMemberImage from 'src/components/static/images/team_member/Geordyn.png';
import { STUDENT_TEAM_EMAIL } from 'src/constants';

const StudentTeamMemberTile = () => {
  return (
    <TeamMemberTile
      imageUrl={studentTeamMemberImage}
      title="Reach out to your Project Support Manager"
    >
      <a
        href={`mailto:${STUDENT_TEAM_EMAIL}`}
        {...externalLink}
        className="tw-text-gray-800 tw-underline"
      >
        Get in touch
      </a>
    </TeamMemberTile>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentTeamMemberTile;
