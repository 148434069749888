import {
  isApplicationReadOnly,
  StudentApplication,
  StudentApplicationStatuses,
} from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import * as studentApplicationApi from '@polygence/common/api/student-application';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Tenant } from '@polygence/common/types/white-label';
import { Button, Icon as ComponentsIcon, Illustration, Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from 'src/students/StudentProfileWelcomePage.module.sass';
import {
  trackApplicationReopened,
  trackApplicationStarted,
} from 'src/students/studentApplicationTracking';
import { useStudentApplication } from 'src/students/useStudentApplication';

interface WhiteLabelStudentProfileWelcomePageProps {
  tenant: Tenant;
}

export const WhiteLabelStudentProfileWelcomePage = ({
  tenant,
}: WhiteLabelStudentProfileWelcomePageProps) => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { studentApplication, getLatestStudentApplication } = useStudentApplication();
  const profileId = currentUser.profileId;
  const applicationId = (studentApplication as { id: number }).id;
  const isApplicationEditable =
    !isEmpty(studentApplication) && !isApplicationReadOnly(studentApplication);
  const [isLoadingStudentApplication, setIsLoadingStudentApplication] = useState(true);

  const ERROR_MESSAGE = `Something went wrong. If the issue persists after reloading the page, please contact us at ${tenant.supportTeamEmail}`;
  useEffect(() => {
    if (profileId) {
      getLatestStudentApplication(profileId)
        .then(() => {
          setIsLoadingStudentApplication(false);
        })
        .catch(console.error);
    }
  }, [getLatestStudentApplication, profileId]);

  const triggerStudentApplicationCreation = () => {
    return marketplaceApi
      .updateStudentProfileForCoreAppStudentPaysWorkflow({
        profileCompletionStage: 0,
        completedApplicationStages: [],
      })
      .then(() => studentApplicationApi.startNewApplication(profileId));
  };

  const triggerStudentApplicationEdit = () => {
    return studentApplicationApi.updateApplication(profileId, applicationId, {
      status: StudentApplicationStatuses.CREATED,
      completedAt: null,
    });
  };

  const handleApplicationStart = () => {
    setIsLoadingStudentApplication(true);
    triggerStudentApplicationCreation()
      .then(({ data: application }: { data: StudentApplication }) => {
        trackApplicationStarted(`${application.id}`);
        return history.push(`/student/${profileId}/application/${application.id}`);
      })
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      })
      .finally(() => setIsLoadingStudentApplication(false));
  };

  const handleApplicationEdit = () => {
    triggerStudentApplicationEdit()
      .then(() => trackApplicationReopened(`${applicationId}`))
      .then(() => history.push(`/student/${profileId}/application/${applicationId}`))
      .catch(() => {
        toast.error(ERROR_MESSAGE);
      });
  };

  if (profileId && applicationId) {
    return <Redirect to={`/student/${profileId}/application/${applicationId}`} />;
  }

  const handleStart = isApplicationEditable ? handleApplicationEdit : handleApplicationStart;
  const startLabel = isApplicationEditable ? `Continue enrollment` : `Start enrollment`;

  return (
    <Row className="justify-content-center mt-8">
      <Col className="d-flex flex-column align-items-center" sm={6}>
        <div className="mb-6 order-1">
          <Illustration name="SpaceRocketKissingSaturn" size={68} />
        </div>
        <div className="mb-8 mb-md-9 order-2">
          <Heading
            size={{
              base: 'h4',
              lg: 'h3',
            }}
            as="h1"
          >
            Welcome! You&apos;re just a few steps away from accessing the brightest minds.
          </Heading>
          <Text size="medium" alignment="center" className={classNames(styles['coreText'], 'mt-9')}>
            Complete this enrollment form below
          </Text>
        </div>

        <div className="mb-3 order-3 order-md-4 mb-8">
          <Button
            onClick={handleStart}
            endIcon={<ComponentsIcon id="arrow-right" />}
            disabled={isLoadingStudentApplication}
            variant="primary"
          >
            <span className="text-bold">{startLabel}</span>
          </Button>
        </div>
      </Col>
    </Row>
  );
};
