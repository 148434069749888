import { Tenant } from '@polygence/common/types/white-label';
import { ReactNode } from 'react';

import { useGetTenantQuery } from 'src/reducers/whiteLabelReducer';
import { extractTenantDomainFromUrl } from 'src/white-label/whiteLabel.utils';

interface WhiteLabelWrapperProps {
  renderElement: (tenant: Tenant) => ReactNode;
  defaultElement?: ReactNode;
}

export const WhiteLabelWrapper = ({ renderElement, defaultElement }: WhiteLabelWrapperProps) => {
  const {
    isLoading: isLoadingTenant,
    data: tenant,
    error: tenantError,
  } = useGetTenantQuery({ domain: extractTenantDomainFromUrl() });

  if (isLoadingTenant || !tenant || tenantError) {
    return null;
  }

  if (tenant.isDefault && defaultElement) {
    return defaultElement;
  }

  return renderElement(tenant);
};
