import { commonThunks } from '@polygence/common';
import { Button } from '@polygence/components';
import { toast } from 'react-toastify';

import { ConfirmationModal } from 'src/components/common/ConfirmationModal';
import { LateCancelModal } from 'src/components/hermes/sessions/LateCancelModal';
import { useAppDispatch } from 'src/store';
import { WhiteLabelWrapper } from 'src/white-label/WhiteLabelWrapper';

interface SessionMeetingCancelModalProps {
  projectId?: number;
  sessionId?: number;
  meetingId: number;
  isLateCancel: boolean;
}

export const SessionMeetingCancelModal = ({
  projectId,
  sessionId,
  meetingId,
  isLateCancel,
}: SessionMeetingCancelModalProps) => {
  const dispatch = useAppDispatch();
  const hasSession = projectId && sessionId;
  const handleCancel =
    ({ isLate }: { isLate: boolean }) =>
    () => {
      if (hasSession) {
        dispatch(commonThunks.hermesThunks.cancelSession({ projectId, sessionId, isLate })).catch(
          () => toast.error('Failed to cancel session'),
        );
      } else {
        dispatch(commonThunks.hermesThunks.cancelMeeting({ meetingId })).catch(() =>
          toast.error('Failed to cancel meeting'),
        );
      }
    };

  if (!hasSession && !meetingId) {
    return null;
  }

  return (
    <ConfirmationModal
      skipConfirmation={!projectId && !sessionId}
      confirmButtonText="Yes, cancel session"
      backdrop
      trigger={
        <Button
          data-value="Cancel"
          variant="link-danger"
          size="sm"
          onClick={handleCancel({ isLate: false })}
        >
          <span>{isLateCancel ? 'Forfeit Session' : 'Cancel'}</span>
        </Button>
      }
      showControls={!(hasSession && isLateCancel)}
    >
      {hasSession && isLateCancel ? (
        <WhiteLabelWrapper
          renderElement={({ resourceLateCancelPolicy, supportTeamEmail }) => (
            <LateCancelModal
              onCancel={handleCancel({ isLate: true })}
              resourceLateCancelPolicy={resourceLateCancelPolicy}
              supportTeamEmail={supportTeamEmail}
            />
          )}
        />
      ) : (
        <div>
          <span>Are you sure you want to cancel this scheduled session?</span>
        </div>
      )}
    </ConfirmationModal>
  );
};
