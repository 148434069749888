import { ProjectStatus, type Project, type StringUserType } from '@polygence/common';

export const isShowcasingMatchingFormEnabled = (
  project:
    | Pick<Project, 'status' | 'isShowcasingMatchingFormEnabled' | 'sessionsRemaining'>
    | undefined,
  userType: StringUserType,
) => {
  if (!project) {
    return false;
  }

  if (userType === 'mentor') {
    return false;
  }

  if (project.status === ProjectStatus.TERMINATED) {
    return false;
  }

  if (project.isShowcasingMatchingFormEnabled) {
    return true;
  }

  return parseInt(project.sessionsRemaining) <= 2;
};
