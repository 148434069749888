import type { AdditionalSession, StringUserType } from '@polygence/common';

import {
  isRequested,
  isResponded,
} from 'src/components/hermes/additional-session/AdditionalSessionStatus';

export type ModalStatus =
  | 'mentor_popup'
  | 'mentor_respond'
  | 'student_request'
  | 'readonly'
  | 'hidden';

export function calculateModalStatus(
  userType: StringUserType,
  additionalSessions: AdditionalSession[] = [],
): ModalStatus {
  const latestAdditionalSession = additionalSessions[0];
  const isMentor = userType === 'mentor';
  const isStudent = userType === 'student';

  if (isMentor) {
    if (latestAdditionalSession && isRequested(latestAdditionalSession)) {
      return 'mentor_respond';
    }
    if (latestAdditionalSession && isResponded(latestAdditionalSession)) {
      return 'readonly';
    }
    return 'mentor_popup';
  }

  if (isStudent) {
    if (
      latestAdditionalSession &&
      (isRequested(latestAdditionalSession) || isResponded(latestAdditionalSession))
    ) {
      return 'readonly';
    }
    return 'student_request';
  }

  return 'hidden';
}

export const buttonTexts: Record<ModalStatus, string> = {
  mentor_respond: 'Respond to Additional Sessions',
  student_request: 'Add Sessions',
  mentor_popup: 'Add Sessions',
  readonly: 'Additional sessions pending',
  hidden: '',
};
