import { commonHooks, commonReducers } from '@polygence/common';
import { Button, Tooltip } from '@polygence/components';

import { useAppDispatch } from 'src/store';

const STUDENT_TOOLTIP =
  "Your mentor can provide basic showcasing guidance for your project. If you'd like to work with a showcasing specialist for structured support with showcasing and publishing for a specific opportunity, click here";

const MENTOR_TOOLTIP =
  'Your student can click here to add premium showcasing support to their Polygence journey. You can check out our Program Page to learn more, or reach out to mentors@polygence.org if you have further questions.';

export const PurchaseShowcasingAddonButton = () => {
  const currentUser = commonHooks.useCurrentUser();
  const selectedProject = commonHooks.useSelectedProject();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    if (currentUser.utilities.isMentor || !selectedProject) {
      return null;
    }
    return dispatch(commonReducers.showcasingAddonActions.openPurchaseModal(selectedProject.id));
  };
  return (
    <Tooltip
      placement="auto"
      tip={currentUser.utilities.isStudent ? STUDENT_TOOLTIP : MENTOR_TOOLTIP}
      trigger={['hover', 'focus']}
    >
      <Button size="sm" onClick={handleClick}>
        Add Premium Showcasing Support
      </Button>
    </Tooltip>
  );
};
