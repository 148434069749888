import MultiLineText from 'src/components/hermes/messages/MultiLineText';

interface UserActionMessageProps {
  content: string;
}

const UserActionMessage: React.FC<UserActionMessageProps> = ({ content }) => {
  return (
    <div className="h-user-action-message fw-bold">
      <MultiLineText>{content}</MultiLineText>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default UserActionMessage;
