import { useEffect, useRef } from 'react';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import { emojis, loadEmoji } from 'src/components/admin/components/aux/emojis';
import { countWords } from 'src/utils';

import 'src/components/textAreaWithWordCount.sass';

interface LengthCheck {
  on: 'words' | 'characters';
  required: boolean;
  recommended: number;
  minimum: number;
  maximum: number;
}

interface TextareaProps {
  name: string;
  placeholder?: string;
  label?: string;
  smallFont?: string;
  value: string;
  onChange: (_: unknown) => void;
  disabled?: boolean;
  onError?: string[];
  required?: boolean;
  htmlRequired?: boolean;
  noRequiredStyle?: boolean;
  onBlur: () => void;
  rows: number;
  lengthCheck?: Partial<LengthCheck>;
}

export const Textarea = ({
  name,
  placeholder,
  label,
  smallFont,
  value,
  onChange,
  disabled = false,
  onError = [],
  required = false,
  htmlRequired = false,
  noRequiredStyle,
  onBlur,
  rows,
  lengthCheck = {},
}: TextareaProps) => {
  const lengthCheckInit: LengthCheck = {
    on: lengthCheck.on || 'words',
    required: lengthCheck.required || false,
    recommended: lengthCheck.recommended || 0,
    minimum: lengthCheck.minimum || 0,
    maximum: lengthCheck.maximum || 0,
  };
  const inputRef = useRef<HTMLInputElement>();
  const valueCount = lengthCheckInit.on === 'words' ? countWords(value) : value.length;
  const recommendedReached = valueCount >= lengthCheckInit.recommended;
  const isRestrictionCountWithinRange =
    valueCount >= lengthCheckInit.minimum && valueCount <= lengthCheckInit.maximum;

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    if (lengthCheckInit.required && valueCount > 0 && !recommendedReached) {
      inputRef.current.setCustomValidity(
        `You haven't reached the minimum ${
          lengthCheckInit.on === 'words' ? 'word' : 'character'
        } count. (${lengthCheckInit.minimum} ${lengthCheckInit.on})`,
      );
    } else {
      inputRef.current.setCustomValidity('');
    }
  }, [
    lengthCheckInit.minimum,
    lengthCheckInit.on,
    lengthCheckInit.required,
    recommendedReached,
    valueCount,
  ]);

  return (
    <>
      <FormInput
        ref={inputRef}
        inputType="textarea"
        type="textarea"
        name={name}
        placeholder={placeholder}
        label={label}
        small_font={smallFont}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onError={onError}
        required={required}
        htmlRequired={htmlRequired}
        containerClassName="mb-0"
        noRequiredStyle={noRequiredStyle}
        rows={rows}
      />

      <p className="text-end text-muted small text-green word-count-wrapper">
        {lengthCheckInit.required &&
          (!isRestrictionCountWithinRange ? (
            <>
              <span role="img" aria-label="red cross mark" className="emoji">
                {loadEmoji(emojis.redCrossMark)}
              </span>
              <span className="error-message">
                {lengthCheckInit.minimum} - {lengthCheckInit.maximum} {lengthCheckInit.on} are
                required
              </span>
            </>
          ) : (
            <>
              <span role="img" aria-label="check mark" className="emoji">
                {loadEmoji(emojis.checkMark)}
              </span>
              <span className="success-message">You have enough {lengthCheckInit.on}</span>
            </>
          ))}
        <span className="word-count">
          {valueCount} {lengthCheckInit.maximum > 0 && `/${lengthCheckInit.maximum} `}
          {lengthCheckInit.on}
        </span>
      </p>
    </>
  );
};
