import { useSelector } from 'react-redux';

/**
 *
 * @param {{session: {assignments: import('src/types/Assignment').Assignment[]}, isSubmitted: boolean}}
 * @returns {number} assignmentId
 */
// eslint-disable-next-line import/no-default-export -- autodisabled
export default function useFirstAssignmentIdBySubmittedStateForSession({ session, isSubmitted }) {
  return useSelector((state) => {
    if (!session?.assignments || !session?.assignments.length) {
      return -1;
    }

    const assignments = session.assignments.map((id) => state.hermes.assignments.byId[id]);
    const firstMatchingAssignment = assignments.find(
      (assignment) => Boolean(assignment?.assignmentSolution?.submittedAt) === isSubmitted,
    );

    return firstMatchingAssignment?.id ?? -1;
  });
}
