import type { CompletedSection } from '@polygence/common';

import type { ApplicationSectionId } from 'src/constants/application-sections';

export const isSectionCompleted = (
  completedSections: CompletedSection[],
  section: ApplicationSectionId,
) => {
  return completedSections.some(
    (completedSection: CompletedSection) => completedSection.section === section,
  );
};

export const uniqueCompletedSections = (completedSections: CompletedSection[]) => {
  const uniqueCompletedSections = [] as CompletedSection[];

  for (const completed of completedSections) {
    if (isSectionCompleted(uniqueCompletedSections, completed.section as ApplicationSectionId)) {
      continue;
    }

    uniqueCompletedSections.push(completed);
  }

  return uniqueCompletedSections;
};
