import { Fireworks } from '@fireworks-js/react';
import type { FireworksHandlers } from '@fireworks-js/react';
import type { AdmissionAdvisorModal, StudentApplication } from '@polygence/common';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Heading, Spacer } from '@polygence/components';
import classNames from 'classnames';
import { useMemo, useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';

import { PageLoad } from 'src/components/PageLoad';
import { StudentCongratulationPage } from 'src/components/student/ApplicationHubPage/StudentCongratulationPage/StudentCongratulationPage';
import styles from 'src/students/StudentProfileCongratulationPage.module.scss';
import { setClarityVariables } from 'src/utils/tracking/clarity';

interface StudentProfileCongratulationPageProps {
  studentApplication: Partial<StudentApplication>;
}

export const StudentProfileCongratulationPage = ({
  studentApplication,
}: StudentProfileCongratulationPageProps) => {
  const [admissionAdvisor, setAdmissionAdvisor] = useState<AdmissionAdvisorModal>({
    firstName: '',
    imageUrl: '',
    role: '',
    calendlyUrl: '',
    calendlyUrlPartnerPays: '',
    fullName: '',
    action: '',
    textTemplate: '',
    textTemplatePartnerPays: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const fireworkRef = useRef<FireworksHandlers>(null);

  useMemo(() => {
    setClarityVariables({
      applicationSubmitted: true,
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    marketplaceApi
      .getMyAdmissionAdvisorModal()
      .then(({ data }) => setAdmissionAdvisor(data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!fireworkRef.current) {
      return;
    }
    fireworkRef.current.start();
    setTimeout(() => {
      if (fireworkRef.current?.isRunning) {
        fireworkRef.current.stop();
      }
    }, 10000);
  }, []);

  if (isLoading) {
    return <PageLoad />;
  }

  const options = {
    hue: { min: 150, max: 190 },
    autoresize: true,
    intensity: 20,
    particles: 100,
    explosion: 4,
    opacity: 0.3,
    friction: 0.91,
    gravity: 0.0,
    flickering: 100,
    traceLength: 1,
    traceSpeed: 20,
    acceleration: 1,
  };

  return (
    <>
      <Fireworks
        ref={fireworkRef}
        options={options}
        style={{
          top: '80px',
          left: 0,
          width: '100%',
          height: '75%',
          position: 'absolute',
          zIndex: -1,
        }}
      />
      <Row className="justify-content-center mt-8">
        <Col className={classNames('d-flex flex-column align-items-center', styles['container'])}>
          <Heading className="mb-6" as="h1">
            Hey, nice work
          </Heading>
          <Heading className="mb-6" fontWeight="normal" size={{ base: 'h5', lg: 'h4' }} as="h2">
            Getting started is the hardest part.
          </Heading>
          <Spacer size={10} />
          {!studentApplication.scholarshipApplicant ? (
            <StudentCongratulationPage admissionAdvisor={admissionAdvisor} />
          ) : (
            <StudentCongratulationPage isScholarship />
          )}
        </Col>
      </Row>
    </>
  );
};
