import type { Meeting, Nullable } from '@polygence/common';
import { externalLink } from '@polygence/common/utils/externalLink';
import { Text, Icon, ToggleSwitch } from '@polygence/components';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ErrorAlert } from 'src/components/hermes/scheduler/ErrorAlert';

interface RecordingOptionProps extends Meeting {
  meetingError: Nullable<{ recordingOn: string[] }>;
  sharingError: boolean;
  onChange: ({ target: { name, value } }: { target: { name: string; value: boolean } }) => void;
  disabled: boolean;
  recordingOptionDisabled: boolean;
  sessionScheduling: boolean;
  resourceRecordingPolicy: string;
}

export const RecordingOption = ({
  recordingOn = true,
  sharingOn,
  meetingError,
  sharingError,
  onChange,
  disabled,
  recordingOptionDisabled,
  sessionScheduling = false,
  resourceRecordingPolicy,
}: RecordingOptionProps) => {
  const handleCheckChange = (name: string, value: boolean) => {
    return onChange({ target: { name, value } });
  };

  return (
    <div className="h-recording">
      <div className="d-flex align-items-center gap-5 px-5 py-3 rounded border bg-grayscale-2 mx-3 my-3">
        <Icon
          id={recordingOn ? 'video' : 'video-off'}
          color={recordingOn ? 'var(--grayscale-6)' : 'var(--danger)'}
        />
        <div>
          <Text
            size="medium"
            className={classnames({ 'text-danger': !recordingOn, 'text-muted': recordingOn })}
          >
            {recordingOn ? 'Session will be recorded.' : "Session won't be recorded."}
          </Text>
          <Text size="small" className="text-muted">
            Learn more from our{' '}
            <Link {...externalLink} to={resourceRecordingPolicy}>
              Recording Policy
            </Link>
          </Text>
        </div>
        <ToggleSwitch
          checked={recordingOn}
          aria-label="enable recording"
          onCheckedChange={(isChecked) => {
            return handleCheckChange('recordingOn', isChecked);
          }}
          className="ms-auto"
          disabled={disabled || recordingOptionDisabled}
        />
      </div>
      {meetingError?.recordingOn && <ErrorAlert messages={meetingError.recordingOn} />}

      <div
        className={classnames('h-recording-details m-5', {
          disabled: !recordingOn,
        })}
      >
        {!sessionScheduling && (
          <div className="form-group ms-7">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="sharingOn"
                id="hermes-scheduler-share-recording"
                disabled={!recordingOn || disabled}
                checked={sharingOn === true}
                onChange={() => {
                  return handleCheckChange('sharingOn', false);
                }}
              />
              <label htmlFor="hermes-scheduler-share-recording">
                Share recording automatically with student.
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="sharingOn"
                id="hermes-scheduler-dont-share-recording"
                disabled={!recordingOn || disabled}
                checked={sharingOn === false}
                onChange={() => {
                  return handleCheckChange('sharingOn', false);
                }}
              />
              <label htmlFor="hermes-scheduler-dont-share-recording">
                Do not share recording with student automatically.
              </label>
            </div>
            {sharingError && (
              <ErrorAlert
                messages={['Please select either share the recording with student or not.']}
              />
            )}
            <Text size="small" alignment="center" className={classnames({ disabled: !sharingOn })}>
              The session recording will be made available to you and your partner on our platform.
            </Text>
          </div>
        )}
        {sessionScheduling && recordingOn && (
          <Text size="small">
            The session recording will be made available to you and your student on our platform.
          </Text>
        )}
      </div>
    </div>
  );
};
