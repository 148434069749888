import * as marketplaceApi from '@polygence/common/api/marketplace';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';

import { SpinningWheel } from 'src/components/SpinningWheel';
import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import femaleExaminesImage from 'src/components/static/images/female-examines.svg';
import { ScholarListItem } from 'src/components/student/ScholarPage/ScholarListItem';

import 'src/components/student/ScholarPage/scholar.sass';

const ScholarPageList = () => {
  const { url: groupUrl } = useParams();
  const [scholarGroup, setScholarGroup] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    marketplaceApi
      .getPeerScholarGroup(groupUrl)
      .then(({ data }) => setScholarGroup(data))
      .catch(console.error)
      .finally(() => {
        return setLoading(false);
      });
  }, [groupUrl]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <Container className="list-page">
      <Row className="justify-content-center my-10">
        <Col
          as="h1"
          className="d-flex flex-wrap flex-md-nowrap align-items-end justify-content-center font-cormorant text-polygence"
        >
          My Peer Students
          {/* eslint-disable-next-line react/jsx-child-element-spacing -- autodisabled */}
          <img
            className="mt-5 mt-md-0 px-5"
            src={femaleExaminesImage}
            alt="Female examines something"
          />
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ gap: '1.5rem' }}>
        {loading && <SpinningWheel />}
        {!loading && !scholarGroup?.peers && <p>You don&apos;t have peer students.</p>}
        {scholarGroup?.peers?.map(
          ({ id, fullName, profilePicture, highSchool, portfolioItems, url, createdAt }) => {
            const projectTitle = portfolioItems && portfolioItems[0]?.title;
            const year = new DateWrapper(createdAt).format(DateFormat.FORMAT_9);
            return (
              <Col
                key={id}
                as={ScholarListItem}
                xs={12}
                sm={6}
                md={3}
                fullName={fullName}
                profilePicture={profilePicture}
                highSchool={highSchool}
                projectTitle={projectTitle}
                year={year}
                url={url}
              />
            );
          },
        )}
      </Row>
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ScholarPageList;
