import { LoginData } from '@polygence/common';
import * as backendApi from '@polygence/common/api/backend';
import { InputField, Button, Icon, Card, Alert, Text } from '@polygence/components';
import { isAxiosError } from 'axios';
import { useState, ChangeEventHandler, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import { WhiteLabelWrapper } from 'src/white-label/WhiteLabelWrapper';

interface WhiteLabelLoginFormProps {
  onLogin: (
    data: Pick<LoginData, 'access' | 'refresh'>,
    callback?: () => void,
    fromPath?: string,
  ) => void;
}

export const WhiteLabelLoginForm = ({ onLogin }: WhiteLabelLoginFormProps) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    genericErrorMessage: '',
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLoginSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setState((state) => ({ ...state, genericErrorMessage: '' }));

    try {
      const { data: result } = await backendApi.login({
        email: state.email,
        password: state.password,
      });

      onLogin(result);
    } catch (error) {
      console.error(error);
      let errorMessage = 'Something went wrong.';

      if (isAxiosError<{ nonFieldErrors?: string; password?: string; email?: string }>(error)) {
        const validationMessages = error.response?.data;
        if (Number(error.response?.status) === 400 && validationMessages?.nonFieldErrors) {
          // eslint-disable-next-line fp/no-mutation
          errorMessage = validationMessages?.nonFieldErrors;
        }
      }

      setState((state) => ({ ...state, genericErrorMessage: errorMessage }));
    }
  };

  const { genericErrorMessage, email, password } = state;

  return (
    <WhiteLabelWrapper
      renderElement={({ name }) => (
        <div className="tw-flex tw-w-screen tw-max-w-[370px] tw-flex-col tw-gap-4">
          <Card>
            <div className="tw-p-8">
              {genericErrorMessage && <Alert variant="danger">{genericErrorMessage}</Alert>}
              <Text
                size="medium"
                fontWeight="bold"
                className="tw-mb-6 tw-text-center"
              >{`Login to the ${name} platform`}</Text>
              <form
                onSubmit={(e) => void handleLoginSubmit(e)}
                autoComplete="on"
                className="tw-flex tw-flex-col tw-gap-4"
              >
                <div>
                  <InputField
                    label="Email Address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                  />
                </div>
                <div className="tw-relative tw-flex tw-flex-col">
                  <InputField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                  <Link
                    to="/user/password-reset"
                    className="tw-absolute tw-right-6 tw-top-4 tw-text-gray-600 hover:tw-text-gray-800"
                  >
                    Forgot?
                  </Link>
                </div>
                <Button startIcon={<Icon id="log-in" />} type="submit" size="lg" variant="primary">
                  Login
                </Button>
              </form>
            </div>
          </Card>
        </div>
      )}
    />
  );
};
