import { commonReducers } from '@polygence/common';
import { Button } from '@polygence/components';
import { useDispatch } from 'react-redux';

import { EXTENSION_REQUEST_ACTIONS } from 'src/components/hermes/constants';
import { useExtensionRequestAction } from 'src/components/hermes/hooks';

export const ProjectDeadlineExtension = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(commonReducers.projectExtensionActions.openProjectExtensionModal());
  };
  const extensionRequestAction = useExtensionRequestAction();

  if (extensionRequestAction !== EXTENSION_REQUEST_ACTIONS.respond) {
    return null;
  }

  return (
    <Button variant="primary" size="sm" onClick={handleClick}>
      {extensionRequestAction}
    </Button>
  );
};
