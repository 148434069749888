import { MyStudents } from 'src/components/Tiles/Hermes/MyStudents';
import { MentorAvailabilityTile } from 'src/components/Tiles/MentorAvailabilityTile/MentorAvailabilityTile';
import { JobBoard } from 'src/components/Tiles/Mentors/JobBoard';
import { OnboardingHub } from 'src/components/Tiles/Mentors/OnboardingHub';
import { TileNavigationItem } from 'src/utils/navigation';

interface getMentorTileNavItemsProps {
  hermesEnabled: boolean;
  experience: boolean;
}

export const getMentorTileNavItems = ({
  hermesEnabled,
  experience,
}: getMentorTileNavItemsProps): TileNavigationItem[] => {
  return [
    {
      name: 'my-students',
      TileComponent: <MyStudents isActive={hermesEnabled} />,
      cols: 4,
    },
    {
      name: 'mentor-onboarding-hub',
      TileComponent: <OnboardingHub experience={experience} />,
      cols: 4,
    },
    {
      name: 'mentor-job-board',
      TileComponent: <JobBoard />,
      cols: 4,
    },
    {
      name: 'mentor-availability',
      TileComponent: <MentorAvailabilityTile />,
      cols: 4,
    },
  ];
};
