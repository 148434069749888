import {
  commonReducers,
  commonThunks,
  commonHooks,
  hermesMobileTabs,
  isValidMobileNavigationTab,
  websocketApi,
  HermesMobileTab,
  ProjectType,
} from '@polygence/common';
import { useCheckServerTime } from '@polygence/common/hooks/useCheckServerTime';
import { Alert } from '@polygence/components';
import { captureMessage } from '@sentry/react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import type { CSSProperties } from 'react';
import { Route, Switch, useParams, useRouteMatch, useLocation, useHistory } from 'react-router';

import { PageLoad } from 'src/components/PageLoad';
import { HermesAISheet } from 'src/components/hermes-ai/HermesAISheet';
import { HermesMobileNavigation } from 'src/components/hermes/MobileNavigation';
import Room from 'src/components/hermes/Room';
import RoomNavigation from 'src/components/hermes/RoomNavigation';
import SessionFeedbackModal from 'src/components/hermes/SessionFeedbackModal';
import SummarySubmitter from 'src/components/hermes/SummarySubmitter';
import { SupportWidget } from 'src/components/hermes/SupportWidget/SupportWidget';
import WritingFeedbackModal from 'src/components/hermes/WritingFeedbackModal';
import { AISummaryReview } from 'src/components/hermes/ai-summary-review/AISummaryReview';
import AssignmentPage from 'src/components/hermes/assignment/AssignmentPage';
import { WorkspaceList } from 'src/components/hermes/chat/WorkspaceList/WorkspaceList';
import { ProjectOverdueModal } from 'src/components/hermes/extension-request/ProjectOverdueModal';
import { useSessionFeedbackRedirectFromMobile } from 'src/components/hermes/hooks';
import { ShowcasingMatchingSurveyModal } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingSurveyModal';
import { ShowcasingMatchingSurveyPage } from 'src/components/hermes/premium-showcasing-support/ShowcasingMatchingSurveyPage';
import { ProjectPlanPage } from 'src/components/hermes/project-plan/ProjectPlanPage';
import Scheduler from 'src/components/hermes/scheduler/Scheduler';
import { Sidebar } from 'src/components/hermes/sidebar/Sidebar';
import { useHermesMobileNavigationGestures } from 'src/hooks/useHermesMobileNavigationGestures';
import { useUserActivity } from 'src/hooks/useUserActivity';
import 'src/components/hermes/hermes.scss';
import { useAppDispatch, useAppSelector } from 'src/store';

const MOBILE_VIEW_WIDTH = '(max-width: 767px)';

const useSetIsUserOnline = () => {
  const dispatch = useAppDispatch();
  const visible = commonHooks.usePageVisibilityWithTimeout();
  const isActive = useUserActivity();
  const isUserOnline = visible && isActive;

  useEffect(() => {
    dispatch(commonReducers.hermesActions.setIsUserOnline(isUserOnline));
  }, [dispatch, isUserOnline]);
};

const DetailView = () => {
  const dispatch = useAppDispatch();
  const selectedRoomId: number | undefined = commonHooks.useSelectedRoomId();

  useEffect(() => {
    websocketApi.getConnection();
  }, [dispatch]);

  return (
    <div className="d-flex overflow-x-hidden">
      <main className="h-container">
        {!selectedRoomId && <PageLoad />}
        {selectedRoomId && (
          <>
            <SummarySubmitter />
            <Scheduler />
            <ShowcasingMatchingSurveyModal />
            <SessionFeedbackModal />
            <WritingFeedbackModal />
            <ProjectOverdueModal />
            <RoomNavigation />
            <Room roomId={selectedRoomId} />
          </>
        )}
      </main>
      {selectedRoomId && (
        <aside className="h-sidebar">
          <Sidebar />
        </aside>
      )}
    </div>
  );
};

const ChatPage = () => {
  const { hash } = useLocation();
  const dispatch = useAppDispatch();
  const activeTab = commonHooks.useMobileNavigationTab();
  const bindNavigationGestures = useHermesMobileNavigationGestures();
  const selectedProject = commonHooks.useSelectedProject();

  const activeTabIndex = Math.max(hermesMobileTabs.indexOf(activeTab), 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkMobileView = useCallback(
    debounce(() => {
      if (hash !== '') {
        // if the hash is set we don't want this hook to modify the navigation tab
        // the proper tab is set by another hook
        return;
      }
      if (
        window.matchMedia(MOBILE_VIEW_WIDTH).matches &&
        activeTab === HermesMobileTab.FullScreen
      ) {
        dispatch(commonReducers.hermesActions.setMobileNavigationTab(HermesMobileTab.RoomList));
      } else if (
        !window.matchMedia(MOBILE_VIEW_WIDTH).matches &&
        activeTab !== HermesMobileTab.FullScreen
      ) {
        dispatch(commonReducers.hermesActions.setMobileNavigationTab(HermesMobileTab.FullScreen));
      }
    }, 100),
    [dispatch, activeTab, hash],
  );

  useEffect(() => {
    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, [checkMobileView]);

  useEffect(() => {
    const tab = hash.substring(1);

    if (isValidMobileNavigationTab(tab)) {
      dispatch(commonReducers.hermesActions.setMobileNavigationTab(tab));
    }
  }, [dispatch, hash]);

  if (!selectedProject) {
    return <PageLoad />;
  }

  const isWhiteLabel = selectedProject.type === ProjectType.FLAGSHIP_WHITE_LABEL;

  return (
    <>
      <div
        className={classNames('hermes-main', isWhiteLabel && 'hermes-main-white-label')}
        // eslint-disable-next-line react/forbid-dom-props
        style={{ '--tabIndex': activeTabIndex } as CSSProperties}
        {...bindNavigationGestures}
      >
        {!isWhiteLabel && <SupportWidget />}
        {!isWhiteLabel && <WorkspaceList />}
        <DetailView />
      </div>
      <HermesMobileNavigation />
    </>
  );
};

const HermesMain = () => {
  const dispatch = useAppDispatch();
  const { wid } = useParams<{ wid: string }>();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const workspaceList = useAppSelector((state) => state.hermes.workspaceList);
  const workspacesLoaded = useAppSelector((state) => state.hermes.workspacesLoaded);
  const currentUser = commonHooks.useCurrentUser();

  useEffect(() => {
    if (!wid) {
      history.push('./');
    }

    if (!workspacesLoaded) {
      return;
    }

    if (
      workspaceList.some((workspace) => {
        return workspace.id === +wid;
      }) ||
      currentUser.isAdmin
    ) {
      dispatch(commonReducers.hermesActions.workspaceSelected(Number(wid)));
      void dispatch(commonThunks.hermesThunks.getWorkspaceById({ workspaceId: Number(wid) }));
    } else {
      history.push('/dashboard/hermes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wid, workspacesLoaded, workspaceList]);

  return (
    <>
      <Switch>
        <Route path={`${path}/assignment/:sessionId`} component={AssignmentPage} />
        <Route path={`${path}/showcasing`} component={ShowcasingMatchingSurveyPage} />
        <Route path={`${path}/project-plan`} component={ProjectPlanPage} />
        <Route path={`${path}/meeting-summary/:meetingId/review`}>
          <AISummaryReview />
        </Route>
        <Route path={path} component={ChatPage} />
      </Switch>
      <Switch>
        <Route path={`${path}/assistant`}>
          <HermesAISheet previousUrl={url} />
        </Route>
      </Switch>
    </>
  );
};

const HermesNoWorkspaceId = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const hasLoadedWorkspaces = useAppSelector((state) => state.hermes.workspacesLoaded);
  const workspaceList = useAppSelector((state) => state.hermes.workspaceList);
  const { userType = '' } = commonHooks.useCurrentUser() || {};

  useEffect(() => {
    if (workspaceList && workspaceList.length > 0) {
      const firstWorkspaceWithoutUnseenMessage = workspaceList.find(
        (workspace) => workspace.unseenMessageCount === 0,
      );
      const firstWorkspace = firstWorkspaceWithoutUnseenMessage ?? workspaceList[0];

      if (firstWorkspace) {
        history.push(`${path}/${firstWorkspace.id}`);
      }
    }
  }, [workspaceList, history, path]);

  return hasLoadedWorkspaces ? (
    <div className="container pt-10">
      <Alert variant="primary" className="text-center">
        You have no {['student', 'mentor'].includes(userType) ? 'projects' : 'messages'} yet.
      </Alert>
    </div>
  ) : (
    <PageLoad />
  );
};

const HermesWrapper = () => {
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const selectedWorkspaceId = commonHooks.useSelectedWorkspaceId();
  useSetIsUserOnline();
  useSessionFeedbackRedirectFromMobile();
  commonHooks.useSendUnsentMessagesOnLoad();
  useCheckServerTime({
    reportError: (userTimeZone, userTime, originalServerTime, convertedServerTime) => {
      captureMessage(`Server time does not match user's time.`, {
        tags: {
          userTime,
          userTimeZone,
          originalServerTime,
          convertedServerTime,
        },
      });
    },
  });

  useEffect(() => {
    void dispatch(commonThunks.hermesThunks.getWorkspaceDisplayList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedWorkspaceId) {
      void dispatch(
        commonThunks.hermesThunks.getAssignmentBlueprints({ workspaceId: selectedWorkspaceId }),
      );
    }
  }, [selectedWorkspaceId, dispatch]);

  useEffect(() => {
    websocketApi.getConnection();
  }, [dispatch]);

  return (
    <Switch>
      <Route path={`${path}/:wid`} component={HermesMain} />
      <Route path="" component={HermesNoWorkspaceId} />
    </Switch>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default HermesWrapper;
