import { ensureProtocol } from '@polygence/common';
import type * as H from 'history';
import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import type { match } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MobileMenu } from 'src/components/NavigationBar/MobileMenu';
import 'src/components/NavigationBar/navigationBar.sass';
import { useNavigationBarContext } from 'src/components/NavigationBar/NavigationBarContext';
import { convertToKeyString } from 'src/utils';
import type { NavbarNavigationItem } from 'src/utils/navigation';
import { getTracker } from 'src/utils/tracking/factory';

interface NavigationBarProps {
  fluid: boolean;
  brand: ReactNode;
  navItems: NavbarNavigationItem[];
}

const NavbarLogo = ({ brand }: { brand: NavigationBarProps['brand'] }) => {
  const { homeUrl } = useNavigationBarContext();
  const isInternal = homeUrl?.startsWith('/');

  return isInternal ? (
    <NavLink to={homeUrl} className="logo">
      {brand}
    </NavLink>
  ) : (
    <a href={ensureProtocol(homeUrl)} className="logo">
      {brand}
    </a>
  );
};

const checkIsActive =
  (activePaths: Readonly<RegExp[]>) => (_match: match | null, location: H.Location) => {
    return activePaths.some((path) => location.pathname.match(path));
  };

const NavigationBar = ({ fluid, navItems, brand }: NavigationBarProps) => {
  return (
    <Container fluid={fluid} className="hide-in-print">
      <div className="navigation-bar__wrapper">
        <NavbarLogo brand={brand} />
        <nav>
          <ul>
            {Array.isArray(navItems) &&
              navItems.map(({ label, href, name, items, DropdownComponent, activePaths }) => {
                if (DropdownComponent) {
                  return <DropdownComponent key={name} label={label} items={items} />;
                }
                return (
                  href && (
                    <li key={href} className={convertToKeyString(label ?? '')}>
                      <NavLink
                        {...(activePaths && activePaths?.length > 0
                          ? { isActive: checkIsActive(activePaths) }
                          : {})}
                        to={href}
                        exact={name === 'home'}
                        onClick={() => getTracker().track('Navigation link clicked', { name })}
                      >
                        {label}
                      </NavLink>
                    </li>
                  )
                );
              })}
          </ul>
        </nav>
      </div>
      <div className="mobileNavigation__wrapper">
        <NavbarLogo brand={brand} />
        <MobileMenu generalNavItems={navItems ?? []} />
      </div>
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default NavigationBar;
