/* eslint-disable sonarjs/no-duplicate-string */
import { StringUserType, UserInfoToken, UserType } from '@polygence/common';
import { getCommonStore } from '@polygence/common/commonSettings';
import { ProductType } from '@polygence/common/types/hermes';
import * as roleUtils from '@polygence/common/utils/roles';
import type { IconVariable } from '@polygence/components';

import { NavbarDropdownMenu } from 'src/components/NavigationBar/NavbarDropdownMenu/NavbarDropdownMenu';
import { ProfileMenu } from 'src/components/NavigationBar/ProfileMenu';
import type tileIllustrations from 'src/components/Tiles/tile-illustrations';
import { PROFILE_MAIN } from 'src/components/constants';
import { getMyInfo, isMentor, isStudent } from 'src/components/getMyInfo';
import { store } from 'src/store';
import { urls } from 'src/urls';
import { getLandingUrl } from 'src/utils';
import { isCartCookieProductValid } from 'src/utils/cart';
import {
  deleteRedirectUrlCookie,
  getRedirectUrlFromCookie,
} from 'src/utils/redirectFromRegistration';
import { getTileNavItemsByUserType } from 'src/utils/tile-nav-items/items';

const DASHBOARD_ENDPOINT = '/dashboard';
const SYMPOSIUM_HUB = 'Symposium Hub';
const APPLE_HIDDEN_EMAIL = 'privaterelay.appleid.com';

export interface NavItems {
  mainNav: NavbarNavigationItem[];
  tilesNav: TileNavigationItem[];
}

export interface NavigationItem {
  name: string;
  href?: string;
  label?: string;
  show?: boolean;
  onClick?: () => void;
  items?: NavigationItem[];
  activePaths?: Readonly<RegExp[]>;
}

export interface NavbarNavigationItem extends NavigationItem {
  iconId?: IconVariable;
  items?: NavbarNavigationItem[];
  DropdownComponent?: React.ElementType;
}

export interface TileNavigationItem extends NavigationItem {
  TileComponent?: JSX.Element;
  illustration?: keyof typeof tileIllustrations;
  highPriority?: boolean;
  lowPriority?: boolean;
  cols: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  rows?: 1 | 2 | 3 | 4;
}

export function getGuidebookUrl({
  userType,
  isEnrolled,
  hasActiveProject,
}: {
  userType?: StringUserType;
  isEnrolled?: boolean;
  hasActiveProject?: boolean;
}) {
  if (!userType) {
    return;
  }
  const showActiveGuidebook = isEnrolled || hasActiveProject;

  const guidebookUrls = {
    student: {
      active: '/student-guidebook/',
      inactive: '/n/student-guidebook-preview/',
    },
    mentor: {
      active: '/mentor-guidebook/',
      inactive: '/mentor-guidebook/',
    },
    parent: {
      active: '/n/parent-guidebook/',
      inactive: '/n/parent-guidebook-preview/',
    },
    counselor: {
      active: '/n/counselor-guidebook/',
      inactive: '/n/counselor-guidebook/',
    },
  } as const;
  return guidebookUrls[userType][showActiveGuidebook ? 'active' : 'inactive'];
}

const getGuidebookActivePaths = ({
  userType,
  isEnrolled,
}: {
  userType?: StringUserType;
  isEnrolled?: boolean;
}) => {
  if (!userType) {
    return;
  }
  const guidebookUrls = {
    student: {
      active: [/\/student-guidebook/g, /\/n\/student-guidebook/g],
      inactive: [/\/n\/student-guidebook-preview/g],
    },
    mentor: {
      active: [/\/mentor-guidebook/g, /\/n\/mentor-guidebook/g],
      inactive: [/\/mentor-guidebook/g, /\/n\/mentor-guidebook/g],
    },
    parent: {
      active: [/\/n\/parent-guidebook/g],
      inactive: [/\/n\/parent-guidebook-preview/g],
    },
    counselor: {
      active: [/\/n\/counselor-guidebook/g],
      inactive: [/\/n\/counselor-guidebook/g],
    },
  } as const;
  const activePaths = guidebookUrls[userType][isEnrolled ? 'active' : 'inactive'];
  return [...activePaths, /\/project-support-video/g];
};

export const getProfileUrl = (userType: StringUserType) => {
  const profileUrls = {
    mentor: '/home/profile',
    student: urls.settingsPage(),
    parent: '/parent/profile',
    counselor: '/counselor/profile',
  };
  return profileUrls[userType];
};

export const getApplicationUrl = (userType: StringUserType, screened?: boolean) => {
  const profileUrls = {
    mentor: !screened && DASHBOARD_ENDPOINT,
    student: '/student/profile',
    counselor: undefined,
    parent: undefined,
  };
  return profileUrls[userType];
};

export const getAfterSignInUrl = () => {
  const { approved, userType, completed, otherInfo, email } = store.getState().user;

  const redirectUrl = getRedirectUrlFromCookie();
  if (redirectUrl) {
    deleteRedirectUrlCookie();
    return redirectUrl;
  }

  if (email.trim().endsWith(APPLE_HIDDEN_EMAIL)) {
    return '/email-change-apple';
  }

  const hasAvailablePodsToken =
    typeof otherInfo['availablePodsTokenCount'] === 'number' &&
    otherInfo['availablePodsTokenCount'] > 0;
  if (
    userType === UserType.STUDENT &&
    (hasAvailablePodsToken ||
      otherInfo['isInterestedInPod'] ||
      isCartCookieProductValid(ProductType.PODS))
  ) {
    return urls.getPodsPage();
  }

  if (userType === UserType.STUDENT && isCartCookieProductValid(ProductType.PATHFINDERS)) {
    return urls.getPathfinderStudentApplication();
  }

  if (!approved && userType === UserType.COUNSELOR) {
    return urls.getCounselorProfile();
  }

  if (completed || userType === UserType.COUNSELOR) {
    return DASHBOARD_ENDPOINT;
  }

  return userType === UserType.STUDENT ? getApplicationUrl(userType) : getProfileUrl(userType);
};

function getProjectLabel(userType: StringUserType) {
  if (userType === UserType.STUDENT) {
    return 'My Projects';
  } else if (userType === UserType.MENTOR) {
    return 'My Students';
  }
  return 'Workspaces';
}

function filterItemToShow({ show }: { show?: boolean | null }) {
  return show == null || show;
}

function isUserInfo(userInfo: UserInfoToken | Record<string, never>): userInfo is UserInfoToken {
  return Object.keys(userInfo).length !== 0;
}

function handleUserSettingsLabel(userType: string, screened: boolean) {
  if (userType === UserType.MENTOR && !screened) {
    return 'Application';
  }

  if (userType === UserType.STUDENT) {
    return 'Settings';
  }

  return 'Profile';
}

export interface getMyNavItemsProps {
  userType: StringUserType;
  screened: boolean;
  approved: boolean;
  eligibleForReferral: boolean;
  hermesEnabled: boolean;
  isReapplyAllowed?: boolean;
  hasActiveProject?: boolean;
  scholarPageEnabled: boolean;
  opportunitiesEnabled?: boolean;
  admissionAdvisorEmail?: string | null;
  enrolledAt?: string;
  scholarPageSlug?: string;
  symposiumPresenter?: boolean;
  pathfindersEnabled?: boolean;
  studentJourneyTileSections?: string[];
  scholarshipApplicant?: boolean;
  isInterestedInPod?: boolean;
  experience?: boolean;
  polyGptEnabled?: boolean;
  isStripeOnboardingAllowed?: boolean;
  onLogout: VoidFunction;
}

export const getMyNavItems = ({
  userType,
  screened,
  approved,
  eligibleForReferral,
  hermesEnabled,
  isReapplyAllowed,
  hasActiveProject,
  opportunitiesEnabled,
  admissionAdvisorEmail,
  enrolledAt,
  symposiumPresenter,
  pathfindersEnabled,
  studentJourneyTileSections,
  scholarshipApplicant,
  isInterestedInPod,
  scholarPageEnabled,
  scholarPageSlug,
  experience,
  isStripeOnboardingAllowed,
  onLogout,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
getMyNavItemsProps): NavItems => {
  const state = getCommonStore().getState();
  const currentUser = state.user;
  const partnerPaysWorkflow = currentUser.otherInfo['isPartnerPaysWorkflow'] as boolean;
  const userInfo = getMyInfo();
  const isUser = isUserInfo(userInfo);
  const type: { roles: string[] } = { roles: [] };
  const userRoles = isUser ? userInfo : type;
  const showReapply = userType === UserType.STUDENT && isReapplyAllowed;
  const studentHasJourneySections =
    !!studentJourneyTileSections && studentJourneyTileSections.length > 0;

  const myStudentsNavItems: NavbarNavigationItem[] = [
    {
      name: 'my-students',
      label: 'My Students',
      show: userType === UserType.COUNSELOR,
      href: '/counselor/students',
      iconId: 'user',
    },
    {
      name: 'my-children',
      label: 'My Children',
      show: userType === UserType.PARENT,
      href: '/parent/dashboard',
      iconId: 'user',
    },
  ];

  const profileNavItems: NavbarNavigationItem[] = [
    {
      name: 'profile',
      label: handleUserSettingsLabel(userType, screened),
      href: getProfileUrl(userType),
      iconId: 'user',
    },
    {
      name: 'hermes',
      label: getProjectLabel(userType),
      show:
        hermesEnabled &&
        ([UserType.MENTOR, UserType.STUDENT] as StringUserType[]).includes(userType),
      href: '/dashboard/hermes',
      iconId: 'edit',
    },
    {
      name: 'user-settings',
      label: 'Settings',
      show: ([UserType.MENTOR, UserType.COUNSELOR, UserType.PARENT] as StringUserType[]).includes(
        userType,
      ),
      href: urls.settingsPage(),
      iconId: 'settings',
    },
    {
      name: 'student-application',
      label: partnerPaysWorkflow ? 'Enrollment' : 'Application',
      show: userType === UserType.STUDENT,
      href: urls.studentApplication(),
      iconId: 'arrow-right',
    },
    {
      name: 'student-reapplication',
      label: 'Reapply',
      show: showReapply,
      href: urls.studentReapplication(),
      iconId: 'rotate-ccw',
    },
    {
      name: 'payment',
      label: 'Payment',
      show:
        userType === UserType.MENTOR ||
        (userType === UserType.COUNSELOR && approved && isStripeOnboardingAllowed),
      href: '/dashboard/payment-setup',
      iconId: 'dollar-sign',
    },
    {
      name: 'reset-password',
      label: 'Reset Password',
      href: '/user/password-reset',
      iconId: 'key',
    },
    {
      name: 'email-change',
      label: 'Change Email',
      href: urls.emailChange(),
      iconId: 'mail',
    },
    {
      name: 'logout',
      label: 'Logout',
      onClick: onLogout,
      iconId: 'log-out',
    },
  ];

  const resourcesNavItems: NavbarNavigationItem[] = [
    {
      name: 'guides',
      label: 'Guidebook',
      show: userType !== UserType.PARENT,
      href: getGuidebookUrl({ userType, hasActiveProject, isEnrolled: Boolean(enrolledAt) }),
      iconId: 'help-circle',
    },
    {
      name: 'core-application',
      label: 'Core Application',
      show: isStudent() && studentHasJourneySections,
      href: urls.studentApplication(),
      iconId: 'polygence',
    },
    {
      name: 'project-idea-generator',
      label: 'Project Idea Generator',
      show: isStudent() && studentHasJourneySections,
      href: urls.projectIdeaGenerator(),
      iconId: 'cpu',
    },
    {
      name: 'pathfinders',
      label: 'Pathfinders',
      show:
        isStudent() &&
        studentHasJourneySections &&
        (pathfindersEnabled || isCartCookieProductValid(ProductType.PATHFINDERS)),
      href: urls.getPathfinderStudentApplication(),
      iconId: 'three-people',
    },
    {
      name: 'pods',
      label: 'Pods',
      show: isStudent() && studentHasJourneySections && isInterestedInPod,
      href: urls.getPodsPage(),
      iconId: 'pods',
    },
    {
      name: 'project-examples',
      label:
        userType === UserType.MENTOR ? 'Student Project Examples' : 'Polygence Student Projects',
      href: getLandingUrl('/projects'),
      iconId: 'archive',
    },
    {
      name: 'showcases',
      label: 'HS Journals & Showcasing Opportunities',
      show: userType !== UserType.PARENT && opportunitiesEnabled,
      href: '/dashboard/showcasing-opportunities',
      iconId: 'box',
    },
    {
      name: 'symposium-hub-for-students',
      label: SYMPOSIUM_HUB,
      show: isStudent() && symposiumPresenter,
      href: urls.symposiumHubForStudents(),
      iconId: 'calendar',
    },
    {
      name: 'symposium-hub-for-mentors',
      label: SYMPOSIUM_HUB,
      show: isMentor(),
      href: urls.symposiumHubForMentors(),
      iconId: 'calendar',
    },
    {
      name: 'mentors',
      label: 'Mentors',
      show: userType === UserType.PARENT && studentHasJourneySections,
      href: '/dashboard/mentors',
      iconId: 'user',
    },
    {
      name: 'blog',
      label: 'Blog',
      href: getLandingUrl('/blog'),
      iconId: 'book-open',
    },
  ];

  const counselorResourcesNavItems: NavbarNavigationItem[] = [
    {
      name: 'educator-brochure',
      label: 'Educator Brochure',
      href: 'https://dpl6hyzg28thp.cloudfront.net/media/Polygence_Educator_Brochure_2025.pdf',
      iconId: 'external-link',
    },
    {
      name: 'family-pitch-deck',
      label: 'Family Pitch Deck',
      href: 'https://dpl6hyzg28thp.cloudfront.net/media/Polygence_Family_Pitch_Deck_2024.pdf',
      iconId: 'external-link',
    },
    {
      name: 'educator-guidebook',
      label: 'Educator Guidebook',
      href: '/n/counselor-guidebook/',
      iconId: 'align-left',
    },
    {
      name: 'past-projects',
      label: 'Past Student Projects',
      href: getLandingUrl('/projects'),
      iconId: 'archive',
    },
    {
      name: 'showcases',
      label: 'HS Journals & Showcasing Opportunities',
      show: opportunitiesEnabled,
      href: '/dashboard/showcasing-opportunities',
      iconId: 'box',
    },
    {
      name: 'ready-to-share-email',
      label: 'Student Info Email Template',
      href: '/counselor/ready-to-share-email',
      iconId: 'share',
      show: !partnerPaysWorkflow,
    },
  ];

  const tilesNavItems: TileNavigationItem[] = [
    ...getTileNavItemsByUserType({
      userType,
      hermesEnabled,
      currentUser: state.user,
      studentJourneyTileSections,
      pathfindersEnabled: pathfindersEnabled ?? false,
      isInterestedInPod: isInterestedInPod ?? false,
      admissionAdvisorEmail: admissionAdvisorEmail,
      scholarshipApplicant: scholarshipApplicant ?? false,
      enrolledAt,
      scholarPageEnabled,
      scholarPageSlug,
      approved,
      screened,
      opportunitiesEnabled: opportunitiesEnabled ?? false,
      experience: experience ?? false,
    }),
  ];

  const mainNavItems: NavbarNavigationItem[] = [
    {
      name: 'home',
      label: 'Home',
      href:
        userType === UserType.COUNSELOR && !approved ? urls.getCounselorProfile() : '/dashboard',
      iconId: 'home',
    },
    ...myStudentsNavItems,
    {
      name: 'application',
      label: partnerPaysWorkflow ? 'Enrollment' : 'Application',
      show: userType === UserType.STUDENT && !hasActiveProject && !enrolledAt,
      href: '/student/profile',
      iconId: 'arrow-right',
      activePaths: [/\/student\/profile/g, /\/student\/\d+\/application\/\d+/g],
    },
    {
      name: 'profile',
      label: 'Profile',
      show: userType === UserType.MENTOR && !approved,
      href: getProfileUrl(userType),
      iconId: 'arrow-left',
    },
    {
      name: 'messages',
      label: 'Messages',
      show: hermesEnabled && userType === UserType.PARENT,
      href: '/dashboard/hermes',
      iconId: 'edit',
    },
    {
      name: 'guides',
      label: 'Guidebook',
      show: userType === UserType.PARENT,
      href: getGuidebookUrl({ userType, hasActiveProject, isEnrolled: Boolean(enrolledAt) }),
      iconId: 'help-circle',
      activePaths: getGuidebookActivePaths({ userType, isEnrolled: Boolean(enrolledAt) }),
    },
    {
      name: 'referral',
      label: `${isStudent() ? 'Nominate' : 'Refer'} a friend`,
      show: eligibleForReferral,
      href: '/referral',
      iconId: 'users',
    },
    {
      name: 'resources',
      label: 'Resources',
      DropdownComponent: NavbarDropdownMenu,
      show:
        (userType === UserType.COUNSELOR && approved) ||
        userType === UserType.STUDENT ||
        userType === UserType.MENTOR ||
        (userType === UserType.PARENT && studentHasJourneySections),
      items:
        userType === UserType.COUNSELOR
          ? counselorResourcesNavItems.filter(filterItemToShow)
          : resourcesNavItems.filter(filterItemToShow),
      href: '#',
      iconId: 'book',
    },
    {
      name: 'admin',
      label: 'Admin',
      show: roleUtils.hasAdminDashboardAccess(userRoles),
      href: '/administrator',
      iconId: 'lock',
    },
    {
      name: PROFILE_MAIN,
      DropdownComponent: ProfileMenu,
      items: profileNavItems.filter(filterItemToShow),
      iconId: 'user',
    },
  ];

  return {
    tilesNav: tilesNavItems.filter(filterItemToShow),
    mainNav: mainNavItems.filter(filterItemToShow),
  };
};
