import { Alert } from '@polygence/components';

interface ErrorPageProps {
  error?: string;
}

export const ErrorPage = ({ error = 'Something went wrong' }: ErrorPageProps) => {
  return (
    <div className="container">
      <Alert variant="danger" className="text-center mt-7">
        {error}
      </Alert>
    </div>
  );
};
