import { commonThunks, commonHooks } from '@polygence/common';
import { Heading, Text, Icon } from '@polygence/components';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { isStudent as testIsStudent } from 'src/components/getMyInfo';
import CollapsibleAssignment from 'src/components/hermes/assignment/Assignment';
import useFirstAssignmentIdBySubmittedStateForSession from 'src/components/hermes/assignment/useFirstAssignmentIdBySubmittedStateForSession';
import useNextSession from 'src/components/hermes/assignment/useNextSession';
import { getDeadlineFromNextSession } from 'src/components/hermes/assignment/utils';
import { getTracker } from 'src/utils/tracking/factory';

const AssignmentPage = () => {
  const dispatch = useDispatch();

  const { sessionId } = useParams();
  const selectedProject = commonHooks.useSelectedProject();
  const selectedWorkspaceId = commonHooks.useSelectedWorkspaceId();
  const session = commonHooks.useSessionBySessionId(Number(sessionId));
  const user = commonHooks.useCurrentUser();
  const isStudent = testIsStudent(user?.userType);
  const assignmentToOpen = useFirstAssignmentIdBySubmittedStateForSession({
    session,
    isSubmitted: !isStudent,
  });
  const nextSession = useNextSession(session);
  const deadline = getDeadlineFromNextSession(nextSession, session?.sessionNumber, user?.timeZone);

  useEffect(() => {
    if (selectedProject?.id && !session?.id) {
      dispatch(commonThunks.hermesThunks.getSessionsForProject({ projectId: selectedProject.id }));
    }
  }, [session?.id, selectedProject?.id, dispatch]);

  useEffect(() => {
    if (session?.id) {
      getTracker().track('Assignment page opened', {
        sessionId: session.id,
        workspaceId: selectedWorkspaceId,
        userType: user.userType,
      });
    }
  }, [session?.id, session?.assignmentSolution, selectedWorkspaceId, user?.userType]);

  if (!session) {
    return null;
  }

  return (
    <Container className="h-assignment">
      <div className="vstack gap-4 mb-9">
        <Link to="./" className="hstack gap-4 fw-light fs-7">
          <Icon id="chevron-left" />
          Back to Project
        </Link>
        <Heading as="h1" size="h3" alignment="left">
          Session {session.sessionNumber} Assignments
        </Heading>
        <div className="hstack gap-8">
          <Text as="span" fontWeight="bold" size="large">
            Due date
          </Text>
          <Text as="span" fontWeight="bold" size="large" className="text-primary">
            {deadline}
          </Text>
        </div>
      </div>
      <section className="vstack gap-4">
        {session.assignments.map((assignment) => (
          <CollapsibleAssignment
            assignmentId={assignment}
            sessionId={sessionId}
            open={assignment === assignmentToOpen}
            key={assignment}
          />
        ))}
      </section>
    </Container>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default AssignmentPage;
