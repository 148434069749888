import { commonHooks, ProjectType, RoomTypes, WorkspaceDisplay } from '@polygence/common';
import { Text } from '@polygence/components';
import classNames from 'classnames';
import { generatePath, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { MessageBadge } from 'src/components/hermes/chat/WorkspaceList/MessageBadge';
import RoomListLoungePicture from 'src/components/hermes/chat/WorkspaceList/RoomListLoungePicture';
import RoomListProfilePictures from 'src/components/hermes/chat/WorkspaceList/RoomListProfilePictures';
import { getOtherParticipants, getRoomName } from 'src/components/hermes/utils';

interface ChatListItemProps {
  workspace: WorkspaceDisplay;
  isSelected: boolean;
}

const PATHFINDERS = 'Pathfinders';
const LAUNCHPAD = 'Launchpad';
const DIAGNOSTIC = 'Reflection Session';
const PATHFINDERS_CLASS = 'h-roomtype-pathfinder';
const PREMIUM_SHOWCASING_CLASS = 'h-roomtype-showcasing';

const CLASS_BY_ROOM_TYPE = {
  [RoomTypes.LOUNGE]: 'h-roomtype-lounge',
  [RoomTypes.BASIC]: '',
  [ProjectType.FLAGSHIP]: 'h-roomtype-flagship',
  [ProjectType.FLAGSHIP_WHITE_LABEL]: 'h-roomtype-flagship',
  [ProjectType.PATHFINDER_DEPRECATED]: PATHFINDERS_CLASS,
  [ProjectType.PATHFINDER_LAUNCHPAD]: PATHFINDERS_CLASS,
  [ProjectType.PATHFINDER_STANDALONE]: PATHFINDERS_CLASS,
  [ProjectType.PATHFINDER_DIAGNOSTIC]: 'h-roomtype-diagnostic',
  [ProjectType.PREMIUM_SHOWCASING_PUBLISHING]: PREMIUM_SHOWCASING_CLASS,
  [ProjectType.PREMIUM_SHOWCASING_PRESENTING]: PREMIUM_SHOWCASING_CLASS,
  [ProjectType.PREMIUM_SHOWCASING_COMPETING]: PREMIUM_SHOWCASING_CLASS,
  [ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA]: PREMIUM_SHOWCASING_CLASS,
  [ProjectType.PODS]: 'h-roomtype-pods',
  [ProjectType.ADVANCED_SCHOLARS_PROGRAM]: 'h-roomtype-asp',
  [ProjectType.RESEARCH_COACH]: 'h-roomtype-research-coach',
} as const;

const LABEL_BY_ROOM_TYPE = {
  [RoomTypes.LOUNGE]: 'Lounge',
  [RoomTypes.BASIC]: '',
  [ProjectType.FLAGSHIP]: 'Project',
  [ProjectType.FLAGSHIP_WHITE_LABEL]: 'Project',
  [ProjectType.PATHFINDER_DEPRECATED]: PATHFINDERS,
  [ProjectType.PATHFINDER_LAUNCHPAD]: LAUNCHPAD,
  [ProjectType.PATHFINDER_STANDALONE]: PATHFINDERS,
  [ProjectType.PATHFINDER_DIAGNOSTIC]: DIAGNOSTIC,
  [ProjectType.PREMIUM_SHOWCASING_PUBLISHING]: 'Publishing',
  [ProjectType.PREMIUM_SHOWCASING_PRESENTING]: 'Presenting',
  [ProjectType.PREMIUM_SHOWCASING_COMPETING]: 'Competing',
  [ProjectType.PREMIUM_SHOWCASING_MULTIMEDIA]: 'Multimedia',
  [ProjectType.PODS]: 'Pods',
  [ProjectType.ADVANCED_SCHOLARS_PROGRAM]: 'Advanced Scholars',
  [ProjectType.RESEARCH_COACH]: 'Research Coach',
} as const;

const isMobile = () => window.matchMedia('(max-width: 576px)').matches;

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
const ChatListItem: React.FC<ChatListItemProps> = ({
  workspace: { id: workspaceId, lounge, participants, type: workspaceType, unseenMessageCount },
  isSelected,
}) => {
  const currentUser = commonHooks.useCurrentUser();
  const { path } = useRouteMatch();
  const otherParticipants = getOtherParticipants(participants, currentUser);
  const roomName = getRoomName(lounge, otherParticipants, currentUser);
  const toWorkspaceLink = generatePath(`${path}${isMobile() ? '#chat' : ''}`, { wid: workspaceId });

  return (
    <Link
      to={toWorkspaceLink}
      className={classNames('h-chat-list-item align-items-center', {
        selected: isSelected,
        unseen: unseenMessageCount > 0,
      })}
      data-testid={roomName}
    >
      {lounge ? (
        <RoomListLoungePicture lounge={lounge} />
      ) : (
        <RoomListProfilePictures participants={otherParticipants} />
      )}
      <div className="h-names-and-roomtype px-2 py-3">
        <Text size="large" fontWeight="semibold">
          {roomName}
        </Text>
        <Text
          size="small"
          className={CLASS_BY_ROOM_TYPE[workspaceType]}
          style={{ marginTop: '-6px' }}
        >
          {LABEL_BY_ROOM_TYPE[workspaceType]}
        </Text>
      </div>
      {unseenMessageCount > 0 && (
        <div className="h-message-badge-container">
          <MessageBadge messageCount={unseenMessageCount} />
        </div>
      )}
    </Link>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ChatListItem;
