import type { StudentProfileId, UserId, WorkspaceId } from '@polygence/common';
import { Heading } from '@polygence/components';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import { MouseEventHandler, createContext, useContext, useMemo, useState } from 'react';

import { Loader } from 'src/components/Loader';
import styles from 'src/components/common/StudentSnapshot/StudentSnapshot.module.scss';
import {
  StudentSnapshotContent,
  StudentSnapshotErrorInContent,
} from 'src/components/common/StudentSnapshot/StudentSnapshotContent';
import { StudentSnapshotSidebar } from 'src/components/common/StudentSnapshot/StudentSnapshotSidebar';
import { UserCardBadge } from 'src/components/common/UserCard/UserCardBadge';
import { UserCardUserInfo } from 'src/components/common/UserCard/UserCardUserInfo';
import {
  StudentSnapshotPage,
  useStudentSnapshotQuery,
  useUserCardsQuery,
} from 'src/reducers/marketplaceReducer';
import { getTracker } from 'src/utils/tracking/factory';

export interface StudentSnapshotProps {
  studentId: StudentProfileId;
  userId: UserId;
  workspaceId?: WorkspaceId;
}

export interface StudentSnapshotContextType {
  workspaceId?: number | undefined;
  studentId?: number;
  selectedTabIndex: number;
  setSelectedTabIndex?: (index: number) => void;
}

export const StudentSnapshotContext = createContext<StudentSnapshotContextType>({
  selectedTabIndex: 0,
});

export const StudentSnapshot = ({ studentId, userId, workspaceId }: StudentSnapshotProps) => {
  const { data: studentSnapshot, isLoading: isSnapshotLoading } = useStudentSnapshotQuery({
    studentId,
    workspaceId,
  });
  const { data: userCard, isLoading: isUserCardLoading } = useUserCardsQuery({
    userId,
    workspaceId,
  });
  const [selectedTab, setSelectedTab] = useState<'projectInfo' | 'timeline' | 'studentInfo'>(
    'projectInfo',
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const contextValue = useMemo(
    () => ({
      selectedTabIndex,
      setSelectedTabIndex,
      workspaceId,
      studentId,
    }),
    [selectedTabIndex, setSelectedTabIndex, workspaceId, studentId],
  );

  const isLoading = isSnapshotLoading || isUserCardLoading;

  const shouldShowTabs =
    studentSnapshot?.studentInfo && studentSnapshot?.projectInfo && studentSnapshot.timeline;

  const selectTab =
    (tabName: 'projectInfo' | 'timeline' | 'studentInfo'): MouseEventHandler =>
    (_) => {
      if (tabName !== selectedTab) {
        setSelectedTab(tabName);
        setSelectedTabIndex(0);
        getTracker().track('Student Snapshot Tab Changed', {
          tab: tabName,
          workspaceId: workspaceId ?? null,
          studentId: studentId,
        });
      }
    };

  if (isLoading) {
    return <Loader className="mx-auto my-8" />;
  }

  if (!studentSnapshot) {
    return null;
  }

  return (
    <StudentSnapshotContext.Provider value={contextValue}>
      <section className={styles['studentSnapshotGrid']}>
        {userCard && (
          <>
            <div className={styles['userInfo']}>
              <UserCardUserInfo {...userCard} />
            </div>
            <div className={styles['userBadge']}>
              {userCard.badges.map((badge) => (
                <UserCardBadge key={badge.label} badge={badge} />
              ))}
            </div>
          </>
        )}
        {shouldShowTabs && (
          <div className={styles['tabs']}>
            <button
              type="button"
              onClick={selectTab('projectInfo')}
              className={classNames({
                [styles['active'] as string]: selectedTab === 'projectInfo',
              })}
            >
              Project info
            </button>
            <button
              type="button"
              onClick={selectTab('timeline')}
              className={classNames({ [styles['active'] as string]: selectedTab === 'timeline' })}
            >
              Timeline
            </button>
            <button
              type="button"
              onClick={selectTab('studentInfo')}
              className={classNames({
                [styles['active'] as string]: selectedTab === 'studentInfo',
              })}
            >
              Student info
            </button>
          </div>
        )}
        {(selectedTab === 'projectInfo' || !shouldShowTabs) && (
          <StudentSnapshotContentWithSidebar page={studentSnapshot.projectInfo} />
        )}
        {(selectedTab === 'timeline' || !shouldShowTabs) && (
          <StudentSnapshotContentWithSidebar page={studentSnapshot.timeline} />
        )}
        {(selectedTab === 'studentInfo' || !shouldShowTabs) && (
          <StudentSnapshotContentWithSidebar page={studentSnapshot.studentInfo} />
        )}
      </section>
    </StudentSnapshotContext.Provider>
  );
};

const StudentSnapshotContentWithSidebar = ({ page }: { page: StudentSnapshotPage | null }) => {
  const { selectedTabIndex } = useContext(StudentSnapshotContext);

  if (!page) {
    return null;
  }

  return (
    <>
      {page.title && (
        <Heading as="h1" size="h5" alignment="left" className={styles['programName']}>
          {page.title}
        </Heading>
      )}

      <StudentSnapshotSidebar tabs={page.tabs} />
      <ErrorBoundary
        key={selectedTabIndex}
        fallback={StudentSnapshotErrorInContent}
        beforeCapture={(scope) => scope.setTag('feature', 'StudentSnapshot')}
      >
        <StudentSnapshotContent data={page} />
      </ErrorBoundary>
    </>
  );
};
