import { externalLink } from '@polygence/common';

import type { ActionStateProps } from 'src/components/hermes/sessions/actions/ActionStateProps';
import { BaseState } from 'src/components/hermes/sessions/actions/State';

const MeetingVideoAvailableState = ({ meeting }: ActionStateProps) => {
  return (
    <BaseState variant="no-action">
      {meeting &&
        meeting.zoomRecordings.map((recording, i) => {
          return (
            <div key={recording.videoId}>
              <a href={`/recordings/video/?uuid=${recording.videoId}`} {...externalLink}>
                video {i > 0 ? i + 1 : ''}
              </a>
            </div>
          );
        })}
    </BaseState>
  );
};

export { MeetingVideoAvailableState };
