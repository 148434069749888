import { WebSocketMessage, getConnection } from '@polygence/common/websocket/hermes-ai';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';

const handleError = (data: WebSocketMessage) => {
  if (data.command === 'error') {
    toast.error('Something went wrong. If the issue persists, try refreshing the page.');
  }
};

export const useHermesAIErrorNotification = () => {
  useEffectOnce(() => {
    const ws = getConnection();
    ws.subscribe(handleError);
    return () => {
      ws.unsubscribe(handleError);
    };
  });
};
