import { commonHooks, isDevelopment, isOndemand, isStaging } from '@polygence/common';
import { Badge } from '@polygence/components';

import NavigationBar from 'src/components/NavigationBar';
import { useNavigationBarContext } from 'src/components/NavigationBar/NavigationBarContext';
import PolygenceLogo from 'src/components/PolygenceLogo';
import { BRANDED_CHANNELS } from 'src/constants';
import { getMyNavItems, NavItems } from 'src/utils/navigation';

interface UserProfileInfo {
  screened: boolean;
  eligibleForReferral: boolean;
  hasActiveProject: boolean;
  scholarPageEnabled: boolean;
  symposiumPresenter: boolean;
  studentJourneyTileSections: string[];
  onLogout: VoidFunction;
}

interface SiteNavigationBarProps extends UserProfileInfo {
  hermesEnabled: boolean;
  profileApproved: boolean;
  polyGptEnabled: boolean;
  navItemsVisible: boolean;
}

const STAGING = isStaging() || isOndemand();
const DEV = isDevelopment();

const SiteNavigationBar = ({
  studentJourneyTileSections,
  screened,
  profileApproved: approved,
  eligibleForReferral,
  hermesEnabled,
  hasActiveProject,
  scholarPageEnabled,
  symposiumPresenter,
  polyGptEnabled,
  onLogout,
}: SiteNavigationBarProps) => {
  const { loggedIn, userType, channel, otherInfo, isStripeOnboardingAllowed } =
    commonHooks.useCurrentUser();
  const items: NavItems = getMyNavItems({
    isReapplyAllowed: otherInfo['isReapplyAllowed'] as boolean | undefined,
    userType,
    screened,
    approved,
    eligibleForReferral,
    hermesEnabled,
    hasActiveProject,
    scholarPageEnabled,
    symposiumPresenter,
    enrolledAt: otherInfo['enrolledAt'] as string | undefined,
    polyGptEnabled,
    isStripeOnboardingAllowed,
    studentJourneyTileSections,
    onLogout,
  });

  const brand = BRANDED_CHANNELS[channel] ? (
    <div className="d-flex align-items-center">
      <PolygenceLogo />
      <div className="partnership ms-3">
        <p className="m-0">in partnership with </p>
        <h6>{BRANDED_CHANNELS[channel]}</h6>
      </div>
    </div>
  ) : (
    <PolygenceLogo />
  );

  const devBrand = (
    <div className="d-flex align-items-center development-brand">
      <PolygenceLogo />
      <Badge variant={STAGING ? 'danger' : 'warning'}>{STAGING ? 'Staging' : 'Development'}</Badge>
    </div>
  );

  const { navItemsVisible } = useNavigationBarContext();

  return (
    <NavigationBar
      fluid
      brand={DEV || STAGING ? devBrand : brand}
      navItems={loggedIn && navItemsVisible ? items.mainNav : []}
    />
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SiteNavigationBar;
