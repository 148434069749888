import { Popover, PopoverTrigger, PopoverContent } from '@polygence/components';
import { ReactNode } from 'react';

import { UserSnippetEditor } from 'src/components/hermes-ai/user-snippet/UserSnippetEditor';

export const UserSnippetPopover = ({
  messageThreadId,
  id,
  children,
}: {
  messageThreadId?: number;
  id: number;
  children: ReactNode;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="tw-z-[1001] tw-min-w-[240px] tw-max-w-[90vw] xl:tw-max-w-[120ch]">
        <UserSnippetEditor aiThreadId={messageThreadId} userSnippetId={id} />
      </PopoverContent>
    </Popover>
  );
};
