import {
  Assignment,
  AssignmentBlueprint,
  AssignmentSolution,
  commonReducers,
  commonHooks,
  permissions,
  ExtensionRequest,
  Lounge,
  Session,
  User,
} from '@polygence/common';
import { camelCase } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { getMyInfo } from 'src/components/getMyInfo';
import {
  getExtensionRequestAction,
  getMilestoneAssignmentAction,
} from 'src/components/hermes/utils';
import { useAppDispatch, useAppSelector as useSelector } from 'src/store';
import { dayjs } from 'src/utils/dayjsCustom';

export function useSelectedRoomOtherParticipants(): User[] {
  const selectedRoom = commonHooks.useSelectedRoom();
  const userId = getMyInfo().user_id;
  if (selectedRoom == null) {
    return [];
  }
  return selectedRoom.participants.filter(({ id }) => {
    return id !== userId;
  });
}

export function useSelectedProjectId(): number | undefined {
  return useSelector((state) => {
    const workspaceId = state.hermes.selectedWorkspaceId;
    if (workspaceId == null) {
      return undefined;
    }
    const workspace = state.hermes.workspaces.byId[workspaceId];
    return workspace?.projectId;
  });
}

export function useSelectedLounge(): Lounge | undefined {
  return useSelector((state) => {
    const workspaceId = state.hermes.selectedWorkspaceId;
    if (workspaceId == null) {
      return undefined;
    }
    const workspace = state.hermes.workspaces.byId[workspaceId];
    const loungeId = workspace?.loungeId;
    if (loungeId == null) {
      return undefined;
    }
    return state.hermes.lounges.byId[loungeId];
  });
}

export function useOldestSessionThatNeedsSummary(): Session | undefined {
  const selectedProject = commonHooks.useSelectedProject();
  const sessions = selectedProject?.sessions || [];
  return sessions.find((session) => {
    return (
      session.meetingId && !session.summary && dayjs(session.scheduledAt).unix() < dayjs().unix()
    );
  });
}

export function useOldestSessionThatNeedsStudentFeedback(): Session | undefined {
  const selectedProject = commonHooks.useSelectedProject();
  const sessions = selectedProject?.sessions || [];
  const userId = getMyInfo().user_id;

  return sessions.find((session) => {
    return (
      session.meetingId &&
      !session.feedbacks.find((fb) => {
        return fb.user === userId;
      }) &&
      dayjs(session.scheduledAt).unix() < dayjs().unix()
    );
  });
}

export function useSelectedAssignmentSolution(): AssignmentSolution {
  return useSelector((state) => {
    return state.hermes.selectedAssignmentSolution || {};
  });
}

export function useSchedulingSession() {
  const selectedProject = commonHooks.useSelectedProject();
  const { sessionId = -1, ...rest } = useSelector((state) => {
    return state.hermes.scheduler;
  });
  const currentSession = selectedProject?.sessions?.find(({ id }) => {
    return id === sessionId;
  });
  const meeting = commonHooks.useMeetingById(currentSession?.meetingId) || {
    scheduledAt: new Date(),
    recordingOn: true,
    sharingOn: false,
  };
  const model = currentSession || {
    id: sessionId,
    number: selectedProject?.sessions?.length ? selectedProject?.sessions?.length + 1 : 1,
  };
  return {
    ...rest,
    model,
    meeting,
  };
}

export function useAssignmentBlueprints(): AssignmentBlueprint[] {
  return useSelector((state) => {
    return state.hermes.assignmentBlueprints.allIds;
  });
}

export function useAssigmentBlueprintBySessionNumber(
  sessionNumber: number | undefined,
): AssignmentBlueprint | undefined {
  const assignmentBlueprints = useAssignmentBlueprints() || [];
  return assignmentBlueprints.find((assignmentBlueprint) => {
    return sessionNumber === assignmentBlueprint.dueSessionNumber;
  });
}

export function useMilestoneAssignmentActionForSession({
  sessionNumber,
}: {
  sessionNumber: number | undefined;
}) {
  const project = commonHooks.useSelectedProject();
  const currentAssignmentBlueprint = useAssigmentBlueprintBySessionNumber(sessionNumber);

  return getMilestoneAssignmentAction(currentAssignmentBlueprint, project);
}

export function useAssignmentActionForAllSessions(): Record<string, unknown> {
  const project = commonHooks.useSelectedProject();
  const sessions = project?.sessions || [];
  const blueprints = useAssignmentBlueprints() || [];
  const assignmentActions: Record<string, unknown> = {};
  sessions.forEach((session) => {
    const currentAssignmentBlueprint = blueprints.find((blueprint) => {
      return session.sessionNumber === blueprint.dueSessionNumber;
    });
    // eslint-disable-next-line fp/no-mutation
    assignmentActions[session.id] = getMilestoneAssignmentAction(
      currentAssignmentBlueprint,
      project,
    );
    return true;
  });
  return assignmentActions;
}

export function useAssignmentCompletionRate(): {
  assignmentTotalCount: number;
  assignmentCompletedCount: number;
} {
  const project = commonHooks.useSelectedProject();
  const blueprints = useAssignmentBlueprints() || [];
  if (project == null) {
    return {
      assignmentTotalCount: 0,
      assignmentCompletedCount: 0,
    };
  }
  const completed = blueprints.filter((blueprint) => {
    return blueprint.assignmentNames.every((assignmentName) => {
      return project.details[camelCase(assignmentName)];
    });
  });
  return {
    assignmentTotalCount: blueprints.length,
    assignmentCompletedCount: completed.length,
  };
}

export function useAttachments(): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    useSelector((state) => {
      const selectedWorkspaceId = state.hermes.selectedWorkspaceId;
      // @ts-expect-error attachments store is not in TS
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.attachments.workspaces.byId[selectedWorkspaceId];
    }) || []
  );
}

export function useHalfSessionRemaining(): boolean {
  const selectedProject = commonHooks.useSelectedProject();
  if (selectedProject == null) {
    return false;
  }
  return parseFloat(selectedProject.sessionsRemaining) % 1 === 0.5;
}

export function useIsWebSocketConnected(): boolean {
  return useSelector((state) => {
    return state.hermes.status === 'connected';
  });
}

export function useLastExtensionRequest(): ExtensionRequest | undefined {
  const selectedProject = commonHooks.useSelectedProject();

  return selectedProject?.extensionRequests?.[0];
}

export function useExtensionRequestAction(): string {
  const extensionRequest = useLastExtensionRequest();
  const currentUser = commonHooks.useCurrentUser();
  const userPermissions = commonHooks.usePermissions();

  if (
    !permissions.canRequestAdditionalSessions(userPermissions) &&
    !permissions.canRespondAdditionalSessions(userPermissions)
  ) {
    return '';
  }

  return getExtensionRequestAction(extensionRequest, currentUser);
}

export function useAssignmentById(assignmentId: number): Assignment | undefined {
  return useSelector((state) => state.hermes.assignments.byId[assignmentId]);
}

export const useSessionFeedbackRedirectFromMobile = () => {
  const dispatch = useAppDispatch();

  const { querySessionId } = useGetQueryParamActions();
  const session = commonHooks.useSessionBySessionIdOrNotNull(Number(querySessionId));
  const { sessionAction } = commonHooks.useSession(session);

  useSessionActionFromQueryParams(
    'submitfeedback',
    () => {
      dispatch(
        commonReducers.hermesActions.openSessionFeedback({ sessionId: Number(querySessionId) }),
      );
    },
    [session.id !== undefined, sessionAction === 'SubmitFeedbackClickableState'],
  );
};

export const useOpenMilestoneModalFromQueryParam = (sessionId: number, callback: () => void) => {
  const { querySessionId } = useGetQueryParamActions();

  useSessionActionFromQueryParams('milestone', callback, [Number(querySessionId) === sessionId]);
};

export const useGetQueryParamActions = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const action = params.get('action') || '';
  const querySessionId = params.get('sessionId') || '';

  return { action, querySessionId };
};

export const useSessionActionFromQueryParams = (
  targetAction: string,
  callback: () => void,
  conditions: boolean[] = [],
) => {
  const { action } = useGetQueryParamActions();
  const areConditionsGood = conditions.every(Boolean);
  useEffect(() => {
    if (action === targetAction && areConditionsGood) {
      callback();
    }
  }, [targetAction, callback, action, areConditionsGood]);
};
