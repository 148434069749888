import type { DateTime } from '@polygence/common/types/common';
import type { Nullable } from '@polygence/common/types/utils';

import { dayjs } from 'src/utils/dayjsCustom';

type Dayjs = ReturnType<typeof dayjs>;

const MEETING_LIMIT_IN_DAYS = 14;
const CONTACT_LIMIT_IN_DAYS = 5;

export function getDaysUntilToday(date: DateTime | Dayjs) {
  return Math.floor(dayjs.duration(dayjs().diff(dayjs(date))).asDays());
}

export function exceededMeetingLimit(lastMet: DateTime) {
  return getDaysUntilToday(lastMet) >= MEETING_LIMIT_IN_DAYS;
}

export function exceededContactLimit(lastContacted: DateTime) {
  return getDaysUntilToday(lastContacted) >= CONTACT_LIMIT_IN_DAYS;
}

export function formatTimeline(date: Nullable<DateTime>) {
  if (date) {
    return dayjs(date, 'YYYY-MM-DD').format('MMMM YYYY');
  }
  return '';
}

export function getDaysRemainingUntil(futureDate: DateTime | Dayjs) {
  return getDaysUntilToday(futureDate) * -1;
}

export function sortProjectsByStartDate<T extends { startedAt: DateTime | null }>(projects: T[]) {
  return projects.sort((a, b) => {
    if (a.startedAt === null && b.startedAt === null) {
      return 0;
    }
    if (a.startedAt === null) {
      return 1;
    }
    if (b.startedAt === null) {
      return -1;
    }
    if (dayjs(a.startedAt).isBefore(dayjs(b.startedAt))) {
      return 1;
    }
    return -1;
  });
}
