import { Icon } from '@polygence/components';
import PropTypes from 'prop-types';
import React from 'react';

import REPORT_ANSWER from 'src/components/hermes/SilentReportButton/reportAnwser';

const SilentReportFeedback = ({ answer }) => {
  const color = answer === REPORT_ANSWER.STILL_WAITING ? 'secondary-dark' : 'grayscale-placehold';

  const text =
    answer === REPORT_ANSWER.STILL_WAITING
      ? 'Thanks for letting us know. We are following up!'
      : 'Thanks for letting us know.';

  return (
    /* eslint-disable-next-line react/jsx-filename-extension, react/forbid-dom-props -- autodisabled, autodisabled */
    <div className="d-flex align-items-center small" style={{ gap: 6 }}>
      <Icon id="check-circle" size="sm" color={`var(--${color})`} />
      {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
      <p className="m-0" style={{ color: `var(--${color})`, paddingTop: 2 }}>
        {text}
      </p>
    </div>
  );
};

SilentReportFeedback.propTypes = {
  answer: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default SilentReportFeedback;
