import { commonHooks } from '@polygence/common';

import { WorkspaceCategoryGroup } from 'src/components/hermes/chat/WorkspaceList/WorkspacesCategoryGroup';
import { TodoList } from 'src/components/todo/TodoList';

export const WorkspaceList = () => {
  const workspaceDisplayList = commonHooks.useWorkspaceDisplayList();

  return (
    <div className="h-chat-list d-flex flex-column">
      <TodoList />
      {workspaceDisplayList.map(({ category, workspaces }) => {
        return (
          <WorkspaceCategoryGroup key={category} category={category} workspaces={workspaces} />
        );
      })}
    </div>
  );
};
