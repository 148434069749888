import { commonReducers, commonHooks } from '@polygence/common';
import { Modal, ModalBody } from '@polygence/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'src/components/Icon';
import { SpinningWheel } from 'src/components/SpinningWheel';
import { SessionSummaryForm } from 'src/components/hermes/SessionSummaryForm';

// eslint-disable-next-line react/function-component-definition -- autodisabled
function SuccessConfirmation({ sessionNumber, remainingSessions }) {
  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="d-flex align-items-center">
      <Icon name="superwoman" width="178px" height="122px" style={{ flex: '1 0 auto' }} />
      <p className="text-center text-bold h-blue">
        Thank you for submitting Session {sessionNumber} summary! There are {remainingSessions}{' '}
        sessions remaining.
      </p>
    </div>
  );
}

const SummarySubmitterModalContent = ({ session, project, projectId, success }) => {
  if (!session || !projectId) {
    return <SpinningWheel />;
  }
  return success ? (
    <SuccessConfirmation
      sessionNumber={session.number}
      remainingSessions={project.sessionsRemaining}
    />
  ) : (
    <SessionSummaryForm session={session} projectId={projectId} project={project} />
  );
};

/* eslint-disable-next-line react/function-component-definition, import/no-default-export -- autodisabled, autodisabled */
export default function SummarySubmitter() {
  const dispatch = useDispatch();
  const { open, sessionId, success } = useSelector((state) => state.hermes.summarySubmitter);
  const { id: projectId, ...project } = commonHooks.useSelectedProject() || {};
  const session = project?.sessions?.find(({ id }) => id === sessionId);

  return (
    <Modal
      show={open}
      onHide={() => dispatch(commonReducers.hermesActions.closeSummarySubmitter())}
      closeButton
    >
      <ModalBody>
        <SummarySubmitterModalContent
          session={session}
          projectId={projectId}
          project={project}
          success={success}
        />
      </ModalBody>
    </Modal>
  );
}
