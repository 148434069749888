import {
  PathfinderStudentApplication,
  PathfinderStudentApplicationStatuses,
  ProjectRequestsGroupStatuses,
  StudentProfileWithApplication,
} from '@polygence/common';
import { toast } from 'react-toastify';

import type { ApplicationSectionId } from 'src/constants/application-sections';
import { filterPagesBasedOnDisplay } from 'src/students/fieldUtils';
import type {
  ApplicationComponentSection,
  ComponentDisplay,
} from 'src/students/student-application';
import { collectFieldsToValidate, isSectionValid } from 'src/students/validation';
import type { getApplicationLocalStorageHandler } from 'src/utils/localStorageManager';

type ValidationResultPerSection = Record<
  ApplicationSectionId,
  {
    valid: boolean;
    fieldResults: { valid: boolean; message: string }[];
  }
>;

type InvalidSection = [
  keyof ValidationResultPerSection,
  ValidationResultPerSection[keyof ValidationResultPerSection],
];

export const getSectionIndexByName = (
  sections: ApplicationComponentSection[],
  sectionId: ApplicationSectionId,
) => {
  const index = sections.findIndex(({ id }) => {
    return id === sectionId;
  });
  return index > -1 ? index : 0;
};

export const PathfinderApplicationComponentStates = {
  MISSING: 'missing',
  IN_PROGRESS: 'in_progress',
  PENDING_PAYMENT: 'pending_payment',
  UNDER_MATCHING: 'under_matching',
  MATCHED: 'matched',
} as const;

export type PathfinderApplicationComponentState =
  (typeof PathfinderApplicationComponentStates)[keyof typeof PathfinderApplicationComponentStates];

const isMissingApplication = (application: Partial<Pick<PathfinderStudentApplication, 'id'>>) =>
  !application || !application.id;

const isInProgressApplication = (
  application: Partial<Pick<PathfinderStudentApplication, 'status'>>,
) => application.status === PathfinderStudentApplicationStatuses.CREATED;

const isPendingPaymentApplication = (
  application: Partial<Pick<PathfinderStudentApplication, 'status'>>,
) => application.status === PathfinderStudentApplicationStatuses.COMPLETED;

const isAcceptedApplication = (
  application: Partial<Pick<PathfinderStudentApplication, 'status'>>,
) => application.status === PathfinderStudentApplicationStatuses.ACCEPTED;

const isProjectRequestedForApplication = (
  application: Partial<Pick<PathfinderStudentApplication, 'projectRequestsGroupStatus'>>,
) => application.projectRequestsGroupStatus === ProjectRequestsGroupStatuses.REQUESTED;

export const getPathfinderApplicationState = (
  application: Partial<PathfinderStudentApplication>,
  partnerPaysWorkflow = false,
): PathfinderApplicationComponentState => {
  if (isMissingApplication(application)) {
    return PathfinderApplicationComponentStates.MISSING;
  }

  if (isInProgressApplication(application)) {
    return PathfinderApplicationComponentStates.IN_PROGRESS;
  }

  if (isPendingPaymentApplication(application) && !partnerPaysWorkflow) {
    return PathfinderApplicationComponentStates.PENDING_PAYMENT;
  }

  if (
    (isAcceptedApplication(application) && isProjectRequestedForApplication(application)) ||
    partnerPaysWorkflow
  ) {
    return PathfinderApplicationComponentStates.UNDER_MATCHING;
  }

  return PathfinderApplicationComponentStates.MATCHED;
};

export const setCachePartially =
  <T>(localStorageHandler: ReturnType<typeof getApplicationLocalStorageHandler<T>>) =>
  (applicationId: number) =>
  ({
    partialProfile = {},
    partialApplication = {},
  }: {
    partialProfile?: Partial<StudentProfileWithApplication>;
    partialApplication?: Partial<T>;
  } = {}) => {
    const cachedState = localStorageHandler.get(`${applicationId}`);

    const cachedProfile = cachedState?.studentProfile ?? {};
    const cachedApplication = cachedState?.application ?? {};

    localStorageHandler.set(
      {
        studentProfile: { ...cachedProfile, ...partialProfile },
        application: { ...cachedApplication, ...partialApplication },
      },
      `${applicationId}`,
    );
  };

export const validateApplicationDataBeforeSubmit = <
  T extends Partial<StudentProfileWithApplication>,
>(
  sections: readonly ApplicationComponentSection[],
  applicationData: T,
) => {
  return function handleOnBeforeSubmit() {
    const filteredSections = applicationData
      ? sections.filter(
          filterPagesBasedOnDisplay(applicationData) as (params: { display?: unknown }) => boolean,
        )
      : [];

    const fieldsToValidate = filteredSections.reduce(
      (acc, section) => ({ ...acc, [section.id]: collectFieldsToValidate(section.elements) }),
      {},
    );

    const validationResultPerSections = Object.entries(fieldsToValidate).reduce(
      (acc, [sectionId, fields]) => {
        return { ...acc, [sectionId]: isSectionValid(fields, applicationData) };
      },
      {},
    ) as ValidationResultPerSection;

    const firstInvalidSection = Object.entries(validationResultPerSections).find(
      ([_, result]) => !result.valid,
    ) as InvalidSection | undefined;

    if (firstInvalidSection) {
      const firstInvalidSectionIndex = getSectionIndexByName(
        filteredSections,
        firstInvalidSection[0],
      );
      toast.error(
        'Could not submit your application! Please go through all the steps below and make sure everything is correct.',
      );
      return Promise.reject(firstInvalidSectionIndex);
    }

    return Promise.resolve();
  };
};

export const getApplicationInitialPageInfo = (
  allSections: ApplicationComponentSection[] = [],
  completedSections: ApplicationSectionId[] = [],
  formData: Partial<StudentProfileWithApplication> = {},
) => {
  const filterByDisplay = filterPagesBasedOnDisplay(formData) as (_: {
    display?: ComponentDisplay;
  }) => boolean;
  const isSectionNotCompleted = ({ id }: ApplicationComponentSection) =>
    !completedSections.includes(id);

  const visibleSections = allSections.filter(filterByDisplay);
  const lastSectionIndex = visibleSections.length - 1;
  const firstNotCompletedSectionIndex = visibleSections.findIndex(isSectionNotCompleted);
  const isNotCompletedIdentified = firstNotCompletedSectionIndex >= 0;
  const initialPageIndex = Math.max(
    isNotCompletedIdentified ? firstNotCompletedSectionIndex : lastSectionIndex,
    0,
  );

  return {
    pageId: visibleSections[initialPageIndex]?.id,
    pageIndex: initialPageIndex,
  };
};
