import * as analyticsApi from '@polygence/common/api/analytics';
import { commonReducers } from '@polygence/common/store/reducers';
import type { OptionType } from '@polygence/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { SingleValue } from 'react-select';

import { usePartnerChannelsQuery, adminApi } from 'src/reducers/adminReducers';

interface EditableChannelProps {
  channel: {
    value: string;
    label?: string;
  };
  userId: number;
  disabled?: boolean;
  label?: string;
}

export const EditableChannel = ({
  channel,
  userId,
  disabled = false,
  label: componentLabel,
}: EditableChannelProps) => {
  const dispatch = useDispatch();
  const { data: partnerChannels } = usePartnerChannelsQuery();
  const [channels, setChannels] = useState<Array<{ value: string; label: string }>>([]);
  const [currentValue, setCurrentValue] = useState({
    label: channel?.label ? `${channel.label} (${channel.value})` : channel?.value,
    value: channel?.value,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!partnerChannels) {
      return;
    }

    const { byTag: partnersByTag, allTags: allPartnerTags } = partnerChannels;
    if (!allPartnerTags.length) {
      return;
    }

    const _channels = allPartnerTags.map((tag) => {
      const partnerInfo = partnersByTag[tag];

      if (!partnerInfo) {
        return { value: '', label: '' };
      }

      const companyName = partnerInfo.companyName;
      const partnershipArrangement = partnerInfo.partnershipArrangement;

      return {
        label: `${companyName} (${tag})`,
        value: tag,
        partnershipArrangement,
      };
    });

    setChannels(_channels);

    const currentChannel = _channels?.find((_channel) => {
      return _channel.value === currentValue?.value;
    });

    dispatch(commonReducers.channelActions.updateChannel(currentChannel));
  }, [dispatch, currentValue, partnerChannels]);

  const handleSelect = (selectedOption: SingleValue<OptionType>) => {
    if (!selectedOption) {
      return;
    }

    setIsLoading(true);
    analyticsApi
      .updateChannel(userId, { channel: selectedOption.value as string })
      .then(() => {
        setCurrentValue({ value: selectedOption.value as string, label: selectedOption.label });
        const partnerInfo = partnerChannels?.byTag[selectedOption.value];

        if (partnerInfo) {
          const partnershipArrangement = partnerInfo.partnershipArrangement;
          dispatch(
            commonReducers.channelActions.updateChannel({
              value: selectedOption.value,
              label: selectedOption.label,
              partnershipArrangement,
            }),
          );
        }
        dispatch(adminApi.util.invalidateTags(['StudentOverview']));
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {componentLabel && <span className="me-3">{componentLabel}</span>}
      <Select
        menuPortalTarget={document.body}
        value={currentValue}
        options={channels}
        onChange={handleSelect}
        isDisabled={disabled || isLoading}
      />
    </>
  );
};
