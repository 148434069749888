import {
  commonHooks,
  commonReducers,
  commonThunks,
  ProjectType,
  featureDecisions,
} from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import * as marketplaceApi from '@polygence/common/api/marketplace';
import { Heading } from '@polygence/components';
import { capitalize, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { formatDateAsLocal } from 'src/components/aux/dateStamp';
import DateWrapper, { DateTimeFormat } from 'src/components/aux/dateWrapper';
import CreateAssignment from 'src/components/hermes/CreateAssignment';
import { OUTPUT_TYPES } from 'src/components/hermes/constants';
import getAdditionalSessions from 'src/components/hermes/surveys/mentor/additionalSessions';
import afterSession3Questions from 'src/components/hermes/surveys/mentor/afterSession3Questions';
import finalMentorSurveyQuestions from 'src/components/hermes/surveys/mentor/finalMentorSurveyQuestions';
import generalQuestions, {
  techIssueQuestions,
} from 'src/components/hermes/surveys/mentor/generalQuestions';
import { launchpadQuestions } from 'src/components/hermes/surveys/mentor/launchpadQuestions';
import { metaQuestions } from 'src/components/hermes/surveys/mentor/metaQuestions';
import midProgramEnjoymentQuestions from 'src/components/hermes/surveys/mentor/midProgramEnjoymentQuestions';
import { pathfindersQuestions } from 'src/components/hermes/surveys/mentor/pathfindersQuestions';
import { premiumShowcasingQuestions } from 'src/components/hermes/surveys/mentor/premiumShowcasingQuestions';
import { reflectionQuestions } from 'src/components/hermes/surveys/mentor/reflectionQuestions';
import { researchCoachQuestions } from 'src/components/hermes/surveys/mentor/researchCoachQuestions';
import session1Questions from 'src/components/hermes/surveys/mentor/session1Questions';
import session3Questions from 'src/components/hermes/surveys/mentor/session3Questions';
import { getCompletedSessionCount, isPSSTrackProjectType } from 'src/components/hermes/utils';
import { FormSection } from 'src/components/mentor/FormSection';
import { usePersistentState } from 'src/hooks/usePersistentState';

const sessionValues = {
  no_show: 0,
  full_session: 1,
  half_session: 0.5,
};

const getSessionTitle = (projectType, sessionNumber, pathfinderType) => {
  switch (projectType) {
    case ProjectType.PATHFINDER_LAUNCHPAD:
      return 'your Launchpad session';
    case ProjectType.PATHFINDER_STANDALONE:
      return 'your Pathfinders session';
    case ProjectType.PATHFINDER_DIAGNOSTIC:
      return pathfinderType === ProjectType.PATHFINDER_LAUNCHPAD
        ? 'your Reflection (Launchpad) session'
        : 'your Reflection (Pathfinders) session';
    default:
      return `Session ${sessionNumber}`;
  }
};

export const SessionSummaryForm = ({ projectId, project, session }) => {
  const sessionsRemaining = Number(project?.sessionsRemaining);
  const savedSummaryId = `project_${projectId}_session_${session.number}`;
  const expectedOutputType =
    OUTPUT_TYPES[project?.questionnaire?.expectedOutputType] ??
    capitalize(project?.questionnaire?.expectedOutputType || '');
  const expectedOutputTypeOther = project?.questionnaire?.expectedOutputTypeOther || '';
  const [data, setData] = useState({
    __sessionNumber: session.number,
    __showCaseType: project?.questionnaire?.showCaseType,
    __endOfProgramSurveyCompleted: project?.questionnaire?.endOfProgramSurveyCompleted,
    __firstSessionSurveyCompleted: project?.questionnaire?.firstSessionSurveyCompleted,
    __thirdSessionSurveyCompleted: project?.questionnaire?.thirdSessionSurveyCompleted,
    __completedSessionCount: getCompletedSessionCount(project?.sessions),
    __expectedEndAt: formatDateAsLocal(project?.expectedEndAt),
    __sessionsRemaining: sessionsRemaining,
    __projectType: project?.type,
    __assignmentEnabled: project?.assignmentEnabled,
    __halfSessionsEnabled: project?.halfSessionsEnabled,
    __pathfinderType: project?.pathfinderType,
    __expectedOutputType: project?.expectedOutputTypeOther
      ? expectedOutputTypeOther
      : expectedOutputType,
    __aiSessionSummaryEnabled: false,
    aiSessionSummaryOptOut: false,
    sessionThreeProjectTitle: project?.details?.title || '',
    sessionThreeProjectDescription: project?.details?.description || '',
    featuresLoaded: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveState = useCallback(
    debounce((newState) => {
      window.localStorage.setItem(savedSummaryId, JSON.stringify(newState));
    }, 700),
    [],
  );
  const [assignments, setAssignments] = usePersistentState(
    [
      {
        _id: uuid(),
        title: '',
        description: '',
      },
    ],
    `assignments_for_session_${session.id}`,
  );

  const summary = useMemo(() => {
    if (project?.type === ProjectType.PATHFINDER_LAUNCHPAD) {
      return launchpadQuestions;
    }

    if (project?.type === ProjectType.PATHFINDER_STANDALONE) {
      return pathfindersQuestions;
    }

    if (project?.type === ProjectType.PATHFINDER_DIAGNOSTIC) {
      return reflectionQuestions;
    }

    if (isPSSTrackProjectType(project?.type)) {
      return [...metaQuestions, ...premiumShowcasingQuestions, ...techIssueQuestions];
    }

    if (project?.type === ProjectType.RESEARCH_COACH) {
      return [...researchCoachQuestions];
    }

    if (!project?.followUpEnabled) {
      return generalQuestions;
    }

    return [
      ...metaQuestions,
      ...session1Questions,
      ...session3Questions,
      ...afterSession3Questions,
      ...midProgramEnjoymentQuestions,
      ...getAdditionalSessions(data.__sessionNumber, data.__sessionsRemaining),
      ...generalQuestions,
      ...finalMentorSurveyQuestions,
    ];
  }, [data.__sessionNumber, data.__sessionsRemaining, project?.followUpEnabled, project?.type]);

  const currentUser = commonHooks.useCurrentUser();
  const selectedRoom = commonHooks.useSelectedRoom();
  const sessionId = session.id;
  const dispatch = useDispatch();
  const sessionValue = sessionValues[data.countAs] || 0;
  const isLastSession = sessionsRemaining <= sessionValue;
  const isAssignmentNotRequired = isLastSession;

  const { sending, error } = useSelector((state) => {
    return state.hermes.summarySubmitter;
  });

  const otherParticipants = selectedRoom.participants.filter(({ id }) => {
    return id !== currentUser.id;
  });

  useEffect(() => {
    const restoredState = window.localStorage.getItem(savedSummaryId) ?? '{}';
    setData((previousState) => {
      return {
        ...JSON.parse(restoredState),
        ...previousState,
        localStorageChecked: true,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    marketplaceApi
      .getUserProfile(currentUser.userType)
      .then(({ data }) => {
        setData((prevData) => ({
          ...prevData,
          aiSessionSummaryOptOut: Boolean(data.aiSessionSummaryOptOut),
        }));
      })
      .catch(console.error);
  }, [currentUser]);

  useEffect(() => {
    featureDecisions
      .getInstance()
      .then((featureDecisionsInstance) => {
        setData((prevData) => {
          return {
            ...prevData,
            __aiSessionSummaryEnabled:
              featureDecisionsInstance.aiSessionSummaryEnabled() &&
              project?.aiSessionSummaryEnabled &&
              !data.aiSessionSummaryOptOut,
            featuresLoaded: true,
          };
        });
      })
      .catch(console.error);
  }, [project?.aiSessionSummaryEnabled, data.aiSessionSummaryOptOut]);

  const onChange = ({ target: { name, value } }) => {
    setData((prevData) => {
      const newState = { ...prevData, [name]: value };
      debouncedSaveState(newState);
      return newState;
    });
  };

  const filterOutEmptyAssignments = () => {
    let notEmptyAssignments = assignments.filter((a) => a.title || a.description);

    // if assignments are required, the first empty assignment has to be considered
    if (!isAssignmentNotRequired && notEmptyAssignments.length === 0) {
      const firstEmpty = assignments.find((a) => !a.title && !a.description);
      if (firstEmpty) {
        // eslint-disable-next-line fp/no-mutation
        notEmptyAssignments = [firstEmpty];
      }
    }

    setAssignments(notEmptyAssignments);

    return notEmptyAssignments;
  };

  const submitAssignments = () => {
    if (data.countAs === 'no_show') {
      return Promise.resolve('No assignments for no-show session');
    }

    if (!data.__assignmentEnabled) {
      return Promise.resolve('Assignments disabled for this project type');
    }

    const notEmptyAssignments = filterOutEmptyAssignments();

    const payload = notEmptyAssignments.map(({ title, description }) => ({
      session: sessionId,
      title,
      description,
    }));

    return hermesApi.createAssignment(payload);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (sending) {
      return;
    }
    dispatch(commonReducers.hermesActions.sessionSummarySaving());
    const form = event.currentTarget;
    const formData = Object.fromEntries(new FormData(form).entries());

    submitAssignments()
      .then(() =>
        dispatch(
          commonThunks.hermesThunks.submitSessionSummary({
            sessionId,
            projectId,
            ...data,
            ...formData,
          }),
        ),
      )
      .catch((error) => toast.error(error.message))
      .finally(() => dispatch(commonReducers.hermesActions.sessionSummaryFinishSaving()));
  };

  // Allows restoring initial state with uncontrolled components
  // Otherwise the initial value is undefined and won't be changed after the data is restored
  if (!data.localStorageChecked || !data.featuresLoaded) {
    return null;
  }

  return (
    <>
      <Heading size="h4" as="h1" alignment="center" className="mb-5">
        Thank you for completing{' '}
        <span className="h-blue">
          {getSessionTitle(project?.type, session.number, data.__pathfinderType)}
        </span>{' '}
        with{' '}
        {otherParticipants
          .map(({ firstName }) => {
            return firstName;
          })
          .join(', ')}{' '}
        on
        <br />
        <time className="h-blue">
          {new DateWrapper(session?.scheduledAt)
            .setTimezone(currentUser.timeZone)
            .format(DateTimeFormat.FORMAT_6)}
        </time>
      </Heading>
      <form onSubmit={handleSubmit}>
        <FormSection
          fields={summary}
          fieldErrors={error?.summary}
          profile={data}
          onChange={onChange}
          helpColumn={false}
        />
        {data.__assignmentEnabled && data.countAs !== 'no_show' && (
          <CreateAssignment
            optional={isAssignmentNotRequired}
            assignments={assignments}
            setAssignments={setAssignments}
          />
        )}
        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={sending}>
            {sending ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </>
  );
};
