import { externalLink } from '@polygence/common';
import type { ComponentProps } from 'react';
import type { Components } from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeMathjax from 'rehype-mathjax/svg';
import remarkMath from 'remark-math';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { SyntaxHighlighter } from 'src/components/aux/markdown/SyntaxHighlighter';
import { CitationLink } from 'src/components/hermes-ai/messages/citation/Citation';
import { UserSnippet } from 'src/components/hermes-ai/user-snippet/UserSnippet';

const isExternalLink = (url: string) => url.startsWith('http');

const LinkWithUserSnippet: Components['a'] = ({ node, ...props }) => {
  const href = String(node?.properties?.['href'] ?? '');
  if (href.startsWith('#us:')) {
    const snippetId = Number(href.split(':')[1]);
    return <UserSnippet id={snippetId} />;
  }

  if (href.startsWith('#cite:')) {
    const title = node?.children[0]?.type === 'text' ? node.children[0].value : '';
    return <CitationLink title={title} citationLink={href.substring(6)} />;
  }

  return isExternalLink(href) ? (
    <a
      className="tw-font-semibold tw-text-primary-600 hover:tw-text-primary-500"
      href={href}
      {...externalLink}
    >
      {props.children}
    </a>
  ) : (
    <Link
      className="tw-font-semibold tw-text-primary-600 hover:tw-text-primary-500"
      to={href}
      {...props}
    >
      {props.children}
    </Link>
  );
};

const markdownComponents = {
  pre: SyntaxHighlighter,
  a: LinkWithUserSnippet,
};

const REMARK_PLUGINS = [remarkMath];

const REHYPE_PLUGINS: ComponentProps<typeof ReactMarkdown>['rehypePlugins'] = [
  rehypeMathjax,
  [rehypeExternalLinks, { target: ['_blank'] }],
];

/*
  Replace \( \) \[ \] math formulas returned by gpt-4o with $$ $$ format, that can be rendered by react-markdown
*/
function replaceMathString(text: string): string {
  return text.replace(/\\\((.*?)\\\)/g, '$$$$$1$$$$').replace(/\\\[(.*?)\\\]/gs, '$$$$$1$$$$');
}

export const MarkdownBase = ({ children }: { children: string }) => {
  const processedText = replaceMathString(children);

  return (
    <ReactMarkdown
      components={markdownComponents}
      remarkPlugins={REMARK_PLUGINS}
      rehypePlugins={REHYPE_PLUGINS}
    >
      {processedText}
    </ReactMarkdown>
  );
};
