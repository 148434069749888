import type { FreebusyTimeSlot } from '@polygence/common/api/external-calendar-sync';
import * as externalCalendarSyncApi from '@polygence/common/api/external-calendar-sync';
import type { ProjectId } from '@polygence/common/types/common';

import { dayjs } from 'src/utils/dayjsCustom';

export const BUSY_HOUR_FORMAT = 'LLLL';

const removeTimezoneFromDatetimeString = (datetime: string) => {
  // Prevent dayjs converting busy slot to local time - it is already converted to the user's timezone on the backend
  return datetime.substring(0, datetime.length - 6);
};

/* eslint-disable fp/no-mutation */
export const processBusyTimeSlots = (timeSlots: FreebusyTimeSlot[]) => {
  const TIMESLOT_INTERVAL = 15;
  return timeSlots.reduce<Record<string, boolean>>((busySlots, timeSlot) => {
    let startDatetime = dayjs.utc(removeTimezoneFromDatetimeString(timeSlot.start));
    const endDateTime = dayjs.utc(removeTimezoneFromDatetimeString(timeSlot.end));
    if (startDatetime.minute() % TIMESLOT_INTERVAL !== 0) {
      // e.g.: if the event starts at 16:18, the start of the busy period will be 16:15
      const startTimeRoundedToSlot =
        Math.floor(startDatetime.minute() / TIMESLOT_INTERVAL) * TIMESLOT_INTERVAL;
      startDatetime = startDatetime.minute(startTimeRoundedToSlot);
    }
    // Ensure 1 hour availability window for session
    const extendedStartDateTime = startDatetime.subtract(45, 'minute');
    let currentTime = extendedStartDateTime;
    while (currentTime.isBefore(endDateTime)) {
      busySlots[currentTime.format(BUSY_HOUR_FORMAT)] = true;
      currentTime = currentTime.add(15, 'minute');
    }

    return busySlots;
  }, {});
};
/* eslint-enable fp/no-mutation */

export const fetchBusyTimeSlots = (projectId: ProjectId, baseTimeToFetch: string) => {
  const baseTimeAsDayjs = dayjs(baseTimeToFetch);
  const minTime = baseTimeAsDayjs.startOf('month').startOf('day').format();
  const maxTime = baseTimeAsDayjs.endOf('month').endOf('day').format();
  return externalCalendarSyncApi.getCalendarFreebusy(projectId, minTime, maxTime);
};
