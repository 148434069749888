import type { Participant } from '../backend';
import type {
  AssignmentId,
  AttachmentId,
  DateTime,
  DayOfWeek,
  ExtensionRequestId,
  FeedbackReviewerId,
  HermesAIMessageThreadId,
  MeetingId,
  MessageId,
  ProjectId,
  ProjectPauseRequestId,
  RoomId,
  SessionId,
  SilentRoomUserReportId,
  SummaryId,
  UserId,
  UserSnippetId,
  UUID,
  WorkspaceId,
} from '../common';
import type {
  Assignment,
  AssignmentSolution,
  Feedback,
  Lounge,
  Meeting,
  MessageType,
  Project,
  ProjectTypes,
  RoomTypes,
  SessionCountAs,
  SharedResource,
  Summary,
} from '../hermes';
import type { Nullable } from '../utils';

import { SimpleUserSerializer } from './backend';
import { OpenAIMessage } from './openai';

export interface WorkspaceSerializer {
  id: WorkspaceId;
  disabled: boolean;
  name: string;
  operationUser: Nullable<UserId>;
  permissions: string[];
  zoomDisabled: boolean;
  project: Project;
  room: RoomSerializer;
  lounge: Nullable<Lounge>;
  error?: unknown;
}

export interface RoomSerializer {
  id: RoomId;
  unseenMessagesCount: number;
  participants: Participant[];
  messages: MessageSerializer[];
  messagesCount: number;
  notificationEnabled: boolean;
  roomType: RoomTypes | ProjectTypes;
}

export interface SessionSerializer {
  id: SessionId;
  feedbacks: Feedback[];
  countAs: SessionCountAs;
  sessionValue: number;
  sessionNumber: number;
  extras: Record<string, unknown>;
  proposing: boolean;
  proposedBy: Nullable<UserId>;
  assignmentSolution: Nullable<AssignmentSolution>;
  assignments: Assignment[];
  summary: Summary;
  meeting: Meeting;
  completedAt: string;
  cancellable: boolean;
}

export interface UpcomingSessionSerializer {
  sessionNumber: number;
  scheduledAt?: string;
}

export interface MessageSerializer {
  id: MessageId;
  createdAt: string;
  updatedAt: string;
  executedAfterCreate: boolean;
  content: string;
  messageType: MessageType;
  extra: {
    attachmentIds: AttachmentId[];
    [key: string]: unknown;
  };
  emailNotificationSentAt: Nullable<string>;
  localMessageId?: Nullable<string>;
  room: RoomId;
  silentReport: Nullable<UserId>;
  seenBy: UserId[];
  sentBy: UserId | undefined;
  aiMessageThread?: Nullable<HermesAIMessageThreadId>;
  aiMessageThreadTitle?: Nullable<string>;
}

export interface CreateMessageSerializer {
  type: 'respond_to_frontend';
  command: 'chat.message.success';
  localMessageId?: Nullable<string>;
  roomId: RoomId;
  workspaceId: WorkspaceId;
  message: MessageSerializer;
  sharedResources: SharedResource[];
}

export interface AttachmentDetailSerializer {
  content: string;
  filename: string;
  id: AttachmentId;
}

export interface SilentRoomUserReportSerializer {
  createdAt: string;
  id: SilentRoomUserReportId;
  lastMessageFromOther: MessageId;
  lastPingedAt: string;
  message: MessageId;
  projectExpectedEndDate: string;
  projectId: ProjectId;
  reportedAnswer: string;
  reportedBy: UserId;
  reporterUser: SilentReportUser;
  roomId: RoomId;
  sessionsRemaining: number;
  silentUser: SilentReportUser;
  workspace: WorkspaceId;
}

export interface SilentReportUser {
  channel: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  pk: SilentRoomUserReportId;
  profileId: number;
  profilePicture: string;
  timeZone: string;
  userType: string;
}

export interface ShowcaseAddonInvoice {
  invoiceUrl: string;
}

export interface TimeSlotCreateSerializer {
  startAt: string;
  endAt: string;
  day: DayOfWeek;
}

export interface ConvertedTimeSlotSerializer {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
}

export interface SummarySerializer {
  id: SummaryId;
  content: string;
  preparation_length: number;
  assignment: string;
  extras: Record<string, unknown>;
  session: SessionId;
}

export interface ExtensionRequestSerializer {
  id: ExtensionRequestId;
  project: ProjectId;
  requested_by: UserId;
  extendedDeadline: string;
  extendedDeadlineBenefit: string;
  extendedDeadlineActions: string;
}

export interface ExtensionRequestUpdateSerializer extends ExtensionRequestSerializer {
  respondedBy: UserId;
  respondedAt: string;
  status: string;
}

export interface AssignmentSerializer {
  id: AssignmentId;
  title: string;
  description: string;
  session?: SessionId;
  assignment_solution: Nullable<AssignmentSolution>;
}

export interface FeedbackRequestPublicSerializer {
  uuid: UUID;
  feedback_file_url: Nullable<string>;
  reviewer_first_name: Nullable<string>;
  paper_link: Nullable<string>;
  paper_due_date: string;
  feedback_subject: string;
  citation_style: string;
  file_style_guides: string;
  pages: Nullable<string>;
  wanted_topics: string;
  already_matched: boolean;
  already_matched_with_request_user: boolean;
  student_first_name: string;
}

export interface FeedbackReviewerSerializer {
  details: {
    contacted_at?: DateTime;
    confirmed_at?: DateTime;
    declined_at?: DateTime;
    paid_at?: DateTime;
    paid_amount?: string;
    feedback_file_url?: string;
  };
  id: FeedbackReviewerId;
  user_id: UserId;
  user_name: string;
}

export enum ProjectPauseRequestStatuses {
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export interface ProjectPauseRequestSerializer {
  id: ProjectPauseRequestId;
  project: ProjectId;
  pause_until: string;
  requested_by: UserId;
  reason_for_pause: string;
  reason_for_decline?: string;
  status: ProjectPauseRequestStatuses;
}

export interface HermesAIMessageThreadSerializer {
  id: HermesAIMessageThreadId;
  workspace: WorkspaceId;
  created_by: SimpleUserSerializer;
  created_at: string;
  conversation_title: string;
  last_message_at: string;
  message_count: number;
  max_number_of_messages: number;
}

export interface HermesAIMessageThreadMessagesSerializer {
  id: HermesAIMessageThreadId;
  conversation_title: string;
  created_by: UserId;
  created_at: string;
  messages: OpenAIMessage[];
  max_number_of_messages: number;
}

export interface UserSnippetSerializer {
  id: UserSnippetId;
  created_by: UserId;
  content: string;
}

export interface AIFeatureStatusSerializer {
  feature_visible: boolean;
  feature_enabled: boolean;
  feature_introduction_modal_seen: boolean;
}

export interface AIMeetingSummaryReviewSerializer {
  id: MeetingId;
  summary_content: string;
  scheduled_at: DateTime;
  email_queued_at: string;
  project_title: string;
  student_names: string[];
  session_number: string;
}
