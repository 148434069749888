import { commonHooks, commonReducers } from '@polygence/common';
import * as certificatesApi from '@polygence/common/api/certificates';
import { Button } from '@polygence/components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { MILESTONE_ACTIONS } from 'src/components/hermes/constants';
import { useMilestoneAssignmentActionForSession } from 'src/components/hermes/hooks';
import { getCertificatePage } from 'src/urls';
import { getTracker } from 'src/utils/tracking/factory';

export const SubmitFinalProjectButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionNumber = 10;
  const assignmentAction = useMilestoneAssignmentActionForSession({
    sessionNumber,
  });
  const workspaceId = commonHooks.useSelectedWorkspaceId();
  const lastAssignmentSubmitted = assignmentAction === MILESTONE_ACTIONS.submittedAssignment;

  const openLastAssignment = () => {
    dispatch(
      commonReducers.hermesActions.openAssignmentModal({
        sessionNumber,
        selectedAssignmentAction: assignmentAction,
      }),
    );
  };

  const navigateToCertificatePage = () => {
    if (!workspaceId) {
      toast.error('Something went wrong identifying the workspace.');
      return;
    }
    certificatesApi
      .getCertificateForStudent(workspaceId)
      .then(({ data: certificateUuid }) => {
        return history.push(getCertificatePage(certificateUuid));
      })
      .catch(() => {
        toast.error('Something went wrong fetching the certificate.');
      });
  };

  const label = lastAssignmentSubmitted
    ? 'Download your certificate!'
    : 'Submit your final project here!';

  const handleClick = () => {
    if (lastAssignmentSubmitted) {
      navigateToCertificatePage();
      getTracker().track('Download certificate clicked', { workspaceId });
    } else {
      openLastAssignment();
      getTracker().track('Submit final project clicked', { workspaceId });
    }
  };

  return (
    <div className="my-3 text-center">
      <Button variant="primary" size="lg" onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
};
