import { ProjectType, UserType } from '@polygence/common';
import useCurrentUser from '@polygence/common/hooks/selectors/useCurrentUser';
import { Heading } from '@polygence/components';
import classNames from 'classnames';
import { createContext } from 'react';
import { useMemo } from 'react';

import styles from 'src/components/Tiles/StudentJourney/StudentJourneyTile.module.scss';
import { CoreJourneyTile } from 'src/components/Tiles/StudentJourney/core/CoreJourneyTile';
import { LaunchpadJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/LaunchpadJourneyTile';
import { PSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PSSJourneyTile';
import tileStyles from 'src/components/Tiles/tilesDashboard.module.scss';

export const StudentJourneyTileContext = createContext<{ showWorkspaceLinks: boolean }>({
  showWorkspaceLinks: true,
});

export const StudentJourneyTile = ({
  sections = [],
  title,
}: {
  sections?: string[];
  title?: string;
}) => {
  const { userType } = useCurrentUser();
  const showWorkspaceLinks = userType === UserType.STUDENT;
  const contextValue = useMemo(() => {
    return { showWorkspaceLinks };
  }, [showWorkspaceLinks]);

  return (
    <StudentJourneyTileContext.Provider value={contextValue}>
      <div className={classNames(tileStyles['tile'], styles['studentJourney'])}>
        <Heading size="h4" alignment="left" fontWeight="bold">
          {title}
        </Heading>
        {sections.includes(ProjectType.PATHFINDER_LAUNCHPAD) && <LaunchpadJourneyTile />}
        {sections.includes(ProjectType.FLAGSHIP) && <CoreJourneyTile />}
        {sections.includes(ProjectType.PREMIUM_SHOWCASING_PUBLISHING) && <PSSJourneyTile />}
      </div>
    </StudentJourneyTileContext.Provider>
  );
};
