import {
  commonReducers,
  commonThunks,
  commonHooks,
  PermanentFileSerializer,
  SharedResource,
} from '@polygence/common';
import { createGoogleFile } from '@polygence/common/api/google';
import { Button, Heading, Text, Modal, ModalBody } from '@polygence/components';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { DOCUMENT } from 'src/components/GoogleDriveWidget/mimeTypeConstants';
import styles from 'src/components/hermes/WritingFeedback.module.sass';
import { FormSection } from 'src/components/mentor/FormSection';
import sharedResources from 'src/components/static/images/shared-resources.png';
import successwoman from 'src/components/static/images/success-super-woman.png';
import { useAppDispatch, useAppSelector } from 'src/store';

const specifyOther = 'Please specify other';
const checkboxClassName = 'form-group form-check ms-2 mt-2';
const getWritingFeedbackParts = (projectTitle: string) => [
  {
    component: 'Heading',
    props: {
      alignment: 'left',
      className: 'tw-mb-2',
      size: 'h3',
      text: 'Writing Feedback Request',
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'You have writing feedback included in your Polygence program. To receive writing feedback from a Polygence Writing Fellow, you can submit a draft (or working-draft) of your written product for review via GoogleDoc. Normally, the feedback is returned in 7-10 days.',
    },
  },
  {
    component: 'Heading',
    props: {
      alignment: 'left',
      className: 'tw-mb-4',
      size: 'h5',
      text: `Project title: ${projectTitle}`,
    },
  },
  {
    component: 'RadioButton',
    props: {
      label: 'What are you submitting for feedback today?',
      name: 'feedbackSubject',
      required: true,
      options: [
        { label: 'Full draft of a research paper', value: 'fullDraft' },
        {
          label: 'Unfinished draft of a research paper',
          value: 'unfinishedDraft',
        },
        {
          label: 'Outline of a research paper',
          value: 'outlineOfResearchPaper',
        },
        {
          label: 'Creative writing piece',
          value: 'creativeWritingPiece',
        },
        {
          label: 'Creative multi-media piece/ art piece',
          value: 'creativeMulti-mediaPiece',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    },
  },
  {
    component: 'FormInput',
    props: {
      small_font: specifyOther,
      name: 'feedbackSubjectOther',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: {
        field: 'feedbackSubject',
        value: 'other',
      },
    },
  },
  {
    component: 'RadioButton',
    props: {
      label:
        "Does your paper need to adhere to a certain citation style? (Check with the conference/journal you're hoping to submit to if you're unsure.)",
      name: 'citationStyle',
      required: true,
      options: [
        { label: 'MLA', value: 'MLA' },
        {
          label: 'APA',
          value: 'APA',
        },
        {
          label: 'Chicago',
          value: 'Chicago',
        },
        {
          label: 'IEEE',
          value: 'IEEE',
        },
        {
          label: "It doesn't matter",
          value: 'doesntMatter',
        },
        {
          label: 'Not sure yet',
          value: 'notSure',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
    },
  },
  {
    component: 'FormInput',
    props: {
      small_font: specifyOther,
      name: 'citationStyleOther',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: {
        field: 'citationStyle',
        value: 'other',
      },
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'If applicable, please upload the rubric/style guide that your chosen publication/submission destination has specified for you. (Some journals provide paper evaluation rubrics and other guides.)',
    },
  },
  {
    component: 'FileUploadInput',
    props: {
      name: 'fileStyleGuide',
      onUpload: 'uploadFile',
      onDelete: 'deleteFile',
    },
  },
  {
    component: 'FormInput',
    props: {
      label:
        "If your document is more than 10 pages, please indicate the 10 pages where you'd like your Writing Fellow to concentrate their feedback (e.g. pages 3-13)",
      name: 'pages',
      placeholder: 'Page',
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: 'What do you most want feedback on?',
      className: 'required-info-after',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Grammar/English',
      name: 'wantedTopicsGrammarEnglish',
      id: 'grammarEnglish',
      className: checkboxClassName,
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Paper structure',
      name: 'wantedTopicsPaperStructure',
      id: 'paperStructure',
      className: checkboxClassName,
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Citation style (parenthetical, works cited)',
      name: 'wantedTopicsCitationStyle',
      id: 'citationStyleToCheck',
      className: checkboxClassName,
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Use of figures/statistics',
      name: 'wantedTopicsStatistics',
      id: 'statistics',
      className: checkboxClassName,
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Other',
      name: 'wantedTopicsOther',
      id: 'otherToCheck',
      className: checkboxClassName,
    },
  },
  {
    component: 'FormInput',
    props: {
      small_font: specifyOther,
      name: 'mostWantedTopicsOther',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: {
        field: 'wantedTopicsOther',
        value: true,
      },
    },
  },
  {
    component: 'FormInput',
    props: {
      label:
        'Please describe your paper in one or two sentences (What was the purpose of your project? What were you interested in finding?)',
      name: 'purposeOfPaper',
      inputType: 'textarea',
      required: true,
      htmlRequired: true,
    },
  },
  {
    component: 'FormInput',
    props: {
      label: 'Last but not least, what are 3-4 keywords that describe your paper?',
      name: 'paperDescription',
      required: true,
      htmlRequired: true,
    },
  },
];

const getLastWritingFeedbackIndex = (sharedResources: SharedResource[]) => {
  const file = sharedResources.find((resource) =>
    resource.displayName.startsWith('Writing feedback'),
  );
  if (!file) {
    return 0;
  }
  return Number(file.displayName.split('-')[2]) || 0;
};

const IntroPage = ({
  close,
}: {
  close: () => {
    payload: undefined;
    type: string;
  };
}) => {
  const dispatch = useAppDispatch();
  const student = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();
  const sharedResources = commonHooks.useSharedResources();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateFile = async () => {
    if (!project || isLoading) {
      return;
    }

    const index = getLastWritingFeedbackIndex(sharedResources);

    try {
      setIsLoading(true);
      const { data } = await createGoogleFile({
        fileName: `Writing feedback - ${student.firstName} ${student.lastName} - ${index + 1}`,
        mimeType: DOCUMENT,
        workspaceId: project.workspaceId,
      });
      await dispatch(
        commonThunks.hermesThunks.updateProject({
          projectId: project.id,
          writingFeedbackDocumentUrl: data.webViewLink,
        }),
      );
      window.open(data.webViewLink, '_blank', 'noopener');
    } catch (e) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <Heading size="h4">Writing feedback</Heading>
        <Text size="medium" className="tw-my-4">
          Create the writing feedback document and then put your content you'd like to submit for
          review in that document.
        </Text>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          type="button"
          variant="primary"
          className="m-4"
          onClick={() => void handleCreateFile()}
          disabled={isLoading}
        >
          Create writing feedback document
        </Button>
        <Button type="button" variant="secondary" className="m-4" onClick={close}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const SuccessConfirmation = ({
  close,
}: {
  close: () => {
    payload: undefined;
    type: string;
  };
}) => {
  return (
    <div>
      <div className="text-center">
        <Heading size="h4">
          We’ve successfully received your submission. We will let you know as soon as your review
          has been completed.
        </Heading>
      </div>
      <div className="d-flex justify-content-center m-4">
        <img src={successwoman} className={styles['successWoman']} alt="successful_woman" />
      </div>
      <div className="d-flex justify-content-center">
        <Button type="button" variant="primary" onClick={close}>
          Ok
        </Button>
      </div>
    </div>
  );
};

const EditOrSubmitStep = ({
  close,
  handleToSubmit,
}: {
  close: () => {
    payload: undefined;
    type: string;
  };
  handleToSubmit: () => void;
}) => {
  const project = commonHooks.useSelectedProject();

  const handleEditFile = () => {
    if (!project?.writingFeedbackDocumentUrl) {
      return;
    }
    window.open(project.writingFeedbackDocumentUrl, '_blank', 'noopener');
  };

  return (
    <div>
      <div className="text-center">
        <Heading size="h4">Writing feedback</Heading>
        <Text size="medium" className="tw-mt-5">
          Feel free to edit the created document as long as you need to. Once you're ready, continue
          with the submission by clicking Submit for writing feedback.
        </Text>
        <Text size="medium" className="tw-mt-5">
          You can also find your document in the shared resources.
        </Text>
        <div className="d-flex justify-content-center m-4">
          <img src={sharedResources} alt="shared resources example" className="tw-w-[80%]" />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button type="button" variant="primary" className="m-4" onClick={handleEditFile}>
          Edit document
        </Button>
        <Button type="button" variant="primary" className="m-4" onClick={handleToSubmit}>
          Submit for writing feedback
        </Button>
        <Button type="button" variant="secondary" className="m-4" onClick={close}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const WritingFeedbackForm = () => {
  const dispatch = useAppDispatch();
  const { sending } = useAppSelector((state) => state.hermes.studentFeedbackRequest);
  const project = commonHooks.useSelectedProject();
  const currentUser = commonHooks.useCurrentUser();
  const projectTitle = project?.title ?? '';
  const [data, setData] = useState<{
    fileStyleGuide: PermanentFileSerializer[];
    [k: string]: unknown;
  }>({
    fileStyleGuide: [],
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value, name },
  }) => {
    return setData(() => {
      return { ...data, [name]: value };
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (!project?.writingFeedbackDocumentUrl) {
      toast.error('Cannot find the document');
      return;
    }
    if (project != null && currentUser.id != null) {
      void dispatch(
        commonThunks.hermesThunks.submitStudentFeedbackRequest({
          projectId: project.id,
          userId: currentUser.id,
          googleDocUrl: project.writingFeedbackDocumentUrl,
          ...data,
        }),
      );
    }
  };

  const writingFeedbackParts = useMemo(() => getWritingFeedbackParts(projectTitle), [projectTitle]);

  return (
    <form onSubmit={handleSubmit}>
      <FormSection
        fields={writingFeedbackParts}
        onChange={handleChange}
        profile={data}
        helpColumn={false}
      />
      <div className="d-flex justify-content-center">
        <Button type="submit" variant="primary" disabled={sending}>
          {sending ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </form>
  );
};

const WritingFeedbackModal = () => {
  const dispatch = useAppDispatch();
  const project = commonHooks.useSelectedProject();
  const [toSubmit, setToSubmit] = useState(false);
  const { open, success } = useAppSelector((state) => state.hermes.studentFeedbackRequest);

  const closeModal = () => {
    return dispatch(commonReducers.hermesActions.closeFeedbackRequest());
  };

  const handleToSubmit = () => {
    setToSubmit(true);
  };

  const modalContent = () => {
    if (success) {
      return <SuccessConfirmation close={closeModal} />;
    }
    if (toSubmit) {
      return <WritingFeedbackForm />;
    }
    if (project?.writingFeedbackDocumentUrl) {
      return <EditOrSubmitStep close={closeModal} handleToSubmit={handleToSubmit} />;
    }
    return <IntroPage close={closeModal} />;
  };

  return (
    <Modal show={open} onHide={closeModal} closeButton>
      <ModalBody>{modalContent()}</ModalBody>
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default WritingFeedbackModal;
