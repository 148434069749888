import { OpenAIMessageId } from '@polygence/common';
import { createContext } from 'react';

interface MessageContextValue {
  messageId: OpenAIMessageId | undefined;
}

export const MessageContext = createContext<MessageContextValue>({
  messageId: undefined,
});
