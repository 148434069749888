import React, { Children, ReactNode } from 'react';

interface SeparatorInserterCaptainProps {
  children: ReactNode;
  separator?: string;
  lastSeparator?: string;
}

export const SeparatorInserterCaptain = ({
  children,
  separator = ', ',
  lastSeparator = ' and ',
}: SeparatorInserterCaptainProps) => {
  const childrenArray = Children.toArray(children);
  if (childrenArray.length <= 1) {
    return <>{children}</>;
  }
  return (
    <>
      {childrenArray.map((child, index, array) => {
        if (index < array.length - 2) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {child}
              {separator}
            </React.Fragment>
          );
        }
        if (index === array.length - 2) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {child}
              {lastSeparator}
            </React.Fragment>
          );
        }
        return child;
      })}
    </>
  );
};
