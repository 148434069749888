import { ApplicationSectionIds } from 'src/constants/application-sections';
import {
  studentApplicationPartnerPaysWorkflowSections,
  studentApplicationStudentPaysWorkflowSections,
} from 'src/students/student-application';
import evaluateFormElementsOperations from 'src/utils/evaluateFormElementsOperations';
import { studentApplicationWhiteLabelSections } from 'src/white-label/student-application/sections/white-label-student-application-sections';

const getElementsFromLayout = (layout) => {
  switch (layout.component) {
    case 'ThreeColumnLayout':
    case 'TwoColumnLayout':
      return layout.props.children[1];
    default:
      return [];
  }
};

const filterLayouts = (section) => {
  const layout = section.elements[0];
  return getElementsFromLayout(layout);
};

const filterFields = (field) => {
  return ![
    'PagerProgress',
    'PagerNavigation',
    'CenteredIllustration',
    'StudentVideoInterview',
    'LaunchpadPitch',
    'Alert',
  ].includes(field.component);
};

const resizeHeadings = (field) => {
  if (field.component === 'ComponentsHeading') {
    return {
      ...field,
      props: {
        ...field.props,
        size: 'h4',
        as: 'h4',
      },
    };
  }

  return field;
};

const resizeSpacers = (field) => {
  if (field.component === 'Spacer') {
    return {
      ...field,
      props: {
        ...field.props,
        size: 4,
      },
    };
  }
  return field;
};

export const validateInitialInputState = (field) => {
  if (field.component === 'InputField') {
    return {
      ...field,
      props: {
        ...field.props,
        validateInitialState: true,
      },
    };
  }
  return field;
};

const filterSections = (section) => {
  return ![
    ApplicationSectionIds.VIRTUAL_INTERVIEW_SECTION_ID,
    ApplicationSectionIds.LAUNCHPAD_PITCH_ID,
  ].includes(section.id);
};

export const singlePageApplicationFields = ({ partnerPaysWorkflow = false }) => {
  const baseFields = partnerPaysWorkflow
    ? studentApplicationPartnerPaysWorkflowSections
    : studentApplicationStudentPaysWorkflowSections;
  return baseFields.filter(filterSections).map((section) => {
    const elements = filterLayouts(section)
      .filter(filterFields)
      .map(resizeHeadings)
      .map(resizeSpacers)
      .map(validateInitialInputState);

    return {
      ...section,
      elements,
    };
  });
};

export const singlePageWhiteLabelApplicationFields = () => {
  // eslint-disable-next-line sonarjs/no-identical-functions
  return studentApplicationWhiteLabelSections.filter(filterSections).map((section) => {
    const elements = filterLayouts(section)
      .filter(filterFields)
      .map(resizeHeadings)
      .map(resizeSpacers)
      .map(validateInitialInputState);

    return {
      ...section,
      elements,
    };
  });
};

export const filterPagesBasedOnDisplay = (data) => {
  return ({ display }) => {
    return evaluateFormElementsOperations(display, data);
  };
};
