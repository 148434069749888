import { commonHooks } from '@polygence/common';
import { Text } from '@polygence/components';

import { isMentor } from 'src/components/getMyInfo';
import type { SessionProps } from 'src/components/hermes/messages/ProposedSession';
import { concatenateCommasAnd } from 'src/utils';

const StudentProposalMessage = ({ sessionNumber, scheduledAt, sessionScheduled }: SessionProps) => {
  const mentors = commonHooks.useOtherParticipants();
  const mentorNames = mentors.flatMap((participant) =>
    isMentor(participant.userType) ? participant.firstName : [],
  );
  return (
    <>
      <Text size="small" fontWeight="bold">
        {sessionScheduled
          ? `Proposed a time for Session ${sessionNumber} at ${scheduledAt} (and it is scheduled).`
          : `Your proposal for Session ${sessionNumber} at ${scheduledAt} was sent. You’ll get notified when your mentor accepts or declines the proposal.`}
      </Text>
      {sessionScheduled && (
        <Text size="small" fontWeight="bold" className="mt-5">
          {concatenateCommasAnd(mentorNames)} has just accepted your proposal. Session{' '}
          {sessionNumber} is now officially scheduled at {scheduledAt}.
        </Text>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default StudentProposalMessage;
