import { StringUserType, UserInfoToken, UserType } from '@polygence/common';
import * as roleUtils from '@polygence/common/utils/roles';

import { NavbarDropdownMenu } from 'src/components/NavigationBar/NavbarDropdownMenu/NavbarDropdownMenu';
import { ProfileMenu } from 'src/components/NavigationBar/ProfileMenu';
import { PROFILE_MAIN } from 'src/components/constants';
import { getMyInfo } from 'src/components/getMyInfo';
import { store } from 'src/store';
import { urls } from 'src/urls';
import { NavItems, NavbarNavigationItem, getGuidebookUrl } from 'src/utils/navigation';

export const getProfileUrl = (userType: StringUserType) => {
  const profileUrls = {
    mentor: '/home/profile',
    student: urls.settingsPage(),
    parent: '/parent/profile',
    counselor: '/counselor/profile',
  };
  return profileUrls[userType];
};

export const getApplicationUrl = (userType: StringUserType) => {
  const profileUrls = {
    mentor: undefined,
    student: '/student/profile',
    counselor: undefined,
    parent: undefined,
  };
  return profileUrls[userType];
};

export const getAfterSignInUrl = () => {
  const { userType, hermesEnabled } = store.getState().user;

  if (userType === UserType.STUDENT) {
    return hermesEnabled ? urls.hermes() : getApplicationUrl(userType);
  }

  return getProfileUrl(userType);
};

function filterItemToShow({ show }: { show?: boolean | null }) {
  return show == null || show;
}

function isUserInfo(userInfo: UserInfoToken | Record<string, never>): userInfo is UserInfoToken {
  return Object.keys(userInfo).length !== 0;
}

function handleUserSettingsLabel(userType: string, screened: boolean) {
  if (userType === UserType.MENTOR && !screened) {
    return 'Application';
  }

  if (userType === UserType.STUDENT) {
    return 'Settings';
  }

  return 'Profile';
}

export interface getMyNavItemsProps {
  userType: StringUserType;
  hasActiveProject?: boolean;
  opportunitiesEnabled?: boolean;
  enrolledAt?: string;
  onLogout: VoidFunction;
}

export const getMyNavItems = ({
  userType,
  hasActiveProject,
  opportunitiesEnabled,
  enrolledAt,
  onLogout,
}: getMyNavItemsProps): NavItems => {
  const userInfo = getMyInfo();
  const isUser = isUserInfo(userInfo);
  const type: { roles: string[] } = { roles: [] };
  const userRoles = isUser ? userInfo : type;

  const profileNavItems: NavbarNavigationItem[] = [
    {
      name: 'profile',
      label: handleUserSettingsLabel(userType, false),
      href: getProfileUrl(userType),
      iconId: 'user',
    },
    {
      name: 'user-settings',
      label: 'Settings',
      show: ([UserType.MENTOR, UserType.COUNSELOR, UserType.PARENT] as StringUserType[]).includes(
        userType,
      ),
      href: urls.settingsPage(),
      iconId: 'settings',
    },
    {
      name: 'reset-password',
      label: 'Reset Password',
      href: '/user/password-reset',
      iconId: 'key',
    },
    {
      name: 'logout',
      label: 'Logout',
      iconId: 'log-out',
      onClick: onLogout,
    },
  ];

  const resourcesNavItems: NavbarNavigationItem[] = [
    {
      name: 'guides',
      label: 'Guidebook',
      show: true,
      href: getGuidebookUrl({ userType, hasActiveProject, isEnrolled: Boolean(enrolledAt) }),
      iconId: 'help-circle',
    },
    {
      name: 'showcases',
      label: 'HS Journals & Showcasing Opportunities',
      show: opportunitiesEnabled,
      href: '/dashboard/showcasing-opportunities',
      iconId: 'box',
    },
  ];

  const mainNavItems: NavbarNavigationItem[] = [
    {
      name: 'application',
      label: hasActiveProject && enrolledAt ? 'Workspace' : 'Enrollment',
      show: true,
      href: hasActiveProject && enrolledAt ? '/dashboard/hermes' : '/student/profile',
      iconId: 'arrow-right',
      activePaths: [
        /\/student\/profile/g,
        /\/student\/\d+\/application\/\d+/g,
        /\/dashboard\/\d+\/hermes\/\d+/g,
      ],
    },
    {
      name: 'resources',
      label: 'Resources',
      DropdownComponent: NavbarDropdownMenu,
      show: true,
      items: resourcesNavItems.filter(filterItemToShow),
      href: '#',
      iconId: 'book',
    },
    {
      name: 'admin',
      label: 'Admin',
      show: roleUtils.hasAdminDashboardAccess(userRoles),
      href: '/administrator',
      iconId: 'lock',
    },
    {
      name: PROFILE_MAIN,
      DropdownComponent: ProfileMenu,
      items: profileNavItems.filter(filterItemToShow),
      iconId: 'user',
    },
  ];

  return {
    mainNav: mainNavItems.filter(filterItemToShow),
    tilesNav: [],
  };
};
