import { OpenAIMessageId } from '@polygence/common';
import { Citation, hermesAiSelectors } from '@polygence/common/store/reducers/hermesAiReducer';
import { Button, Icon } from '@polygence/components';

import { useAppSelector } from 'src/store';

const CitationListItem = ({ citation, index }: { citation: Citation; index: number }) => {
  return (
    <Button
      as="a"
      href={citation.url}
      variant="link"
      size="sm"
      startIcon={
        citation.type === 'polygence' ? (
          <Icon
            id="polygence"
            strokeWidth={2}
            fill="var(--primary)"
            size="xs"
            className="tw-text-primary-400"
          />
        ) : (
          <Icon id="globe" size="xs" />
        )
      }
      className="tw-max-w-64 tw-overflow-hidden tw-py-1 tw-pr-0"
      title={citation.title}
    >
      <span className="tw-truncate">
        [{index + 1}] {citation.title}
      </span>
    </Button>
  );
};

export const CitationList = ({ messageId }: { messageId: OpenAIMessageId | undefined }) => {
  const citations = useAppSelector((state) =>
    hermesAiSelectors.citationsForMessage(state, messageId),
  );

  if (!citations || !citations.length) {
    return null;
  }

  return (
    <div className="tw-my-2 tw-flex tw-flex-wrap tw-gap-2">
      {citations.map((citation, index) => (
        <CitationListItem key={citation.url} citation={citation} index={index} />
      ))}
    </div>
  );
};
