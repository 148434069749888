import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { externalLinkPlugin } from 'src/components/aux/markdown/externalLinkPlugin';

interface SystemMessageProps {
  content: string;
}

export const SystemMessage: React.FC<SystemMessageProps> = ({ content }) => {
  return (
    <div className="h-system-message">
      <ReactMarkdown rehypePlugins={externalLinkPlugin}>{content}</ReactMarkdown>
    </div>
  );
};
