import { Text } from '@polygence/components';

import { PSS_TRACK_PROJECT_TYPES } from 'src/components/hermes/utils';

const displayAll = { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } };

const displayAllExceptLastSession = {
  $and: [
    { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } },
    { $not: { field: '__isLastSession', value: true } },
  ],
};

const displayOnlyLastSession = {
  $and: [
    { $includes: { field: '__projectType', value: PSS_TRACK_PROJECT_TYPES } },
    { $is: { field: '__isLastSession', value: true } },
  ],
};

const generalQuestions = [
  {
    component: 'FormInput',
    props: {
      inputType: 'textarea',
      label: (
        <>
          Please summarize your session in 4-5 sentences.{' '}
          <Text as="span" fontWeight="bold" size="medium">
            Please note that this summary will be visible for your specialist and your parent or
            counselor if either have an account.
          </Text>
        </>
      ),
      name: 'studentSessionSummary',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  {
    component: 'FormInput',
    props: {
      label: 'What were your initial expectations for this session?',
      inputType: 'textarea',
      name: 'initialExpectations',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  {
    component: 'FormInput',
    props: {
      label: 'Based on these expectations, did this session meet them? Why or why not?',
      inputType: 'textarea',
      name: 'initialExpectationsFollowUp',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  {
    component: 'NumberSelector',
    props: {
      label:
        'How useful was your last session in working towards your showcasing goal on a scale of 1-5? 1 being not useful, 5 being very useful',
      name: 'rating',
      required: true,
      startFrom: 0,
      numberCount: 6,
    },
    display: displayAll,
  },
  {
    component: 'FormInput',
    props: {
      label: 'What was or wasn’t useful for you specifically?',
      inputType: 'textarea',
      name: 'ratingFollowUp',
      required: true,
      htmlRequired: true,
    },
    display: displayAll,
  },
  {
    component: 'FormInput',
    props: {
      label: 'How many hours did you spend preparing for this session?',
      name: 'preparationLength',
      inputType: 'input',
      type: 'number',
      min: 0,
      htmlRequired: true,
    },
    display: displayAll,
  },
];

const onlyFirstSessionQuestions = [
  {
    component: 'NumberSelector',
    props: {
      label:
        'Polygence puts a lot of effort into matching you with a showcasing specialist that fits your needs. Now that you’ve met your showcasing specialist, how did we do on a scale of 0-10? 0 being the worst, 10 being the best',
      name: 'matchRating',
      required: true,
      startFrom: 0,
      numberCount: 11,
    },
    display: {
      $is: { field: '__sessionNumber', value: 1 },
    },
  },
];

const allExceptLastSessionQuestions = [
  {
    component: 'FormInput',
    props: {
      label: 'Was the showcasing planning template useful for you? Why or why not?',
      inputType: 'textarea',
      name: 'showcasingTemplateUsefulness',
      required: true,
      htmlRequired: true,
    },
    display: displayAllExceptLastSession,
  },
];

const onlyLastSessionQuestions = [
  {
    component: 'NumberSelector',
    props: {
      label:
        'On a scale of 0-10 (0 being not likely, 10 being very likely) how likely are you to recommend premium showcasing support to another student looking to showcase their polygence project?',
      name: 'pssRecommendation',
      required: true,
      startFrom: 0,
      numberCount: 11,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'NumberSelector',
    props: {
      label:
        'On a scale of 0-3 (0 being not satisfied at all, 3 being very satisfied) How satisfied were you with premium showcasing support?',
      name: 'pssSatisfaction',
      required: true,
      startFrom: 0,
      numberCount: 4,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label:
        'Are there any next steps to showcase your project? Any unfinished work that wasn’t accomplished in your sessions together?',
      inputType: 'textarea',
      name: 'anyNextSteps',
      required: true,
      htmlRequired: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label:
        'We know there’s always room for improvement, what can Polygence do to make showcasing support even better for future students?',
      inputType: 'textarea',
      name: 'feedbackToPolygence',
      required: true,
      htmlRequired: true,
    },
    display: displayOnlyLastSession,
  },
  {
    component: 'FormInput',
    props: {
      label: 'How would you sum up your showcasing support experience?',
      name: 'pssOverallExperience',
      inputType: 'textarea',
    },
    display: displayOnlyLastSession,
  },
];

export const premiumShowcasingQuestions = [
  ...onlyFirstSessionQuestions,
  ...generalQuestions,
  ...allExceptLastSessionQuestions,
  ...onlyLastSessionQuestions,
  {
    component: 'FormInput',
    props: {
      label: 'How could we have improved your experience of the session today?',
      name: 'content',
      inputType: 'textarea',
    },
    display: displayAllExceptLastSession,
  },
];
