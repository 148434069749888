import { hermesAiSelectors } from '@polygence/common/store/reducers/hermesAiReducer';
import { cn } from '@polygence/components';

import { MessageCount } from 'src/components/hermes-ai/messages/toolbar/MessageCount';
import { ReactToMessage } from 'src/components/hermes-ai/messages/toolbar/ReactToMessage';
import { ShareMessage } from 'src/components/hermes-ai/messages/toolbar/ShareMessage';
import { useAppSelector } from 'src/store';

export const MessageToolbar = ({
  show,
  messageCount,
  maxNumberOfMessages,
  messageId,
}: {
  show: boolean;
  messageCount: number | null;
  maxNumberOfMessages: number | null;
  messageId?: number | null;
}) => {
  const selectedThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);

  return (
    <div
      className={cn(
        'tw-flex tw-gap-2 tw-opacity-0 tw-transition-opacity group-hover/message:tw-opacity-100',
        show && 'tw-opacity-100',
      )}
    >
      <MessageCount count={messageCount} maxNumberOfMessages={maxNumberOfMessages} />
      {!!messageId && !!selectedThreadId && (
        <>
          <ReactToMessage messageId={messageId} reaction="thumbs-up" threadId={selectedThreadId} />
          <ReactToMessage
            messageId={messageId}
            reaction="thumbs-down"
            threadId={selectedThreadId}
          />
          <ShareMessage messageId={messageId} threadId={selectedThreadId} />
        </>
      )}
    </div>
  );
};
