import { useGetUserSnippetsQuery } from '@polygence/common/api/hermes-ai';
import { hermesAiSelectors } from '@polygence/common/store/reducers/hermesAiReducer';
import { UserSnippetId } from '@polygence/common/types/common';

import { UserSnippetButton } from 'src/components/hermes-ai/user-snippet/UserSnippetButton';
import { UserSnippetPopover } from 'src/components/hermes-ai/user-snippet/UserSnippetPopover';
import { useAppSelector } from 'src/store';

export const UserSnippet = ({ id }: { id: UserSnippetId }) => {
  const messageThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  const { data } = useGetUserSnippetsQuery(messageThreadId ?? 0, { skip: !messageThreadId });

  const snippet = data?.find((snippet) => snippet.id === id);

  if (!snippet || !messageThreadId) {
    // magic number dimensions, to match the height/width of the actual button
    return <div className="tw-inline-flex tw-h-[33px] tw-w-[224px] tw-align-middle" />;
  }

  return (
    <UserSnippetPopover messageThreadId={messageThreadId} id={id}>
      <UserSnippetButton snippet={snippet} />
    </UserSnippetPopover>
  );
};
