import type {
  SimpleUser,
  SimpleUserWithAdmissionAdvisorDetails,
  SimpleUserWithContactDetails,
  UserType,
} from '../../types/backend';
import type {
  ProspectiveUserSources,
  ProspectiveUserStatusType,
  SimpleUserSerializer,
} from '../../types/data/backend';
import type { SearchParams, StringProjectStatus, StringProjectType } from '../../types/data/common';
import type {
  AdmissionAdvisorModalSerializer,
  StudentOwnProfileSerializer,
  UserCardPartnerTag,
} from '../../types/data/marketplace';
import type { FunnelStep, LeadQualificationStep } from '../../types/data/sales';
import type { PartnerShipArrangementDiscountSerializer } from '../../types/data/studentApplication';
import type { Nullable } from '../../types/utils';
import type { FeedbackRequestContent } from '../../types/zeus';
import type { Profile } from '../analytics';
import type {
  AdminWelcomePageId,
  AdmissionAdvisorModalId,
  CohortId,
  ConfigurationId,
  CounselorProfileId,
  CourseId,
  DateTime,
  DiscountId,
  EmailId,
  FeatureRequestId,
  FeedbackRequestId,
  HubSpotTransactionalEmailId,
  InstitutionId,
  InterestId,
  JSONObject,
  KeywordId,
  MentorProfileId,
  MentorRequestId,
  PartnerChannelId,
  PartnerCompanyId,
  PartnerTagId,
  PartnershipArrangementId,
  PaymentIntentId,
  ProductId,
  ProjectId,
  ProjectRequestId,
  ProjectRequestsGroupId,
  ProjectTypeId,
  ProspectiveUserId,
  ResearchHubId,
  ResearchPaperId,
  ResourceId,
  RevenueSharePaymentRequestId,
  RoomId,
  ScriptLogId,
  SessionId,
  Slug,
  StudentApplicationId,
  StudentProfileId,
  SymposiumId,
  TableauReportId,
  TagId,
  TimeZone,
  UUID,
  UserId,
  WorkspaceGroupId,
  WorkspaceId,
} from '../common';
import type {
  Lounge,
  Message,
  Project,
  ProjectStatus,
  ProjectTypes,
  Room,
  SessionCountAs,
  WorkspaceTypes,
} from '../hermes';
import type {
  OwnMentorProfile,
  PremiumShowcasingSupportMatchingRequestStatuses,
} from '../marketplace';
import type { TypeOfPartnership } from '../partnerships';
import type {
  PaymentIntentOrdering,
  PaymentIntentStatuses,
  PaymentIntentTargets,
  PaymentIntentTypes,
  StripeCustomer,
} from '../payment';

import type { ProjectSessionSerializer } from './analytics';
import type { FeedbackReviewerSerializer, MessageSerializer } from './hermes';
import type { FrequentlyAskedQuestionSerializer } from './landing';
import type {
  NoteSerializer,
  PermanentFileSerializer,
  StudentApplicationV2Serializer,
  StudentScholarPageSelectSerializer,
  TagSelectorSerializer,
  TagSerializer,
  TempFileSerializer,
} from './marketplace';
import { PaymentIntentReadOnlyAdminSerializer } from './payment';
import type {
  AdminSimpleUserProfileSerializer,
  MessageTicketUserStatus,
  ParticipantSerializer,
  UserWithProfileIdAndPictureSerializer,
} from './user';

export type AdmissionAdvisorConcerns =
  | 'no_concerns'
  | 'price'
  | 'time_commitment'
  | 'program_fit'
  | 'not_the_right_time'
  | 'alternative_program'
  | 'other';

export type AdmissionAdvisorStudentsInterests =
  | 'no_interests'
  | 'college_admissions'
  | 'recommendation_letters'
  | 'mental_health'
  | 'non_traditional_activities'
  | 'publish_opportunities'
  | 'pursue_passion'
  | 'student_engagement'
  | 'other_interests';

export type PingTypes = 'talk' | 'schedule' | 'submit_summary' | 'not_on_track';
export type Pings = Partial<Record<PingTypes, string[]>>;

export enum ApplicationRejectionReasons {
  WEAK = 'weak',
  NOT_CONTACTED = 'not_contacted',
  DELAYED = 'delayed',
}

export interface StudentProfileOverviewSerializer extends StudentOwnProfileSerializer {
  userId: UserId;
  channel: string;
  channel_label: string;
  counselor_id: Nullable<CounselorProfileId>;
  counselor_details: {
    first_name: string;
    last_name: string;
    channel: string;
    email: string;
    company_name: string;
    partnership_arrangement_name: string;
  };
  screening_interview_note: Nullable<NoteSerializer>;
  profile_completion_date: Nullable<DateTime>;
  application_last_accepted_at: DateTime;
  admission_advisor_opinion: string;
  computer_science_language_proficiencies: string;
  computer_science_completed_programs_ap_comp_sci_a: boolean;
  computer_science_completed_programs_ap_comp_sci_principles: boolean;
  computer_science_completed_programs_ai: boolean;
  computer_science_completed_programs_usaco: boolean;
  science_experiment_considers_other_option: boolean;
  science_experiment_description: string;
  science_experiment_materials: string;
  science_experiment_location: string;
  science_experiment_can_conduct_from_home: boolean;
  dismissed_from_partner_connect_at: Nullable<DateTime>;
}

export interface ProjectOverviewSerializer {
  admission_advisor_workflow: boolean;
  admission_team_contact: boolean;
  automated_enrollment: boolean;
  automated_mentor_payments: boolean;
  details_enabled: boolean;
  details: Record<string, unknown>;
  expected_end_at: Nullable<string>;
  expected_start_at: string;
  extras: Record<string, unknown>;
  final_student_survey_enabled: boolean;
  final_student_survey_required_for_zoom_join: boolean;
  follow_up_enabled: boolean;
  id: ProjectId;
  is_showcasing_matching_form_enabled: boolean;
  mentor_cost: number;
  mentor_rate: number;
  mentor_request: Nullable<MentorRequestId>;
  mentors: OwnMentorProfile[];
  milestones_enabled: boolean;
  paused_until: Nullable<string>;
  pings: Record<UserId, Pings>;
  premium_showcasing_addon_purchase_enabled: boolean;
  premium_showcasing_support_purchased_at: Nullable<string>;
  project_request_interest_id: Nullable<number>;
  questionnaire: Record<string, unknown>;
  recent_messages: Message[];
  recording_opt_out: boolean;
  rematch: boolean;
  reviews_paid_for: number;
  reviews_remaining: number;
  room: Room;
  sessions_paid_for: number;
  sessions_remaining: number;
  sessions?: ProjectSessionSerializer;
  status: ProjectStatus;
  students: Profile[];
  surveys_required_for_1st_session_for_zoom_join: boolean;
  test_project: boolean;
  type: ProjectTypes;
  uuid: UUID;
  workspace_id: WorkspaceId;
}

export interface BaseAdminProjectSerializer {
  id: ProjectId;
  mentors: AdminSimpleUserProfileSerializer[];
  students: AdminSimpleUserProfileSerializer[];
  is_paid: boolean;
  sessions_remaining: number;
  expected_end_at: Nullable<string>;
  mentor_request: Nullable<MentorRequestId>;
  pending_extended_deadline: Nullable<DateTime>;
}

export interface AdminProjectSerializer extends BaseAdminProjectSerializer {
  id: ProjectId;
  admission_team_contact: boolean;
  last_messages: MessageSerializer[];
  last_session_submitted: Nullable<AdminSimpleSessionSerializer>;
  last_session_scheduled: Nullable<AdminSimpleSessionSerializer>;
  next_session_scheduled: Nullable<AdminSimpleSessionSerializer>;
  payment_intent_id: Nullable<UUID>;
  pings: Record<string, unknown>;
  room_id: RoomId;
  snoozed_at: Nullable<string>;
  workspace_id: WorkspaceId;
  title: string;
  reviews_remaining: number;
  type: StringProjectType;
  questionnaire: Record<string, unknown>;
}

export interface AdminSimpleSessionSerializer {
  id: SessionId;
  count_as: SessionCountAs;
  session_value: number;
  session_number: number;
  scheduled_at: DateTime;
  proposing: boolean;
}

export interface SimpleUserAdminSerializer extends SimpleUserSerializer {}

export interface ConnectableStudentSerializer {
  id: StudentProfileId;
  full_name: string;
  email: string;
  created: DateTime;
  channel: string;
  usherer: SimpleUserAdminSerializer;
}

export type ProjectRequestStatuses =
  | 'requested'
  | 'declined'
  | 'cancelled'
  | 'fulfilled'
  | 'pending_payment';

export const ProjectRequestsGroupStatuses = {
  REQUESTED: 'requested',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  FULFILLED: 'fulfilled',
  PENDING_PAYMENT: 'pending_payment',
} as const;

export type ProjectRequestsGroupStatus =
  (typeof ProjectRequestsGroupStatuses)[keyof typeof ProjectRequestsGroupStatuses];

export type ProjectRequestsGroupTypes =
  | 'pathfinder_ppp'
  | 'pathfinder_launchpad_asp'
  | 'pathfinder_launchpad'
  | 'pathfinder_standalone';

export interface ProjectRequestsGroupCreateSerializer {
  id: ProjectRequestsGroupId;
  type: ProjectRequestsGroupTypes;
  students: StudentProfileId[];
  interests: InterestId[];
  project_requests_limit: number;
}

export interface InterestSerializer {
  id: InterestId;
  required_subject_tags: number[];
  optional_subject_tags: number[];
  keywords: number[];
  notes: string;
  required_subject_tag_names: string[];
  keyword_details: KeywordSerializer[];
}

export interface UserProfileSerializer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture: string;
  time_zone: string;
  owner_id: UserId;
  channel: string;
}

export interface StudentProfileWithParentInfoSerializer extends UserProfileSerializer {
  parent_first: string;
  parent_last: string;
  parent_phone: string;
  parent_email: string;
}

export interface ProjectRequestProjectSerializer {
  title: string;
  workspace_url: string;
}

export interface ProjectRequestListSerializer {
  id: ProjectRequestId;
  status: ProjectRequestStatuses;
  is_fulfilled: boolean;
  interest: InterestSerializer;
  students: UserProfileSerializer[];
  mentors: UserProfileSerializer[];
  project: Nullable<ProjectRequestProjectSerializer>;
  project_type: ProjectTypes;
}

export interface ProjectRequestSerializer {
  status: ProjectRequestStatuses;
  interest: InterestId;
  students: StudentProfileId[];
  mentors: MentorProfileId[];
  project_type: ProjectTypeId;
  project: Nullable<ProjectId>;
  workspace: Nullable<WorkspaceId>;
  metadata: Record<string, string | number>;
  declined_at: Nullable<DateTime>;
  canceled_at: Nullable<DateTime>;
  fulfilled_at: Nullable<DateTime>;
  rematched_at: Nullable<DateTime>;
}

export interface ProjectRequestUpdatePayloadSerializer {
  interest: InterestId;
  students: StudentProfileId[];
  mentors: MentorProfileId[];
}

export interface ProjectRequestStartProjectPayloadSerializer {
  project_title: string;
}

export interface ProjectRequestStartProjectResponseSerializer {
  fulfilled: boolean;
}

export interface ProjectRequestsGroupListSerializer {
  id: ProjectRequestsGroupId;
  status: ProjectRequestsGroupStatus;
  type: string;
  students: UserProfileSerializer[];
  project_requests_limit: number;
}

export interface ProjectRequestsGroupDetailsSerializer {
  id: ProjectRequestsGroupId;
  status: ProjectRequestsGroupStatus;
  project_requests: ProjectRequestListSerializer[];
  type: ProjectRequestsGroupTypes;
  students: UserProfileSerializer[];
  interests: InterestId[];
  program_expectation: Nullable<string>;
  output_expectation: Nullable<string>;
  project_requests_limit: number;
}

export type WorkspaceGroupStatuses = 'active' | 'completed';
export type WorkspaceGroupTypes = 'pathfinder';
export type WorkspaceGroupFinanceTypes =
  | 'Pathfinder Launchpad'
  | 'Pathfinder ASP'
  | 'Pathfinder PPP'
  | 'Pathfinder Standalone';

export type CoreResponse = 'yes' | 'no' | 'maybe';

export interface WorkspaceGroupListSerializer {
  id: WorkspaceGroupId;
  status: WorkspaceGroupStatuses;
  type: WorkspaceGroupTypes;
  finance_type: WorkspaceGroupFinanceTypes;
  student: Nullable<StudentProfileWithParentInfoSerializer>;
  ppp_counselor_name: string;
  pam_usher_name: string;
  workspaces: WorkspaceGroupWorkspaceSerializer[];
  created_at: Nullable<DateTime>;
  updated_at: Nullable<DateTime>;
  metadata: Record<string, string>;
  mentor_response_to_core: Nullable<CoreResponse>;
}

export interface WorkspaceGroupWorkspaceSerializer {
  id: WorkspaceGroupId;
  link: string;
  project_id: ProjectId;
  project_title: string;
  project_type: ProjectTypes;
  project_status: ProjectStatus;
  project_started_at: Nullable<DateTime>;
  project_due_date: DateTime;
  mentor: UserProfileSerializer;
  interest_id: Nullable<InterestId>;
  mentor_response_to_core: Nullable<CoreResponse>;
}

export interface ProjectRequestsGroupListParams {
  search?: string;
  status?: string;
  type?: string;
  page?: number;
  page_size?: number;
}

export interface WorkspaceGroupListParams {
  search?: string;
  status?: string;
  finance_type?: string;
  page?: number;
  page_size?: number;
}

export interface PendingSubjectCountSerializer {
  id: number;
  subject_tag: string;
  count: number;
}

export interface KeywordSerializer {
  id: KeywordId;
  value: string;
}

export interface PodsRegisteredStudentSerializer {
  student: StudentProfileSerializer;
  payment_intent: PaymentIntentSerializer;
}

export interface PodsCourseListSerializer {
  id: CourseId;
  title: string;
  slug: string;
  tag_details?: TagSelectorSerializer;
  image?: TempFileSerializer[] | PermanentFileSerializer[];
  active: boolean;
  spring_break: boolean;
}

export interface PodsCourseDetailsSerializer extends PodsCourseListSerializer {
  active: boolean;
  description: string;
  short_title: string;
  overview: string;
  outcome: string;
  faqs: FrequentlyAskedQuestionSerializer[];
  meta_title: string;
  meta_description: string;
  week_by_week_overview: string[];
  outcome_type: number;
  tag: number;
  price_in_cents: number;
  product_id: ProductId;
  spring_break: boolean;
}

export interface PodsCohortListSerializer {
  id: CohortId;
  course: Omit<PodsCourseListSerializer, 'slug'>;
  workspace_id: string;
  start_date: string;
  active: boolean;
  current_group_size: number;
  maximum_group_size: number;
}

export interface PodsCohortDetailsSerializer extends PodsCohortListSerializer {
  number_of_sessions: number;
  current_group_size: number;
  price: number;
  active: boolean;
  sign_up_deadline: string;
  maximum_group_size: number;
  minimum_group_size: number;
  pods_registered_students: PodsRegisteredStudentSerializer[];
  project_request: ProjectRequestListSerializer;
  mentor_bio: string;
}

export interface PodsCohortCreateSerializer {
  active: boolean;
  course: number;
  start_date: string;
  number_of_sessions: number;
  minimum_group_size: number;
  maximum_group_size: number;
  mentors: number[];
}

export interface ConfigurationSerializer {
  id: ConfigurationId;
  key: string;
  value: JSONObject;
}

export interface RoomListSerializer {
  id: RoomId;
  unseen_messages_count: number;
  participants: ParticipantSerializer[];
  messages: Message[];
  messages_count: number;
  notification_enabled: boolean;
  workspace_id: WorkspaceId;
  unsentMessages: Partial<Message>[];
  roomType: WorkspaceTypes | ProjectTypes;
}

export interface RoomAdminListSerializer extends Omit<RoomListSerializer, 'participants'> {
  participants: UserWithProfileIdAndPictureSerializer[];
}

export interface TableauReportSerializer {
  id: TableauReportId;
  title: string;
  description: string;
  url: string;
}

export interface WorkspaceAdminListSerializer {
  id: WorkspaceId;
  name: string;
}

export interface WorkspaceAdminDetailsSerializer extends WorkspaceAdminListSerializer {
  room: RoomAdminListSerializer;
  project: Nullable<Project>;
  lounge: Nullable<Lounge>;
  permissions: string[];
  disabled: boolean;
  zoom_disabled: boolean;
  operation_user: Nullable<SimpleUserAdminSerializer>;
}

export interface StudentPipelineApplicationSerializer {
  id: StudentApplicationId;
}

export interface SymposiumSerializer {
  id: SymposiumId;
  title: string;
  participant_details: StudentScholarPageSelectSerializer[];
  participants: number[];
}

export interface AdmissionAdvisorStudentApplicationSerializer
  extends StudentApplicationV2Serializer {
  id: StudentApplicationId;
  user_id: UserId;
  first_name: string;
  last_name: string;
  profile_picture: string;
  channel: string;
  email: string;
  channel_label: string | null;
  counselor: CounselorProfileId;
  screening_interview_note: Nullable<NoteSerializer>;
  profile_completion_date: Nullable<DateTime>;
  application_last_accepted_at: DateTime;
  admission_advisor_opinion: string;
  user_status: string;
  admission_advisor_concerns: AdmissionAdvisorConcerns[];
  admission_advisor_concern_other: string;
  admission_advisor_students_interests: AdmissionAdvisorStudentsInterests[];
  admission_advisor_students_interest_other: string;
  admission_advisor: SimpleUserAdminSerializer;
  is_partner_pays_workflow: boolean;
  pitchable_product_ids: number[];
}

export interface StudentApplicationAccept {
  price: number;
  possibleRejection?: boolean;
  invoiceDescription: string;
  notes: string;
  keywords: number[];
  additionalSubjectTags: number[];
  mustHaveSubjectTags: number[];
}

export interface ProspectiveStudentSerializer {
  id: ProspectiveUserId;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  status: ProspectiveUserStatusType;
  counselor_email: string;
  channel: string;
  usherer: SimpleUserWithAdmissionAdvisorDetails;
  hubspot_contact_id: Nullable<string>;
  original_source: ProspectiveUserSources;
  source: ProspectiveUserSources;
  registration_url: string;
}

export interface CounselorProfileSerializer {
  pk: CounselorProfileId;
  first_name: string;
  last_name: string;
  channel: string;
  company_name_from_profile: string;
  email: string;
  company_name: Nullable<string>;
  user_id: UserId;
  profile_picture: string;
  contact_owner: SimpleUserAdminSerializer;
  phone_number: Nullable<string>;
  partnership_arrangement: string;
  partner_since: string;
  usherer: Nullable<UserId>;
  nps_value: Nullable<number>;
  is_preferred_partner: boolean;
  retired_at: Nullable<DateTime>;
  discounts: DiscountId[];
}

export interface ProspectiveCounselorSerializer {
  id: ProspectiveUserId;
  first_name: string;
  last_name: string;
  email: string;
  company_name: Nullable<string>;
  phone_number: Nullable<string>;
  is_preferred_partner: boolean;
  status: ProspectiveUserStatusType;
  account_create_url: string;
  original_source: ProspectiveUserSources;
  source: ProspectiveUserSources;
}

export interface CounselorOverviewSerializer extends CounselorProfileSerializer {
  average_student_nps: Nullable<number>;
  students_north_star_metric: Nullable<number>;
  total_number_of_url_clicks: Nullable<number>;
  average_mentor_ratings: Nullable<number>;
  is_partner_pays_workflow: Nullable<boolean>;
  total_revenue_by_current_year: string;
  total_revenue_by_current_quarter: string;
  total_revenue: string;
  referral_students_by_current_quarter: number;
  referral_students_by_current_year: number;
  enrolled_students_count: number;
  enrolled_students_by_current_quarter: number;
  enrolled_students_by_current_year: number;
  retired_at: Nullable<DateTime>;
  partner_tags: UserCardPartnerTag[];
}

export interface PartnershipArrangementSerializer {
  id: PartnershipArrangementId;
  name: string;
  room_price: Nullable<number>;
  email_template: string;
  partner_pays_workflow: boolean;
  type_of_partnership: TypeOfPartnership;
  discounts: DiscountId[];
}

export interface PartnerTagSerializer {
  id: PartnerTagId;
  name: string;
  label: string;
  definition: string;
  severity: 'medium' | 'high';
}

export interface PartnerChannelSerializer {
  pk: PartnerChannelId;
  tag: string;
  company_name: string;
  partnership_arrangement: PartnerShipArrangementDiscountSerializer;
}

export interface MessageTicketSerializer {
  created_by: UserId;
  hermes_workspace_id: Nullable<WorkspaceId>;
  old_room: RoomId;
  hermes_room: RoomId;
  assignee: UserId;
  seen_by: UserId;
  read_by: UserId;
  completed_at: DateTime;
  messages: JSONObject;
  user_type: UserType;
  user_status: MessageTicketUserStatus;
}

export interface EmailModelSerializer {
  id: EmailId;
  recipient: Nullable<string>;
  subject: Nullable<string>;
  django_url: string;
  sent_at: DateTime;
  copies: string;
}

export interface HubSpotTransactionalEmailSerializer {
  id: HubSpotTransactionalEmailId;
  recipient: string;
  subject?: string;
  django_url: string;
  copies: string[];
  email_template_url: string;
  hubspot_template_url: string;
  sent_at?: string;
  created_date: string;
  fail_message: string;
}

export interface AdminWelcomePageSerializer {
  welcome_page: {
    id: AdminWelcomePageId;
    markdown: string;
  };
  mentors_needs_approval: number;
  all_approved_mentors: number;
  applications_to_review: number;
  applications_to_match_core: number;
  applications_to_match_pf: number;
  applications_to_match_pss: number;
  writing_feedback_requests: number;
  case_study_reviews: number;
  payments_in_last_7_days: number;
  silent_users: number;
}

export interface SimpleProjectSerializer {
  id: ProjectId;
  sessions_paid_for: number;
  sessions_remaining: number;
  status: ProjectStatus;
  expected_end_at: string;
  created_at: string;
  completed_at: string;
  terminated_at: string;
  type: StringProjectType;
}

export interface PaymentIntentSerializer {
  id: PaymentIntentId;
  uuid: UUID;
  editable: boolean;
  base_amount: string;
  amount: string;
  cart_uuid: UUID;
  currency: string;
  custom_history: {
    changes: Record<string, string>;
    other: Record<string, string | number | boolean>;
    recorded_at: string;
  }[];
  description: string;
  extra: Record<string, unknown>;
  feature_type_change_by_user: boolean;
  first_payment_received_at: DateTime;
  installment_count: number;
  invoice_created_at: Nullable<string>;
  invoice_due_at: Nullable<string>;
  issued_by: SimpleUserAdminSerializer;
  issued_to_international: boolean;
  issued_to_name: string;
  issued_to_type: UserType | 'partner';
  issued_to_url: string;
  issued_to: StripeCustomer;
  is_wire_transfer_enabled: boolean;
  last_payment_received_at: Nullable<string>;
  next_due_amount: string;
  next_due_at: string;
  paid_amount: string;
  payment_count: number;
  payment_failed_at: Nullable<string>;
  payment_url: string;
  refunded_amount: string;
  refunded_by: Nullable<SimpleUserAdminSerializer>;
  revenue_share_percentage: Nullable<number>;
  risk_premium: string;
  selected_products: Nullable<ProductId[]>;
  should_send_revenue_share: boolean;
  status: PaymentIntentStatuses;
  stripe_customer_url: string;
  student_projects: SimpleProjectSerializer[];
  target: PaymentIntentTargets;
  transfer_started_at: Nullable<DateTime>;
  type: PaymentIntentTypes;
}

export interface PaymentIntentReadOnlyAdminListSerializer
  extends PaymentIntentReadOnlyAdminSerializer {
  due_amount: string;
  next_due: DateTime;
}

export interface PaymentIntentAdminFilterParams extends SearchParams {
  ordering?: PaymentIntentOrdering;
  status?: PaymentIntentStatuses;
  target?: PaymentIntentTargets;
  type?: PaymentIntentTypes;
  due_amount__gt?: string;
  due_amount__lt?: string;
  next_due__gt?: DateTime;
  next_due__lt?: DateTime;
  failed_only?: boolean;
  due_only?: boolean;
}

type FeatureRequestStatuses = 'requested' | 'completed';

export interface FeatureRequestSerializer {
  id: FeatureRequestId;
  completed_by: Nullable<string | UserId>;
  requested_by: string;
  title: string;
  description: string;
  liked_by_users: UserId[];
  submitted_by: UserId;
  status: FeatureRequestStatuses;
  created_at: DateTime;
}

export interface PartnerCompanyAdminSerializer {
  tag: string;
  company_name: string;
  payment_email: string;
  partner_pays_workflow: boolean;
  automated_revenue_share_payment: boolean;
  partner_type: string;
  partner_source: number;
  partnership_arrangement: PartnershipArrangementId;
  pitchable_products: ProductId[];
  contact?: UserId;
  usherer: UserId;
  skip_five9: boolean;
  logo?: string;
  logo_uuid?: UUID;
  discounts: DiscountId[];
  show_scholarship: boolean;
  partner_tags: PartnerTagId[];
  mou_agreement: boolean;
  disabled: boolean;
  show_counselor_in_welcome_email: boolean;
  lead_qualification_step: LeadQualificationStep;
}

export interface PartnerCompanyAdminListSerializer extends PartnerCompanyAdminSerializer {
  id: PartnerCompanyId;
  company_name: string;
  created_date: DateTime;
  arrangement: string;
  registered_counselors_count: number;
  referred_students_count: number;
  quarterly_referred_students_count: number;
  enrolled_students_count: number;
  quarterly_enrolled_students_count: number;
  enrolled_students_ratio: number;
  usherer_display: SimpleUser;
  contact_display: SimpleUserWithContactDetails;
  nps_value_cumulated: number;
  polygence_preferred_partner: boolean;
}

export interface RevenueSharePaymentRequestAdminListSerializer {
  id: RevenueSharePaymentRequestId;
  incoming_amount_in_cents: number;
  amount_to_pay_in_cent: number;
  partner_company: PartnerCompanyAdminSerializer;
  outgoing_payment: PaymentIntentSerializer;
  payment_intent: PaymentIntentSerializer;
  status: string;
  failed_at: string;
  successful_at: string;
  created_at: string;
  student: StudentProfileSerializer;
  revenue_share_percentage: number;
}

export interface UserPermissionUpdateSerializer {
  is_intern: boolean;
  is_active: boolean;
  is_test: boolean;
}

export interface ResearchHubListSerializer {
  id: ResearchHubId;
  subject: string;
  slug: Slug;
  image_url: string;
  sub_title: string;
}

export interface ResearchHubSerializer {
  description: string;
  id: ResearchHubId;
  image_url: string;
  main_subject_tag: TagId;
  main_subject_tag_details: TagSelectorSerializer;
  slug: Slug;
  sub_title: string;
  subject: string;
  executed_after_create: boolean;
  created_at: DateTime;
  updated_at: string;
  contentful_body_id: string;
  additional_Articles: string;
}

export interface PartnerCompanyAdminUpdateSerializer {
  discounts: DiscountId[];
}

export interface ProjectOverviewListSerializer {
  id: ProjectId;
  uuid: UUID;
  status: StringProjectStatus;
  student_first_name: Nullable<string>;
  student_last_name: Nullable<string>;
  student_profile_picture: Nullable<string>;
  type: StringProjectType;
  mentor_name: string;
}

interface StudentProfileSerializer {
  id: StudentProfileId;
  email: string;
  first_name: string;
  last_name: string;
  profile_picture: string;
  time_zone: TimeZone;
  owner_id: UserId;
}
export interface MentorProfileSerializer {
  id: StudentProfileId;
  email: string;
  first_name: string;
  last_name: string;
  profile_picture: string;
  time_zone: TimeZone;
  owner_id: UserId;
}

export interface PremiumShowcasingSupportMatchingSerializer {
  uuid: UUID;
  status: PremiumShowcasingSupportMatchingRequestStatuses;
  requester: StudentProfileSerializer;
  project_type: ProjectTypes;
  mentor_profile?: Nullable<MentorProfileSerializer>;
  mentor_profile_id: Nullable<MentorProfileId>;
  must_have_tags: TagSerializer[];
  previous_mentors: MentorProfileSerializer[];
  previous_mentor_ids: MentorProfileId[];
  proposed_at: Nullable<DateTime>;
  accepted_at: Nullable<DateTime>;
  declined_at: Nullable<DateTime>;
  rematch_requested_at: Nullable<DateTime>;
  workspace: WorkspaceId;
  extras: Record<string, unknown>;
  submitted_at: DateTime;
  subject_tags: string;
  expected_project_outcome: string;
  selected_showcasing_opportunities: string;
  additional_writing_support: string;
  timeline: string;
  project: Nullable<AdminProjectSerializer>;
  preferred_showcasing_specialties: string[];
  response: Record<
    number,
    {
      mentor_name: string;
      [key: string]: unknown;
    }
  >;
  competition?: string;
  participation?: string;
  competition_subject?: string;
  competition_deadline?: string;
  additional_information?: string;
}

export interface PremiumShowcasingSupportMentorProposingSerializer {
  id: MentorProfileId;
  full_name: string;
  email: string;
  readable_showcasing_specialties: string;
  active_pss_workspace_count: number;
  subject_tags: TagSelectorSerializer[];
  owner_id: UserId;
}

export interface WritingFeedbackMentorProposingSerializer {
  id: MentorProfileId;
  user_id: UserId;
  full_name: string;
  email: string;
  in_progress_request_count: number;
  finished_request_count: number;
  tags: TagSelectorSerializer[];
}

export interface ProposedPathfinderMentorSerializer {
  // proposed_pathfinder_mentor_serializer
  id: MentorProfileId;
  name: string;
  email: string;
  user_id: UserId;
  primary_tag: TagSerializer;
  subject_tags: TagSerializer[];
  keywords: KeywordSerializer[];
  number_of_active_projects: number;
}

export interface ProjectRequestProposedMentorsSerializer {
  proposed_mentors: ProposedPathfinderMentorSerializer[];
  created_at: Nullable<DateTime>;
}

export interface DiscountAdminSerializer {
  id: DiscountId;
  created_at: DateTime;
  flat_amount_in_cents: number;
  percentage_amount: number;
  product_name: string;
  is_fixed_price_discount: boolean;
}

export interface AdmissionAdvisorModalAdminSerializer extends AdmissionAdvisorModalSerializer {
  assigned_to_count: number;
  deactivated_at: DateTime;
  id: AdmissionAdvisorModalId;
  is_assignable: boolean;
  is_tenured: boolean;
  monthly_revenue_goal_in_cents: number;
  user_id: UserId;
}

export interface ScriptLogSerializer {
  id: ScriptLogId;
  status: 'pending' | 'running' | 'success' | 'failed';
  logs: Record<string, unknown>;
  name: string;
  run_by: SimpleUserAdminSerializer;
  created_at: DateTime;
}

interface CompletedSection {
  completed_at: DateTime;
  section: string;
}

export interface StudentPipelineRegistrationApplicationSerializer {
  accepted_at: Nullable<DateTime>;
  id: StudentApplicationId;
  updated_at: DateTime;
  last_interaction_at: DateTime;
  scholarship_applicant: boolean;
  aa_call_scheduled_at: Nullable<DateTime>;
  aa_call_end_at: Nullable<DateTime>;
  aa_call_canceled: boolean;
  completed_sections: CompletedSection[];
}

export interface F9DispositionEventSerializer {
  call_ended_at: DateTime;
  campaign_name: string;
  disposition_name: string;
}

export const referralAttribution = {
  'partner IEC': 'Partner IEC',
  'non-partner IEC': 'Non-partner IEC',
  paid_social: 'Paid social',
  paid_search: 'Paid search',
  organic_search: 'Organic search',
  organic_social: 'Organic social',
  word_of_mouth_friend: 'Word of mouth',
  symposium: 'Symposium',
  parent: 'Parent',
  youtube_influencer: 'YouTube influencer',
  student_referral: 'Student referral',
  mentor_referral: 'Mentor referral',
  other: 'Other',
  unknown: 'Unknown',
} as const;

export type ReferralAttribution = keyof typeof referralAttribution;

export interface StudentPipelineRegistrationSerializer {
  admission_advisor: Nullable<SimpleUserAdminSerializer>;
  channel: string;
  created_at: DateTime;
  email: string;
  five9_calls_counter: number;
  five9_history: { event: string; created_at: DateTime; details: string }[];
  five9_last_disposition: Nullable<F9DispositionEventSerializer>;
  five9_synced: boolean;
  five9_current_list_name: string;
  full_name: string;
  id: StudentProfileId;
  is_international: Nullable<boolean>;
  phone_number: string;
  profile_picture: string;
  referral_attribution: ReferralAttribution;
  student_application: Nullable<StudentPipelineRegistrationApplicationSerializer>;
  hubspot_contact_id: Nullable<string>;
  funnel_step: FunnelStep;
}

export interface SymposiumPresentersUpdateView {
  email: string;
  has_student_profile: boolean;
  has_scholar_page: boolean;
  has_active_or_completed_projects: boolean;
  has_project_title: boolean;
}

export interface SymposiumPresentersBulkUploadValuesSerializer {
  errors: SymposiumPresentersUpdateView[];
  addedScholarPages: {
    id: number;
    full_name: string;
    email: string;
  }[];
}

export interface PartnerSourceSerializer {
  id: number;
  source_name: string;
  description: string;
}

export interface CounselorDiscountUpdateSerializer {
  discounts: DiscountId[];
}

export interface TaskList {
  [key: string]: number;
}

export interface TaskMonitoringData {
  default: TaskList;
  high: TaskList;
  hubspot: TaskList;
  gpt: TaskList;
}

export interface FeedbackRequestProjectMentorSerializer {
  full_name: string;
}

export interface FeedbackRequestContentSerializer {
  feedback_subject?: string;
  citation_style?: string;
  google_doc_url?: string;
  pages?: string;
  wanted_topics_grammar_english?: boolean;
  wanted_topics_paper_structure?: boolean;
  wanted_topics_citation_style?: boolean;
  wanted_topics_statistics?: boolean;
  wanted_topics_other?: boolean;
  most_wanted_topics_other?: string;
  purpose_of_paper?: string;
  paper_content?: string;
  paper_description?: string;
}

export interface FeedbackRequestListSerializer {
  content: FeedbackRequestContent;
  created_at: string;
  first_name: string;
  id: FeedbackRequestId;
  uuid: UUID;
  last_name: string;
  mentors: FeedbackRequestProjectMentorSerializer[];
  profile_id: StudentProfileId;
  profile_picture: string;
  project_deadline: string;
  reviewers: FeedbackReviewerSerializer[];
  previous_reviewers: FeedbackReviewerSerializer[];
  time_zone: string;
  workspace_id: WorkspaceId;
  pending_extended_deadline: DateTime | null;
  user_id: UserId;
  feedback_file_url: Nullable<string>;
  matched_reviewer: Nullable<SimpleUser>;
  must_have_tags: TagSelectorSerializer[];
  status: 'unhandled' | 'pending' | 'ready_for_review' | 'completed';
}

export interface InstitutionSerializer {
  id: InstitutionId;
  label: string;
  value: string;
  enabled: boolean;
  student_college_choice_count: number;
  mentor_profile_count: number;
}

export interface CaseStudyUpdateSerializer {
  student_picture: string;
  project_image: string;
  description: string;
  student_description: string;
  student_review: string;
  mentor_review: string;
  project_outcome: string;
}

export interface ResourceSerializer {
  id: ResourceId;
  type: string;
  public_url: string;
  metadata: Record<string, unknown>;
}

export interface ResearchPaperListAdminSerializer {
  id: ResearchPaperId;
  title: string;
  authors: string[];
  publisher: string;
  abstract: string;
  published_at: Nullable<string>;
  document_url: Nullable<string>;
  shared_by: SimpleUserSerializer[];
  doi: string;
  resource: ResourceSerializer;
  sharedInWorkspaces: WorkspaceId[];
  similarity: Nullable<number>;
}
