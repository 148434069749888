import { Text } from '@polygence/components';

import { ApplicationHubCard } from 'src/components/student/ApplicationHubPage/ApplicationHubCard';

export const LoginToWorkspaceStep = ({ partnerPaysWorkflow }: { partnerPaysWorkflow: boolean }) => {
  return (
    <ApplicationHubCard narrow step={3} status="unavailable">
      <Text size="medium" fontWeight="bold">
        Login To Workspace
      </Text>
      <Text size="medium">
        Once {partnerPaysWorkflow ? 'enrolled' : 'accepted'}, this will be your go-to place to
        access your mentor
      </Text>
    </ApplicationHubCard>
  );
};
