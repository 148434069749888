import { commonReducers, commonHooks } from '@polygence/common';
import { Button } from '@polygence/components';
import { useDispatch } from 'react-redux';

import {
  calculateModalStatus,
  buttonTexts,
} from 'src/components/hermes/additional-session/AdditionalSessionModalStatus';

export const AdditionalSession = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(commonReducers.additionalSessionActions.openAdditionalSessionModal());
  };
  const { userType } = commonHooks.useCurrentUser();
  const project = commonHooks.useSelectedProject();
  const modalStatus = calculateModalStatus(userType, project?.additionalSessions);
  const buttonText = buttonTexts[modalStatus];
  const isMentorRespond = modalStatus === 'mentor_respond';

  if (modalStatus === 'hidden') {
    return null;
  }

  return (
    <Button
      type="button"
      size="sm"
      variant={isMentorRespond ? 'primary' : 'secondary'}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};
