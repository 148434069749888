import { commonReducers, commonHooks } from '@polygence/common';
import * as hermesApi from '@polygence/common/api/hermes';
import { Button, Icon } from '@polygence/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { isStudent } from 'src/components/getMyInfo';
import { ConfirmMentorNoShowModal } from 'src/components/hermes/sessions/mentor-no-show/ConfirmMentorNoShowModal';
import useIsPastDateTime from 'src/hooks/useIsPastDateTime';
import { dayjs } from 'src/utils/dayjsCustom';

const NO_SHOW_MINIMUM_WAIT_TIME_IN_MINUTES = 10;

const ReportMentorNoShow = ({ meeting }) => {
  const { id: meetingId, scheduledAt, mentorNoShows = [] } = meeting;
  const currentUser = commonHooks.useCurrentUser();
  const [confirmNoShowModalOpen, setConfirmNoShowModalOpen] = useState(false);
  const dispatch = useDispatch();

  // When rescheduling, we reuse the same meeting object, so it's possible to have multiple no show reports for the
  // same meeting. We need to check if there is one for the currently scheduled time.
  const mentorNoShow = mentorNoShows.find((noShow) => {
    return noShow.scheduledAt === scheduledAt;
  });
  const showMentorNoShowButton = isStudent(currentUser.userType) && !mentorNoShow;

  const timeWhenReportNoShowShouldBeEnabled = dayjs(scheduledAt)
    .add(NO_SHOW_MINIMUM_WAIT_TIME_IN_MINUTES, 'minutes')
    .toISOString();
  const isPastNoShowTime = useIsPastDateTime({
    dateTime: timeWhenReportNoShowShouldBeEnabled,
  });

  const enableMentorNoShowButton = showMentorNoShowButton && isPastNoShowTime;

  const reportMentorNoShow = (comment) => {
    hermesApi
      .reportMentorNoShow(meetingId, { comment })
      .then(() => {
        dispatch(commonReducers.hermesActions.openNoShowReportedModal());
        setConfirmNoShowModalOpen(false);
      })
      .catch(() => {
        toast.error('Error while reporting no show.');
      });
  };

  if (showMentorNoShowButton) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
      <>
        <ReactTooltip
          id={`no-show-${meetingId}`}
          effect="solid"
          type="dark"
          border
          disable={enableMentorNoShowButton}
        >
          Please wait at least {NO_SHOW_MINIMUM_WAIT_TIME_IN_MINUTES} mins after session start time
          before reporting.
        </ReactTooltip>
        <Button
          type="button"
          variant="link"
          size="sm"
          startIcon={<Icon id="info" data-tip data-for={`no-show-${meetingId}`} />}
          onClick={() => {
            setConfirmNoShowModalOpen(true);
          }}
          disabled={!enableMentorNoShowButton}
        >
          Report no show
        </Button>
        <ConfirmMentorNoShowModal
          isOpen={confirmNoShowModalOpen}
          onConfirm={reportMentorNoShow}
          onClose={() => setConfirmNoShowModalOpen(false)}
        />
      </>
    );
  }

  return isStudent(currentUser.userType) && mentorNoShow ? (
    <p className="text-danger my-2">Mentor no show reported.</p>
  ) : null;
};

ReportMentorNoShow.propTypes = {
  meeting: PropTypes.shape({
    id: PropTypes.number,
    scheduledAt: PropTypes.string,
    mentorNoShows: PropTypes.arrayOf(PropTypes.any),
  }),
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ReportMentorNoShow;
