import { useGetMessagesQuery, usePrefetch } from '@polygence/common/api/hermes-ai';
import useSelectedWorkspaceId from '@polygence/common/hooks/selectors/useSelectedWorkspaceId';
import {
  hermesAiActions,
  hermesAiSelectors,
} from '@polygence/common/store/reducers/hermesAiReducer';
import {
  Icon,
  Sheet,
  Text,
  SheetClose,
  SheetContent,
  SheetHeader,
  icons,
  HamburgerMenu,
} from '@polygence/components';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch, useHistory } from 'react-router';

import { HermesAIMessageList } from 'src/components/hermes-ai/HermesAIMessageList';
import { HermesAIThreadSelector } from 'src/components/hermes-ai/HermesAIThreadSelector';
import { HermesAIMessageEditor } from 'src/components/hermes-ai/editor/HermesAIMessageEditor';
import { useHermesAIErrorNotification } from 'src/components/hermes-ai/useHermesAIErrorNotification';
import { useAppDispatch, useAppSelector } from 'src/store';

const Sparkles = icons.sparkles;

const NoAIMessageThreadSelected = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="tw-flex tw-grow tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-overflow-y-auto tw-p-8"
    >
      <Sparkles size="60" className="tw-text-gray-400" />
      <Text fontWeight="semibold" alignment="center" size="large" className="tw-text-gray-500">
        Project Assistant
      </Text>
      <Text size="medium" fontWeight="normal" alignment="center" className="tw-text-gray-500">
        Got stuck? Ask anything about your project or the Polygence program in general.
      </Text>
      <div className="tw-h-1 tw-w-12 tw-bg-gray-200" />
      <ul className="tw-m-0 tw-list-inside tw-list-none tw-font-light tw-text-gray-400">
        <li className="mb-2">... what resource did my mentor recommend in the last session?</li>
        <li className="mb-2">... what task should I focus on next?</li>
        <li className="mb-2">... what is Polygence's policy on XYZ?</li>
      </ul>
      <div className="tw-h-1 tw-w-12 tw-bg-gray-200" />

      <Text size="medium" fontWeight="light" alignment="center" className="tw-text-gray-400">
        This conversation is visible to all users in the workspace.
      </Text>
    </motion.div>
  );
};

const HermesAIConversation = () => {
  const { messageThreadId } = useParams<{ messageThreadId: string }>();
  const messageThreadIdNumber = Number(messageThreadId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hermesAiActions.setSelectedThreadId(messageThreadIdNumber));
    return () => {
      dispatch(hermesAiActions.setSelectedThreadId(null));
    };
  }, [messageThreadIdNumber, dispatch]);

  return <HermesAIMessageList aiThreadId={messageThreadIdNumber} />;
};

const HermesAISheetHeader = () => {
  const selectedWorkspaceId = useSelectedWorkspaceId();
  const messageThreadId = useAppSelector(hermesAiSelectors.selectedThreadId);
  const { conversationTitle } = useGetMessagesQuery(messageThreadId ?? 0, {
    skip: !messageThreadId,
    selectFromResult: ({ data }) => ({ conversationTitle: data?.conversationTitle ?? '' }),
  });
  const isThreadSelectorOpen = useAppSelector(hermesAiSelectors.isThreadSelectorOpen);
  const dispatch = useAppDispatch();
  const prefetch = usePrefetch('listMessageThreads');

  const prefetchMessageThreads = () => {
    if (!selectedWorkspaceId) {
      return;
    }

    prefetch(selectedWorkspaceId ?? 0);
  };

  const toggleThreadSelector = () => {
    dispatch(hermesAiActions.toggleThreadSelector());
  };

  return (
    <SheetHeader className="tw-flex tw-items-center tw-justify-between">
      <div className="tw-flex tw-w-12 tw-grow-0 tw-items-center tw-justify-start">
        <SheetClose className="tw-flex tw-size-8 tw-appearance-none tw-items-center tw-justify-center tw-rounded-full tw-border-none tw-bg-transparent tw-outline-none hover:tw-bg-gray-200">
          <Icon id="x" />
          <span className="tw-sr-only">Close</span>
        </SheetClose>
      </div>
      <Text
        className="tw-grow tw-justify-self-center"
        size="medium"
        alignment="center"
        fontWeight="bold"
      >
        {messageThreadId ? conversationTitle || 'New conversation' : null}
      </Text>
      <div className="tw-flex tw-w-12 tw-grow-0 tw-items-center tw-justify-end">
        <button
          type="button"
          className="tw-m-0 tw-appearance-none tw-border-none tw-bg-transparent tw-p-0"
          aria-label="Select conversations"
          title="Conversations"
          onClick={toggleThreadSelector}
          onMouseEnter={prefetchMessageThreads}
          onPointerDown={prefetchMessageThreads}
        >
          <HamburgerMenu
            open={isThreadSelectorOpen}
            color="currentColor"
            className="tw-text-gray-600"
          />
        </button>
      </div>
    </SheetHeader>
  );
};

export const HermesAISheet = ({ previousUrl }: { previousUrl: string }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  useHermesAIErrorNotification();

  useEffect(() => {
    return () => {
      dispatch(hermesAiActions.closeThreadSelector());
    };
  }, [dispatch]);

  return (
    <Sheet
      open
      modal={false}
      onOpenChange={(open) => {
        !open && history.push(previousUrl);
      }}
    >
      <SheetContent
        className="tw-z-[1000] tw-border-0 tw-border-l-[1px] tw-border-solid tw-border-gray-200 tw-bg-gray-100 tw-shadow-2xl tw-drop-shadow-2xl"
        header={<HermesAISheetHeader />}
        onInteractOutside={(e) => {
          if (!e.target) {
            return;
          }

          if (
            (e.target as HTMLElement).classList?.contains('.Toastify__toast-container') ||
            (e.target as HTMLElement).closest?.('.Toastify__toast-container')
          ) {
            e.preventDefault();
          }
        }}
      >
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-pile tw-w-full tw-grow tw-overflow-x-hidden tw-overflow-y-scroll">
            <Switch>
              <Route path={`${path}/:messageThreadId`} component={HermesAIConversation} />
              <Route path={path} component={NoAIMessageThreadSelected} />
            </Switch>
            <HermesAIThreadSelector />
          </div>
          <HermesAIMessageEditor />
        </div>
      </SheetContent>
    </Sheet>
  );
};
