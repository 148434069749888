import { Button, Heading, Text } from '@polygence/components';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfirmationModalContext } from 'src/components/common/ConfirmationModal';
import lateCancelSvg from 'src/components/hermes/sessions/late-cancel.svg';

interface LateCancelModalProps {
  onCancel: () => void;
  resourceLateCancelPolicy: string;
  supportTeamEmail: string;
}

export const LateCancelModal: React.FC<LateCancelModalProps> = ({
  onCancel: handleCancel,
  resourceLateCancelPolicy,
  supportTeamEmail,
}) => {
  const { closeModal } = useContext(ConfirmationModalContext);

  return (
    <div className="d-flex flex-column p-4 p-sm-7">
      <div className="text-center mb-7">
        <img src={lateCancelSvg} alt="Time management" />
      </div>
      <Heading size="h4" className="mb-7">
        You are about to forfeit a session less than 24 hours before it is scheduled.
      </Heading>
      <Text size="medium" className="mb-7 text-center">
        To be respectful of our mentors’ time and as per our No Show Policy, this means you will be
        forfeiting this session. You will therefore have one fewer session in your program. Here’s a
        kind reminder of our{' '}
        <Link target="_blank" to={resourceLateCancelPolicy}>
          company policy
        </Link>
        .
      </Text>
      <div className="mb-8 text-center">
        <Button className="whitespace-normal" variant="danger" onClick={handleCancel}>
          I understand and would like to forfeit the session
        </Button>
        <Button variant="link" onClick={closeModal}>
          Keep my scheduled session
        </Button>
      </div>
      <Text size="small" className="text-center">
        We understand emergencies come up. If you feel this is an extenuating circumstance, please
        email <a href={`mailto:${supportTeamEmail}`}>{supportTeamEmail}</a> to let us know the
        situation. We’ll review whether we can re-instate the session based on your situation.
      </Text>
    </div>
  );
};
