import { ProjectPauseRequestStatuses, dayjs, type ProjectPauseRequest } from '@polygence/common';
import { Button, RadioButton } from '@polygence/components';
import React, { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';

import { FormInputWithValidation as FormInput } from 'src/components/FormInputWithValidation';
import {
  DatePickerChangeTarget,
  DatePickerWithLabel,
} from 'src/components/common/DatePickerWithLabel';

interface ProjectPauseFormProps {
  data?: ProjectPauseRequest;
  onSubmit?: (data: Pick<ProjectPauseRequest, 'pauseUntil' | 'reasonForPause'>) => void;
  onRespond?: (data: Pick<ProjectPauseRequest, 'status' | 'reasonForDecline'>) => void;
  onClose: (reasonForDecline: string) => void;
  acceptText?: string;
  cancelText?: string;
  declineVariant?: 'secondary' | 'danger';
  readOnly?: boolean;
  loading?: boolean;
  actionsVisible?: boolean;
  mentorResponding?: boolean;
}

export const ProjectPauseForm: React.FC<ProjectPauseFormProps> = ({
  data: defaultData,
  onSubmit,
  onRespond,
  onClose,
  acceptText = '',
  cancelText = '',
  declineVariant = 'secondary',
  readOnly = false,
  loading = false,
  actionsVisible = true,
  mentorResponding = false,
}) => {
  const [data, setData] = useState({
    pauseUntil: defaultData?.pauseUntil,
    reasonForPause: defaultData?.reasonForPause,
    reasonForDecline: undefined,
    status: undefined,
  });

  const submitDisabled = !data.pauseUntil || !data.reasonForPause;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      mentorResponding &&
      onRespond &&
      data.status &&
      (data.status === ProjectPauseRequestStatuses.ACCEPTED || data.reasonForDecline)
    ) {
      onRespond({
        status: data.status,
        reasonForDecline: data.reasonForDecline,
      });
    } else if (onSubmit && data.pauseUntil && data.reasonForPause) {
      onSubmit({
        pauseUntil: data.pauseUntil,
        reasonForPause: data.reasonForPause,
      });
    }
  };

  const handleClose = () => {
    if (mentorResponding && data.reasonForDecline) {
      onClose(data.reasonForDecline);
    } else {
      onClose('');
    }
  };

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement> | DatePickerChangeTarget) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Form name="project-pause-request-form" onSubmit={handleSubmit}>
      <DatePickerWithLabel
        id="pauseUntil"
        name="pauseUntil"
        label="Until when would you like to pause the project?"
        value={data.pauseUntil}
        onChange={handleChange}
        placeholder="No value set"
        disabled={readOnly}
        minDate={new Date()}
        maxDate={dayjs().add(30, 'days').toDate()}
        required
      />
      <FormInput
        inputType="textarea"
        type="textarea"
        name="reasonForPause"
        label="Why do you need to pause the project?"
        value={data.reasonForPause}
        onChange={handleChange}
        isDisabled={readOnly}
        htmlRequired
        small_font="Note: All answers in this request will be shared with students, parents, and mentors verbatim."
      />
      {mentorResponding && (
        <>
          <p>Do you accept this pause request?</p>
          <RadioButton
            label="Accept"
            checked={data.status === ProjectPauseRequestStatuses.ACCEPTED}
            name="status"
            value={ProjectPauseRequestStatuses.ACCEPTED}
            onChange={() => {
              handleChange({
                target: { name: 'status', value: ProjectPauseRequestStatuses.ACCEPTED },
              });
            }}
          />
          <RadioButton
            label="Decline"
            checked={data.status === ProjectPauseRequestStatuses.DECLINED}
            name="status"
            value={ProjectPauseRequestStatuses.DECLINED}
            onChange={() => {
              handleChange({
                target: { name: 'status', value: ProjectPauseRequestStatuses.DECLINED },
              });
            }}
          />
          {data.status === ProjectPauseRequestStatuses.DECLINED && (
            <FormInput
              inputType="textarea"
              type="textarea"
              name="reasonForDecline"
              label="Why are you declining the pause request?"
              value={data.reasonForDecline || ''}
              onChange={handleChange}
              htmlRequired
            />
          )}
        </>
      )}
      {actionsVisible && (
        <div className="d-flex justify-content-end gap-5">
          <Button variant={declineVariant} onClick={handleClose} disabled={loading}>
            {cancelText}
          </Button>
          <Button type="submit" variant="primary" disabled={submitDisabled}>
            {acceptText}
          </Button>
        </div>
      )}
    </Form>
  );
};
