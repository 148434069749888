import { Checkbox, Spacer, Text, Tooltip, cn } from '@polygence/components';
import { motion } from 'framer-motion';

interface TodoListItemProps {
  title: string;
  description: string;
  manual: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const TodoListItem = ({
  title,
  description,
  manual,
  checked,
  onChange,
}: TodoListItemProps) => {
  const tip = (
    <Text size="small" alignment="center" className="tw-text-gray-50">
      {!manual && '[Completion verified by system]'}
      {!manual && <Spacer size={2} />}
      {description}
    </Text>
  );
  return (
    <Tooltip placement="top" tip={tip} trigger={['hover', 'focus']} delay={{ show: 700, hide: 0 }}>
      <motion.li
        initial={false}
        animate={{ opacity: 1, height: 'auto' }}
        exit={{ opacity: 0, height: 0 }}
        className={cn(
          'tw-relative tw-mb-3 tw-flex tw-w-full tw-select-none tw-items-center tw-justify-between tw-gap-3 tw-overflow-hidden',
          'has-[:checked]:*:tw-text-gray-300 has-[:checked]:*:tw-line-through',
          '[&_.label-text]:tw-text-gray-800',
        )}
      >
        <Checkbox
          label={title}
          name="todo"
          size="small"
          checked={checked}
          onChange={(e) => onChange?.(e.currentTarget.checked)}
          disabled={!manual}
        />
      </motion.li>
    </Tooltip>
  );
};
