import { ConfirmProposalClickableState } from 'src/components/hermes/sessions/actions/ConfirmProposalClickableState';
import { DisabledProposeState } from 'src/components/hermes/sessions/actions/DisabledProposeState';
import { DisabledScheduleState } from 'src/components/hermes/sessions/actions/DisabledScheduleState';
import { DisabledSubmitSummaryState } from 'src/components/hermes/sessions/actions/DisabledSubmitSummaryState';
import { DoneState } from 'src/components/hermes/sessions/actions/DoneState';
import { LateCancelledState } from 'src/components/hermes/sessions/actions/LateCancelledState';
import { MeetingInsightState } from 'src/components/hermes/sessions/actions/MeetingInsightState';
import { MeetingVideoAvailableState } from 'src/components/hermes/sessions/actions/MeetingVideoAvailableState';
import { NoActionState } from 'src/components/hermes/sessions/actions/NoActionState';
import { NoShowState } from 'src/components/hermes/sessions/actions/NoShowState';
import { OfficiallyScheduledState } from 'src/components/hermes/sessions/actions/OfficiallyScheduledState';
import { ProposeClickableState } from 'src/components/hermes/sessions/actions/ProposeClickableState';
import { ScheduleClickableState } from 'src/components/hermes/sessions/actions/ScheduleClickableState';
import { SubmitFeedbackClickableState } from 'src/components/hermes/sessions/actions/SubmitFeedbackClickableState';
import { SubmitSummaryClickableState } from 'src/components/hermes/sessions/actions/SubmitSummaryClickableState';
import { WaitingForResponseState } from 'src/components/hermes/sessions/actions/WaitingForResponseState';

export const states = {
  ConfirmProposalClickableState,
  DisabledSubmitSummaryState,
  DoneState,
  MeetingVideoAvailableState,
  MeetingInsightState,
  NoActionState,
  NoShowState,
  ProposeClickableState,
  ScheduleClickableState,
  SubmitFeedbackClickableState,
  SubmitSummaryClickableState,
  DisabledScheduleState,
  DisabledProposeState,
  WaitingForResponseState,
  OfficiallyScheduledState,
  LateCancelledState,
};
