import {
  projectIssueQuestions,
  techIssueQuestions,
} from 'src/components/hermes/surveys/mentor/generalQuestions';
import { MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK } from 'src/constants';

export const researchCoachQuestions = [
  {
    component: 'Box',
    props: {
      children: [
        {
          component: 'ComponentsText',
          props: {
            children: 'Report a no-show:',
            size: 'medium',
            fontWeight: 'bold',
            className: 'mb-3',
          },
        },
        {
          component: 'ComponentsRadioButton',
          validation: {
            required: true,
          },
          props: {
            name: 'countAs',
            label: 'This was a full session (Approx 45 minutes)',
            value: 'full_session',
            className: 'mb-3',
          },
        },
        {
          component: 'ComponentsRadioButton',
          validation: {
            required: true,
          },
          props: {
            name: 'countAs',
            label: "The student didn't attend (no-show)",
            value: 'no_show',
            className: 'mb-3',
          },
        },
      ],
    },
  },
  {
    component: 'StarRating',
    props: {
      label: `Rate your conversation on a scale of 1-5 stars (1 - didn't seem helpful, student not engaged, 5 - super helpful, student was engaged):`,
      name: 'studentConversationRating',
      starCount: 5,
      required: true,
    },
    display: {
      $and: [{ $not: { field: 'countAs', value: 'no_show' } }],
    },
  },
  {
    component: 'MarkdownEditor',
    labelVariables: ['__sessionNumber'],
    props: {
      label: 'Session {{__sessionNumber}} summary',
      name: 'content',
      inputType: 'textarea',
      small_font: `What type of session was this (College Ask Me Anything or Project Coaching). What topics did you talk about? Any pending action items?`,
      required: true,
      htmlRequired: true,
      editorStyle: {
        minHeight: '180px',
        padding: '0 5px',
      },
      placeholder: `I gave you feedback on…`,
    },
    display: {
      $and: [{ $not: { field: 'countAs', value: 'no_show' } }],
    },
  },
  ...techIssueQuestions,
  ...projectIssueQuestions,
  {
    component: 'Paragraph',
    props: {
      text: `You are always welcome to book a meeting with us [here](${MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK}) to discuss anything: issues, strategies, etc`,
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
] as const;
