import { commonHooks } from '@polygence/common';
import { Text } from '@polygence/components';
import classNames from 'classnames';

import { formatDatetimeAsLocal } from 'src/components/aux/dateStamp';

interface MentorRescheduledProposedSessionProps {
  content: string;
  extra: {
    scheduledAt: string;
    sessionId: number;
  };
}

const MentorRescheduledProposedSession = ({
  content,
  extra: { scheduledAt, sessionId },
}: MentorRescheduledProposedSessionProps) => {
  const project = commonHooks.useSelectedProject();
  const session = project?.sessions?.find(({ id }) => {
    return id === sessionId;
  });
  const sessionCanceled = !session;
  const formattedDate = formatDatetimeAsLocal(scheduledAt);
  const splitContent = content.split(scheduledAt)[0] || '';
  const formattedContent = `${splitContent} ${formattedDate}.`;

  return (
    <div
      data-cy="session-proposal-response"
      className={classNames('h-proposed-session-message', {
        'text-line-through': sessionCanceled,
      })}
    >
      <Text size="small" fontWeight="bold">
        {formattedContent}
      </Text>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default MentorRescheduledProposedSession;
